import React, { useEffect } from "react";
import { SnackbarProvider } from "notistack";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import BackUpContextProvider from "./context/Backupdata";
import RoleContextProvider from "./context/RoleContext";
import LeadReportProvider from "./context/LeadReport";

import L3Provider from "./components/pages/L3/L3Context";


import { Provider } from "react-redux";
import store from "./redux/store";
const root = createRoot(document.getElementById("root"));

const RenderApplication = () => {
  useEffect(() => {
    function adjustFontSize() {
      if (window.innerWidth >= 1440) {
        document.documentElement.style.fontSize = "1.1vw";
        document.body.style.fontSize = "1.1vw";
      } else {
        document.documentElement.style.fontSize = "";
        document.body.style.fontSize = "";
      }
    }

    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    // Fix refresh issue by ensuring styles apply after initial render
    setTimeout(() => {
      adjustFontSize();
    }, 100);

    return () => window.removeEventListener("resize", adjustFontSize);
  }, []);

  // function adjustFontSize() {
  //   if (window.innerWidth >= 1440) {
  //     document.documentElement.style.fontSize = "1.1vw"; // Adjusted for better scaling
  //     document.body.style.fontSize = "1.1vw";
  //   }
  // }

  // useEffect(() => {
  //   adjustFontSize();
  //   window.addEventListener("resize", adjustFontSize);
  //   return () => window.removeEventListener("resize", adjustFontSize);
  // }, []);

  // const { role: userRoleValue } = useRole();
  // const userRole = userRoleValue || sessionStorage.getItem("userRole");
  return (
    <Provider store={store}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <App />
      </SnackbarProvider>
    </Provider>
  );
};
root.render(
  <Provider store={store}>
    <BackUpContextProvider>
      <RoleContextProvider>
        <LeadReportProvider>
          <L3Provider>
            <RenderApplication />
          </L3Provider>
        </LeadReportProvider>
      </RoleContextProvider>
    </BackUpContextProvider>
  </Provider>
);

reportWebVitals();
