import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { fetchStatus } from "../../../../redux/slices/statusSlice"; 

const QuotedMeanL2 = ({ quotedMeanDataL2 }) => {
  const dropdownRefService = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefService.current &&
        !dropdownRefService.current.contains(event.target)
      ) {
        setShowStatusDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const userInteracted = useRef(false);
  const dispatch = useDispatch();

  const [filteredLeadReports, setFilteredLeadReports] = useState([]);

  useEffect(() => {
    const filteredLeads = quotedMeanDataL2.filter(
      (lead) => lead.Status === "Won" || lead.Status === "Won Recurring"
    );
    setFilteredLeadReports(filteredLeads);
  }, [quotedMeanDataL2]);

  const [showStatusDropdown, setShowStatusDropdown] = useState("");
  const [TypeStatus, setTypeStatus] = useState("All Status");
  const [statusTypes, setStatuses] = useState([]);
  const [showStatusTypeMessage, setShowStatusTypeMessage] = useState(false);

  const { status } = useSelector((state) => state.status);

  useEffect(() => {
    dispatch(fetchStatus());
  }, [dispatch]);

  useEffect(() => {
    if (status.length > 0) {
      const FilteredStatus = status.filter(
        (status) => status.status === "Won" || status.status === "Won Recurring"
      );
      setStatuses(FilteredStatus);
    }
  }, [status]);

  const handleStatusChange = (status) => {
    const value = status;
    userInteracted.current = true;

    if (status === "All Status") {
      setTypeStatus("All Status"); // Set "All" as selected
    } else {
      setTypeStatus(value); // Otherwise, set selected status
    }
    // dispatch(setMeanStatusRedux(value))
    setShowStatusDropdown(false);
    setShowStatusTypeMessage(false);
  };

  const [meanData, setMeanData] = useState([]);
  const [countN, setCountN] = useState(0);
  console.log("meanData", meanData);
  console.log("meanData countN", countN);

  // useEffect(() => {
  //   const applyFilters = () => {
  //     let updatedLeads = [...filteredLeadReports].sort(
  //       (a, b) => a.QuotedValue - b.QuotedValue
  //     );
  //      if(TypeStatus) {
  //       updatedLeads = updatedLeads.filter(
  //         (lead) => lead.Status === TypeStatus
  //       );

  //       setMeanData(updatedLeads);
  //     }

  //     setCountN(updatedLeads.length);
  //   };

  //   applyFilters();
  // }, [TypeStatus, filteredLeadReports]);
  useEffect(() => {
    const applyFilters = () => {
      let updatedLeads = [...filteredLeadReports].sort(
        (a, b) => a.QuotedValue - b.QuotedValue
      );
  
      // If TypeStatus is "All Status", show all leads
      if (TypeStatus !== "All Status") {
        updatedLeads = updatedLeads.filter(
          (lead) => lead.Status === TypeStatus
        );
      }
  
      setMeanData(updatedLeads);
      setCountN(updatedLeads.length);
    };
  
    applyFilters();
  }, [TypeStatus, filteredLeadReports]);
  
  

  const [totalQuotedValue, setTotalQuotedValue] = useState(0);
  console.log("meanData totalQuotedValue", totalQuotedValue);
  const [meanValue, setMeanValue] = useState(0);
  console.log("meanData meanValue", meanValue);
  const [S1, setS1] = useState(0);
  console.log("meanData S1", S1);
  const [S2, setS2] = useState(0);
  console.log("meanData S2", S2);
  const [s1Value, setS1Value] = useState(0);
  console.log("meanData s1Value", s1Value);
  const [s2Value, setS2Value] = useState(0);
  console.log("meanData s2Value", s2Value);
  const [median, setMedian] = useState(0);
  console.log("meanData median", median);
  const [quotedArray, setQuotedArray] = useState([]);
  console.log("meanData quotedArray", quotedArray);
  const [medianMinusData, setMedianMinusData] = useState([]);
  console.log("meanData medianMinusData", medianMinusData);
  const [squaredArray, setSquaredArray] = useState([]);
  console.log("meanData squaredArray", squaredArray);
  const [sumSquare, setSumSquare] = useState(0);
  console.log("meanData sumSquare", sumSquare);
  const [sd, setSd] = useState();
  console.log("meanData sd", sd);
  const [sdSquareSIGMA, setSdSquareSIGMA] = useState();
  console.log("meanData sdSquareSIGMA", sdSquareSIGMA);
  const [, setVariance] = useState();
  // console.log('meanData variance',variance);
  const [resultArray, setResultArray] = useState([]);
  console.log("meanData resultArray", resultArray);

  // useEffect(() => {
  //   if (meanValue && sdSquareSIGMA) {
  //     const arr = [
  //       meanValue - 3 * sdSquareSIGMA,
  //       meanValue - 2 * sdSquareSIGMA,
  //       meanValue - sdSquareSIGMA,
  //       meanValue,
  //       meanValue + sdSquareSIGMA,
  //       meanValue + 2 * sdSquareSIGMA,
  //       meanValue + 3 * sdSquareSIGMA,
  //     ].map(customRound); // Apply customRound to each element

  //     setResultArray(arr);
  //   }
  // }, [meanValue, sdSquareSIGMA]);
    useEffect(() => {
      if (meanValue && sdSquareSIGMA !== undefined) {
        const arr =
          sdSquareSIGMA === 0
            ? Array(7).fill(meanValue) // If sdSquareSIGMA is 0, return an array of meanValue
            : [
                meanValue - 3 * sdSquareSIGMA,
                meanValue - 2 * sdSquareSIGMA,
                meanValue - sdSquareSIGMA,
                meanValue,
                meanValue + sdSquareSIGMA,
                meanValue + 2 * sdSquareSIGMA,
                meanValue + 3 * sdSquareSIGMA,
              ].map(customRound);
    
        setResultArray(arr);
      }
    }, [meanValue, sdSquareSIGMA]);

  const customRound = (num) => {
    const decimal = num - Math.floor(num);
    if (decimal <= 0.5) {
      return Math.floor(num);
    } else {
      return Math.ceil(num);
    }
  };

  useEffect(() => {
    if (meanData.length === 0) {
      setTotalQuotedValue(0);
      setMeanValue(0);
      setS1(0);
      setS2(0);
      setS1Value(0);
      setS2Value(0);
      setMedian(0);
      setQuotedArray([]);
      setMedianMinusData([]);
      setSquaredArray([]);
      setSumSquare(0);
      setSd(0);
      setSdSquareSIGMA(0);
      setResultArray([]);
      setVariance(0);
      return;
    }

    const total = meanData.reduce((acc, curr) => {
      return acc + Number(curr.QuotedValue); // convert to number
    }, 0);
    setTotalQuotedValue(total);

    const Mean = customRound(total / countN);
    setMeanValue(Mean);

    const S1 = customRound(countN / 2);
    setS1(S1);
    const S2 = customRound(countN / 2 + 1);
    setS2(S2);

    const index1 = S1 - 1;
    const index2 = S2 - 1;

    const valueS1 = meanData[index1]?.QuotedValue || 0;
    const valueS2 = meanData[index2]?.QuotedValue || 0;
    setS1Value(valueS1);
    setS2Value(valueS2);

    const median = customRound((valueS1 + valueS2) / 2);
    setMedian(median);

    const quotedValuesArray = meanData.map((item) => Number(item.QuotedValue));
    setQuotedArray(quotedValuesArray);

    const medianMinusArray = quotedValuesArray.map((value) =>
      customRound(value - median)
    );
    setMedianMinusData(medianMinusArray);

    const squaredArray = medianMinusArray.map((num) => num * num);
    setSquaredArray(squaredArray);

    const totalSumofSquare = customRound(
      squaredArray.reduce((acc, curr) => acc + curr, 0)
    );
    setSumSquare(totalSumofSquare);

    const SD = customRound(totalSumofSquare / countN);
    setSd(SD);

    const SDSquareSIGMA = customRound(Math.sqrt(SD));
    setSdSquareSIGMA(SDSquareSIGMA);

    const Variance = SDSquareSIGMA * SDSquareSIGMA;
    setVariance(Variance);
  }, [meanData, countN]);

  const chartRefMean = useRef(null);

  useEffect(() => {
    if (!chartRefMean.current) return;
    const formatDollar = (num) => {
      if (num === undefined || num === null) return "$0"; // Default value
      return `${Number(num).toLocaleString("en-US", {
        maximumFractionDigits: 0,
      })}`;
    };

    const myChart = echarts.init(chartRefMean.current);

    // Check if meanData is empty, and display a "No Data Available" message
    if (meanData.length === 0) {
      // const myChart = echarts.init(chartRefMean.current);
      myChart.setOption({
        title: {
          text: "No data available",
          left: "center",
          top: "middle",
          textStyle: {
            fontSize: 19.5,
            fontWeight: "normal",
            color: "#ef4450",
          },
        },
      });
      return;
    }

    const option = {
      grid: {
        left: 10, // Increase this value to add more space on the left
        right: 16, // Keep or adjust as needed
        top: 50, // Adjust top margin if necessary
        bottom: 20, // Adjust bottom margin if necessary
        containLabel: true, // Ensures labels are properly positioned
      },
      title: {
        text: resultArray[3] !== undefined ? `Mean : $${formatDollar(resultArray[3])}` : "N/A",
        left: "center",
        top: "5%",
        textStyle: {
          fontSize: 13,
          fontWeight: "bold",
          color: "black",
        },
      },

      tooltip: {
        trigger: "axis", // Shows tooltip when hovering anywhere near the line
        axisPointer: {
          type: "none",
          show: false,
        },
        show: true,
        formatter: function (params) {
          const index = params[0].dataIndex; // Get the index of hovered point
          const labels = [
            "μ - 3σ",
            "μ - 2σ",
            "μ - σ",
            "μ",
            "μ + σ",
            "μ + 2σ",
            "μ + 3σ",
          ]; // Define labels dynamically
          return `<b>${labels[index]} = </b> ${formatDollar(
            resultArray[index]
          )}`; // Show mapped label and actual value
        },
      },

      xAxis: {
        grid: { display: false },
        type: "category",
        // name: "$ value of opportunities",
        nameLocation: "middle",
        nameGap: 10,
        nameTextStyle: {
          color: "black",
          fontSize: 11,
          fontWeight: "bold",
        },
        axisLabel: { show: false }, //  Hides the x-axis labels (1,2,3,4)
        axisTick: { show: false }, //  Hides the small vertical tick marks
        axisLine: { show: false },
      },
      yAxis: {
        show: true,
        grid: {
          display: false,
        },
        axisLabel: {
          show: false,
        },
        splitLine: { show: false }, // ✅ This removes the horizontal grid lines
      },
      series: [
              {
                name: "Leads",
                data: [0, 30, 80, 150, 80, 30, 0],
                type: "line",
                smooth: true,
                symbol: "none",
                symbolSize: 1,
                lineStyle: {
                  color: "#008631", // Change the line color (border)
                  width: 2, // Adjust thickness if needed
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    // X-axis gradient
                    { offset: 0, color: "#008631" }, // Light Blue (Start)
                    { offset: 0.1, color: "#82b229" }, // Match right-side color
                    { offset: 0.3, color: "#008631" }, // Match right-side color
                    { offset: 0.5, color: "#008631" }, // Middle transition
                    { offset: 0.7, color: "#008631" }, // Match right-side color
                    { offset: 0.9, color: "#82b229" }, // Match right-side color
                    { offset: 1, color: "#008631" }, // Ensure symmetry with start
                  ]),
                },
                markLine: {
                  silent: true,
                  symbol: "none", // No arrow symbols
                  lineStyle: {
                    color: "white",
                    type: "solid",
                    width: 0.5, // Adjust line width
                  },
                  label: {
                    show: false, // Hide labels
                  },
                  data: [
                    { xAxis: 1 }, // Line at 30
                    { xAxis: 2 }, // Line at 80
                    { xAxis: 3 }, // Line at 150
                    { xAxis: 4 }, // Line at 80
                    { xAxis: 5 }, // Line at 30
                  ],
                },
              },
            ],
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [meanData, resultArray]);

  return (
    <div className="ml-2 pl-2 pr-2 rounded-md bg-white px-3">
      <div className=" py-2 ">
        <h1 className="text-2xl mb-2 font-semibold sm:text-sm md:text-sm lg:text-xl xl:text-xl ">
          Revenue Distribution
        </h1>
        <hr className="bg-[#e0e7ff]" />
        <div className="flex justify-start align-middle items-start">
          <div className="w-[50%] p-2">
            <label className="block text-xs" htmlFor="Service">
              Status
            </label>
            <div className="  relative border w-full border-[#e3eaff]  rounded-md">
              <div
                ref={dropdownRefService}
                className="relative   p-0    rounded-xl align-middle items-center"
              >
                <ExpandMoreIcon
                  className="absolute  right-0 cursor-pointer pt-1 bg-white"
                  onClick={() => setShowStatusDropdown(!showStatusDropdown)}
                />

                <button
                  type="button"
                  onClick={() => setShowStatusDropdown(!showStatusDropdown)}
                  className=" flex justify-start items-center align-middle w-full h-full p-1.5 text-nowrap truncate focus:outline-none text-xs"
                >
                  {TypeStatus ? (
                    TypeStatus
                  ) : (
                    <p className="text-xs">All Status</p>
                  )}
                </button>

                {showStatusDropdown && (
                  <div
                    style={{ zIndex: 9999 }}
                    className="absolute top-full left-0 w-full p-1 cursor-pointer max-h-36 overflow-y-auto bg-white text-sm border border-gray-300 shadow-lg flex flex-col gap-1"
                  >

                      {/* Add "All" option */}
    <div
      className="p-1 hover:bg-gray-200 cursor-pointer text-xs"
      onClick={() => handleStatusChange("All Status")}
    >
      All Status
    </div>

                    {statusTypes.map((statusTypes) => (
                      <div
                        className="p-1 hover:bg-gray-200 cursor-pointer text-xs ]"
                        onClick={() => handleStatusChange(statusTypes.status)}
                        key={statusTypes.id}
                        value={statusTypes.status}
                      >
                        {statusTypes.status}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {showStatusTypeMessage && meanData.length === 0 && (
              <p className="text-red-600 text-xs  pl-2">Select Status</p>
            )}
          </div>
        </div>
      </div>

      <div
        ref={chartRefMean}
        style={{ width: "100%", height: "220px" }}
        className="pb-3"
      />
    </div>
  );
};

export default QuotedMeanL2;
