import React from "react";
import { Navigate } from "react-router-dom";
import { useRole } from "../context/RoleContext";

const ProtectedRoute = ({ children, allowedRoles, userRole }) => {
  const { setRole } = useRole();
  setRole(sessionStorage.getItem('userRole'))
  if (allowedRoles?.includes(userRole)) {
    return children;
  }
  return <Navigate to={'/dashboard/'+{userRole}}/>;
};

export default ProtectedRoute;
