import { useSnackbar } from "notistack";
import { createContext, useContext, useState, useEffect } from "react";
import { ValidDate } from "../components/Utils/DateUtils";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeads } from "../redux/slices/leadsSlice";

export const BackUpContext = createContext();

const BackUpContextProvider = ({ children }) => {
   
    const [filteredLeadReports, setFilteredLeadReports] = useState([]);
    const [LeadReports, setLeadReports] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
const dispatch = useDispatch()
    const {leads} = useSelector((state)=> state.leads)

    useEffect(()=>{
dispatch(fetchLeads())
    },[dispatch])

    useEffect(() => {
        const processLeads = () => {
            try {
                const twoYearsAgo = new Date();
                twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2); // Get date 2 years ago
    
                const normalizedData = leads.map((lead) => ({
                        ...lead,
                        LeadDate: ValidDate(lead.LeadDate), // Apply ValidDate function here
                    }))
                    .filter((lead) => new Date(lead.LeadDate) >= twoYearsAgo); // Filter latest 2 years only
    
                setLeadReports(normalizedData);
                setFilteredLeadReports(normalizedData);
            } catch (error) {
                console.error('Error processing leads:', error);
                enqueueSnackbar('Error processing leads!', { variant: 'error' });
            }
        };
    
        processLeads();
    }, [leads, enqueueSnackbar]); // Depend on `leads` so it updates when `leads` changes
    
    const sortedFilteredReports = filteredLeadReports
        .filter((lead) => lead.Status === "Pending" || lead.Status === "Won Recurring")
        .sort((a, b) => {
            const dateA = new Date(a.FollowupDate);
            const dateB = new Date(b.FollowupDate);
            return dateA - dateB; // Ascending order
        });

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    const currentItems = sortedFilteredReports.slice(startIdx, endIdx);

    const totalItems = sortedFilteredReports.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
        <BackUpContext.Provider value={{
            filteredLeadReports, setFilteredLeadReports, sortedFilteredReports,
            currentItems, totalItems, totalPages,
            startIdx, endIdx, itemsPerPage,
            currentPage, setCurrentPage, enqueueSnackbar,
            LeadReports, setLeadReports
        }}>
            {children}
        </BackUpContext.Provider>
    )
}

export const useBackup = () => useContext(BackUpContext);

export default BackUpContextProvider;