import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import "react-phone-input-2/lib/style.css";
import { FaTrash } from "react-icons/fa";
import { MdEditSquare } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmationModal from "./ConfirmationModal";
import L1Sidebar from "./L1Sidebar";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { MdSearch } from "react-icons/md";
import { useLead } from "../../../context/LeadReport";
import { useDispatch } from "react-redux";
import { deleteLead, fetchLeads } from "../../../redux/slices/leadsSlice";

const LeadReport11 = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {potentialReport_11,probabilityReport_11, leadSourceReport_11, dashboardReport_11,
    setPotentialReport_11, setProbabilityReport_11,  setLeadSourceReport_11, setDashboardReport_11,
    historicalL1, setHistoricalL1, historicalTableL1, setHistoricalTableL1
  } = useLead();

  const [originalLeadReportsL1_11, setOriginalLeadReportsL1_11] = useState([]);
  const [filteredLeadReports, setFilteredLeadReports] = useState([]);
  
  useEffect(() => {
    if (originalLeadReportsL1_11.length === 0 && filteredLeadReports.length > 0) {
      setOriginalLeadReportsL1_11(filteredLeadReports); // Store only once when data loads
    }
  }, [filteredLeadReports, originalLeadReportsL1_11]);

useEffect(() => {
  // Prioritize setting the latest available data
  if (leadSourceReport_11.length > 0) {
    setFilteredLeadReports(leadSourceReport_11);
  } else if (probabilityReport_11.length > 0) {
    setFilteredLeadReports(probabilityReport_11);
  } else if (potentialReport_11.length > 0) {
    setFilteredLeadReports(potentialReport_11);
  } else if (dashboardReport_11.length > 0) {
    setFilteredLeadReports(dashboardReport_11);
  } else if(historicalL1.length > 0){
    setFilteredLeadReports(historicalL1)
  }else if(historicalTableL1.length > 0){
    setFilteredLeadReports(historicalTableL1)
  }else {
    setFilteredLeadReports([]); // Clear state if no data is available
  }

  return () => {
    // Reset context state when component unmounts (user leaves the page)
    setPotentialReport_11([]);
    setProbabilityReport_11([]);
    setLeadSourceReport_11([]);
    setDashboardReport_11([]);
    setHistoricalL1([]);
    setHistoricalTableL1([])
  };
  
}, [dashboardReport_11, potentialReport_11, probabilityReport_11, leadSourceReport_11,
  setDashboardReport_11, setLeadSourceReport_11 , setProbabilityReport_11, setPotentialReport_11,
  historicalL1, setHistoricalL1, historicalTableL1, setHistoricalTableL1
]);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [LeadToDelete, setLeadToDelete] = useState(null);
  const openDeleteModal = (id) => {
    setLeadToDelete(id);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setLeadToDelete(null);
  };

  const confirmDelete = async () => {
    if (LeadToDelete) {
      await deleteLeadReport(LeadToDelete); // Call your existing delete function
    }
    closeDeleteModal();
  };


  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    filterLeadReports(query);
    setCurrentPage(1);
  };
  const filterLeadReports = (query) => {
    const lowerCaseQuery = query.toLowerCase().trim(); // Normalize query
    if (!query.trim()) {
      setFilteredLeadReports(originalLeadReportsL1_11); // Restore original data
      return;
  }
  
    const filtered = originalLeadReportsL1_11.filter((LeadReport) =>
      Object.entries(LeadReport).some(([key, value]) => {
        if (key === "comments" || key === "Reference" || key === "ClientContactName"
          || key === "TypeService" || key === "Probability" || key === "ClientPhone" || key==="ClientEmail") return false;   
        if (value !== null && value !== undefined) {
          let formattedValue = value.toString().trim().toLowerCase(); // Trim spaces & normalize
  
          // ✅ Handle Date Formatting (if value is Date object)
          if (value instanceof Date) {
            formattedValue = formatDate(value).trim().toLowerCase();
          }
          // ✅ Handle Date String Formatting (if stored as string)
          else if (typeof value === "string") {
            const dateValue = parseDate(value);
            if (dateValue) {
              formattedValue = formatDate(dateValue).trim().toLowerCase();
            }
          }  
          return formattedValue.includes(lowerCaseQuery);
        }
        return false;
      })
    );
  
    setFilteredLeadReports(filtered);
  };
  
  // Function to parse different date formats into Date object
  const parseDate = (dateStr) => {
    let parsedDate = new Date(dateStr);
  
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate;
    }
  
    // Try to parse manually formatted "DD-MM-YYYY" dates
    const parts = dateStr.split("-");
    if (parts.length === 3) {
      const [day, month, year] = parts.map(Number);
      return new Date(year, month - 1, day);
    }
  
    return null;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };


  const dispatch = useDispatch(); // ✅ Initialize Redux dispatch

  const deleteLeadReport = async (id) => {
  try {
    await dispatch(deleteLead(id)).unwrap(); // ✅ Call Redux action instead of axios
    enqueueSnackbar("Leadreport deleted successfully!", { variant: "success" });
    dispatch(fetchLeads())
    
  } catch (error) {
    console.error("Error:", error);
    enqueueSnackbar("Failed to delete Leadreport!", { variant: "error" });
  }
};

  const [itemsPerPage] = useState(10);
  const totalItems = filteredLeadReports.length;
  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;

  // Function to calculate the number of pages
  const totalPages = Math.ceil(filteredLeadReports.length / itemsPerPage);
  //   // Slice the filteredLeadReport based on the current page
  const currentData = filteredLeadReports.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  
  const [, setRefreshTable] = useState(false);

  const sortAlphabetically = (targetToSort, order) => {
    const key = `${targetToSort}-${order}`;
    switch (key) {
      case "business-az":
        filteredLeadReports.sort((a, b) =>
          a.AssignedGroup.localeCompare(b.AssignedGroup)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "business-za":
        filteredLeadReports.sort((a, b) =>
          b.AssignedGroup.localeCompare(a.AssignedGroup)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "client-az":
        filteredLeadReports.sort((a, b) =>
          a.ClientName.localeCompare(b.ClientName)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "client-za":
        filteredLeadReports.sort((a, b) =>
          b.ClientName.localeCompare(a.ClientName)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "project-az":
        filteredLeadReports.sort((a, b) =>
          a.ProjectName.localeCompare(b.ProjectName)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "project-za":
        filteredLeadReports.sort((a, b) =>
          b.ProjectName.localeCompare(a.ProjectName)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "leadtype-az":
        filteredLeadReports.sort((a, b) =>
          a.LeadType.localeCompare(b.LeadType)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "leadtype-za":
        filteredLeadReports.sort((a, b) =>
          b.LeadType.localeCompare(a.LeadType)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "leadstatus-az":
        filteredLeadReports.sort((a, b) => a.Status.localeCompare(b.Status));
        setRefreshTable((prev) => !prev);
        break;

      case "leadstatus-za":
        filteredLeadReports.sort((a, b) => b.Status.localeCompare(a.Status));
        setRefreshTable((prev) => !prev);
        break;

      case "leadate-az": // Oldest to Latest
        filteredLeadReports.sort(
          (a, b) => new Date(a.LeadDate) - new Date(b.LeadDate)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "leadate-za": // Latest to Oldest
        filteredLeadReports.sort(
          (a, b) => new Date(b.LeadDate) - new Date(a.LeadDate)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "followupdate-az": // Oldest to Latest, N/A at end
        filteredLeadReports.sort((a, b) => {
          if (!a.FollowupDate) return 1; // Move N/A to the end
          if (!b.FollowupDate) return -1;
          return new Date(a.FollowupDate) - new Date(b.FollowupDate);
        });
        setRefreshTable((prev) => !prev);
        break;

        case "source-az":
          filteredLeadReports.sort((a, b) =>
            a.Source.localeCompare(b.Source)
          );
          setRefreshTable((prev) => !prev);
          break;
  
        case "source-za":
          filteredLeadReports.sort((a, b) =>
            b.Source.localeCompare(a.Source)
          );
          setRefreshTable((prev) => !prev);
          break;

      case "followupdate-za": // Latest to Oldest, N/A at end
        filteredLeadReports.sort((a, b) => {
          if (!a.FollowupDate) return 1; // Move N/A to the end
          if (!b.FollowupDate) return -1;
          return new Date(b.FollowupDate) - new Date(a.FollowupDate);
        });
        setRefreshTable((prev) => !prev);
        break;

      default:
        console.error("Invalid Order");
    }
  };

  const numericSort = (order) => {
    order === "az"
      ? filteredLeadReports.sort(
          (a, b) => Number(a.QuotedValue) - Number(b.QuotedValue)
        )
      : filteredLeadReports.sort(
          (a, b) => Number(b.QuotedValue) - Number(a.QuotedValue)
        );

    setRefreshTable((prev) => !prev);
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
      />
      <L1Sidebar />

      <div
        className="ml-[20%] p-4 bg-white  relative overflow-y-scroll  w-[80%] justify-center"
        style={{ marginLeft: "20%", paddingLeft: "1%" }}
      >
        <div className="flex-col pt-4">
    

          <div className="flex justify-between items-center">
            <div>
            <h1 className="text-2xl w-full  mb-2 font-semibold">
            All Lead Reports
          </h1>
            </div>
           
             <div className="flex gap-2 p-1 align-middle items-center bg-[#e0e7ff] rounded-full">
                            <MdSearch
                              className=" w-5 h-5 text-gray-500"
                            />
                            <input type="text"  
                              value={searchQuery}
                              onChange={handleSearchInputChange}
                              placeholder="Search"
                              className="bg-[#e0e7ff] outline-none w-[80%]"
                            />
                          </div>
          </div>
        </div>
        <hr />
        <hr />
        <br />
        <div className="max-h-[580px] overflow-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-black sticky top-0">
              <tr className="text-white">
                <th className="px-4 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                  #
                </th>
                <th className="px-4 w-24 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                  <div className="flex items-center justify-center gap-x-4">
                    Lead Date
                    <div className="flex flex-col gap-y-1">
                      <div
                        className="cursor-pointer "
                        onClick={() => sortAlphabetically("leadate", "az")}
                      >
                        <ArrowDropUp color="white" sx={{ fontSize: "22px" }} />
                      </div>

                      <div
                        className="cursor-pointer -mt-2"
                        onClick={() => sortAlphabetically("leadate", "za")}
                      >
                        <ArrowDropDown
                          color="white"
                          sx={{ fontSize: "22px" }}
                        />
                      </div>
                    </div>
                  </div>
                </th>
                <th className="px-4 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                  <div className="flex items-center justify-between gap-x-4">
                    Client Name
                    <div className="flex flex-col gap-y-1">
                      <div
                        className="cursor-pointer "
                        onClick={() => sortAlphabetically("client", "az")}
                      >
                        <ArrowDropUp color="white" sx={{ fontSize: "22px" }} />
                      </div>

                      <div
                        className="cursor-pointer -mt-2"
                        onClick={() => sortAlphabetically("client", "za")}
                      >
                        <ArrowDropDown
                          color="white"
                          sx={{ fontSize: "22px" }}
                        />
                      </div>
                    </div>
                  </div>
                </th>
                <th className="px-4 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                  <div className="flex items-center justify-between gap-x-4">
                    Project Name
                    <div className="flex flex-col gap-y-1">
                      <div
                        className="cursor-pointer"
                        onClick={() => sortAlphabetically("project", "az")}
                      >
                        <ArrowDropUp color="white" sx={{ fontSize: "22px" }} />
                      </div>

                      <div
                        className="cursor-pointer -mt-2"
                        onClick={() => sortAlphabetically("project", "za")}
                      >
                        <ArrowDropDown
                          color="white"
                          sx={{ fontSize: "22px" }}
                        />
                      </div>
                    </div>
                  </div>
                </th>
                <th className="px-4 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                  <div className="flex items-center justify-center gap-x-4">
                    Lead type
                    <div className="flex flex-col gap-y-1">
                      <div
                        className="cursor-pointer "
                        onClick={() => sortAlphabetically("leadtype", "az")}
                      >
                        <ArrowDropUp color="white" sx={{ fontSize: "22px" }} />
                      </div>

                      <div
                        className="cursor-pointer -mt-2"
                        onClick={() => sortAlphabetically("leadtype", "za")}
                      >
                        <ArrowDropDown
                          color="white"
                          sx={{ fontSize: "22px" }}
                        />
                      </div>
                    </div>
                  </div>
                </th>
                <th className="px-4 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                  <div className="flex items-center justify-between">
                    Quoted Value
                    <div className="flex flex-col gap-y-1">
                      <div
                        className="cursor-pointer"
                        onClick={() => numericSort("za")}
                      >
                        <ArrowDropUp color="white" sx={{ fontSize: "22px" }} />
                      </div>

                      <div
                        className="cursor-pointer -mt-2"
                        onClick={() => numericSort("az")}
                      >
                        <ArrowDropDown
                          color="white"
                          sx={{ fontSize: "22px" }}
                        />
                      </div>
                    </div>
                  </div>
                </th>
                <th className="px-4 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                  <div className="flex items-center justify-center gap-x-4">
                    Status
                    <div className="flex flex-col gap-y-1">
                      <div
                        className="cursor-pointer "
                        onClick={() => sortAlphabetically("leadstatus", "az")}
                      >
                        <ArrowDropUp color="white" sx={{ fontSize: "22px" }} />
                      </div>

                      <div
                        className="cursor-pointer -mt-2"
                        onClick={() => sortAlphabetically("leadstatus", "za")}
                      >
                        <ArrowDropDown
                          color="white"
                          sx={{ fontSize: "22px" }}
                        />
                      </div>
                    </div>
                  </div>
                </th>
                <th className="px-4 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                   <div className="flex items-center justify-center">
                                    Source
                                      <div className="flex flex-col gap-y-1">
                                        <div
                                          className="cursor-pointer"
                                          onClick={() => sortAlphabetically("source", "az")}
                                        >
                                          <ArrowDropUp color="white" sx={{ fontSize: "22px" }} />
                                        </div>
                  
                                        <div
                                          className="cursor-pointer -mt-2"
                                          onClick={() => sortAlphabetically("source", "za")}
                                        >
                                          <ArrowDropDown
                                            color="white"
                                            sx={{ fontSize: "22px" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                </th>
                <th className="px-4 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                  <div className="flex items-center justify-between">
                    Group
                    <div className="flex flex-col gap-y-1">
                      <div
                        className="cursor-pointer"
                        onClick={() => sortAlphabetically("business", "az")}
                      >
                        <ArrowDropUp color="white" sx={{ fontSize: "22px" }} />
                      </div>

                      <div
                        className="cursor-pointer -mt-2"
                        onClick={() => sortAlphabetically("business", "za")}
                      >
                        <ArrowDropDown
                          color="white"
                          sx={{ fontSize: "22px" }}
                        />
                      </div>
                    </div>
                  </div>
                </th>
                <th className="px-4 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                  <div className="flex items-center justify-center">
                    Follow-up Date
                    <div className="flex flex-col gap-y-1">
                      <div
                        className="cursor-pointer"
                        onClick={() => sortAlphabetically("followupdate", "az")}
                      >
                        <ArrowDropUp color="white" sx={{ fontSize: "22px" }} />
                      </div>

                      <div
                        className="cursor-pointer -mt-2"
                        onClick={() => sortAlphabetically("followupdate", "za")}
                      >
                        <ArrowDropDown
                          color="white"
                          sx={{ fontSize: "22px" }}
                        />
                      </div>
                    </div>
                  </div>
                </th>
                <th className="px-4 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                  Edit
                </th>
                <th className="px-8 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                  <FaTrash size={18} />
                </th>
              </tr>
            </thead>

            {/* {groupFilter === "All" || groupFilter === "" ? ( */}
            <tbody className="bg-white divide-y divide-gray-200">
              {currentData.length > 0 ? (
                currentData.map((LeadReport, index) => (
                  <tr className="border text-sm" key={LeadReport.id}>
                    <td className="px-3 text-center py-2">
                      {index + 1 + (currentPage - 1) * 10}
                    </td>
                    <td className="px-3 text-center py-2">
                      {formatDate(LeadReport.LeadDate)}
                    </td>
                    <td className="px-3 text-center py-2">
                      {LeadReport.ClientName}
                    </td>
                    <td className="px-3 text-center py-2">
                      {LeadReport.ProjectName}
                    </td>
                    <td className="px-3 text-center py-2">
                      {LeadReport.LeadType}
                    </td>
                    <td className="px-3 text-center py-2">
                      ${LeadReport.QuotedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",")}
                    </td>
                    <td className="px-3 text-center py-2">
                      {LeadReport.Status}
                    </td>
                    <td className="px-3 text-center py-2">
                      {LeadReport.Source}
                    </td>
                    <td className="px-3 text-center py-2">
                      {LeadReport.AssignedGroup}
                    </td>
                    <td className="px-3 text-center py-3">
                      {LeadReport.Status === "Won" ||
                      LeadReport.Status === "Lost"
                        ? "N/A"
                        : formatDate(LeadReport.FollowupDate)}
                    </td>
                    <td
                     onClick={() =>
                      navigate(`/dashboard/L1/edit-lead/${LeadReport.id}`, {
                        state: { from: location.pathname },
                      })
                    }
                      className="px-4 hover:bg-black cursor-pointer hover:text-white text-center py-2"
                    >
                      <MdEditSquare size={20} />
                    </td>
                    <td
                      onClick={() => openDeleteModal(LeadReport.id)}
                      className="px-8 hover:bg-black cursor-pointer hover:text-white text-center py-2"
                    >
                      <FaTrash size={18} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="6"
                    className="border p-4 text-start text-red-500"
                  >
                    {searchQuery ? `No result found for "${searchQuery}"` : "No result found"}                   

                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        
        <div className="pagination mt-4 flex justify-between items-center bg-white rounded-md p-1">
            <div>
              {totalItems > 0 ? (
                <>
                  Showing {startIdx + 1} to {Math.min(endIdx, totalItems)} of{" "}
                  {totalItems} Entries
                </>
              ) : (
                <>Showing 0 Entries</>
              )}
            </div>
            {totalPages > 1 && (
              <div>
                <button
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  className="px-4 py-2 mx-2 border rounded"
                  disabled={currentPage === 1}
                >
                  Previous
                </button>

                {Array.from({ length: 3 }, (_, index) => {
                  // Calculate the page number for the current range
                  const pageNumber = currentPage - 1 + index;

                  // Ensure the pageNumber stays within bounds
                  if (pageNumber >= 1 && pageNumber <= totalPages) {
                    return (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={`px-4 py-2 mx-2 border rounded ${currentPage === pageNumber ? "bg-gray-300" : ""
                          }`}
                      >
                        {pageNumber}
                      </button>
                    );
                  }

                  // Return null for out-of-bounds values
                  return null;
                })}

                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  className="px-4 py-2 mx-2 border rounded"
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            )}
          </div>
         
         
      </div>
    </>
  );
};

export default LeadReport11;
