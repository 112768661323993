import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
// import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import AdminLoader from "../../loader/AdminLoader";
import L1Sidebar from "./L1Sidebar";
// import PersonIcon from "@mui/icons-material/Person";
import { useLead } from "../../../context/LeadReport";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeads, updateLead } from "../../../redux/slices/leadsSlice";
import { fetchGroups } from "../../../redux/slices/groupSlice";
import { fetchStatus } from "../../../redux/slices/statusSlice";
import { fetchSources } from "../../../redux/slices/sourceSlice";
import { fetchLeadTypes } from "../../../redux/slices/leadTypeSlice";
import { fetchServiceTypes } from "../../../redux/slices/serviceTypesSlice";
import { fetchFilesByLeadId, uploadFileInReport } from "../../../redux/slices/fileSlice";
import { logoutSession } from "../../../redux/slices/sessionSlice";

const EditLead = () => {
  const dispatch = useDispatch();

  const [LeadReports, setLeadReports] = useState([]);
  const [GroupNames, setGroupNames] = useState([]);
  const [LeadTypes, setLeadTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [Sources, setSources] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);

const {leads} = useSelector((state)=> state.leads)
const {group} = useSelector((state)=> state.group)
const {status} = useSelector((state)=> state.status)
const {source} = useSelector((state)=> state.source)
const {leadTypesRedux} = useSelector((state)=> state.leadTypesRedux)
const {serviceTypesRedux} = useSelector((state)=> state.serviceTypesRedux)

 useEffect(()=>{
dispatch(fetchLeads())
dispatch(fetchGroups)
dispatch(fetchStatus());
dispatch(fetchSources());
dispatch(fetchLeadTypes())
dispatch(fetchServiceTypes())
      },[dispatch])

useEffect(()=>{
setLeadReports(leads)
setGroupNames(group)
setLeadTypes(leadTypesRedux)
setStatuses(status)
setSources(source)
setServiceTypes(serviceTypesRedux)
},[leads,group,leadTypesRedux, status, source,serviceTypesRedux ])

  const [LeadOwner, SetLeadOwner] = useState("");
  const [ClientName, setClientName] = useState("");
  const [TypeService, setTypeService] = useState("");
  const [LeadType, setLeadType] = useState("");
  const [AssignedGroup, setAssignedGroup] = useState("");
  const [QuotedValue, setQuotedValue] = useState("");
  const [ProjectName, setProjectName] = useState("");
  const [LeadDate, setLeadDate] = useState("");
  const [ClientPhone, setClientPhone] = useState("");
  const [ClientContactName, setClientContactName] = useState("");
  const [Status, setStatus] = useState("");
  const [Source, setSource] = useState("");
  const [FollowupDate, setFollowupDate] = useState("");
  const [, setShowProb] = useState(false);
  const [Probability, setProbability] = useState("");
  const [, setshowRef] = useState(false);
  const [Reference, setReference] = useState("");
  const [ClientEmail, setClientEmail] = useState("");
  const [comments, setComments] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [todaysDate, setTodaysDate] = useState("");

  const [editingLeadReport, setEditingLeadReport] = useState(null);

  const [isLoading, SetisLoading] = useState(false);
  const [showSourceDropdown, setShowSourceDropdown] = useState("");
  const [showGroupDropdown, setShowGroupDropdown] = useState("");
  const [showServiceDropdown, setShowServiceDropdown] = useState("");
  const [showLeadTypeDropdown, setShowLeadTypeDropdown] = useState("");
  const [showStatusDropdown, setShowStatusDropdown] = useState("");
  const [showProbabilityDropdown, setShowProbabilityDropdown] = useState("");
  const [showLogout, setshowlogout] = useState(false);
  const popupRef = useRef(null);
  const name = localStorage.getItem("name");

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setshowlogout(false); // Close the popup if clicked outside
    }
  };
  useEffect(() => {
    if (showLogout) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showLogout]);

  const logout = (e) => {
    e.preventDefault();
    setshowlogout(!showLogout);
  };
  const logoutUser = (e) => {
    e.preventDefault();
    dispatch(logoutSession())
    enqueueSnackbar("Successfully Logged out!", { variant: "success",autoHideDuration: 1500 });
    navigate("/");
  };

  const { id } = useParams();
  const dropdownRefSource = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefSource.current &&
        !dropdownRefSource.current.contains(event.target)
      ) {
        setShowSourceDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRefGroup = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefGroup.current &&
        !dropdownRefGroup.current.contains(event.target)
      ) {
        setShowGroupDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRefService = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefService.current &&
        !dropdownRefService.current.contains(event.target)
      ) {
        setShowServiceDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRefLeadType = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefLeadType.current &&
        !dropdownRefLeadType.current.contains(event.target)
      ) {
        setShowLeadTypeDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRefStatus = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefStatus.current &&
        !dropdownRefStatus.current.contains(event.target)
      ) {
        setShowStatusDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRefProbability = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefProbability.current &&
        !dropdownRefProbability.current.contains(event.target)
      ) {
        setShowProbabilityDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
 

  // Set editingLeadReport after LeadReports is populated
  useEffect(() => {
    if (LeadReports.length > 0) {
      const ReportToEdit = LeadReports.find(
        (LeadReport) => String(LeadReport.id) === String(id)
      );
      setEditingLeadReport(ReportToEdit || null);
    }
  }, [LeadReports, id]);

  const handleSourceChange = (source) => {
    const value = source;
    setSource(value);
    // setsourceOpen(false);
    // Clear the error when a valid source is selected

    setShowSourceDropdown(false);
  };
  const handleGroupChange = (Group) => {
    const value = Group;
    setAssignedGroup(value);

    // Clear the error when a valid group is selected

    setShowGroupDropdown(false)
  };
  const handleServiceChange = (service) => {
    const value = service;
    setTypeService(value);
    setShowServiceDropdown(false)
  };
  const handleLeadTypeChange = (Leadtype) => {
    const value = Leadtype;
    setLeadType(value);
    setShowLeadTypeDropdown(false);
  };
  const handleStatusChange = (status) => {
    const value = status;
    setStatus(value);
    setShowStatusDropdown(false);
  };
  const handleProbabilityChange = (Probability) => {
    const value = Probability;
    setProbability(value);

    setShowProbabilityDropdown(false);
  };


  useEffect(() => {
  }, [LeadReports]);

  const Cancel = (e) => {
    e.preventDefault();
    setClientName("");
    setLeadDate("");
    setClientPhone("");
    setClientContactName("");
    setStatus("");
    setProjectName("");
    setQuotedValue("");
    setTypeService("");
    setLeadType("");
    setAssignedGroup("");
    setSource("");
    setFollowupDate("");
    setComments("")
    navigate("/dashboard/L1/lead-report");
  };

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setTodaysDate(today);
  }, []);

  useEffect(() => {
    if (editingLeadReport) {
      SetLeadOwner(editingLeadReport.LeadOwner);
      setClientName(editingLeadReport.ClientName);
      setLeadDate(editingLeadReport.LeadDate);
      setSource(editingLeadReport.Source);
      setClientContactName(editingLeadReport.ClientContactName);
      setClientPhone(editingLeadReport.ClientPhone);
      setProjectName(editingLeadReport.ProjectName);
      setTypeService(editingLeadReport.TypeService);
      setQuotedValue(editingLeadReport.QuotedValue);
      setAssignedGroup(editingLeadReport.AssignedGroup);
      setLeadType(editingLeadReport.LeadType);
      setStatus(editingLeadReport.Status);
      setFollowupDate(editingLeadReport.FollowupDate);
      setClientEmail(editingLeadReport.ClientEmail);
      setReference(editingLeadReport.Reference);
      setComments(editingLeadReport.comments);
      setProbability(editingLeadReport.Probability);
    }
  }, [editingLeadReport]);

  useEffect(() => {
    if (Source === "Reference") {
      setshowRef(true);
    }

    if (Status === "Pending") {
      setShowProb(true);
    }
  }, [Source, Status]);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  
  const Submit = async (e) => {
    e.preventDefault();
  
    if (
      !ClientName ||
      !LeadType ||
      (Status !== "Won" && Status !== "Lost" && !FollowupDate) ||
      !LeadDate ||
      !Source ||
      !ClientPhone ||
      !ClientContactName ||
      !TypeService ||
      !Status ||
      !ProjectName ||
      !QuotedValue
    ) {
      enqueueSnackbar("Please fill all the fields!", { variant: "error" });
      return;
    } 
    
    if (!emailRegex.test(ClientEmail)) {
      enqueueSnackbar("Please enter a valid email address!", { variant: "error" });
      return;
    } 
    
    if (Status === "Pending" && !Probability) {
      enqueueSnackbar("Please provide Probability for Pending status!", { variant: "error" });
      return;
    }
  
    if (Source === "Reference" && !Reference) {
      enqueueSnackbar("Please enter Reference!", { variant: "error" });
      return;
    }
  
    SetisLoading(true);
    
    try {
      const updatedLeadData = {
        LeadOwner,
        ClientName,
        ProjectName,
        LeadDate,
        QuotedValue,
        Status,
        AssignedGroup,
        LeadType,
        FollowupDate,
        Probability: Status === "Pending" ? Probability : "",
        Source,
        Reference,
        TypeService,
        ClientContactName,
        ClientEmail,
        ClientPhone,
        comments,
      };
  
      await dispatch(updateLead({ id: editingLeadReport.id, updatedData: updatedLeadData })).unwrap();

      if (fileRefValue && fileRefValue.target && fileRefValue.target.files) {
        await handleFileUpload(fileRefValue, editingLeadReport.id);
      }
      enqueueSnackbar("Lead Updated Successfully!", { variant: "success" });
      navigate("/dashboard/L1/lead-report");
      dispatch(fetchLeads())
      // Reset form fields
      setClientName("");
      setLeadDate("");
      setClientPhone("");
      setClientContactName("");
      setStatus("");
      setProjectName("");
      setQuotedValue("");
      setTypeService("");
      setAssignedGroup("");
      setLeadType("");
      setFollowupDate("");
      setProbability("");
      setClientEmail("");
      setSource("");
      setReference("");
      setComments("");

    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar(error?.message || "Something went wrong!", { variant: "error" });
    } finally {
      SetisLoading(false);
    }
  };

  function formatNumberWithCommas(number) {
    if (!number) return ""; // Handle case where number is undefined or null
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  const {  setUploadedFiles } = useLead();
  const { setFileUpload } = useLead();
  const [showuploadedfiles, ] = useState([]);
  const [fetchedfiles, setFetchedFiles] = useState([]);
  

  const fetchFiles = async (leadId) => {
    try {
      const response = await dispatch(fetchFilesByLeadId(leadId)).unwrap(); // Dispatch Redux action
      setUploadedFiles(response); // Save files in state
      setFetchedFiles(response);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };
 
  const [fileRefValue, setFileRef] = useState();
  const [currentFileName, setCurrentFileName] = useState("No File Selected");


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const maxSize = 2 * 1024 * 1024; // 2MB in bytes
    const allowedFormats = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (!allowedFormats.includes(file.type)) {
      enqueueSnackbar("Only PDF and DOC files are allowed", { variant: "error" });
      e.target.value = ""; // Clear the file input
      return;
    }

    if (file.size > maxSize) {
      enqueueSnackbar("File size exceeds 2MB! Please upload a smaller file.", {
        variant: "error",
      });
      e.target.value = ""; // Clear the file input
      return;
    }


    // Check if the filename already exists
    const isFileNameExists = (name) =>
      showuploadedfiles.some((uploadedFile) => uploadedFile.fileName === name);

    if (isFileNameExists(file.name)) {
      enqueueSnackbar("This file has already been uploaded. Please upload a different file.", { variant: "warning" });
      e.target.value = ""; // Clear the file input
      return; // Stop execution
    }
    if (file) {
      enqueueSnackbar("File Uploaded Successfully !", {
        variant: "success",
      });
    }


    setFileRef(e);
    setCurrentFileName(file.name);
  };


  const handleFileUpload = async (event, id) => {
     const fileInput = event.target;
     const file = fileInput.files[0];
     if (!file) return;
   
     let fileName = file.name;
     const isFileNameExists = (name) =>
       fetchedfiles.some((uploadedFile) => uploadedFile.fileName === name);
     let fileType = file.type; // Get MIME type
     let fileExtension = fileName.split(".").pop().toLowerCase();
   
     if (file.size > 2 * 1024 * 1024) {
       enqueueSnackbar("File size must be less than 2MB.", {
         variant: "warning",
       });
       event.target.value = "";
       return;
     }
   
     const allowedTypes = [
       "application/pdf",
       "application/msword",
       "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
     ];
     const allowedExtensions = ["pdf", "doc", "docx"];
   
     if (!allowedTypes.includes(fileType) || !allowedExtensions.includes(fileExtension)) {
       enqueueSnackbar("Only PDF and DOC/DOCX files are allowed.", { variant: "error" });
       event.target.value = "";
       return;
     }
   
     if (isFileNameExists(fileName)) {
       enqueueSnackbar("This file has already been uploaded. Please upload a different file.", {
         variant: "warning",
       });
       fileInput.value = null;
       return;
     }
   
     setCurrentFileName(fileName);
   
     const formData = new FormData();
     formData.append("file", file, fileName);
     formData.append("leadId", id);
     formData.append("uploadDate", new Date());
   
     try {
       await dispatch(uploadFileInReport(formData)).unwrap();
   
      //  enqueueSnackbar("File uploaded successfully", { variant: "success" });
   
       setFileUpload((prev) => prev + 1);
       dispatch(fetchFilesByLeadId(id)); // Fetch updated files after upload
   
       fileInput.value = null;
       fetchFiles();
       setCurrentFileName("No File Selected");
     } catch (error) {
       enqueueSnackbar("Error Occurred While uploading file", { variant: "error" });
       console.error("Error uploading file:", error.response?.data?.error || error.message);
     }
   };


  return (
    <div className=" bg-[#f3f8f8] h-full">
      <L1Sidebar />

      {isLoading ? (
        <AdminLoader isLoading={isLoading} />
      ) : (
        <div
          className="ml-[18%]   relative overflow-hidden px-3 justify-center top-2"
          style={{ paddingLeft: "1%" }}
        >
          <div className="bg-white p-2 flex justify-between align-middle items-center rounded-md">
            <h1 className="text-xl font-semibold">Edit Lead</h1>
            <div className="relative cursor-pointer bg-transparent">
              <FaUserCircle onClick={logout} className="text-black w-5 h-5" />
              {showLogout && (
                <div
                  ref={popupRef}
                  className="absolute right-0 top-full mt-1 text-black text-center px-2 w-36 shadow-2xl overflow-hidden bg-white border border-gray-400 z-50 rounded-lg"
                >
                  <h1 className="px-6 py-2 rounded-md my-1">{name}</h1>
                  <h1
                    onClick={logoutUser}
                    className="flex items-center gap-2 px-6 py-2 rounded-md my-1 cursor-pointer hover:bg-black hover:text-white"
                  >
                    <MdLogout size={24} /> Logout
                  </h1>
                </div>
              )}
            </div>
          </div>
          <br />
          <div className="bg-white rounded-md p-2">
            <div
              className="grid grid-cols-1 md:grid-cols-4 gap-4 "
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {/* Lead Date */}
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block " htmlFor="date">
                  Lead Date
                  <span className="text-red-700 text-xl">*</span>
                </label>
                <DatePicker
                  selected={LeadDate}
                  onChange={(date) => setLeadDate(date)}
                  dateFormat="MM-dd-yyyy"
                  className="border w-full mr-14 pr-16 border-gray-400 rounded-md p-2.5 overflow-hidden shadow-md outline-none"
                  placeholderText="mm-dd-yyyy"
                  maxDate={new Date()}
                  readOnly
                  required
                />
              </div>

              {/* Client Name */}
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Name">
                  Client Name<span className="text-red-700 text-xl">*</span>
                </label>
                <input
                  className="border w-full border-gray-400 rounded-md p-2.5 overflow-hidden shadow-md outline-none"
                  type="text"
                  placeholder="Enter Name"
                  value={ClientName}
                  maxLength={30}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^a-zA-Z\s]/g, ""); // Allows only letters and spaces
                    value = value.charAt(0).toUpperCase() + value.slice(1); // Capitalizes the first letter
                    setClientName(value);
                  }}
                />
              </div>

              <div className="pt-1" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Source">
                  Source<span className="text-red-700 text-xl">*</span>
                </label>
                <div className=" bg-white">
                  <div
                    ref={dropdownRefSource}
                    className="relative flex  p-0  rounded-xl align-middle items-center"
                  >
                    <ExpandMoreIcon
                      color="white"
                      className="absolute cursor-pointer right-2 m-auto"
                      onClick={() => setShowSourceDropdown(!showSourceDropdown)}
                    />

                    <button
                      type="button"
                      onClick={() => setShowSourceDropdown(!showSourceDropdown)}
                      className="border w-full bg-white border-gray-400 rounded-md p-2 shadow-md text-start overflow-hidden  outline-none"
                    >
                      {Source ? Source : <p>Select Source</p>}
                    </button>

                    {showSourceDropdown && (
                      <div
                        style={{ zIndex: 9999 }}
                        className="absolute top-full left-0 w-full p-1 cursor-pointer max-h-96 overflow-y-auto bg-white text-sm border border-gray-300 shadow-lg flex flex-col gap-1"
                      >
                        {Sources.map((Sourc) => (
                          <div
                            className="p-1 hover:bg-gray-200 cursor-pointer"
                            onClick={() => handleSourceChange(Sourc.newSource)}
                            key={Sourc.id}
                            value={Sourc.newSource}
                          >
                            {Sourc.newSource}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Reference */}
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Name">
                  Reference:
                  {Source === "Reference" ? (
                    <span className="text-red-700 text-xl">*</span>
                  ) : (
                    <span className="text-red-700 text-xl"></span>
                  )}
                </label>
                <input
                  className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                  type="text"
                  maxLength={30}
                  placeholder="Enter Reference"
                  value={Source === "Reference" ? Reference : ""}
                      onChange={(e) => {
                        let value = e.target.value.replace(/[^a-zA-Z\s]/g, ""); // Allows only letters and spaces
                        value = value.charAt(0).toUpperCase() + value.slice(1); // Capitalizes the first letter
                        setReference(value);
                      }}
                  disabled={Source !== "Reference"}
                />
              </div>
            </div>

            <div
              className="grid grid-cols-1 md:grid-cols-4 gap-4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >

              <div className="pt-1" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Source">
                  Group<span className="text-red-700 text-xl">*</span>
                </label>
                <div className="w-full ">
                  <div
                    ref={dropdownRefGroup}
                    className="relative flex w-full  p-0 rounded-xl align-middle items-center"
                  >
                    <ExpandMoreIcon
                      color="white"
                      className="absolute cursor-pointer right-0 border-r border-gray-400 m-auto bg-white"
                      onClick={() => setShowGroupDropdown(!showGroupDropdown)}
                    />

                    <button
                      type="button"
                      onClick={() => setShowGroupDropdown(!showGroupDropdown)}
                      className="border w-full bg-white border-gray-400 text-nowrap rounded-md p-2 shadow-md text-start overflow-hidden  outline-none"
                      style={
                        {
                          // backgroundColor: "#f8f5fc", // Light background color for dropdown
                          // Set the height
                          // Adjust padding
                        }
                      }
                    >
                      {AssignedGroup ? AssignedGroup : <p>Select Group</p>}
                    </button>

                    {showGroupDropdown && (
                      <div
                        style={{ zIndex: 9999 }}
                        className="absolute top-full left-0 p-1 w-full cursor-pointer max-h-96 overflow-y-auto bg-white text-sm border border-gray-300 shadow-lg flex flex-col gap-1"
                      >
                        {GroupNames.map((group) => (
                          <div
                            className="p-1 hover:bg-gray-200 cursor-pointer"
                            onClick={() => handleGroupChange(group.newGroupName)}
                            key={group.id}
                            value={group.newGroupName}
                          >
                            {group.newGroupName}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="pt-1" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Source">
                  Type of Service<span className="text-red-700 text-xl">*</span>
                </label>
                <div className=" bg-white">
                  <div
                    ref={dropdownRefService}
                    className="relative flex  p-0  rounded-xl align-middle items-center"
                  >
                    <ExpandMoreIcon
                      color="white"
                      className="absolute cursor-pointer right-2 m-auto bg-white"
                      onClick={() => setShowServiceDropdown(!showServiceDropdown)}
                    />

                    <button
                      type="button"
                      onClick={() => setShowServiceDropdown(!showServiceDropdown)}
                      className="border w-full bg-white border-gray-400 text-nowrap rounded-md p-2 shadow-md text-start overflow-hidden  outline-none"
                      style={
                        {
                          // backgroundColor: "#f8f5fc", // Light background color for dropdown
                          // Set the height
                          // Adjust padding
                        }
                      }
                    >
                      {TypeService ? TypeService : <p>Select Service Type</p>}
                    </button>

                    {showServiceDropdown && (
                      <div
                        style={{ zIndex: 9999 }}
                        className="absolute top-full left-0 p-1 w-full cursor-pointer max-h-96 overflow-y-auto bg-white text-sm border border-gray-300 shadow-lg flex flex-col gap-1"
                      >
                        {serviceTypes.map((serviceType) => (
                          <div
                            className="p-1 hover:bg-gray-200 cursor-pointer"
                            onClick={() =>
                              handleServiceChange(serviceType.ServiceType)
                            }
                            key={serviceType.id}
                            value={serviceType.ServiceType}
                          >
                            {serviceType.ServiceType}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Project Name */}
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Name">
                  Project Name<span className="text-red-700 text-xl">*</span>
                </label>
                <input
                  className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                  type="text"
                  placeholder="Enter Project Name"
                  maxLength={30}
                  value={ProjectName}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^a-zA-Z\s]/g, ""); // Allows only letters and spaces
                    value = value.charAt(0).toUpperCase() + value.slice(1); // Capitalizes the first letter
                    setProjectName(value);
                  }}
                />
              </div>

              {/* Quoted Value */}
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Name">
                  Quoted Value:<span className="text-red-700 text-xl">*</span>
                </label>
                <input
                  className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                  type="text"
                  placeholder="Enter Quoted Value"
                  inputMode="numeric"
                  maxLength={12}
                  pattern="[0-9]*"
                  value={formatNumberWithCommas(QuotedValue)}
                  onChange={(e) =>
                    setQuotedValue(
                      e.target.value.replace(/,/g, "").replace(/\D/g, "")
                    )
                  }
                />
              </div>
            </div>

            <div
              className="grid grid-cols-1 md:grid-cols-4 gap-4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {/* Client Contact Name */}
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Name">
                  Contact Person<span className="text-red-700 text-xl">*</span>
                </label>
                <input
                  className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                  type="text"
                  placeholder="Enter Name"
                  maxLength={30}
                  value={ClientContactName}
                  // onChange={(e) => setClientContactName(e.target.value)}
                  // onChange={(e) => {
                  //   const value = e.target.value;
                  //   const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
                  //   setClientContactName(capitalizedValue);
                  // }}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^a-zA-Z\s]/g, ""); // Allows only letters and spaces
                    value = value.charAt(0).toUpperCase() + value.slice(1); // Capitalizes the first letter
                    setClientContactName(value);
                  }}
                />
              </div>

              {/* Client Email */}
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="email">
                  Email ID<span className="text-red-700 text-xl">*</span>
                </label>
                <input
                  className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                  type="text"
                  placeholder="Enter Email ID"
                  value={ClientEmail}
                  onChange={(e) => setClientEmail(e.target.value)}
                />
              </div>

              {/* Client Phone */}
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Name">
                  Mobile Number<span className="text-red-700 text-xl">*</span>
                </label>
                <PhoneInput
                  country={"us"}
                  value={String(ClientPhone)}
                  onChange={(value) => setClientPhone(value)}
                  inputProps={{
                    required: true,
                    className:
                      "w-full h-10 border text-sm border-gray-400 outline-0 rounded overflow-hidden shadow-md p-2 pl-10",
                    style: {
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                    },
                  }}
                />
              </div>

              <div className="pt-1" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Source">
                  Lead Type<span className="text-red-700 text-xl">*</span>
                </label>
                <div className=" bg-white">
                  <div
                    ref={dropdownRefLeadType}
                    className="relative flex  p-0  rounded-xl align-middle items-center"
                  >
                    <ExpandMoreIcon
                      color="white"
                      className="absolute cursor-pointer right-2 m-auto bg-white"
                      onClick={() =>
                        setShowLeadTypeDropdown(!showLeadTypeDropdown)
                      }
                    />

                    <button
                      type="button"
                      onClick={() =>
                        setShowLeadTypeDropdown(!showLeadTypeDropdown)
                      }
                      className="border w-full bg-white border-gray-400 text-nowrap rounded-md p-2 shadow-md text-start overflow-hidden  outline-none"
                    >
                      {LeadType ? LeadType : <p>Select Lead Type</p>}
                    </button>

                    {showLeadTypeDropdown && (
                      <div
                        style={{ zIndex: 9999 }}
                        className="absolute top-full p-1 left-0 w-full cursor-pointer max-h-96 overflow-y-auto bg-white text-sm border border-gray-300 shadow-lg flex flex-col gap-1"
                      >
                        {LeadTypes.map((LeadTyp) => (
                          <div
                            className="p-1 hover:bg-gray-200 cursor-pointer"
                            onClick={() => handleLeadTypeChange(LeadTyp.LeadType)}
                            key={LeadTyp.id}
                            value={LeadTyp.LeadType}
                          >
                            {LeadTyp.LeadType}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="grid grid-cols-1 md:grid-cols-4 gap-4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >

              <div className="pt-1.5" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Status">
                  Status<span className="text-red-700 text-xl">*</span>
                </label>
                <div className=" bg-white">
                  <div
                    ref={dropdownRefStatus}
                    className="relative flex  p-0  rounded-xl align-middle items-center"
                  >
                    <ExpandMoreIcon
                      color="white"
                      className="absolute cursor-pointer right-2 m-auto bg-white"
                      onClick={() => setShowStatusDropdown(!showStatusDropdown)}
                    />

                    <button
                      type="button"
                      onClick={() => setShowStatusDropdown(!showStatusDropdown)}
                      className="border w-full bg-white border-gray-400 text-nowrap rounded-md p-2 shadow-md text-start overflow-hidden  outline-none"
                      style={
                        {
                          // backgroundColor: "#f8f5fc", // Light background color for dropdown
                          // Set the height
                          // Adjust padding
                        }
                      }
                    >
                      {Status ? Status : <p>Select Status</p>}
                    </button>

                    {showStatusDropdown && (
                      <div
                        style={{ zIndex: 9999 }}
                        className="absolute top-full p-1 left-0 w-full cursor-pointer max-h-96 overflow-y-auto bg-white text-sm border border-gray-300 shadow-lg flex flex-col gap-1"
                      >
                        {statuses.map((statuse) => (
                          <div
                            className="p-1 hover:bg-gray-200 cursor-pointer"
                            onClick={() => handleStatusChange(statuse.status)}
                            key={statuse.id}
                            value={statuse.status}
                          >
                            {statuse.status}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Probability */}
    
              <div className="pt-1" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Status">
                  Probability{" "}
                  {Status === "Pending" ? (
                    <span className="text-red-700 text-xl">*</span>
                  ) : (
                    <span className="text-red-700 text-xl"></span>
                  )}
                </label>
                <div className=" bg-white">
                  <div
                    ref={dropdownRefProbability}
                    className="relative flex  p-0  rounded-xl align-middle items-center"
                  >
                    <ExpandMoreIcon
                      color="white"
                      className="absolute cursor-pointer right-2 m-auto bg-white"
                    // onClick={() =>
                    //   setShowProbabilityDropdown(!showProbabilityDropdown)
                    // }
                    />

                    <button
                      type="button"
                      onClick={() =>
                        setShowProbabilityDropdown(!showProbabilityDropdown)
                      }
                      disabled={Status !== "Pending"} // Disable when Status is not "Pending"
                      className="border w-full bg-white border-gray-400 text-nowrap rounded-md p-2 shadow-md text-start overflow-hidden  outline-none"
                      style={
                        {
                          // backgroundColor: "#f8f5fc", // Light background color for dropdown
                          // Set the height
                          // Adjust padding
                        }
                      }
                    >
                      {Probability ? Probability : <p>Select Probability</p>}
                    </button>

                    {showProbabilityDropdown && (
                      <div
                        style={{ zIndex: 9999 }}
                        className="absolute top-full left-0 w-full p-1 cursor-pointer max-h-96 overflow-y-auto bg-white text-sm border border-gray-300 shadow-lg flex flex-col gap-1"
                      >
                        {/* {statuses.map((statuse) => ( */}
                        <div
                          className="p-1 hover:bg-gray-200 cursor-pointer"
                          onClick={() => handleProbabilityChange("High")}
                        >
                          High
                        </div>
                        <div
                          className="p-1 hover:bg-gray-200 cursor-pointer"
                          onClick={() => handleProbabilityChange("Medium")}
                        >
                          Medium
                        </div>
                        <div
                          className="p-1 hover:bg-gray-200 cursor-pointer"
                          onClick={() => handleProbabilityChange("Low")}
                        >
                          Low
                        </div>
                        {/* ))} */}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="mb-4" style={{ width: "22.5%", height: "4%" }}>
                <label className="block " htmlFor="date">
                  {Status === "Won" || Status === "Lost" ? (
                    ""
                  ) : (
                    <>
                      {" "}
                      Next Follow-up Date
                      <span className="text-red-700 text-xl">*</span>
                    </>
                  )}
                </label>
                {Status !== "Won" && Status !== "Lost" && (
                  <DatePicker
                    selected={FollowupDate}
                    onChange={(date) => setFollowupDate(date)}
                    dateFormat="MM-dd-yyyy"
                    className="border w-full mr-16 ml-0.5 pr-16 border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                    minDate={todaysDate}
                    placeholderText="mm-dd-yyyy"
                    disabled={Status === "Won" || Status === "Lost"}
                  />
                )}
              </div>
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <div className="">
                  <label className="block" htmlFor="LeadType">
                    Upload File
                    {/* <span className="text-red-500 text-xl">*</span> */}
                  </label>

                  <div className="w-full flex justify-start gap-1 items-center p-1">
                    <input
                      type="file"
                      id={`file-upload-${LeadReports.length + 1 || "from-addlead"
                        }`}
                      className="hidden"
                      onChange={(e) => handleFileChange(e)} // Use your predefined function
                    />
                    <label
                      htmlFor={`file-upload-${LeadReports.length + 1 || "from-addlead"
                        }`}
                      className="bg-gray-800 text-xs text-white hover:text-black p-2 rounded-md cursor-pointer hover:bg-gray-300"
                    >
                      Upload File
                    </label>

                    <span className="">{currentFileName}</span>
                  </div>
                  <div className="flex gap-2 text-red-500">
                    <p className="text-xs pl-2">
                      (Please upload .pdf and .docx files only)
                    </p>
                  </div>

                </div>
              </div>
            </div>
            <br></br>
            {/* Comments */}
            <div className="">
              <label className="block" htmlFor="comments">
                Comments
              </label>
              <textarea
                className="border w-1/2 border-gray-400 rounded-md p-4 overflow-hidden shadow-md outline-none resize-none"
                placeholder="Enter Comments"
                id="comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                required={Status === "Pending"}
                name="comments"
                rows={2}
              />
            </div>

            <div className="flex mt-8 gap-5 justify-start">
              <button
                onClick={Submit}
                className=" py-2 px-7 bg-[#e32278] overflow-hidden shadow-md text-white rounded-lg"
              >
                Confirm
              </button>
              <button
                onClick={Cancel}
                className="py-2 px-7 bg-black hover:bg-white hover:text-black border border-black overflow-hidden shadow-md text-white rounded-lg"
              >
                Cancel
              </button>
            </div>
          </div>
          <br />
        </div>
      )}
    </div>
  );
};

export default EditLead;
