import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AdminLoader from "../../loader/AdminLoader";
import { useBackup } from "../../../context/Backupdata";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { addLead } from "../../../redux/slices/leadsSlice";
import { useDispatch, useSelector } from "react-redux";
import { uploadFileInReport } from "../../../redux/slices/fileSlice";
import L3Sidebar from "./L3Sidebar";
import { fetchLeadTypes } from "../../../redux/slices/leadTypeSlice";
import { fetchSources } from "../../../redux/slices/sourceSlice";
import { fetchStatus } from "../../../redux/slices/statusSlice";
import { fetchServiceTypes } from "../../../redux/slices/serviceTypesSlice";
import { fetchEmployeeAndLeads, fetchEmployeeAuthData, fetchLeadsByGroups } from "../../../redux/slices/leadsSliceL3";
import { logoutSession } from "../../../redux/slices/sessionSlice";


const AddLead = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showLogout, setshowlogout] = useState(false);
  const popupRef = useRef(null);
  const name = localStorage.getItem("name");

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setshowlogout(false); // Close the popup if clicked outside
    }
  };
  useEffect(() => {
    if (showLogout) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showLogout]);

  const logout = (e) => {
    e.preventDefault();
    setshowlogout(!showLogout);
  };
  const logoutUser = (e) => {
    e.preventDefault();
    dispatch(logoutSession())
    enqueueSnackbar("Successfully Logged out!", {
      variant: "success",
      autoHideDuration: 1500,
    });
  };
  const token = localStorage.getItem("token");
  if (!token) {
    enqueueSnackbar("Login to Navigate!", { variant: "error" });
    navigate("/");
  }

  const [isLoading, SetisLoading] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const [ClientName, setClientName] = useState("");

  const [TypeService, setTypeService] = useState("");

  const [LeadType, setLeadType] = useState("");

  const [AssignedGroup, setAssignedGroup] = useState("");

  const [QuotedValue, setQuotedValue] = useState("");
  const [ProjectName, setProjectName] = useState("");
  const [LeadDate, setLeadDate] = useState(() => formatDate(new Date()));
  const [Reference, setReference] = useState("");
  const [ClientPhone, setClientPhone] = useState("");
  const [ClientContactName, setClientContactName] = useState("");
  const [ClientEmail, setClientEmail] = useState("");
  const [Status, setStatus] = useState("");
  const [Source, setSource] = useState("");
  const [FollowupDate, setFollowupDate] = useState(null);
  const [Probability, setProbability] = useState("");
  const [, setShowProb] = useState(false);
  const [, setshowRef] = useState(false);
  const [, setTodaysDate] = useState("");
  const [comments, setComments] = useState("");
  const [showSourceDropdown, setShowSourceDropdown] = useState("");
  const [showGroupDropdown, setShowGroupDropdown] = useState("");
  const [showServiceDropdown, setShowServiceDropdown] = useState("");
  const [showLeadTypeDropdown, setShowLeadTypeDropdown] = useState("");
  const [showStatusDropdown, setShowStatusDropdown] = useState("");
  const [showProbabilityDropdown, setShowProbabilityDropdown] = useState("");

  const dropdownRefSource = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefSource.current &&
        !dropdownRefSource.current.contains(event.target)
      ) {
        setShowSourceDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRefGroup = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefGroup.current &&
        !dropdownRefGroup.current.contains(event.target)
      ) {
        setShowGroupDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRefService = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefService.current &&
        !dropdownRefService.current.contains(event.target)
      ) {
        setShowServiceDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRefLeadType = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefLeadType.current &&
        !dropdownRefLeadType.current.contains(event.target)
      ) {
        setShowLeadTypeDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRefStatus = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefStatus.current &&
        !dropdownRefStatus.current.contains(event.target)
      ) {
        setShowStatusDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dropdownRefProbability = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefProbability.current &&
        !dropdownRefProbability.current.contains(event.target)
      ) {
        setShowProbabilityDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [employeesName, setEmployeesName] = useState([]);
  const [GroupNames, setGroupNames] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [Sources, setSources] = useState([]);
  const [LeadTypes, setLeadTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const dispatch = useDispatch();

  const { employees } = useSelector((state) => state.employees);
    const { empGroups } = useSelector((state) => state.leadsL3);
  const { serviceTypesRedux } = useSelector((state) => state.serviceTypesRedux);
  const { source } = useSelector((state) => state.source);
  const { leadTypesRedux } = useSelector((state) => state.leadTypesRedux);
  const { status } = useSelector((state) => state.status);

useEffect(()=>{
dispatch(fetchLeadTypes());
dispatch(fetchSources());
dispatch(fetchStatus());
dispatch(fetchServiceTypes())
dispatch(fetchEmployeeAuthData())
dispatch(fetchEmployeeAndLeads())
},[dispatch])
  useEffect(() => {
    setEmployeesName(employees);
    setGroupNames(empGroups);
    setServiceTypes(serviceTypesRedux);
    setSources(source);
    setLeadTypes(leadTypesRedux);
    setStatuses(status);
  }, [employees, empGroups, serviceTypesRedux, source, leadTypesRedux, status]);

  const [, setLeadOwner] = useState("");
  // Get the name from localStorage
  useEffect(() => {
    const fetchEmployees = () => {
      try {
        if (!employeesName || employeesName.length === 0) {
          setLeadOwner("No Employees Found");
          return;
        }

        // Filter employees where first or last name contains the localStorage name
        const filteredEmployees = employeesName.filter(
          (emp) =>
            (emp.EmpFirstName &&
              emp.EmpFirstName.toLowerCase().includes(name?.toLowerCase())) ||
            (emp.EmpLastName &&
              emp.EmpLastName.toLowerCase().includes(name?.toLowerCase()))
        );

        if (filteredEmployees.length > 0) {
          // Use a Set to store unique names
          const uniqueNames = new Set(
            filteredEmployees.map((emp) => {
              const firstName = emp.EmpFirstName || "";
              const lastName = emp.EmpLastName || "";
              return (firstName + " " + lastName).trim();
            })
          );

          // Convert Set to a string
          setLeadOwner([...uniqueNames].join(", "));
        } else {
          setLeadOwner("No Matching Employees Found");
        }
      } catch (error) {
        console.error("Error:", error);
        setLeadOwner("Error Processing Data");
      }
    };

    fetchEmployees();
  }, [name, employeesName]); // ✅ Added `employeesName` as a dependency


  const Submit = async (e) => {
    e.preventDefault();
    if (
      !ClientName ||
      (Status !== "Won" && Status !== "Lost" && !FollowupDate) ||
      !LeadDate ||
      !Source ||
      !ClientPhone ||
      !ClientContactName ||
      !TypeService ||
      !Status ||
      !QuotedValue
      // !LeadOwner
    ) {
      enqueueSnackbar("Please fill all the fields!", { variant: "error" });
      return;
    }
    const lastTenDigits = ClientPhone.slice(-10);
    if (/^0{10}$/.test(lastTenDigits)) {
      enqueueSnackbar("Enter Valid Phone Number!", { variant: "error" });
      return;
    }
    const numericPhone = ClientPhone.replace(/\D/g, ""); // Removes non-numeric characters
if (numericPhone.length < 10) {
  enqueueSnackbar("Phone number must be at least 10 digits!", { variant: "error" });
  return;
}
    if (!emailRegex.test(ClientEmail)) {
      enqueueSnackbar("Please enter a valid email address!", {
        variant: "error",
      });
      return;
    }

    if (Source === "Reference" && !Reference) {
      enqueueSnackbar("Please enter Reference!", { variant: "error" });
      return;
    }

    SetisLoading(true);

    try {
      // ✅ Create a new lead object
      const newLead = {
        // LeadOwner,
        ClientName,
        ClientEmail,
        ProjectName,
        LeadDate,
        ClientPhone,
        QuotedValue,
        ClientContactName,
        Status,
        Source,
        TypeService,
        AssignedGroup,
        LeadType,
        FollowupDate,
        Probability,
        Reference,
        comments,
      };

      // ✅ Dispatch addLead action to Redux
      const response = await dispatch(addLead(newLead)).unwrap();
      // ✅ If successful, show success message and navigate
      enqueueSnackbar("Lead Added Successfully!", { variant: "success" });
      navigate("/dashboard/L3/lead-report");
      // const data = response.data.lead
      // await handleFileUpload(fileRefValue, data.id);
      const data = response.lead; // Directly accessing the lead object
      await handleFileUpload(fileRefValue, data.id); // Use `data.id` (which is 243)

      // setLeadOwner("");
      setClientName("");
      setLeadDate("");
      setClientPhone("");
      setClientContactName("");
      setStatus("");
      setClientEmail("");
      setProjectName("");
      setQuotedValue("");
      setTypeService("");
      setAssignedGroup("");
      setLeadType("");
      setFollowupDate("");
      setShowProb(false);
      setProbability("");
      setSource("");
      setshowRef(false);
      setReference("");
      setComments("");
      dispatch(fetchLeadsByGroups())
      
    } catch (error) {
      console.error("Error:", error);

      if (error.response) {
        if (error.response.status === 400) {
          enqueueSnackbar(error.response.data.error, { variant: "error" });
        } else {
          enqueueSnackbar("Please Check Your Connection!", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Something went wrong. Please try again.", {
          variant: "error",
        });
      }
    } finally {
      SetisLoading(false);
    }
  };

  const Cancel = (e) => {
    e.preventDefault();
    // setLeadOwner("");
    setClientName("");
    setClientEmail("");
    setLeadDate("");
    setClientPhone("");
    setClientContactName("");
    setStatus("");
    setProjectName("");
    setQuotedValue("");
    setTypeService("");
    setLeadType("");
    setAssignedGroup("");
    setSource("");
    setFollowupDate("");
    setShowProb(false);
    setProbability("");
    setshowRef(false);
    setReference("");
    setComments("");
    setCurrentFileName("No File Selected"); // Clear file name

    // Clear the file input manually
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setTodaysDate(today);
  }, []);

  function formatNumberWithCommas(number) {
    if (!number) return ""; // Handle case where number is undefined or null
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  const { LeadReports } = useBackup();
  // const handleClientNameChange = (e) => {
  //   let value = e.target.value;

  //   // Allow only letters and spaces, restrict to 30 characters
  //   if (/^[a-zA-Z\s]*$/.test(value) && value.length <= 30) {
  //     // Capitalize first letter of the first word
  //     value = value.charAt(0).toUpperCase() + value.slice(1);
  //     setClientName(value);
  //   }
  //   setClientError(value === ""); // Set error state based on input value
  // };

  const handleSourceChange = (source) => {
    const value = source;
    setSource(value);
    // setsourceOpen(false);
    // Clear the error when a valid source is selected
    // if (value !== "") {
    //   setSourceError(false);
    // }
    setShowSourceDropdown(false);
  };

  // const handleReferenceChange = (e) => {
  //   let value = e.target.value;

  //   // Check if input contains only letters and spaces, and is within 30 characters
  //   if (/^[a-zA-Z\s]*$/.test(value) && value.length <= 30) {
  //     // Capitalize the first letter of the first word
  //     value = value.charAt(0).toUpperCase() + value.slice(1);

  //     setReference(value);
  //   }

  //   // Clear the error when the Reference field is not empty
  //   if (value !== "") {
  //     setReferenceError(false);
  //   }
  // };

  // const handleGroupChange = (selectedGroup) => {
  //   setAssignedGroup(selectedGroup);
  //   setGroupOpen(false); // Close the dropdown

  //   // Validation: Clear error if a valid group is selected
  //   setGroupError(selectedGroup === "");
  // };

  const handleGroupChange = (Group) => {
    const value = Group;
    setAssignedGroup(value);

    // Clear the error when a valid group is selected
    // if (value !== "") {
    //   setGroupError(false);
    // }
    setShowGroupDropdown(false);
  };
  const handleServiceChange = (service) => {
    const value = service;
    setTypeService(value);
    // setServiceOpen(false); // Close the dropdown

    // Clear error when a valid service is selected
    // if (value !== "") {
    //   setServiceError(false);
    // }
    setShowServiceDropdown(false);
  };

  // Format the quoted value with commas

  // Handle Project Name change
  // const handleProjectNameChange = (e) => {
  //   let value = e.target.value;

  //   if (/^[a-zA-Z\s]*$/.test(value) && value.length <= 30) {
  //     // Capitalize only the first letter of the first word
  //     value = value.charAt(0).toUpperCase() + value.slice(1);

  //     setProjectName(value);
  //   }

  //   setProjectNameError(value === "");
  // };

  // const validateNumberInput = (value, maxLength = 12) => {
  //   return /^\d*$/.test(value) && value.length <= maxLength;
  // };

  // const handleQuotedValueChange = (e) => {
  //   let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

  //   if (validateNumberInput(value)) {
  //     setQuotedValue(value);
  //   }

  //   setQuotedValueError(value === ""); // Handle empty input case
  // };
  // // Handle Quoted Value change
  // const handleQuotedValueChange = (e) => {
  //     const value = e.target.value.replace(/,/g, "").replace(/\D/g, ""); // Strip non-numeric characters
  //     if (/^[a-zA-Z\s]*$/.test(value) && value.length <= 10) {
  //         setQuotedValue(value);
  //     }

  //     if (value === "") {
  //         setQuotedValueError(true);
  //     } else {
  //         setQuotedValueError(false);
  //     }
  // };

  // const handleClientContactNameChange = (e) => {
  //   let value = e.target.value;

  //   if (/^[a-zA-Z\s]*$/.test(value) && value.length <= 30) {
  //     // Capitalize the first letter of the first word
  //     value = value.charAt(0).toUpperCase() + value.slice(1);

  //     setClientContactName(value);
  //   }

  //   if (value === "") {
  //     setClientContactNameError(true);
  //   } else {
  //     setClientContactNameError(false);
  //   }
  // };

  // Handle Client Email change
  // const handleClientEmailChange = (e) => {
  //   const value = e.target.value;
  //   setClientEmail(value);

  //   if (value === "") {
  //     setClientEmailError(true);
  //   } else {
  //     setClientEmailError(false);
  //   }
  // };

  // Handle Lead Type change
  const handleLeadTypeChange = (Leadtype) => {
    const value = Leadtype;
    setLeadType(value);

    // Clear the error when a valid lead type is selected
    // if (value !== "") {
    //   setLeadTypeError(false);
    // }
    setShowLeadTypeDropdown(false);
  };
  // const handlePhoneChange = (value) => {
  //   setClientPhone(value);

  //   // Clear the error if the phone number is valid
  //   if (value && value.length >= 10) {
  //     setClientPhoneError(false);
  //   }
  // };

  // const handlePhoneBlur = () => {
  //   // Set error if phone number is empty or invalid length
  //   setClientPhoneError(ClientPhone === "" || ClientPhone.length < 10);
  // };
  const handleStatusChange = (status) => {
    const value = status;
    setStatus(value);
    setShowStatusDropdown(false);
  };

  const handleProbabilityChange = (Probability) => {
    const value = Probability;
    setProbability(value);
    setShowProbabilityDropdown(false);
  };

  const fileInputRef = useRef(null);
  const [fileRefValue, setFileRef] = useState();
  const [currentFileName, setCurrentFileName] = useState("No File Selected");


  const handleFileChange = (event) => {
    if (!event || !event.target || !event.target.files) return; // Prevents errors

    const file = event.target.files[0]; // Get the file from input

    if (!file) return;

    setCurrentFileName(file.name);

    const maxSize = 2 * 1024 * 1024; // 2MB in bytes
    const allowedFormats = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (!allowedFormats.includes(file.type)) {
      enqueueSnackbar("Only PDF and DOC files are allowed", {
        variant: "error",
      });
      event.target.value = ""; // Clear the file input
      setCurrentFileName("No File Selected"); // Clear the file input
      return;
    }

    if (file.size > maxSize) {
      enqueueSnackbar("File size exceeds 2MB! Please upload a smaller file.", {
        variant: "error",
      });
      event.target.value = "";
      setCurrentFileName("No File Selected");
      return;
    }

    enqueueSnackbar("File Uploaded Successfully!", { variant: "success" });

    setFileRef(event);
    setCurrentFileName(file.name);
  };

  const handleFileUpload = async (event, id) => {
    if (!event || !event.target || !event.target.files) return;
    const file = event.target.files[0];
    if (!file) return;

    const fileName = file.name;
    setCurrentFileName(fileName);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("leadId", id);
    formData.append("uploadDate", new Date().toISOString());

    try {
      // const uploadedFile =
       await dispatch(
        uploadFileInReport(formData)
      ).unwrap(); // Use Redux thunk


      setCurrentFileName("");

      // enqueueSnackbar("File uploaded successfully!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error occurred while uploading file", {
        variant: "error",
      });
      console.error("Error uploading file:", error);
    }
  };
  

  return (
    <div className=" bg-[#f3f8f8] h-full">
      <L3Sidebar />

      {isLoading ? (
        <AdminLoader isLoading={isLoading} />
      ) : (
        <div
          className="ml-[18%]   relative overflow-hidden px-3 justify-center top-2"
          style={{ paddingLeft: "1%" }}
        >
          <div className="bg-white p-2 flex justify-between align-middle items-center rounded-md">
            <h1 className="text-xl font-semibold">Add Lead</h1>
            <div className="relative cursor-pointer bg-transparent">
              <FaUserCircle onClick={logout} className="text-black w-6 h-6" />
              {showLogout && (
                <div
                  ref={popupRef}
                  className="absolute right-0 top-full mt-1 text-black text-center px-2 w-36 shadow-2xl overflow-hidden bg-white border border-gray-400 z-50 rounded-lg"
                >
                  <h1 className="px-6 py-2 rounded-md my-1">{name}</h1>
                  <h1
                    onClick={logoutUser}
                    className="flex items-center gap-2 px-6 py-2 rounded-md my-1 cursor-pointer hover:bg-black hover:text-white"
                  >
                    <MdLogout size={24} /> Logout
                  </h1>
                </div>
              )}
            </div>
          </div>
          <br />
          <div className="bg-white rounded-md p-2">
            <div
              className="grid grid-cols-1 md:grid-cols-4 gap-4 "
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {/* Lead Date */}
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="date">
                  Lead Date
                  <span className="text-red-700 text-xl">*</span>
                </label>
                <DatePicker
                  selected={LeadDate}
                  onChange={(date) => setLeadDate(date)}
                  dateFormat="MM-dd-yyyy"
                  className="border w-full mr-14 pr-16 border-gray-400 rounded-md p-2.5 overflow-hidden shadow-md outline-none"
                  placeholderText="mm-dd-yyyy"
                  maxDate={new Date()}
                  readOnly
                  required
                />
              </div>

              {/* Client Name */}
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Name">
                  Client Name<span className="text-red-700 text-xl">*</span>
                </label>
                <input
                  className="border w-full border-gray-400 rounded-md p-2.5 overflow-hidden shadow-md outline-none"
                  type="text"
                  maxLength={30}
                  placeholder="Enter Name"
                  value={ClientName}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^a-zA-Z\s]/g, ""); // Allows only letters and spaces
                    value = value.charAt(0).toUpperCase() + value.slice(1); // Capitalizes the first letter
                    setClientName(value);
                  }}
                />
              </div>
              <div className="pt-1" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Source">
                  Source<span className="text-red-700 text-xl">*</span>
                </label>
                <div className=" bg-white">
                  <div
                    ref={dropdownRefSource}
                    className="relative flex  p-0  rounded-xl align-middle items-center"
                  >
                    <ExpandMoreIcon
                      color="white"
                      className="absolute cursor-pointer right-2 m-auto"
                      onClick={() => setShowSourceDropdown(!showSourceDropdown)}
                    />

                    <button
                      type="button"
                      onClick={() => setShowSourceDropdown(!showSourceDropdown)}
                      className="border w-full bg-white border-gray-400 rounded-md p-2 shadow-md text-start overflow-hidden  outline-none"
                    >
                      {Source ? Source : <p>Select Source</p>}
                    </button>

                    {showSourceDropdown && (
                      <div
                        style={{ zIndex: 9999 }}
                        className="absolute top-full left-0 w-full p-1 cursor-pointer max-h-96 overflow-y-auto bg-white text-sm border border-gray-300 shadow-lg flex flex-col gap-1"
                      >
                        {Sources.map((Sourc) => (
                          <div
                            className="p-1 hover:bg-gray-200 cursor-pointer"
                            onClick={() => handleSourceChange(Sourc.newSource)}
                            key={Sourc.id}
                            value={Sourc.newSource}
                          >
                            {Sourc.newSource}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

      
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Name">
                  Reference
                  {Source === "Reference" ? (
                    <span className="text-red-700 text-xl">*</span>
                  ) : (
                    <span className="text-red-700 text-xl"></span>
                  )}
                </label>
                <input
                  className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                  type="text"
                  placeholder="Enter Reference"
                  maxLength={30}
                  value={Source === "Reference" ? Reference : ""}
                  // onChange={(e) => setReference(e.target.value)}
                  // onChange={(e) => {
                  //   const value = e.target.value;
                  //   const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
                  //   setReference(capitalizedValue);
                  // }}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^a-zA-Z\s]/g, ""); // Allows only letters and spaces
                    value = value.charAt(0).toUpperCase() + value.slice(1); // Capitalizes the first letter
                    setReference(value);
                  }}
                  disabled={Source !== "Reference"}
                />
              </div>
            </div>

            <div
              className="grid grid-cols-1 md:grid-cols-4 gap-4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >

              <div className="pt-1" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Source">
                  Group<span className="text-red-700 text-xl">*</span>
                </label>
                <div className="w-full ">
                  <div
                    ref={dropdownRefGroup}
                    className="relative flex w-full  p-0 rounded-xl align-middle items-center"
                  >
                    <ExpandMoreIcon
                      color="white"
                      className="absolute cursor-pointer right-0 border-r border-gray-400 m-auto bg-white"
                      onClick={() => setShowGroupDropdown(!showGroupDropdown)}
                    />

                    <button
                      type="button"
                      onClick={() => setShowGroupDropdown(!showGroupDropdown)}
                      className="border w-full bg-white border-gray-400 text-nowrap rounded-md p-2 shadow-md text-start overflow-hidden  outline-none"
                      style={
                        {
                          // backgroundColor: "#f8f5fc", // Light background color for dropdown
                          // Set the height
                          // Adjust padding
                        }
                      }
                    >
                      {AssignedGroup ? AssignedGroup : <p>Select Group</p>}
                    </button>

                    {showGroupDropdown && (
                      <div
                        style={{ zIndex: 9999 }}
                        className="absolute top-full left-0 p-1 w-full cursor-pointer max-h-96 overflow-y-auto bg-white text-sm border border-gray-300 shadow-lg flex flex-col gap-1"
                      >
                        {GroupNames.map((group) => (
                          <div
                            className="p-1 hover:bg-gray-200 cursor-pointer"
                            onClick={() =>
                              handleGroupChange(group)
                            }
                            key={group.id}
                            value={group}
                          >
                            {group}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="pt-1" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Source">
                  Type of Service<span className="text-red-700 text-xl">*</span>
                </label>
                <div className=" bg-white">
                  <div
                    ref={dropdownRefService}
                    className="relative flex  p-0  rounded-xl align-middle items-center"
                  >
                    <ExpandMoreIcon
                      color="white"
                      className="absolute cursor-pointer right-2 m-auto bg-white"
                      onClick={() =>
                        setShowServiceDropdown(!showServiceDropdown)
                      }
                    />

                    <button
                      type="button"
                      onClick={() =>
                        setShowServiceDropdown(!showServiceDropdown)
                      }
                      className="border w-full bg-white border-gray-400 text-nowrap rounded-md p-2 shadow-md text-start overflow-hidden  outline-none"
                      style={
                        {
                          // backgroundColor: "#f8f5fc", // Light background color for dropdown
                          // Set the height
                          // Adjust padding
                        }
                      }
                    >
                      {TypeService ? TypeService : <p>Select Service Type</p>}
                    </button>

                    {showServiceDropdown && (
                      <div
                        style={{ zIndex: 9999 }}
                        className="absolute top-full left-0 p-1 w-full cursor-pointer max-h-96 overflow-y-auto bg-white text-sm border border-gray-300 shadow-lg flex flex-col gap-1"
                      >
                        {serviceTypes.map((serviceType) => (
                          <div
                            className="p-1 hover:bg-gray-200 cursor-pointer"
                            onClick={() =>
                              handleServiceChange(serviceType.ServiceType)
                            }
                            key={serviceType.id}
                            value={serviceType.ServiceType}
                          >
                            {serviceType.ServiceType}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Project Name */}
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Name">
                  Project Name<span className="text-red-700 text-xl">*</span>
                </label>
                <input
                  className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                  type="text"
                  maxLength={30}
                  placeholder="Enter Project Name"
                  value={ProjectName}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^a-zA-Z\s]/g, ""); // Allows only letters and spaces
                    value = value.charAt(0).toUpperCase() + value.slice(1); // Capitalizes the first letter
                    setProjectName(value);
                  }}
                />
              </div>

              {/* Quoted Value */}
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Name">
                  Quoted Value<span className="text-red-700 text-xl">*</span>
                </label>
                <input
                  className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                  type="text"
                  placeholder="Enter Quoted Value"
                  inputMode="numeric"
                  maxLength={12}
                  pattern="[0-9]*"
                  value={formatNumberWithCommas(QuotedValue)}
                  onChange={(e) =>
                    setQuotedValue(
                      e.target.value.replace(/,/g, "").replace(/\D/g, "")
                    )
                  }
                />
              </div>
            </div>

            <div
              className="grid grid-cols-1 md:grid-cols-4 gap-4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {/* Client Contact Name */}
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Name">
                  Contact Person<span className="text-red-700 text-xl">*</span>
                </label>
                <input
                  className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                  type="text"
                  maxLength={30}
                  placeholder="Enter Name"
                  value={ClientContactName}
                  onChange={(e) => {
                    let value = e.target.value.replace(/[^a-zA-Z\s]/g, ""); // Allows only letters and spaces
                    value = value.charAt(0).toUpperCase() + value.slice(1); // Capitalizes the first letter
                    setClientContactName(value);
                  }}
                />
              </div>

              {/* Client Email */}
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="email">
                  Email ID<span className="text-red-700 text-xl">*</span>
                </label>
                <input
                  className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                  type="text"
                  placeholder="Enter Email ID"
                  value={ClientEmail}
                  onChange={(e) => setClientEmail(e.target.value)}
                />
              </div>

              {/* Client Phone */}
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Name">
                  Mobile Number<span className="text-red-700 text-xl">*</span>
                </label>
        
                <PhoneInput
                  country={"us"}
                  value={String(ClientPhone)}
                  onChange={(value) => setClientPhone(value)}
                  inputProps={{
                    required: true,
                    className:
                      "w-full h-10 border text-sm border-gray-400 outline-0 rounded overflow-hidden shadow-md p-2 pl-10",
                    style: {
                      fontFamily:
                        "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                    },
                  }}
                />
              </div>

        
              <div className="pt-1" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Source">
                  Lead Type<span className="text-red-700 text-xl">*</span>
                </label>
                <div className=" bg-white">
                  <div
                    ref={dropdownRefLeadType}
                    className="relative flex  p-0  rounded-xl align-middle items-center"
                  >
                    <ExpandMoreIcon
                      color="white"
                      className="absolute cursor-pointer right-2 m-auto bg-white"
                      onClick={() =>
                        setShowLeadTypeDropdown(!showLeadTypeDropdown)
                      }
                    />

                    <button
                      type="button"
                      onClick={() =>
                        setShowLeadTypeDropdown(!showLeadTypeDropdown)
                      }
                      className="border w-full bg-white border-gray-400 text-nowrap rounded-md p-2 shadow-md text-start overflow-hidden  outline-none"
                    >
                      {LeadType ? LeadType : <p>Select Lead Type</p>}
                    </button>

                    {showLeadTypeDropdown && (
                      <div
                        style={{ zIndex: 9999 }}
                        className="absolute top-full p-1 left-0 w-full cursor-pointer max-h-96 overflow-y-auto bg-white text-sm border border-gray-300 shadow-lg flex flex-col gap-1"
                      >
                        {LeadTypes.map((LeadTyp) => (
                          <div
                            className="p-1 hover:bg-gray-200 cursor-pointer"
                            onClick={() =>
                              handleLeadTypeChange(LeadTyp.LeadType)
                            }
                            key={LeadTyp.id}
                            value={LeadTyp.LeadType}
                          >
                            {LeadTyp.LeadType}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="grid grid-cols-1 md:grid-cols-4 gap-4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >


              <div className="pt-1.5" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Status">
                  Status<span className="text-red-700 text-xl">*</span>
                </label>
                <div className=" bg-white">
                  <div
                    ref={dropdownRefStatus}
                    className="relative flex  p-0  rounded-xl align-middle items-center"
                  >
                    <ExpandMoreIcon
                      color="white"
                      className="absolute cursor-pointer right-2 m-auto bg-white"
                      onClick={() => setShowStatusDropdown(!showStatusDropdown)}
                    />

                    <button
                      type="button"
                      onClick={() => setShowStatusDropdown(!showStatusDropdown)}
                      className="border w-full bg-white border-gray-400 text-nowrap rounded-md p-2 shadow-md text-start overflow-hidden  outline-none"
                    >
                      {Status ? Status : <p>Select Status</p>}
                    </button>

                    {showStatusDropdown && (
                      <div
                        style={{ zIndex: 9999 }}
                        className="absolute top-full p-1 left-0 w-full cursor-pointer max-h-96 overflow-y-auto bg-white text-sm border border-gray-300 shadow-lg flex flex-col gap-1"
                      >
                        {statuses.map((statuse) => (
                          <div
                            className="p-1 hover:bg-gray-200 cursor-pointer"
                            onClick={() => handleStatusChange(statuse.status)}
                            key={statuse.id}
                            value={statuse.status}
                          >
                            {statuse.status}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="pt-1" style={{ width: "22%", height: "4%" }}>
                <label className="block" htmlFor="Status">
                  Probability{" "}
                  {Status === "Pending" ? (
                    <span className="text-red-700 text-xl">*</span>
                  ) : (
                    <span className="text-red-700 text-xl"></span>
                  )}
                </label>
                <div className=" bg-white">
                  <div
                    ref={dropdownRefProbability}
                    className="relative flex  p-0  rounded-xl align-middle items-center"
                  >
                    <ExpandMoreIcon
                      color="white"
                      className="absolute cursor-pointer right-2 m-auto bg-white"
                    />

                    <button
                      type="button"
                      onClick={() =>
                        setShowProbabilityDropdown(!showProbabilityDropdown)
                      }
                      disabled={Status !== "Pending"} // Disable when Status is not "Pending"
                      className="border w-full bg-white border-gray-400 text-nowrap rounded-md p-2 shadow-md text-start overflow-hidden  outline-none"
                      style={
                        {
                          // backgroundColor: "#f8f5fc", // Light background color for dropdown
                          // Set the height
                          // Adjust padding
                        }
                      }
                    >
                      {Probability ? Probability : <p>Select Probability</p>}
                    </button>

                    {showProbabilityDropdown && (
                      <div
                        style={{ zIndex: 9999 }}
                        className="absolute top-full left-0 w-full p-1 cursor-pointer max-h-96 overflow-y-auto bg-white text-sm border border-gray-300 shadow-lg flex flex-col gap-1"
                      >
                        {/* {statuses.map((statuse) => ( */}
                        <div
                          className="p-1 hover:bg-gray-200 cursor-pointer"
                          onClick={() => handleProbabilityChange("High")}
                        >
                          High
                        </div>
                        <div
                          className="p-1 hover:bg-gray-200 cursor-pointer"
                          onClick={() => handleProbabilityChange("Medium")}
                        >
                          Medium
                        </div>
                        <div
                          className="p-1 hover:bg-gray-200 cursor-pointer"
                          onClick={() => handleProbabilityChange("Low")}
                        >
                          Low
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="mb-4" style={{ width: "22.5%", height: "4%" }}>
                <label className="block " htmlFor="date">
                  {Status === "Won" || Status === "Lost" ? (
                    ""
                  ) : (
                    <>
                      {" "}
                      Next Follow-up Date
                      <span className="text-red-700 text-xl">*</span>
                    </>
                  )}
                </label>
                {Status !== "Won" && Status !== "Lost" && (
                  <DatePicker
                    selected={FollowupDate}
                    onChange={(date) => setFollowupDate(date)}
                    dateFormat="MM-dd-yyyy"
                    className="border w-full mr-16 ml-0.5 pr-16 border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                    minDate={
                      LeadDate
                        ? new Date(
                            new Date(LeadDate).setDate(
                              new Date(LeadDate).getDate() + 1
                            )
                          )
                        : null
                    }
                    placeholderText="mm-dd-yyyy"
                    disabled={Status === "Won" || Status === "Lost"}
                  />
                )}
              </div>
              <div className="mb-4" style={{ width: "22%", height: "4%" }}>
                <div className="">
                  <label className="block" htmlFor="LeadType">
                    Upload File
                    {/* <span className="text-red-500 text-xl">*</span> */}
                  </label>

                  <div className="w-full flex justify-start gap-1 items-center p-1">
                    <input
                      type="file"
                      id={`file-upload-${
                        LeadReports.length + 1 || "from-addlead"
                      }`}
                      className="hidden"
                      onChange={(e) => handleFileChange(e)} // Use your predefined function
                    />
                    <label
                      htmlFor={`file-upload-${
                        LeadReports.length + 1 || "from-addlead"
                      }`}
                      className="bg-gray-800 text-xs text-white hover:text-black p-2 rounded-md cursor-pointer hover:bg-gray-300"
                    >
                      Upload File
                    </label>

                    <span className="">{currentFileName}</span>
                  </div>
                  <div className="flex gap-2 text-red-700">
                    <p className="text-xs pl-2">
                      (Please upload .pdf and .docx files only)
                    </p>
                  </div>
                  {/* <div>
                      {showUploadedFiles.map((LeadReport, index) => (
                        <div key={index} className="flex text-xs">
                          <p>{index + 1}.</p>
                          <p className="text-xs">{LeadReport.fileName}</p>{" "}
                        </div>
                      ))}
                    </div> */}
                </div>
              </div>
            </div>
            <br></br>
            {/* Comments */}
            <div className="">
              <label className="block" htmlFor="comments">
                Comments
              </label>
              <textarea
                className="border w-1/2 border-gray-400 rounded-md p-4 overflow-hidden shadow-md outline-none resize-none"
                placeholder="Enter Comments"
                id="comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                required={Status === "Pending"}
                name="comments"
                rows={2}
              />
            </div>

            <div className="flex mt-8 gap-5 justify-start">
              <button
                onClick={Submit}
                className=" py-2 px-7 bg-[#e32278] overflow-hidden shadow-md text-white rounded-lg"
              >
                Confirm
              </button>
              <button
                onClick={Cancel}
                className="py-2 px-7 bg-black hover:bg-white hover:text-black border border-black overflow-hidden shadow-md text-white rounded-lg"
              >
                Cancel
              </button>
            </div>
          </div>
          <br />
        </div>
      )}
    </div>
  );
};

export default AddLead;
