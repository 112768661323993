import React, { useEffect, useMemo, useRef, useState } from "react";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useLead } from "../../../../context/LeadReport";
import { ValidDate } from "../../../Utils/DateUtils";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import TableChartIcon from "@mui/icons-material/TableChart";
import HistoricaltableL1 from "./HistoricaltableL1";
import HistoricalDownload from "./HistoricalDownload";
// import GroupServiceMeanL1 from './GroupServiceMeanL1';
import QuotedMeanL1 from "./QuotedMeanL1";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { fetchStatus } from "../../../../redux/slices/statusSlice";
import { setHistoricalStatusRedux, setHistoricalYearRedux } from "../../../../redux/slices/filterSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HistoricalDataL1 = ({ filteredLeadReports, newLeadReports }) => {

  const dispatch = useDispatch()

  const [quotedMeanL1, setQuotedMeanL1] = useState([])
  const [filteredLeadReportsL1, setFilteredLeadReportsL1] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const { status } = useSelector((state) => state.status);

  useEffect(() => {
    dispatch(fetchStatus())
  }, [dispatch])

  useEffect(() => {
    setQuotedMeanL1(filteredLeadReports)
    setFilteredLeadReportsL1(newLeadReports)
  }, [filteredLeadReports, newLeadReports]);

  useEffect(() => {
    if (status.length > 0) {
      const updatedStatuses = [
        ...status,
        { status: "Non Responsive" },
      ];
      setStatuses(updatedStatuses)
    }
  }, [status])


  const navigate = useNavigate()
  const { setChartDataArray, setChartAmount, setHistoricalL1 } = useLead();

  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowYearDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const StatusdropdownRef = useRef(null);
  const [showstatusDropdown, setshowstatusDropdown] = useState(false);
  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        StatusdropdownRef.current &&
        !StatusdropdownRef.current.contains(event.target)
      ) {
        setshowstatusDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const finalAmountYearWise = useMemo(() => {
    return filteredLeadReportsL1.reduce(
      (amount, current) => amount + current.QuotedValue,
      0
    );
  }, [filteredLeadReportsL1]);

  useEffect(() => {
    setChartDataArray(filteredLeadReportsL1);
    setChartAmount(finalAmountYearWise);
  }, [
    filteredLeadReportsL1,
    finalAmountYearWise,
    setChartAmount,
    setChartDataArray,
  ]);

  const [historicalTab, setHistoricalTab] = useState("graph");

  const [selectedYears, setSelectedYears] = useState([]); // Default empty (user must select)
  const [selectedStatus, setSelectedStatus] = useState([]);

  const handleYearChange = (event) => {
    const year = Number(event.target.value);

    setSelectedYears((prevSelected) => {
      const updatedYears = prevSelected.includes(year)
        ? prevSelected.filter((y) => y !== year) // Remove if already selected
        : [...prevSelected, year]; // Add if not selected

      dispatch(setHistoricalYearRedux(updatedYears)); // Update Redux state

      return updatedYears;
    });
  };

  // const handleStatusChange = (event) => {
  //   const selectedValue = event.target.value;

  //   setSelectedStatus((prev) => {
  //     const updatedStatus = event.target.checked
  //       ? [...prev, selectedValue] // Add if checked
  //       : prev.filter((stat) => stat !== selectedValue); // Remove if unchecked

  //     dispatch(setHistoricalStatusRedux(updatedStatus)); // Update Redux state

  //     return updatedStatus;
  //   });
  // };
  const handleStatusChange = (event) => {
    const selectedValue = event.target.value;

    setSelectedStatus((prev) => {
      let updatedStatus;

      if (selectedValue === "Non Responsive" && event.target.checked) {
        // If "Non Responsive" is selected, uncheck everything else
        updatedStatus = ["Non Responsive"];
      } else if (event.target.checked) {
        // If another status is selected, and "Non Responsive" was already selected, remove it
        updatedStatus = prev
          .filter((stat) => stat !== "Non Responsive")
          .concat(selectedValue);
      } else {
        // If any checkbox is unchecked, just remove it from the list
        updatedStatus = prev.filter((stat) => stat !== selectedValue);
      }

      dispatch(setHistoricalStatusRedux(updatedStatus));
      return updatedStatus;
    });
  };


  const selectedYearsHistorical = useSelector((state) => state.filters.selectedYearsHistorical);
  const selectedStatusHistorical = useSelector((state) => state.filters.selectedStatusHistorical);

  useEffect(() => {
    if (selectedYearsHistorical !== undefined && selectedYearsHistorical !== null) {
      setSelectedYears(selectedYearsHistorical)
    } else { setSelectedYears([]) }
  }, [selectedYearsHistorical])

  useEffect(() => {
    if (selectedStatusHistorical !== undefined && selectedStatusHistorical !== null) {
      setSelectedStatus(selectedStatusHistorical)
    } else { setSelectedStatus([]) }
  }, [selectedStatusHistorical])

  // const handleYearChange = (event) => {
  //   const year = Number(event.target.value);
  //   setSelectedYears(
  //     (prevSelected) =>
  //       prevSelected.includes(year)
  //         ? prevSelected.filter((y) => y !== year) // Remove if already selected
  //         : [...prevSelected, year] // Add if not selected
  //   );
  // };



  const years = useMemo(() => {
    const allYears = filteredLeadReportsL1
      .map((lead) => new Date(lead.LeadDate).getFullYear())
      .filter((year, index, self) => self.indexOf(year) === index) // Remove duplicates
      .sort((a, b) => b - a); // Sort in descending order (optional)
    return allYears;
  }, [filteredLeadReportsL1]);



  const [historicalDownload, setHistoricalDownload] = useState([])


  const graphData = useMemo(() => {
    const yearsToInclude =
      selectedYears.length === 0 ? years : selectedYears.map(Number);
    const twoYearsAgo = new Date();
    twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);

    let firstLeadDate = null;
    let lastLeadDate = null;

    // Step 1: Find the first and last occurrence of a lead across all years
    filteredLeadReportsL1.forEach((lead) => {
      const normalizedDate = ValidDate(lead.LeadDate);
      if (!normalizedDate) return;

      const leadDate = new Date(normalizedDate);

      if (!firstLeadDate || leadDate < firstLeadDate) {
        firstLeadDate = leadDate;
      }
      if (!lastLeadDate || leadDate > lastLeadDate) {
        lastLeadDate = leadDate;
      }
    });

    // If no valid lead dates found, return empty array
    if (!firstLeadDate || !lastLeadDate) return [];

    const allMonths = [];
    const selectedYearsSet = new Set(
      selectedYears.length ? selectedYears.map(Number) : years
    );

    let tempDate = new Date(
      firstLeadDate.getFullYear(),
      firstLeadDate.getMonth(),
      1
    );
    const endDate = new Date(
      lastLeadDate.getFullYear(),
      lastLeadDate.getMonth(),
      1
    );

    while (tempDate <= endDate) {
      // Only include months from selected years
      if (selectedYearsSet.has(tempDate.getFullYear())) {
        const label = `${[
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ][tempDate.getMonth()]
          }\u00A0'${tempDate.getFullYear().toString().slice(-2)}`;

        allMonths.push({
          label,
          year: tempDate.getFullYear(),
          month: tempDate.getMonth() + 1,
          Won: 0,
          WonRecurring: 0,
          Pending: 0,
          Lost: 0,
        });
      }
      tempDate.setMonth(tempDate.getMonth() + 1); // Move to next month
    }


    // Step 3: Fill in actual data
    filteredLeadReportsL1.forEach((lead) => {
      const normalizedDate = new Date(lead.LeadDate);
      if (!normalizedDate) return;

      const leadDate = new Date(normalizedDate);
      const leadYear = leadDate.getFullYear();
      const leadMonth = leadDate.getMonth() + 1;

      if (!yearsToInclude.includes(leadYear)) return;

      if (selectedStatus.includes("Non Responsive")) {
        if (lead.Status !== "Pending") return;
        if (leadDate >= twoYearsAgo) return;
      } else {
        const isStatusIncluded =
          selectedStatus.length === 0 || selectedStatus.includes(lead.Status);
        if (!isStatusIncluded) return;
      }

      const monthLabel = `${[
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ][leadMonth - 1]
        }\u00A0'${leadYear.toString().slice(-2)}`;

      let entry = allMonths.find((e) => e.label === monthLabel);
      if (entry) {
        if (selectedStatus.includes("Non Responsive")) {
          // entry.Won++;
          // entry.WonRecurring++;
          entry.Pending++;
          // entry.Lost++;
        } else {
          if (lead.Status === "Won") entry.Won++;
          else if (lead.Status === "Won Recurring") entry.WonRecurring++;
          else if (lead.Status === "Pending") entry.Pending++;
          else if (lead.Status === "Lost") entry.Lost++;
        }
      }
    });


    let validFilteredLeads = filteredLeadReportsL1.filter((lead) => {
      const normalizedDate = ValidDate(lead.LeadDate);
      if (!normalizedDate) return false;

      const leadDate = new Date(normalizedDate);
      const leadYear = leadDate.getFullYear();

      if (!yearsToInclude.includes(leadYear)) return false;

      if (selectedStatus.includes("Non Responsive")) {
        if (lead.Status !== "Pending") return false;
        if (leadDate >= twoYearsAgo) return false;
      } else {
        const isStatusIncluded =
          selectedStatus.length === 0 || selectedStatus.includes(lead.Status);
        if (!isStatusIncluded) return false;
      }

      return true;
    });
    // Store the filtered leads in state
    setHistoricalDownload(validFilteredLeads);

    return allMonths;
  }, [filteredLeadReportsL1, selectedYears, selectedStatus, years]);

  const totalQuotedValue = useMemo(() => {
    const yearsToInclude =
      selectedYears.length === 0
        ? years // ✅ Includes all available years
        : selectedYears.map(Number);

    const today = new Date();
    const twoYearsAgo = new Date(today);
    twoYearsAgo.setFullYear(today.getFullYear() - 2);

    const total = filteredLeadReportsL1.reduce((sum, lead) => {
      const normalizedDate = ValidDate(lead.LeadDate);
      if (!normalizedDate) return sum;

      const [year, month, day] = normalizedDate.split("-").map(Number);
      const leadDate = new Date(year, month - 1, day);

      // ✅ Check if lead year is in selectedYears
      if (!yearsToInclude.includes(year)) return sum;

      // ✅ If "Non Responsive" is selected, only include "Pending" leads older than 2 years
      if (selectedStatus.includes("Non Responsive")) {
        if (lead.Status !== "Pending" || leadDate >= twoYearsAgo) return sum;
      } else {
        // ✅ Check if lead status is in selectedStatus (or show all if none selected)
        const isStatusIncluded =
          selectedStatus.length === 0 || selectedStatus.includes(lead.Status);
        if (!isStatusIncluded) return sum;
      }

      // ✅ Add QuotedValue if lead matches filters
      return sum + lead.QuotedValue;
    }, 0);
    return total;
  }, [filteredLeadReportsL1, selectedYears, selectedStatus, years]);


  const formatNumber = (num) => {
    if (!num) return "";
    const value = Number(num);
    if (value >= 1_000_000_000) {
      // Billion or more
      const formattedValue = (value / 1_000_000_000).toFixed(1);
      return formattedValue.endsWith(".0")
        ? `${Math.floor(value / 1_000_000_000)}B`
        : `${formattedValue}B `;
    } else if (value >= 1_000_000) {
      // Million or more
      const formattedValue = (value / 1_000_000).toFixed(1);
      return formattedValue.endsWith(".0")
        ? `${Math.floor(value / 1_000_000)}M`
        : `${formattedValue}M `;
    }
    return new Intl.NumberFormat("en-US").format(value); // Add commas for thousands
  };

  const yAxisCanvasRef = useRef(null);
  const chartRef = useRef(null); // Reference for Chart.js

  useEffect(() => {
    if (yAxisCanvasRef.current && chartRef.current) {
      const canvas = yAxisCanvasRef.current;
      const ctx = canvas.getContext("2d");

      // Clear previous labels
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Get Y-axis labels from Chart.js
      const yScale = chartRef.current.scales.y;
      if (!yScale) return;

      const yLabels = yScale.ticks.map((tick) => tick.value); // Extract computed labels
      const canvasHeight = canvas.height;

      // Set styles for text
      ctx.font = "14px Arial";
      ctx.fillStyle = "black";
      ctx.textAlign = "right";

      // Draw Y-axis title (rotated)
      ctx.save();
      ctx.translate(15, canvasHeight / 2);
      ctx.rotate(-Math.PI / 2);
      ctx.fillText("# of Leads", 0, 0);
      ctx.restore();

      // Draw labels from Chart.js on the canvas
      yLabels.forEach((label, index) => {
        const yPos =
          (1 - index / (yLabels.length - 1)) * (canvasHeight - 40) + 20;
        ctx.fillText(label, 45, yPos);
      });
    }
  }, [graphData]); // Re-run when data updates

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      if (selectedStatus.includes("Non Responsive")) {
        // Find the first bar with actual Pending data
        const firstNonEmptyIndex = graphData.findIndex((entry) => entry.Pending > 0);

        if (firstNonEmptyIndex !== -1) {
          const targetElement = scrollContainer.children[firstNonEmptyIndex];

          if (targetElement) {
            // Smooth scrolling to the first data point
            scrollContainer.scrollTo({
              left: targetElement.offsetLeft - 50, // Adjust for padding
              behavior: "smooth", // Enables smooth scrolling
            });
          }
        }
      } else {
        // Default scrolling behavior (scroll to the rightmost)
        scrollContainer.scrollTo({
          left: scrollContainer.scrollWidth,
          behavior: "smooth",
        });

        // Prevent excessive right scrolling
        const handleScroll = () => {
          if (
            scrollContainer.scrollLeft >=
            scrollContainer.scrollWidth - scrollContainer.clientWidth
          ) {
            scrollContainer.scrollLeft =
              scrollContainer.scrollWidth - scrollContainer.clientWidth;
          }
        };

        scrollContainer.addEventListener("scroll", handleScroll);
        return () => scrollContainer.removeEventListener("scroll", handleScroll);
      }
    }
  }, [graphData, selectedStatus, historicalTab]);



  const minBars = 3; // Minimum bars to take full width
  const barWidth = 80; // Width of a single bar
  const totalBars = graphData.length;

  // Calculate width based on bar count
  const containerWidth =
    totalBars < minBars ? minBars * barWidth : totalBars * barWidth;

  // const statusKeyMap = {
  //   Won: "Won",
  //   "Won Recurring": "WonRecurring", // Fix the mismatch
  //   Pending: "Pending",
  //   Lost: "Lost",
  //   // "Non Responsive": "NonResponsive"
  // };

  // const selectedStatusesMapped = selectedStatus
  //   .filter((status) => Object.keys(statusKeyMap).includes(status))
  //   .map((status) => statusKeyMap[status]);

  // // Include trendline **only when one status is selected**
  // // const showTrendline = selectedStatusesMapped.length === 1;

  const datasets = [
    {
      label: "Won",
      data: graphData.map((entry) => entry.Won > 0 ? entry.Won : undefined),
      // data: graphData.map((entry) => entry.Won), 
      backgroundColor: "#15803d",
      stack: "Stack 0",
      borderRadius: 8,
      borderWidth: 0,
      order: 2,
    },
    {
      label: "Won Recurring",
      data: graphData.map((entry) =>
        entry.WonRecurring > 0 ? entry.WonRecurring : null
      ),
      backgroundColor: "#89AC46",
      stack: "Stack 0",
      borderRadius: 8,
      borderWidth: 0,
      order: 2,
    },
    {
      label: "Pending",
      data: graphData.map((entry) =>
        entry.Pending > 0 ? entry.Pending : null
      ),
      backgroundColor: "orange",
      stack: "Stack 0",
      borderRadius: 8,
      borderWidth: 0,
      order: 2,
    },
    {
      label: "Lost",
      data: graphData.map((entry) => (entry.Lost > 0 ? entry.Lost : null)),
      backgroundColor: "#DC2626",
      stack: "Stack 0",
      borderRadius: 8,
      borderWidth: 0,
      order: 2,
    },
  ];


  const historicalNavigationL1 = (Status, monthName, year) => {
    const filteredData = historicalDownload.filter(lead => {
      // ✅ Convert LeadDate to a standard Date object
      let leadDate = new Date(lead.LeadDate);
      // ✅ Extract month name and year from LeadDate
      let leadMonthName = leadDate.toLocaleString('en-US', { month: 'long' });

      let leadYear = leadDate.getFullYear();

      // ✅ Compare Status, Month, and Year
      return lead.Status === Status && leadMonthName === monthName && leadYear === year;
    });

    setHistoricalL1(filteredData); // ✅ Set state with filtered results
    navigate('/dashboard/L1/leadreport-11')
  };



  const handleBarClick = (event, elements) => {
    if (!elements.length) return; // No bar clicked

    const chart = chartRef.current; // Get chart instance
    const datasetIndex = elements[0].datasetIndex; // Dataset index (stack)
    const dataIndex = elements[0].index; // Data index (month/year)

    const dataset = chart.data.datasets[datasetIndex]; // Get dataset
    const Status = dataset.label; // Stack label (Won, Lost, etc.)

    // ✅ Get corresponding month and year from graphData
    const clickedData = graphData[dataIndex];
    const monthNumber = clickedData.month; // e.g., 1 for Jan, 2 for Feb
    const year = clickedData.year; // e.g., 1989, 2024

    // ✅ Convert month number to full name
    const monthName = new Date(year, monthNumber - 1).toLocaleString('en-US', {
      month: 'long',
    });

    historicalNavigationL1(Status, monthName, year)
  };




  return (
    <div className="pt-2 pb-2 pl-2 rounded-md flex">
      <div
        className="w-[70%]  bg-white relative overflow-auto justify-center rounded-md p-2"
        style={{ fontFamily: "Lora", fontWeight: 600 }}
      >
        <div className="flex justify-between border-b border-[#e0e7ff]">
          <div className="mr-[3%]">
            <h1 className="text-2xl mb-2 font-semibold sm:text-sm md:text-sm lg:text-xl xl:text-xl">
              HISTORICAL DATA
            </h1>
          </div>
          <div className="flex gap-2">
            <div className="mr-[0.9%]">

              <HistoricalDownload historicalDownload={historicalDownload} />
            </div>

            <div>
              <div className="flex items-center justify-between  ">
                {historicalTab === "table" && (
                  <span title="Graph">
                    <SignalCellularAltIcon
                      className="cursor-pointer"
                      onClick={() => setHistoricalTab("graph")}
                    // fontSize="4rem"
                    />
                  </span>
                )}
                {historicalTab === "graph" && (
                  <span title="Table">
                    <TableChartIcon
                      className="cursor-pointer"
                      onClick={() => setHistoricalTab("table")}
                    // fontSize="4rem"
                    />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <br />

        <div>

          <div className=" gap-1">
            <div className="flex flex-col gap-y-1">
              <div className="flex flex-wrap lg:flex-nowrap md:gap-y-2 justify-between gap-x-4">
                <div className="flex gap-2">
                  <div className="flex h-7 gap-1">
                    <div className="text-xs h-full  p-0.5 flex align-middle items-center text-center">
                      Year
                    </div>
                    <div ref={dropdownRef} className="relative border w-24 border-[#e0e7ff] rounded-md p-0.5">
                      <ExpandMoreIcon
                        color="white"
                        className="absolute right-0 bg-white"
                        onClick={() => setShowYearDropdown(!showYearDropdown)}

                      />
                      <button
                        type="button"
                        onClick={() => setShowYearDropdown(!showYearDropdown)}
                        className="flex justify-start items-center align-middle w-full h-full focus:outline-none text-xs"
                      >
                        {selectedYears.length === 0
                          ? "Select Year"
                          : selectedYears.join(", ").length <= 10 // Adjust length based on button width
                            ? selectedYears.join(", ")
                            : "Multiple"}
                      </button>

                      {showYearDropdown && (
                        <div className="absolute z-50 w-24 bg-white text-xs border border-gray-300 overflow-y-auto shadow-lg max-h-48 mt-0.5">
                          {years.map((year) => (
                            <label key={year} className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer">
                              <input
                                type="checkbox"
                                value={year}
                                checked={selectedYears.includes(year)}
                                onChange={handleYearChange}
                                className="mr-2"
                              />
                              {year}
                            </label>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>



                  <div className="flex gap-1 h-7 ">
                    <div className="text-xs h-full   p-0.5 flex items-center text-center">
                      Status
                    </div>
                    <div ref={StatusdropdownRef} className="relative border w-24 border-[#e0e7ff] rounded-md shadow-sm p-0.5">
                      <ExpandMoreIcon
                        color="white"
                        className="absolute right-0 bg-white"
                        onClick={() => setshowstatusDropdown(!showstatusDropdown)}

                      />
                      <button
                        type="button"
                        onClick={() => setshowstatusDropdown(!showstatusDropdown)}
                        className="flex justify-start items-center w-full h-full focus:outline-none text-xs overflow-hidden truncate p-2 min-w-0 flex-1 text-left"
                      >
                        {selectedStatus.length === 0
                          ? "All Status"
                          : selectedStatus.join(", ").length < 14  // Adjust length limit as needed
                            ? selectedStatus.join(", ")
                            : "Multiple"}
                      </button>

                      {showstatusDropdown && (
                        <div className="absolute z-10 min-w-40 bg-white text-sm border border-gray-300 shadow-lg max-h-48 overflow-y-auto overflow-x-hidden mt-1">
                          {[...statuses] // Create a new array to avoid mutating the original
                            .sort((a, b) => {
                              const order = ["Won", "Won Recurring", "Pending", "Lost", "Non Responsive"];
                              const indexA = order.indexOf(a.status);
                              const indexB = order.indexOf(b.status);
                              if (indexA === -1 && indexB === -1) return 0;
                              if (indexA === -1) return 1;
                              if (indexB === -1) return -1;
                              return indexA - indexB;
                            })
                            .map((status) => (
                              <label key={status.id} className="flex items-center px-4 py-2 min-w-40 hover:bg-gray-100 cursor-pointer">
                                <input
                                  type="checkbox"
                                  value={status.status}
                                  checked={selectedStatus.includes(status.status)}
                                  onChange={handleStatusChange}
                                  className="mr-2"
                                />
                                {status.status}
                              </label>
                            ))}
                        </div>
                      )}

                    </div>
                  </div>
                </div>

                <div className=" flex gap-2 py-1">
                  <label htmlFor="Select Status" className="pt-1">
                    Total Quoted Value{"    "}
                  </label>
                  <h2 className="text-2xl  text-[#157f3d]">
                    ${formatNumber(totalQuotedValue)}
                  </h2>
                </div>
              </div>
            </div>
            <hr />
            <hr />
            {historicalTab === "graph" ? (
              <div className="pt-10  flex ">
                <div className="flex w-full">
                  <div style={{ width: "23px" }} className="">
                    <Bar
                      ref={chartRef}
                      data={{
                        labels: graphData.map((entry) => entry.label),
                        datasets: [
                          {
                            label: "Won",
                            data: graphData.map((entry) => entry.Won),
                            backgroundColor: "#00BFFF",
                            stack: "Stack 0",
                            borderRadius: 8,
                          },
                          {
                            label: "Won Recurring",
                            data: graphData.map((entry) => entry.WonRecurring),
                            backgroundColor: "yellowgreen",
                            stack: "Stack 0",
                            borderRadius: 8,
                          },
                          {
                            label: "Pending",
                            data: graphData.map((entry) => entry.Pending),
                            backgroundColor: "#FF9A00",
                            stack: "Stack 0",
                            borderRadius: 8,
                          },
                          {
                            label: "Lost",
                            data: graphData.map((entry) => entry.Lost),
                            backgroundColor: "#FF6347",
                            stack: "Stack 0",
                            borderRadius: 8,
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        layout: {
                          padding: {
                            bottom: 45,
                          },
                        },
                        plugins: {
                          legend: { display: false },
                          tooltip: {
                            enabled: true,
                            callbacks: {
                              title: () => null,
                              label: (tooltipItem) =>
                                `${tooltipItem.dataset.label}: ${tooltipItem.raw}`,
                            },
                          },
                        },
                        scales: {
                          x: {
                            ticks: { display: false }, // Hide X-axis labels
                            grid: { drawBorder: false }, // Hide X-axis grid lines
                            border: { display: false }, // Hide X-axis border
                          },
                          y: {
                            stacked: true,
                            grid: { drawTicks: false, display: false }, // Hide Y-axis grid lines
                            ticks: { display: false }, // Hide Y-axis ticks
                            border: { display: false }, //  Hide Y-axis line
                            title: {
                              display: true, // Show Y-axis title
                              text: "# of Leads",
                              font: { size: 14, family: "Arial", weight: "bold" }, // Customize font
                              color: "black",
                              padding: { right: 8 },
                            },
                          },
                        },

                        barThickness: 40,
                      }}
                    />
                  </div>

                  <div
                    ref={scrollContainerRef}
                    className="overflow-x-auto "
                    style={{ width: "calc(100% - 40px)" }}
                  >
                    <div
                      style={{
                        width: `${containerWidth}px`,
                        minWidth: "100%",
                        height: "450px",
                        display: "flex",
                        justifyContent:
                          totalBars < minBars ? "flex-start" : "center", // Left-align if < 4 bars
                      }}
                    >
                      {graphData.length > 0 ? (

                        <Bar
                          ref={chartRef}
                          data={{
                            labels: graphData.map((entry) => entry.label),
                            datasets: datasets, // ✅ Dynamically controlled datasets
                          }}
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            elements: {

                              bar: {
                                minBarLength: 0,
                                borderWidth: 0,
                                bar: { clip: false },
                                line: { clip: false },
                              },
                            },
                            plugins: {
                              legend: { display: false },
                              tooltip: {
                                enabled: true,
                                callbacks: {
                                  title: () => null,
                                  label: (tooltipItem) =>
                                    `${tooltipItem.dataset.label}: ${Math.round(tooltipItem.raw)}`,
                                },
                              },
                            },
                            scales: {
                              x: {
                                display: true,
                                beginAtZero: true,
                                stacked: true,
                                ticks: { // Changed from 'tick' to 'ticks'
                                  autoSkip: false,
                                  color: "black",
                                  font: {
                                    family: "Arial", // Ensure the font family is correctly spelled
                                    size: 14, // Set the desired font size
                                    weight: 'bold', // Set the font weight to bold
                                  },
                                },
                                grid: { display: false },
                                categoryPercentage: 0.9,
                                barPercentage: 0.6,
                              },
                              // x: {
                              //   display: true,
                              //   beginAtZero: true,
                              //   stacked: true,
                              //   tick: {
                              //     autoSkip: false,
                              //     color: "black",
                              //     font: {
                              //       family:"Aerial",
                              //       size: 14, // Increase font size
                              //       weight: 'bold', // Make font bold
                              //     },
                              //    },
                              //   grid: { display: false },
                              //   categoryPercentage: 0.9,
                              //   barPercentage: 0.6,
                              //   ticks: { autoSkip: false },
                              // },
                              y: {
                                display: false,
                                stacked: true,
                                grid: { drawTicks: false, display: false },
                                ticks: {
                                  display: true,
                                  color: "#A0A4A5",
                                  autoSkip: false,
                                  stepSize: 1,
                                  beginAtZero: true,
                                  precision: 0,
                                  maxTicksLimit: 10,
                                },
                              },
                              yTrend: {
                                position: "right",
                                display: false,
                                min: 0,
                                suggestedMin: 0,
                                suggestedMax: Math.ceil(
                                  Math.max(...graphData.map(
                                    (entry) => entry.Won + entry.WonRecurring + entry.Pending + entry.Lost
                                  ))
                                ),
                              },
                            },
                            barThickness: 40,
                            onClick: handleBarClick,
                          }}
                        />
                      ) : (
                        <div className="flex justify-center items-center w-full text-gray-500 text-lg ">
                          <h2 className="text-[#ef4450]">No data available</h2>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

              </div>
            ) : (
              <div className="w-full mt-4">
                <HistoricaltableL1 historicalDownload={historicalDownload} selectedStatus={selectedStatus} />
              </div>

            )}
          </div>

        </div>
      </div>
      <div className="w-[30%] ">
        <QuotedMeanL1 quotedMeanL1={quotedMeanL1} />
        {/* <GroupServiceMeanL1 /> */}
      </div>

    </div>
  );
};

export default HistoricalDataL1;