import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";

// **Fetch all service types**
export const fetchServiceTypes = createAsyncThunk("serviceTypes/fetch", async () => {
    const response = await axiosInstance.get("/serviceTypes");
    return response.data;

});

// **Add a new service type**
export const addServiceType = createAsyncThunk("serviceTypes/add", async (serviceType, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post("/serviceTypes", { ServiceType: serviceType });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Error adding service type");
  }
});

// **Update a service type**
export const updateServiceType = createAsyncThunk("serviceTypes/update", async ({ id, serviceType }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.put(`/serviceTypes/${id}`, { ServiceType: serviceType });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Error updating service type");
  }
});

// **Delete a service type**
export const deleteServiceType = createAsyncThunk("serviceTypes/delete", async (id, { rejectWithValue }) => {
  try {
    await axiosInstance.delete(`/serviceTypes/${id}`);
    return id;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Error deleting service type");
  }
});

// **Redux Slice**
const serviceTypeSlice = createSlice({
  name: "serviceTypesRedux",
  initialState: {
    serviceTypesRedux: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceTypes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServiceTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceTypesRedux = action.payload;
      })
      .addCase(fetchServiceTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addServiceType.fulfilled, (state, action) => {
        state.serviceTypesRedux.push(action.payload);
      })
      .addCase(updateServiceType.fulfilled, (state, action) => {
        const index = state.serviceTypesRedux.findIndex(st => st.id === action.payload.id);
        if (index !== -1) state.serviceTypesRedux[index] = action.payload;
      })
      .addCase(deleteServiceType.fulfilled, (state, action) => {
        state.serviceTypesRedux = state.serviceTypesRedux.filter(st => st.id !== action.payload);
      });
  },
});

export default serviceTypeSlice.reducer;
