import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import React, { useState, useCallback, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import TableChartIcon from "@mui/icons-material/TableChart";
import { useNavigate } from "react-router-dom";
import Chart from "chart.js/auto";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Document, Packer, Paragraph, Table, TableCell, TableRow } from "docx";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useLead } from "../../../../context/LeadReport";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { fetchStatus } from './../../../../redux/slices/dataSliceL3';
import { setPotentialFromDateReduxL3, setPotentialStatusReduxL3, setPotentialToDateReduxL3 } from "../../../../redux/slices/filterSlice";

const PotentialStatusL3 = ({ filteredLeadReports }) => {

  const [potentialStatusDownload, setPotentialStatusDownload] = useState([]);
  const StatusdropdownRef = useRef(null);
  const [showsourseDropdown, setshowsourceDropdown] = useState(false);
  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        StatusdropdownRef.current &&
        !StatusdropdownRef.current.contains(event.target)
      ) {
        setshowsourceDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const formatDateExcel = (date) => {
    const d = new Date(date);
    const yy = d.getFullYear().toString().slice(-2); // Extract last 2 digits of the year
    const mm = String(d.getMonth() + 1).padStart(2, "0"); // Month (0-based)
    const dd = String(d.getDate()).padStart(2, "0"); // Day
    return `${yy}-${mm}-${dd}`;
  };

  const handleDownload = (event) => {
    const value = event.target.value;

    if (value === "pdf") {
      downloadPDF();
    } else if (value === "word") {
      downloadWord();
    } else if (value === "excel") {
      downloadExcel();
    }
    setIsDropdownVisible(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible); // Toggle dropdown visibility
  };

  const downloadExcel = () => {
    const sortedLeads = [...potentialStatusDownload].sort(
      (a, b) => new Date(a.LeadDate) - new Date(b.LeadDate)
    );

    const ws = XLSX.utils.json_to_sheet(
      sortedLeads.map((LeadReport, index) => ({
        "#": index + 1,
        "Lead Date": LeadReport.LeadDate
          ? formatDateExcel(LeadReport.LeadDate)
          : "N/A",
        "Client Name": LeadReport.ClientName,
        "Project Name": LeadReport.ProjectName,
        "Lead Type": LeadReport.LeadType,
        "Quoted Value": Number(LeadReport.QuotedValue), // ✅ Ensure it's a number
        Source: LeadReport.Source,
        Status: LeadReport.Status,
        Group: LeadReport.AssignedGroup,
        "Follow-up Date":
          LeadReport.Status === "Won" || LeadReport.Status === "Lost"
            ? "N/A"
            : formatDate(LeadReport.FollowupDate),
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Lead Reports");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelFile = new Blob([excelBuffer], {
      bookType: "xlsx",
      type: "application/octet-stream",
    });
    saveAs(excelFile, "LeadReports.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "#",
          "Lead Date",
          "Client Name",
          "Project Name",
          "Lead Type",
          "Quoted Value",
          "Source",
          "Status",
          "Group",
          "Follow-up Date",
        ],
      ],
      body: potentialStatusDownload.map((LeadReport, index) => [
        index + 1,
        LeadReport.LeadDate ? formatDate(LeadReport.LeadDate) : "N/A",
        LeadReport.ClientName,
        LeadReport.ProjectName,
        LeadReport.LeadType,
        `$${LeadReport.QuotedValue.toString().replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        )}`,
        LeadReport.Source,
        LeadReport.Status,
        LeadReport.AssignedGroup,
        LeadReport.Status === "Won" || LeadReport.Status === "Lost"
          ? "N/A"
          : formatDate(LeadReport.FollowupDate),
      ]),
      styles: {
        fontSize: 7,
      },
      headStyles: {
        fontSize: 8,
      },
    });
    doc.save("LeadReports.pdf");
  };

  const downloadWord = () => {
    const headerRow = new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph("S.No")],
        }),
        new TableCell({
          children: [new Paragraph("Lead Date")],
        }),
        new TableCell({
          children: [new Paragraph("Client Name")],
        }),
        new TableCell({
          children: [new Paragraph("Project Name")],
        }),
        new TableCell({
          children: [new Paragraph("Lead Type")],
        }),
        new TableCell({
          children: [new Paragraph("Quoted Value")],
        }),
        new TableCell({
          children: [new Paragraph("Source")],
        }),
        new TableCell({
          children: [new Paragraph("Status")],
        }),
        new TableCell({
          children: [new Paragraph("Group")],
        }),
        new TableCell({
          children: [new Paragraph("Follow-up Date")],
        }),
      ],
    });

    const dataRows = potentialStatusDownload.map((LeadReport, index) => {
      return new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph(`${index + 1}`)],
          }),
          new TableCell({
            children: [
              new Paragraph(
                LeadReport.LeadDate ? formatDate(LeadReport.LeadDate) : "N/A"
              ),
            ],
          }),
          new TableCell({
            children: [new Paragraph(LeadReport.ClientName)],
          }),
          new TableCell({
            children: [new Paragraph(LeadReport.ProjectName)],
          }),
          new TableCell({
            children: [new Paragraph(LeadReport.LeadType)],
          }),
          new TableCell({
            children: [
              new Paragraph(
                `$${LeadReport.QuotedValue.toString().replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )}`
              ),
            ],
          }),
          new TableCell({
            children: [new Paragraph(LeadReport.Source)],
          }),
          new TableCell({
            children: [new Paragraph(LeadReport.Status)],
          }),
          new TableCell({
            children: [new Paragraph(LeadReport.AssignedGroup)],
          }),
          new TableCell({
            children: [
              new Paragraph(
                LeadReport.Status === "Won" || LeadReport.Status === "Lost"
                  ? "N/A"
                  : formatDate(LeadReport.FollowupDate)
              ),
            ],
          }),
        ],
      });
    });

    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Table({
              rows: [headerRow, ...dataRows],
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "LeadReports.docx");
    });
  };

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsDropdownVisible(false); // Close the popup if clicked outside
    }
  };
  useEffect(() => {
    if (isDropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isDropdownVisible]);

  const [filteredLeadReportsLead, setFilteredLeadReportsLead] = useState([]);

  useEffect(() => {
    setFilteredLeadReportsLead(filteredLeadReports);
  }, [filteredLeadReports]);


  const [totalGraphCount, setTotalGraphCount] = useState(0);
  const [totalPercentage, setTotalPercentage] = useState(0);

  const aggregatePotentialStatusData = useCallback(
    (filteredData) => {
      if (filteredLeadReportsLead.length === 0) {
        return;
      }

      // Calculate total amount and count for filtered data
      const totalFilteredAmount = filteredData.reduce(
        (sum, lead) => sum + (lead.QuotedValue || 0),
        0
      );
      const totalFilteredCount = filteredLeadReportsLead.length;
      setTotalGraphCount(totalFilteredCount);
      const totalCauculatedCount = filteredData.length;

      // Group data by status
      const groupedData = filteredData.reduce((acc, lead) => {
        const status = lead.Status;
        if (!acc[status]) {
          acc[status] = [];
        }
        acc[status].push(lead);
        return acc;
      }, {});

      // Process each status group
      const statusData = Object.keys(groupedData).map((status) => {
        const leadsForStatus = groupedData[status];
        const totalQuotedAmount = leadsForStatus.reduce(
          (sum, lead) => sum + (lead.QuotedValue || 0),
          0
        );
        const count = leadsForStatus.length;
        const percentage = ((count / totalFilteredCount) * 100).toFixed(2);

        return {
          status,
          amount: totalQuotedAmount,
          count,
          percentage: parseFloat(percentage), // Convert to number for accurate summation
        };
      });

      // Calculate total percentage
      const totalPercentage = statusData
        .reduce((sum, status) => sum + status.percentage, 0)
        .toFixed(2);

      // Update state with aggregated data
      setPotentialStatus(statusData);
      setTotalStatusAmount(totalFilteredAmount);
      setTotalStatusCount(totalCauculatedCount);
      setTotalPercentage(totalPercentage); // Store total percentage in state
    },
    [ filteredLeadReportsLead]
  );

  useEffect(() => {
    if (filteredLeadReportsLead.length > 0) {
      aggregatePotentialStatusData(filteredLeadReports);
      setPotentialStatusDownload(filteredLeadReports);
    }
  }, [filteredLeadReports, aggregatePotentialStatusData,filteredLeadReportsLead]);

  const [selectedTab, setSelectedTab] = useState("graph");

  const dispatch = useDispatch()
  const statusPieChart = useRef();
  const statusChartRef = useRef(null);
  const navigate = useNavigate();
  const [potentialStatusfinal] = useState("All");
  const [potentialStatus, setPotentialStatus] = useState([]);
  const [totalStatusAmount, setTotalStatusAmount] = useState(0);
  const [totalstatusCount, setTotalStatusCount] = useState(0);
  const [statuses, setStatuses] = useState([]);
  const [statusFilterDateFrom, setStatusFilterDateFrom] = useState();
  const [statusFilterDateTo, setStatusFilterDateTo] = useState();
  const [statusFilter, setStatusFilter] = useState("All");

    const handleFromDateChange = (date) => {
      if(!date) return;
      setStatusFilterDateFrom(date);
      dispatch(setPotentialFromDateReduxL3(date));
    };
    const handleToDateChange = (date) => {
      if(!date) return;
      setStatusFilterDateTo(date);
      dispatch(setPotentialToDateReduxL3(date))
    };
    const handleStatusChange = (status) => {
      setStatusFilter(status);
      setshowsourceDropdown(false); // Close dropdown after selection
      dispatch(setPotentialStatusReduxL3(status))
    };

  const { status, } = useSelector((state) => state.data);
    const statusFilterDateFromReduxL3 = useSelector((state) => state.filters.statusFilterDateFromL3);
    const statusFilterDateToReduxL3 = useSelector((state) => state.filters.statusFilterToFromL3);
    const statusPotentialReduxL3 = useSelector((state) => state.filters.statusFilterL3);
    
      useEffect(()=>{
        if(statusFilterDateFromReduxL3 !== undefined && statusFilterDateFromReduxL3 !== null){
          setStatusFilterDateFrom(statusFilterDateFromReduxL3)
        }else{setStatusFilterDateFrom("")}
      },[statusFilterDateFromReduxL3])
  
      useEffect(()=>{
  if(statusFilterDateToReduxL3 !== undefined && statusFilterDateToReduxL3 !== null){
    setStatusFilterDateTo(statusFilterDateToReduxL3)
  }else{setStatusFilterDateTo("")}
      },[statusFilterDateToReduxL3])
  
      useEffect(()=>{
  if(statusPotentialReduxL3 !== undefined && statusPotentialReduxL3 !== null){
    setStatusFilter(statusPotentialReduxL3)
  }else{setStatusFilter("All")}
    },[statusPotentialReduxL3])
  


  useEffect(() => {
    dispatch(fetchStatus());
  }, [dispatch]);

  useEffect(()=>{
    setStatuses(status)
  },[status])



  const sortedStatuses = [...statuses].sort((a, b) => {
    const order = ["Won", "Won Recurring", "Pending", "Lost"];
    const indexA = order.indexOf(a.status);
    const indexB = order.indexOf(b.status);
  
    if (indexA === -1 && indexB === -1) return 0;
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
  
    return indexA - indexB;
  });
  

  const { setPotentialReportL3_11 } = useLead();

  const potentialNavigationl3 = useCallback(
    (clickedStatus) => {
      const filteredDatapotentiall3 = potentialStatusDownload.filter(
        (item) => item.Status === clickedStatus
      );

      setPotentialReportL3_11(filteredDatapotentiall3);
      navigate("/dashboard/L3/lead-report13");
    },
    [potentialStatusDownload, navigate, setPotentialReportL3_11]
  );


  const handlePotentialStatusFilter = () => {
    // Filter data based on date range and status selection
    const filteredData = filteredLeadReports.filter((item) => {
      const itemDate = new Date(item.LeadDate); // Replace 'date' with your actual date field name

      const isWithinDateRange =
        (!statusFilterDateFrom || itemDate >= statusFilterDateFrom) &&
        (!statusFilterDateTo || itemDate <= statusFilterDateTo);

      const isMatchingStatus =
        !statusFilter || statusFilter === "All" || item.Status === statusFilter;

      return isWithinDateRange && isMatchingStatus;
    });

    // Update state or pass filtered data for further processing
    setPotentialStatus(filteredData);
    aggregatePotentialStatusData(filteredData);
    setPotentialStatusDownload(filteredData);
  };

  useEffect(() => {
    const renderStatusChart = () => {
      if (potentialStatus.length > 0 && selectedTab === "graph") {
        const ctxStatus = statusPieChart.current;

        if (statusChartRef.current) {
          statusChartRef.current.destroy();
        }

        const statusOrder = ["Won", "Won Recurring", "Pending", "Lost"];
        const sortedPotentialStatus = [...potentialStatus].sort(
          (a, b) =>
            statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
        );

        const completePotentialStatus = statusOrder.map((status) => {
          const found = sortedPotentialStatus.find(
            (item) => item.status === status
          );
          return found ? found : { status, count: 0 };
        });

        const customDefaultLabelPlugin = {
          id: "customDefaultLabel",
          afterDatasetsDraw(chart) {
            const { ctx, data } = chart;
            ctx.save();
            ctx.font = "bold 14px Arial";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";

            data.datasets.forEach((dataset, datasetIndex) => {
              dataset.data.forEach((value, index) => {
                const meta = chart.getDatasetMeta(datasetIndex);
                const bar = meta.data[index];

                if (bar && value !== null) {
                  const { x, y } = bar.tooltipPosition();
                  const percentage = ((value / totalGraphCount) * 100).toFixed(
                    2
                  );
                  const adjustedY = y - 6;

                  ctx.fillStyle = "black";
                  const numericPercentage = Number(percentage);
                  if (numericPercentage !== 0) {
                    ctx.fillText(`${numericPercentage}%`, x, adjustedY);
                  }
                                 }
              });
            });

            ctx.restore();
          },
        };

        statusChartRef.current = new Chart(ctxStatus, {
          type: "bar",
          data: {
            labels: completePotentialStatus.map((item) => item.status),
            datasets: [
              {
                label: "Percentage",
                borderRadius: 8,
                data: completePotentialStatus.map((item) =>
                  potentialStatusfinal === "All" ||
                  item.status === potentialStatusfinal
                    ? item.count
                    : 0
                ),
                backgroundColor: ["#15803d", "#89AC46", "orange", "#DC2626"],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: { display: false },
              tooltip: { enabled: false },
            },
            onClick: (event, elements) => {

              if (elements.length > 0) {
                const clickedElementIndex = elements[0].index;
                const clickedStatus =
                  completePotentialStatus[clickedElementIndex]?.status;
                potentialNavigationl3(clickedStatus);

    
              } else {
                console.error("No elements found in click event.");
              }
            },

            scales: {
              x: {
                border: {
                  color: "black",
                },
                grid: { display: false },
                ticks: {
                  color: "black",
                  font: {
                    font: 14,
                    weight: "bold",
                  },
                },
              },
              y: {
                display: false,
                grid: { display: false },
                ticks: { display: false },
                beginAtZero: true,
                suggestedMax:
                  Math.max(
                    ...completePotentialStatus.map((item) => item.count)
                  ) * 1.2,
              },
            },
          },
          plugins: [customDefaultLabelPlugin],
        });
      }
    };
    renderStatusChart();
  }, [
    potentialStatus,
    potentialStatusfinal,
    selectedTab,
    totalGraphCount,
    potentialNavigationl3,
  ]);

  return (
    <div className="h-full ">
      <div className="h-[20%]">
        <div className=" flex  flex-wrap justify-between items-center align-middle md:gap-x-2 sm:gap-x-2 md:flex-nowrap xl:flex xl:justify-between xl:gap-x-8">
          <div className="">
            <h1 className="text-2xl font-semibold sm:text-sm md:text-sm lg:text-xl">
              POTENTIAL STATUS
            </h1>
          </div>
          <div className="md:flex lg:ml-20 lg:flex lg:items-center lg:justify-end xl:flex xl:justify-end">
            <div className="show-potential-download  text-3xl">
              <div className=" inline-block relative px-3 mt-2">
                <div className="flex items-center text-4xl sm:text-md md:text-xl lg:text-xl xl:text-2xl 2xl:text-4xl">
                  <CloudDownloadIcon
                    onClick={toggleDropdown}
                    className="cursor-pointer"
                    // fontSize="4rem"
                  />

                  {isDropdownVisible && (
                    <div
                      ref={popupRef}
                      className="absolute top-8 -left-0 shadow-lg z-50 bg-white text-base"
                    >
                      <div
                        className="px-2 py-2 cursor-pointer hover:bg-gray-100"
                        onClick={() =>
                          handleDownload({ target: { value: "pdf" } })
                        }
                      >
                        PDF
                      </div>
                      <div
                        className="px-2 py-2 cursor-pointer hover:bg-gray-100"
                        onClick={() =>
                          handleDownload({ target: { value: "excel" } })
                        }
                      >
                        Excel
                      </div>
                      <div
                        className="px-2 py-2 cursor-pointer hover:bg-gray-100"
                        onClick={() =>
                          handleDownload({ target: { value: "word" } })
                        }
                      >
                        Word
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="show-potential-download lg:justify-end">
              <div className=" flex items-center justify-between text-3xl sm:text-xl md:text-md">
                {selectedTab === "table" && (
                  <span title="Graph">
                    <SignalCellularAltIcon
                      className="cursor-pointer"
                      onClick={() => setSelectedTab("graph")}
                      fontSize="3rem"
                    />
                  </span>
                )}

                {selectedTab === "graph" && (
                  <span title="Table">
                    <TableChartIcon
                      className="cursor-pointer"
                      onClick={() => setSelectedTab("table")}
                      fontSize="3rem"
                    />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <hr />
        <hr />

        <div className="flex gap-3 items-center w-full justify-between pt-1 lg:flex-nowrap lg:gap-2">
          <div className="flex justify-between align-middle items-center w-full gap-1">

            <div className="w-[25%] flex align-middle p-1 border border-[#e3eaff] rounded-md ">
              <div className="pl-1 pt-1.5 text-xs">
                <p >
                  From-
                </p>

              </div>
              <div className="h-full w-full ">
                <DatePicker
                  selected={statusFilterDateFrom}
                  onChange={handleFromDateChange}
                  dateFormat="MM-dd-yyyy"
                  className=" w-full h-full text-xs  m-0  outline-none date-picker-placeholder"
                  placeholderText="MM-DD-YYYY"
                  required
                />
              </div>
            </div>
            |
            <div className="w-[25%] flex align-middle p-1 border border-[#e3eaff] rounded-md ">
              <div className="pl-1 pt-1.5 text-xs">
                <p >
                  To-
                </p>

              </div>
              <div className="h-full w-full ">
                <DatePicker
                  selected={statusFilterDateTo}
                  onChange={handleToDateChange}
                  dateFormat="MM-dd-yyyy"
                  className=" w-full h-full text-xs  m-0  outline-none date-picker-placeholder"
                  placeholderText="MM-DD-YYYY"
                  required
                  minDate={
                    statusFilterDateFrom
                      ? new Date(statusFilterDateFrom.getTime() + 86400000)
                      : null
                  }
                />
              </div>
            </div>
            <div className="relative flex align-middle items-center w-[25%]">
    
              <div className="absolute cursor-pointer right-0 border-r border-[#e3eaff] bg-white z-20">
                <ExpandMoreIcon
                  color="white"
                  className=""
                  onClick={() => setshowsourceDropdown(!showsourseDropdown)}
                />
              </div>
              <div
                ref={StatusdropdownRef}
                className="relative border w-full border-[#e3eaff]  rounded-md"
              >
                <button
                  type="button"
                  onClick={() => setshowsourceDropdown(!showsourseDropdown)}
                  className=" flex justify-start items-center align-middle w-full h-full p-2 text-nowrap truncate focus:outline-none text-xs"
                >
                  {statusFilter}
                </button>

                {showsourseDropdown && (
                  <div className="absolute flex flex-col w-28 p-0.5 gap-2 z-50  bg-white text-xs border border-gray-300 overflow-y-auto shadow-lg max-h-48  mt-0.5">
                    <label
                      htmlFor="All"
                      className="p-1 hover:bg-gray-300"
                      onClick={() => {
                        handleStatusChange("All");
                      }}
                    >
                      All
                    </label>

                    {sortedStatuses.map((statuse) => (
                      <label
                        onClick={() => {
                          handleStatusChange(statuse.status);
                        }}
                        key={statuse.id}
                        value={statuse.status}
                        className="p-1 hover:bg-gray-300"
                      >
                        {statuse.status}
                      </label>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="w-[25%] flex justify-end">
              <button
                className="text-white bg-[#e32278] p-2 px-4 text-xs rounded-md max-w-24 shadow-md"
                onClick={handlePotentialStatusFilter}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="h-[80%] flex ">
        {selectedTab === "table" ? (
          potentialStatus.length > 0 ? (
            <div className="w-full pt-3">
              <div className=" overflow-auto">
                <table className="w-full divide-y divide-gray-200">
                  <thead className="bg-black sticky top-0">
                    <tr className="text-white">
                      <th className="px-4 py-3 text-left border text-sm font-medium uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                        Count
                      </th>
                      <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                        %
                      </th>
                      <th className="px-4 py-3 text-right border text-sm font-medium uppercase tracking-wider">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {potentialStatus
                      .sort((a, b) => {
                        const statusOrder = [
                          "WON",
                          "WON RECURRING",
                          "PENDING",
                          "LOST",
                        ];
                        return (
                          statusOrder.indexOf(a.status.toUpperCase()) -
                          statusOrder.indexOf(b.status.toUpperCase())
                        );
                      })
                      .map((item, index) => (
                        <tr className="border text-md" key={index}>
                          <td
                            onClick={() => potentialNavigationl3(item.status)}
                            style={{ cursor: "pointer" }}
                            className="px-3 text-left py-2 font-thin"
                          >
                            {item.status.toUpperCase()}
                          </td>
                          <td className="px-3 text-center py-2">
                            {item.count}
                          </td>
                          <td className="px-3 text-center py-2">
                            {item.percentage} %
                          </td>
                          <td className="px-3 text-right py-2">
                            $
                            {item.amount
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                        </tr>
                      ))}
                    <tr className="border bg-gray-200 font-bold text-md">
                      <td className="px-3 text-left py-2">TOTAL :</td>
                      <td className="px-3 text-center py-2">
                        {totalstatusCount}
                      </td>
                      <td className="px-3 text-center py-2">
                        {totalPercentage === "99.99" ? 100 : totalPercentage}%
                      </td>{" "}
                      <td className="px-3 text-right py-2">
                        $
                        {totalStatusAmount
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="flex justify-center align-middle items-center text-center mb-5  w-full">
            <p className="text-red-500 text-lg font-semibold ">
          No data available
        </p> </div>
          )
        ) : potentialStatus.length > 0 ? (
          <canvas id="statusPieChart" ref={statusPieChart}></canvas>
        ) : (
          <div className="flex justify-center align-middle items-center text-center mb-5  w-full">
            <p className="text-red-500 text-lg font-semibold ">
          No data available
        </p> </div>
        )}
      </div>
    </div>
  );
};

export default PotentialStatusL3;
