import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FaTrash } from "react-icons/fa";
import { MdEditSquare } from "react-icons/md";
import ConfirmationModal from './ConfirmationModal';
import AdminLoader from '../../loader/AdminLoader';
import L1Sidebar from './L1Sidebar';
import { FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { addLeadType, deleteLeadType, fetchLeadTypes, updateLeadType } from '../../../redux/slices/leadTypeSlice';
import { logoutSession } from '../../../redux/slices/sessionSlice';

const LeadType = () => {

    const [isLoading, SetisLoading] = useState(false);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

     const [showLogout, setshowlogout] = useState(false);
            const popupRef = useRef(null);
            const name = localStorage.getItem("name");
          
            const handleClickOutside = (event) => {
              if (popupRef.current && !popupRef.current.contains(event.target)) {
                setshowlogout(false); // Close the popup if clicked outside
              }
            };
            useEffect(() => {
              if (showLogout) {
                document.addEventListener("mousedown", handleClickOutside);
              } else {
                document.removeEventListener("mousedown", handleClickOutside);
              }
              return () => document.removeEventListener("mousedown", handleClickOutside);
            }, [showLogout]);
          
            const logout = (e) => {
              e.preventDefault();
              setshowlogout(!showLogout);
            };
            const logoutUser = (e) => {
              e.preventDefault();
              dispatch(logoutSession())
              enqueueSnackbar("Successfully Logged out!", { variant: "success",autoHideDuration: 1500 });
              navigate("/");
            };  

    const token = localStorage.getItem('token');
    if (!token){
        enqueueSnackbar('Login to Navigate!',{variant:'error'});
        navigate('/');
    }

        const [LeadType, setLeadType] = useState('');
        const [editingLeadTypeId, setEditingLeadTypeId] = useState(null);
        const [LeadTypes, setLeadTypes] = useState([]);

        const dispatch = useDispatch();
        const {leadTypesRedux} = useSelector((state)=> state.leadTypesRedux)

        useEffect(()=>{
         dispatch(fetchLeadTypes())
        },[dispatch])

    useEffect(()=>{
        setLeadTypes(leadTypesRedux)
    },[leadTypesRedux])

        const editLeadType = (id, LeadTypeName) => {
            setEditingLeadTypeId(id);
            setLeadType(LeadTypeName);
        };

        const Submit = async (e) => {
            e.preventDefault();
            
            if (!LeadType) {
                enqueueSnackbar('Please fill all the fields!', { variant: 'error' });
                return;
            }
        
            SetisLoading(true);
        
            try {
                if (!editingLeadTypeId) {
                    // **Dispatch Add Lead Type Action**
                    const newLeadType = await dispatch(addLeadType(LeadType)).unwrap();
                    enqueueSnackbar('Lead Type Added Successfully!', { variant: 'success' });
        
                    setLeadType('');
                    setLeadTypes([...LeadTypes, newLeadType]); // Update Local State
                } else {
                    // **Dispatch Update Lead Type Action**
                    const updatedLeadType = await dispatch(updateLeadType({ id: editingLeadTypeId, LeadType })).unwrap();
                    enqueueSnackbar('Lead Type Updated Successfully!', { variant: 'success' });
        
                    setLeadType('');
                    setEditingLeadTypeId(null);
                    setLeadTypes(LeadTypes.map(type => (type.id === editingLeadTypeId ? updatedLeadType : type))); // Update Local State
                }
            } catch (error) {
                console.error("Error:", error);
                if (error.response?.data?.error) {
                    enqueueSnackbar(error.response.data.error, { variant: "error" });
                } else if (error.response?.data?.message) {
                    enqueueSnackbar(error.response.data.message, { variant: "error" });
                } else {
                    enqueueSnackbar("Something went wrong!", { variant: "error" });
                }
            } finally {
                SetisLoading(false);
            }
        };
        
        const handleDeleteLeadType = async (id) => {
            try {
                // **Dispatch Redux Action to Delete Lead Type**
                await dispatch(deleteLeadType(id)).unwrap();
        
                // **Show Success Message**
                enqueueSnackbar('Lead Type Deleted Successfully!', { variant: 'success' });
        
                // **Update Local State Without Reloading**
                setLeadTypes((prevLeadTypes) => prevLeadTypes.filter(type => type.id !== id));
                
            } catch (error) {
                enqueueSnackbar(error || 'Failed to delete Lead Type!', { variant: 'error' });
            }
        };
        
        const Cancel = (e) => {
            e.preventDefault();
            setLeadType('');
            setEditingLeadTypeId(null);
        };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [leadTypetoDelete, setleadTypetoDelete] = useState(null);
    
    const openDeleteModal = (id) => {
        setleadTypetoDelete(id);
        setIsModalOpen(true);
    };
    
    const closeDeleteModal = () => {
        setIsModalOpen(false);
        setleadTypetoDelete(null);
    };
    
    const confirmDelete = async () => {
        if (leadTypetoDelete) {
            await handleDeleteLeadType(leadTypetoDelete);  // Call your existing delete function
        }
        closeDeleteModal();
    };

    return (
        <>
       <div className=" bg-[#f3f8f8] pb-6 h-screen"> 
            <ConfirmationModal
                isOpen={isModalOpen}
                onClose={closeDeleteModal}
                onConfirm={confirmDelete}
            />
      <L1Sidebar />


{isLoading ? (
        <AdminLoader isLoading={isLoading} />
      ) : (
        <div className=" ml-[21%] w-3/4 relative pt-4">
             <div className="bg-white p-2  flex justify-between align-middle items-center rounded-md">
                                      <h1 className="text-xl font-semibold"> </h1>
                                      <div className="relative cursor-pointer bg-transparent">
                                        <FaUserCircle onClick={logout} className="text-black w-6 h-6" />
                                        {showLogout && (
                                          <div
                                            ref={popupRef}
                                            className="absolute right-0 top-full mt-1 text-black text-center px-2 w-36 shadow-2xl overflow-hidden bg-white border border-gray-400 z-50 rounded-lg"
                                          >
                                            <h1 className="px-6 py-2 rounded-md my-1">{name}</h1>
                                            <h1
                                              onClick={logoutUser}
                                              className="flex items-center gap-2 px-6 py-2 rounded-md my-1 cursor-pointer hover:bg-black hover:text-white"
                                            >
                                              <MdLogout size={24} /> Logout
                                            </h1>
                                          </div>
                                        )}
                                      </div>
                                    </div>
    
            <div className=' flex w-full relative top-6'>
                <div className='mr-4 p-4 h-fit w-2/5 bg-white border border-gray-200 overflow-hidden shadow-lg rounded-md'>
                    <h1 className='text-2xl mb-2 font-semibold'>{editingLeadTypeId ? 'Edit Lead Type' : 'Add Lead Type'}</h1><hr /><hr /><br />
                    <div className='mx-4'>
                        <label className='block' htmlFor="Name">Lead Type</label>
                        <input className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' type="text" placeholder='Enter Lead Type' value={LeadType} onChange={(e) => 
                            {const newValue = e.target.value.replace(/[^a-zA-Z0-9\s-]/g, '').slice(0, 30)
                                setLeadType(newValue);
                            }
                        }/>
                    </div>
                    <div className='mx-4 mt-8'>
                    <button onClick={Submit} className='mr-2 py-1 px-5 bg-[#e32278] hover:bg-[#e12298] overflow-hidden shadow-md text-white rounded-md'>{editingLeadTypeId ? 'Update' : 'Submit'}</button>
                    <button onClick={Cancel} className='ml-2 py-1 px-5 bg-black hover:bg-white hover:text-black border border-black overflow-hidden shadow-md text-white rounded-md'>Cancel</button>
                    </div>
                </div>
                <div className='ml-4 w-3/5 h-fit p-4 bg-white border border-gray-200 overflow-hidden shadow-lg rounded-md'> 
                    <h1 className='text-2xl mb-2 font-semibold'>Lead Types</h1><hr /><hr /><br />
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className='bg-black text-white'>
                            <tr>
                                <th className="border px-6 py-3 text-center tracking-wider text-sm font-medium uppercase">#</th>
                                <th className="border px-6 py-3 text-center tracking-wider text-sm font-medium uppercase">Lead Type</th>
                                <th className="border px-3 py-3 text-center tracking-wider text-sm font-medium uppercase">Edit</th>
                                <th className="border px-3 py-3 text-center tracking-wider text-sm font-medium uppercase">Delete</th>
                            </tr>
                        </thead>
                        <tbody className='bg-white divide-y divide-gray-200'>
                            {LeadTypes.map((LeadType, index) => (
                                <tr className='border' key={index}>
                                    <td className="px-6 text-center py-2">{index + 1}</td>
                                    <td className="px-6 text-center py-2">{LeadType.LeadType}</td>
                                    <td onClick={() => editLeadType(LeadType.id, LeadType.LeadType)} className="px-3 text-center py-2 hover:bg-black hover:text-white cursor-pointer">
                                        <div className="flex justify-center items-center">
                                            <MdEditSquare size={20}/>
                                        </div>
                                    </td>
                                    <td onClick={() => openDeleteModal(LeadType.id)} className="px-3 text-center py-2 hover:bg-black hover:text-white cursor-pointer">
                                        <div className="flex justify-center items-center">
                                            <FaTrash size={18}/>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            </div> 
        
        )}
        </div>
        </>
    );
};

export default LeadType;
