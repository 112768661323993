import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";

// ** Fetch all Statuses **
export const fetchStatus = createAsyncThunk("status/fetchStatus", async () => {
 
    const response = await axiosInstance.get("/status");
    return response.data;
});

// ** Add a new Status **
export const addStatus = createAsyncThunk("status/addStatus", async (newStatus, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post("/status", { newStatus });
    return response.data;
  } catch (error) {
    console.error("Error adding status:", error);
    return rejectWithValue(error.response?.data || "Error adding status");
  }
});

// ** Update an existing Status **
export const updateStatus = createAsyncThunk("status/updateStatus", async ({ id, newStatus }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.put(`/status/${id}`, { newStatus });
    return response.data;
  } catch (error) {
    console.error("Error updating status:", error);
    return rejectWithValue(error.response?.data || "Error updating status");
  }
});

// ** Delete a Status **
export const deleteStatus = createAsyncThunk("status/deleteStatus", async (id, { rejectWithValue }) => {
  try {
    await axiosInstance.delete(`/status/${id}`);
    return id; // Return ID to remove from state
  } catch (error) {
    console.error("Error deleting status:", error);
    return rejectWithValue(error.response?.data || "Error deleting status");
  }
});

// ** Redux Slice **
const statusSlice = createSlice({
  name: "status",
  initialState: {
    status: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch statuses
      .addCase(fetchStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.status = action.payload;
      })
      .addCase(fetchStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Add Status
      .addCase(addStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(addStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.status.push(action.payload);
      })
      .addCase(addStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Status
      .addCase(updateStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.status.findIndex((status) => status.id === action.payload.id);
        if (index !== -1) {
          state.status[index] = action.payload;
        }
      })
      .addCase(updateStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete Status
      .addCase(deleteStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.status = state.status.filter((status) => status.id !== action.payload);
      })
      .addCase(deleteStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default statusSlice.reducer;
