import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";
import config from "../../routes/api";

const BackendURL = config.backendUrl

export const fetchLeadsByGroups = createAsyncThunk("leads/fetchLeadsByGroups",  async () => {
        const response = await axiosInstance.get("/leads");
      return response.data;  
    }
  );

export const fetchEmployeeAuthData = createAsyncThunk("leads/fetchEmployeeAuthData",async (_, { rejectWithValue }) => {
    try {
      const loggedInUserEmail = localStorage.getItem("name");
      if (!loggedInUserEmail) throw new Error("No user email found");

      const response = await axiosInstance.get(`${BackendURL}/employees`);
      const employees = response.data;
      const matchingEmployee = employees.find(
        (employee) => employee.EmpFirstName === loggedInUserEmail
      );
      if (!matchingEmployee) throw new Error("Employee not found");
      localStorage.setItem("authLevel", matchingEmployee.EmpAuthLevel);
      localStorage.setItem("groupAssigned", matchingEmployee.EmpGroups);

      return {
        authLevel: matchingEmployee.EmpAuthLevel,
        empGroups: matchingEmployee.EmpGroups,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const fetchEmployeeAndLeads = createAsyncThunk(
  "leads/fetchEmployeeAndLeads",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const employeeData = await dispatch(fetchEmployeeAuthData()).unwrap();
      if (employeeData.empGroups.length > 0) {
        await dispatch(fetchLeadsByGroups(employeeData.empGroups)).unwrap();
      }
      return true;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const leadsSliceL3 = createSlice({
  name: "leadsL3",
  initialState: {
    leadReports: [],
    filteredLeadReports: [],
    groupNames: [],
    authLevel: null,
    empGroups: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearLeadsData: (state) => {
      state.leadReports = [];
      state.filteredLeadReports = [];
      state.groupNames = [];
      state.authLevel = null;
      state.empGroups = [];
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //  Fetch Employee Data
      .addCase(fetchEmployeeAuthData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEmployeeAuthData.fulfilled, (state, action) => {
        state.loading = false;
        state.authLevel = action.payload.authLevel;
        state.empGroups = action.payload.empGroups;
        state.error = null;
      })
      .addCase(fetchEmployeeAuthData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      //  Fetch Leads by Groups
      .addCase(fetchLeadsByGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLeadsByGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.leadReports = action.payload;
        state.filteredLeadReports = action.payload;
        state.error = null;
      })
      .addCase(fetchLeadsByGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // ✅ Fetch Employee & Leads Combined
      .addCase(fetchEmployeeAndLeads.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEmployeeAndLeads.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchEmployeeAndLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearLeadsData } = leadsSliceL3.actions;
export default leadsSliceL3.reducer;
