import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FaUser } from "react-icons/fa";
// import { FaUser, FaLock } from "react-icons/fa";
import "../../App.css";
import axios from "axios";
import config from "../../routes/api";
import AdminLoader from "../loader/AdminLoader";

const Forgotpw = () => {
  const [email, setemail] = useState("");
  // const [randomCharacters, setRandomCharacters] = useState('');
  // const [userInput, setUserInput] = useState('');

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
const BackendURL = config.backendUrl;
  const [isLoading, SetisLoading] = useState(false);

  // useEffect(() => {
  //     generateRandomCharacters();
  // }, []);

  // const generateRandomCharacters = () => {
  //     const characters = 'ABCDEFGHJKMNOPQRSTUVWXYZ0123456789abcdefghijkmnopqrstuvwxyz0123456789';
  //     let result = '';
  //     for (let i = 0; i < 10; i++) {
  //         result += characters.charAt(Math.floor(Math.random() * characters.length));
  //     }
  //     setRandomCharacters(result);
  // };
  const [message, setMessage] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleConfirm = async (e) => {
    e.preventDefault();
    if (!email) {
      enqueueSnackbar("Please enter email correctly!", { variant: "error" });

    } else {
      SetisLoading(true);
      try {
        const response = await axios.post(
          `${BackendURL}/pass/forgot-password`,
          { email: email }
        );
        if (response.status >= 200 && response.status < 300) {
          enqueueSnackbar(
            "Kindly check your email! We have sent you a link to reset your password.",
            { variant: "success" }
          );
          setMessage(
            `Kindly check your entered email, We have sent you a link to reset your password.`
          );
          setIsEmailSent(true);
          setemail("");
          // navigate('/');
          setTimeout(() => {
            navigate("/");
          }, 5000);
        } else {
          enqueueSnackbar("Incorrect Email!", { variant: "error" });
          setemail("");
          // setUserInput('');
        }
      } catch (error) {
        console.error("Error during forgot password process:", error);
        enqueueSnackbar("Please Enter Valid Email!", { variant: "error" });
      } finally {
        SetisLoading(false);
      }
    }
  };

  const HandleBack = () => {
    navigate("/");
  };

  return (
    <>
      {isLoading ? (
        <AdminLoader isLoading={isLoading} />
      ) : (
        <div className="min-h-screen flex items-center bg-gray-100 justify-center">
          <div className="text-center my-20 px-48 py-12 bg-white overflow-hidden shadow-2xl rounded-lg border border-gray-200">
            <h1 className="text-7xl font-extrabold">
              <span className="text-pink-600">Q</span>
              <span className="text-teal-600">Trackr</span>
            </h1>
            <br />
            {/* <h1 className='font-extrabold text-2xl'>Forgot Password?</h1> */}
            <br />
            {/* <label htmlFor="captcha">Captcha</label>
                <p className='text-center w-full bg-black text-white p-2 rounded-md text-2xl overflow-hidden shadow-md outline-0 non-selectable'>
                    <strong>{randomCharacters}</strong></p> */}
            {/* <br /><br /> */}
            {/* <div className='flex justify-center'>
                    <FaUser style={{ alignItems: 'center', marginTop: '2%' }} size={24} />
                    <input className='border ml-2 w-full border-gray-300 rounded-md p-2 overflow-hidden shadow-md outline-none' value={email} type="text" placeholder='Enter Email' onChange={(e) => setemail(e.target.value)} />
                </div>
                <br /> */}
            {/* <div className='flex'>
                    <FaLock style={{ alignItems: 'center', marginTop: '3%' }} size={24} />
                    <input className='border ml-2 w-full border-gray-300 rounded-md p-2 overflow-hidden shadow-md outline-none'
                     value={userInput} type="text" placeholder='Enter Captcha' onChange={(e) => setUserInput(e.target.value)} />
                </div> */}

            {!isEmailSent && (
              <>
                <h1 className="font-extrabold text-2xl mb-7">
                  Forgot Password?
                </h1>
                <div className="flex justify-center">
                  <FaUser
                    style={{ alignItems: "center", marginTop: "2%" }}
                    size={24}
                  />
                  <input
                    className="border ml-2 w-full border-gray-300 rounded-md p-2 overflow-hidden shadow-md outline-none"
                    value={email}
                    type="text"
                    placeholder="Enter Email"
                    onChange={(e) => setemail(e.target.value)}
                  />
                </div>
                <br />
                <button
                  onClick={handleConfirm}
                  className="py-1 text-center overflow-hidden shadow-md rounded-sm text-white px-6 bg-blue-600 hover:bg-blue-900"
                >
                  Confirm
                </button>
              </>
            )}

            {/* <button onClick={handleConfirm} className='py-1 text-center overflow-hidden shadow-md rounded-sm text-white px-6 bg-blue-600 hover:bg-blue-900'>Confirm</button> */}
            {/* <button onClick={HandleBack} className="py-1 text-center shadow-md rounded-sm text-white ml-2 px-12 border bg-gray-600 hover:bg-gray-900">
                     Back </button> */}

            <button
              onClick={HandleBack}
              className="py-1 text-center shadow-md rounded-sm text-white ml-2 px-12 border bg-gray-600 hover:bg-gray-900"
            >
              {isEmailSent ? "Back To Login" : "Back"}
            </button>

            <div className="mt-4">
              <p>{message}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Forgotpw;
