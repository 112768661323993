import React, { useState, useRef, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import "react-phone-input-2/lib/style.css";

import { MdEditSquare } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import AdminLoader from "../../loader/AdminLoader";
import L3Sidebar from "./L3Sidebar";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { fetchEmployeeAuthData, fetchLeadsByGroups } from "../../../redux/slices/leadsSliceL3";
import { useDispatch, useSelector } from "react-redux";
import { logoutSession } from "../../../redux/slices/sessionSlice";
import { fetchClientRatingData, updateClientRatingData } from "../../../redux/slices/clientRatingDashboardSliceL3";

const ClientRatingDashboardL3 = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [GroupNamesL3, setGroupNamesL3] = useState([]);
  const dispatch = useDispatch()
  const [LeadReports, setLeadReports] = useState([])
  const [, setFilteredLeadReports] = useState([]);
  const [, setClientName] = useState([]);
  const [AssignedGroup, setAssignedGroup] = useState("");
  const [AssignedClient, setAssignedClient] = useState("");
  const [finalScale, setfinalScale] = useState("");
  const [, setTodaysDate] = useState("");
  const [showLogout, setshowlogout] = useState(false);
  const popupRef = useRef(null);
  const names = localStorage.getItem("name");
  const [, setIsDropdownOpen] = useState(false);
  const [isGroupOpen, setIsGroupOpen] = useState(false);
  const [isClientOpen, setIsClientOpen] = useState(false);
  const [totalfinalRating, settotalfinalRating] = useState(0);
  const [PercentageWithoutClient, setPercentageWithoutClient] = useState(0);
  const [PercentageWithClient, setPercentageWithClient] = useState(0);
  const [, settableData] = useState([])
  const [historyWithoutClient, sethistoryWithoutClient] = useState(0);
  const [historyWithClient, sethistoryWithClient] = useState(0);
  const [ratingData, setRatingData] = useState([]);
  // const [sortedRatingData, setSortedRatingData] = useState([]);
  const [assignedPercentage, setAssignedPercentage] = useState(0);
  const [otherPercentage, setOtherPercentage] = useState(0);
  const [editingIndex, setEditingIndex] = useState(null);
  const [tempRatingName, setTempRatingName] = useState("");


  const {  leadReports } = useSelector((state) => state.leadsL3);
    const { empGroups } = useSelector((state) => state.leadsL3);

      useEffect(() => {  
        dispatch(fetchLeadsByGroups());
        dispatch(fetchEmployeeAuthData());
      }, [dispatch]);

      useEffect(()=>{
if(empGroups){setGroupNamesL3(empGroups)}
      },[empGroups])

  useEffect(() => {
    
        if (leadReports) {
          const filteredData = leadReports.filter(lead =>
            empGroups.includes(lead.AssignedGroup) // Check if the AssignedGroup is in empGroups
          );
          const formattedClientNames = filteredData.map((lead, index) => ({
            id: index + 1, // Assigning a sequential ID
            newClientName: lead.ClientName, // Storing ClientName as newGroupName
            _id: lead._id, // Keeping original _id
            __v: lead.__v || 0, // Keeping version key, default to 0 if undefined
          }));
          console.log("this is the response filteredDataClientrating", filteredData)

          setClientName(formattedClientNames)
          setLeadReports(filteredData)
          setFilteredLeadReports(filteredData); // Initialize filtered list
        } else {
          // enqueueSnackbar('Failed to fetch Status', { variant: 'error' });
        }
    
  }, [empGroups, leadReports]);


  // for history====>
  useEffect(() => {
    const fetchStatus = async () => {
      const leads = LeadReports
        .filter((lead) => lead.AssignedGroup === AssignedGroup && lead.ClientName === AssignedClient) // Filter by group & client
        .map((lead) => new Date(lead.LeadDate));

      console.log("leads==>", leads);
      if (leads.length > 0) {
        const oldestLead = new Date(Math.min(...leads));
        const currentYear = new Date().getFullYear();
        const oldestLeadYear = oldestLead.getFullYear();

        const yearDifference = currentYear - oldestLeadYear;

        console.log("Oldest Lead Date:", oldestLead.toISOString().split("T")[0]);
        console.log("Current Year:", currentYear);
        console.log("Difference in Years:", yearDifference);
        sethistoryWithClient(yearDifference)
      }

      const nonClientLeads = LeadReports
        .filter((lead) => lead.ClientName !== AssignedClient)
        .map((lead) => new Date(lead.LeadDate).getFullYear());

      // ✅ Remove Duplicate Years
      const uniqueYears = [...new Set(nonClientLeads)];

      if (uniqueYears.length > 0) {
        const averageYear =
          uniqueYears.reduce((sum, year) => sum + year, 0) / uniqueYears.length;

        const roundedAvgYear = Math.round(averageYear);

        const avgYearDifference = new Date().getFullYear() - roundedAvgYear;


        console.log("Unique Years of Non-Client Leads:", uniqueYears);
        console.log("Average Year of Non-Client Leads:", roundedAvgYear);
        console.log("Difference with Current Year:", avgYearDifference);
        sethistoryWithoutClient(avgYearDifference)
      }
      console.log("nonClientLeads==>", nonClientLeads);
    };

    fetchStatus();
  }, [AssignedClient, AssignedGroup, LeadReports]);

  // for won & won Rec average
  useEffect(() => {
    const fetchStatus = async () => {

      // Fetch data (assuming an API call)
      const response = LeadReports


      // Get current date and subtract 5 years
      const fiveYearsAgo = new Date();
      fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);

      // Filter leads from the last 5 years
      const recentLeads = response.filter(
        (lead) => new Date(lead.LeadDate) >= fiveYearsAgo
      );

      // First Calculation: Leads under AssignedClient & AssignedGroup with "Won" or "Won & Won rec"
      const clientGroupLeads = recentLeads.filter(
        (lead) =>
          lead.ClientName === AssignedClient &&
          lead.AssignedGroup === AssignedGroup &&
          (lead.Status === "Won" || lead.Status === "Won Recurring")
      );

      // Second Calculation: Leads where ClientName is NOT AssignedClient
      const otherClientsLeads = recentLeads.filter(
        (lead) => lead.ClientName !== AssignedClient
      );

      // Calculate averages
      const avgQuotedClient = clientGroupLeads.length
        ? clientGroupLeads.reduce((sum, lead) => sum + lead.QuotedValue, 0) / clientGroupLeads.length
        : 0;

      const avgQuotedOthers = otherClientsLeads.length
        ? otherClientsLeads.reduce((sum, lead) => sum + lead.QuotedValue, 0) / otherClientsLeads.length
        : 0;
      setPercentageWithClient(avgQuotedClient)
      setPercentageWithoutClient(avgQuotedOthers)
      console.log("Avg Quoted Value for Assigned Client & Group (Won/Won & Won rec):", clientGroupLeads);
      console.log("Avg Quoted Value for Assigned Client & Group (Won/Won & Won rec):", avgQuotedClient);
      console.log("Avg Quoted Value for Leads NOT under Assigned Client:", avgQuotedOthers);


    };

    fetchStatus();
  }, [AssignedClient, AssignedGroup, LeadReports]);



  // for won & won Rec %
  useEffect(() => {
    const calculatePercentages = () => {
      if (!LeadReports || LeadReports.length === 0) return;
      // Get date 5 years ago
      const fiveYearsAgo = new Date();
      fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);

      const totalLeadReport = LeadReports.filter(
        (lead) => new Date(lead.LeadDate).getTime() >= fiveYearsAgo.getTime()
      );


      // Filter leads from the last 5 years
      const TotalLeads = LeadReports.filter(
        (lead) =>
          lead.AssignedGroup === AssignedGroup &&
          lead.ClientName === AssignedClient &&
          new Date(lead.LeadDate) >= fiveYearsAgo // Ensure lead is within last 5 years
      );

      const wonAssignedLeads = TotalLeads.filter(lead =>
        lead.Status === "Won" || lead.Status === "Won Recurring"
      );

      const otherClientsLeads = LeadReports.filter(
        (lead) => lead.ClientName !== AssignedClient
      );
      const percentageWithClient = (wonAssignedLeads.length / TotalLeads.length) * 100 || 0
      const percentageOtherClient = (otherClientsLeads.length / totalLeadReport.length) * 100 || 0
      setAssignedPercentage(percentageWithClient)
      setOtherPercentage(percentageOtherClient)
      console.log("this is the selected wonAssignedLeads=====>", wonAssignedLeads.length)
      console.log("this is the selected TotalLeads=====>", TotalLeads.length)
      console.log("this is the selected percentageWithClient=====>", percentageWithClient)
      console.log("this is the selected otherClientsLeads=====>", otherClientsLeads)
      console.log("this is the selected percentageOtherClient=====>", percentageOtherClient)



    };

    calculatePercentages();
  }, [AssignedClient, AssignedGroup, LeadReports]);


  useEffect(() => {
    console.log("this is the selected assignedPercentage=====>", assignedPercentage)
    console.log("this is the selected otherPercentage=====>", otherPercentage)
  }, [assignedPercentage, otherPercentage]);



  // const [LeadReports, setLeadReports] = useState([]);

  

  const [isLoading,] = useState(false);





  const dropdownRef = useRef(null);
  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const groupdownRef = useRef(null);
  const handleClickOutsidegroup = (e) => {
    if (groupdownRef.current && !groupdownRef.current.contains(e.target)) {
      setIsGroupOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsidegroup);
    return () => {
      document.removeEventListener("click", handleClickOutsidegroup);
    };
  }, []);

  const clientdownRef = useRef(null);

  const handleClickOutsideclient = (e) => {
    if (clientdownRef.current && !clientdownRef.current.contains(e.target)) {
      setIsClientOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideclient);
    return () => {
      document.removeEventListener("click", handleClickOutsideclient);
    };
  }, []);


  const handleClickOutsides = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setshowlogout(false); // Close the popup if clicked outside
    }
  };
  useEffect(() => {
    if (showLogout) {
      document.addEventListener("mousedown", handleClickOutsides);
    } else {
      document.removeEventListener("mousedown", handleClickOutsides);
    }
    return () => document.removeEventListener("mousedown", handleClickOutsides);
  }, [showLogout]);

  const logout = (e) => {
    e.preventDefault();
    setshowlogout(!showLogout);
  };
  const logoutUser = (e) => {
    e.preventDefault();
    dispatch(logoutSession());
    enqueueSnackbar("Successfully Logged out!", { variant: "success", autoHideDuration: 2000 });
    navigate("/");
  };
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setTodaysDate(today);
  }, []);

  useEffect(() => {
  }, [AssignedGroup, AssignedClient]);

  useEffect(() => {
    const fetchRating = async () => {
      try {
        const response = await dispatch(fetchClientRatingData({ AssignedClient, AssignedGroup })).unwrap();
  
        console.log("Client rating for group & client ====>", response);
  
        // Check if response is an array; otherwise, set an empty array
        if (Array.isArray(response)) {
          setRatingData(response);
        } else {
          setRatingData([]); // If not an array, set empty array
        }
      } catch (error) {
        console.error("Error fetching client rating data:", error);
        setRatingData([]); // Handle errors by setting empty array
      }
    };
  
    fetchRating();
  }, [AssignedGroup, AssignedClient,dispatch]);
  

  useEffect(() => {
    console.log("Updated ratingData:", ratingData);
  }, [ratingData]);

  const predefinedOrder = useMemo(() => ["ClientHistory", "Won & Won rec", "conversionRate"], []);

  const [sortedData, setsortedData] = useState([]);

  const sortedRatingData = useMemo(() => {
    console.log("Recomputing sortedRatingData===>", ratingData);
    return [...ratingData].sort((a, b) => {
      const indexA = predefinedOrder.indexOf(a.RatingName);
      const indexB = predefinedOrder.indexOf(b.RatingName);

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB; // Sort by predefined order
      }
      if (indexA !== -1) return -1; // Move predefined items to the top
      if (indexB !== -1) return 1;

      return 0; // Keep remaining items in original order
    });
  }, [ratingData, predefinedOrder]);


  useEffect(() => {
    console.log("this is the sortedRatingData==>", sortedRatingData)

    if (ratingData.length === 0) {
      setsortedData([])
    } else {
      settableData(sortedRatingData);
      setsortedData(sortedRatingData)
    }

  }, [sortedRatingData, ratingData]);

  // console.log("this is the sortedRatingData==>", sortedData)
  // console.log("this is the ratingData==>", ratingData)
  const defaultRatings = useMemo(() => [
    { RatingName: "ClientHistory", ScaleNo: 20, ScoreMark: "0", TotalRating: totalfinalRating, clientName: AssignedClient, groupName: AssignedGroup },
    { RatingName: "Won & Won rec", ScaleNo: 20, ScoreMark: "0", TotalRating: totalfinalRating, clientName: AssignedClient, groupName: AssignedGroup },
    { RatingName: "conversionRate", ScaleNo: 20, ScoreMark: "0", TotalRating: totalfinalRating, clientName: AssignedClient, groupName: AssignedGroup },
    { RatingName: "Ease of Business", ScaleNo: 20, ScoreMark: "0", TotalRating: totalfinalRating, clientName: AssignedClient, groupName: AssignedGroup },
    { RatingName: "Communication", ScaleNo: 20, ScoreMark: "0", TotalRating: totalfinalRating, clientName: AssignedClient, groupName: AssignedGroup },
  ], [totalfinalRating, AssignedClient, AssignedGroup]); // Dependencies that trigger recalculation


  const [ratings, setRatings] = useState(defaultRatings);

  useEffect(() => {
    if (sortedRatingData.length > 0) {
      setRatings(sortedRatingData);
    }
  }, [sortedRatingData]);


  const handleChange = (index: number, field: string, value: string) => {
    // Allow only numbers and a single decimal point
    const numericValue = value.replace(/[^0-9.]/g, "");

    // Prevent multiple decimal points
    const sanitizedValue =
      numericValue.split(".").length > 2 ? numericValue.slice(0, -1) : numericValue;

    const updatedRatings = [...ratings];

    if (field === "ScoreMark") {
      const scaleValue = parseFloat(updatedRatings[index]["ScaleNo"]) || 0;
      const newValue = parseFloat(sanitizedValue) || 0;

      // Ensure ScoreMark does not exceed ScaleNo
      if (newValue > scaleValue) return;
    }

    updatedRatings[index][field] = sanitizedValue;
    setRatings(updatedRatings);
  };


  // const totalCalculatedRating = ratings.reduce(
  //   (sum, rating) => sum + Number(rating.ScoreMark) / Number(rating.ScaleNo),
  //   0
  // );
  // const totalScaleNo = ratings.reduce(
  //   (sum, rating) => sum + Number(rating.ScaleNo),
  //   0
  // );
  // useEffect(() => {
  //   console.log("this is the totalCalculatedRating====>",totalCalculatedRating)
  //   settotalfinalRating(totalCalculatedRating)
  //   setfinalScale(totalScaleNo)
  // }, [totalCalculatedRating, totalScaleNo]);
  const totalMainRating = ratings.reduce((sum, rating) => {
    const scaleNo = Number(rating.ScaleNo);
    const scoreMark = Number(rating.ScoreMark);
    const starConversion = scaleNo !== 0 ? (scaleNo / 100) * 5 : 0;
    const ScoreandScale = scaleNo !== 0 ? scoreMark / scaleNo : 0;
    const MainRating = ScoreandScale * starConversion;

    return sum + (isNaN(MainRating) ? 0 : MainRating); // Sum up all MainRatings
  }, 0);

  // const totalRows = ratings.length;
  // const finalTotalRating = totalRows > 0 ? totalMainRating / totalRows : 0;

  const totalScaleNo = ratings.reduce(
    (sum, rating) => sum + (Number(rating.ScaleNo) || 0), // Ensure no NaN
    0
  );

  useEffect(() => {
    console.log("Total Main Rating:", totalMainRating);

    settotalfinalRating(totalMainRating.toFixed(2));
  }, [totalMainRating]);
  useEffect(() => {
    // console.log("this is the totalCalculatedRating====>", totalCalculatedRating);
    // settotalfinalRating(isNaN(totalCalculatedRating) ? 0 : totalCalculatedRating);
    setfinalScale(isNaN(totalScaleNo) ? 0 : totalScaleNo);
  }, [totalScaleNo]);


  console.log("this is the ratings==>", ratings)

  const handleEditClick = (index, currentName) => {
    setEditingIndex(index);
    setTempRatingName(currentName);
  };

  const handleInputChange = (e) => {
    setTempRatingName(e.target.value);
  };
  const handleBlur = (index) => {
    const updatedRatings = [...ratings];
    updatedRatings[index].RatingName = tempRatingName;
    setRatings(updatedRatings);
    setEditingIndex(null);
  };
  const handleAddRow = () => {
    setRatings((prevRatings) => {
      if (prevRatings.length >= 15) {
        enqueueSnackbar("You can add only up to 15 rows.", { variant: "error" });
        return prevRatings; // Prevent adding more rows
      }
      return [
        ...prevRatings,
        { RatingName: "New Rating", ScaleNo: 0, ScoreMark: "0", TotalRating: totalfinalRating, clientName: AssignedClient, groupName: AssignedGroup },
      ];
    });
  };


  useEffect(() => {
    setRatings((prevRatings) =>
      prevRatings.map((rating) => ({
        ...rating,
        TotalRating: totalfinalRating,
        clientName: AssignedClient,
        groupName: AssignedGroup,
      }))
    );
  }, [totalfinalRating, AssignedGroup, AssignedClient]);

  const handleRemoveRow = () => {
    setRatings((prevRatings) => {
      if (prevRatings.length > 3) {
        return prevRatings.slice(0, -1);

      }
      enqueueSnackbar("You must keep at least 3 ratings!", { variant: "warning" });
      return prevRatings;
    });
  };

  useEffect(() => {
    console.log("this is the ratings==>", ratings)
    console.log("this is the ratings==>", defaultRatings)

  }, [ratings, defaultRatings]);
  const Submit = async (e) => {
    e.preventDefault();
  
    if (finalScale !== 100) {
      enqueueSnackbar("Scale must be 100!", { variant: "error" });
      return;
    }
  
    const ratingNamesSet = new Set();
    let hasDuplicate = ratings.some((rating) => {
      if (ratingNamesSet.has(rating.RatingName)) {
        return true;
      }
      ratingNamesSet.add(rating.RatingName);
      return false;
    });
  
    if (hasDuplicate) {
      enqueueSnackbar("Rating Names Should be Unique!", { variant: "error" });
      return;
    }
  
    console.log("This is the ratings ===>", ratings);
  
    try {
      const response = await dispatch(updateClientRatingData(ratings)).unwrap();
      
      console.log("This is the response ===>", response);
  
      enqueueSnackbar("Rating Added Successfully!", { variant: "success" });
      setAssignedGroup("");
      setAssignedClient("");
    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar("Failed to add rating. Please try again.", { variant: "error" });
    }
  };
  const getUniqueClients = () => {
    let filtered = [];
  
    if (AssignedGroup && AssignedGroup !== "All") {
      filtered = LeadReports.filter(
        (lead) => lead.AssignedGroup === AssignedGroup
      );
    } else if (AssignedGroup === "All") {
      filtered = [...LeadReports];
    } else {
      filtered = [...LeadReports]; // Default to all
    }
  
    const uniqueClientsMap = new Map();
  
    filtered.forEach((lead) => {
      const key = lead.ClientName.toLowerCase().trim();
      if (!uniqueClientsMap.has(key)) {
        uniqueClientsMap.set(key, lead.ClientName); // or lead, if you want more data
      }
    });
  
    return Array.from(uniqueClientsMap.values());
  };

  return (
    <div className=" bg-[#f3f8f8] h-full">

      <L3Sidebar />
      {isLoading ? (
        <AdminLoader isLoading={isLoading} />
      ) : (
        <div className=" ml-[18%] p-4 relative  scroll-visible justify-center top-0">
          <div className="flex-col p-">
            <div className="flex bg-white p-2 rounded-md justify-between align-middle items-center">
              <h1 className="text-xl w-4/5 font-semibold">
                Enter Client Rating
              </h1>
              <div className="relative cursor-pointer bg-transparent">
                <FaUserCircle onClick={logout} className="text-black w-6 h-6" />
                {showLogout && (
                  <div
                    ref={popupRef}
                    className="absolute right-0 top-full mt-1 text-black text-center px-2 w-36 max-h-96 shadow-2xl overflow-y-scroll bg-white border border-gray-400 z-50 rounded-lg"
                  >
                    <h1 className="px-6 py-2 rounded-md my-1">{names}</h1>
                    <h1
                      onClick={logoutUser}
                      className="flex items-center gap-2 px-6 py-2 rounded-md my-1 cursor-pointer hover:bg-black hover:text-white"
                    >
                      <MdLogout size={24} /> Logout
                    </h1>
                  </div>
                )}
              </div>
            </div>
            <div className="flex gap-1 justify-between bg-white mt-2 rounded-md p-1">
              <div className="flex gap-3 p-1">
                <div className="relative flex gap-2 justify-center align-middle items-center" ref={groupdownRef}>
                  <label htmlFor="selectedStatus" className="block text-sm font-semibold text-gray-700 ">
                    GROUP
                  </label>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsGroupOpen(!isGroupOpen);
                    }}
                    className="w-24 relative outline-none date-picker-placeholder flex justify-start border border-[#e0e7ff] rounded-md p-2 text-xs"
                  >
                    <span className="truncate min-w-0 flex-1 text-left">{AssignedGroup ? AssignedGroup : "All"}</span>
                    <ExpandMoreIcon
                      className="absolute cursor-pointer right-1 top-1 bg-white" />
                  </button>
                  {isGroupOpen && (
                    <div className="absolute left-0 top-full w-full text-xs border overflow-hidden text-wrap border-gray-400 bg-white shadow-md rounded-md z-50">
                      {GroupNamesL3.map((group) => (
                        <div
                          key={group.id}
                          className={`flex items-center p-2 hover:bg-gray-200 cursor-pointer ${AssignedGroup === group ? "bg-gray-300" : ""
                            }`}
                          onClick={() => {
                            setAssignedGroup(group);
                            setIsGroupOpen(false);
                            setRatings(defaultRatings);
                            setAssignedClient("")

                          }}
                        >
                          <label>{group}</label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="relative flex gap-2 justify-center align-middle items-center" ref={clientdownRef}>
                  <label htmlFor="selectedStatus" className="block text-sm font-semibold text-gray-700">
                    Client
                  </label>
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsClientOpen(!isClientOpen);
                    }}
                    className="w-24 relative outline-none date-picker-placeholder flex justify-start border border-[#e0e7ff] rounded-md p-2 text-xs"
                  >
                    <span className="truncate min-w-0 flex-1 text-left">{AssignedClient || "All"}</span>
                    <ExpandMoreIcon className="absolute cursor-pointer right-1 top-1 bg-white" />
                  </button>

                  {isClientOpen && (
                    <div className="absolute left-0 top-full w-full max-h-96 text-xs border overflow-y-scroll text-wrap border-gray-400 bg-white shadow-md rounded-md z-50">
                      {getUniqueClients().map((clientName, idx) => (
                              <p
                                className="p-2 hover:bg-gray-200"
                                
                                onClick={() => {
                                  setAssignedClient(clientName);
                                  setIsClientOpen(false);
                                }}
                                key={idx}
                              >
                                {clientName}
                              </p>
                            ))}
                      {/* {(() => {
                        const uniqueClients = new Set();

                        // If no group is selected OR "All" is selected, show all unique clients
                        const filteredClients = LeadReports
                          .filter((lead) => AssignedGroup === "" || AssignedGroup === "All" || lead.AssignedGroup === AssignedGroup)
                          .sort((a, b) => a.ClientName.trim().localeCompare(b.ClientName.trim()))
                          .filter((lead) => {
                            const clientLower = lead.ClientName.toLowerCase();
                            if (uniqueClients.has(clientLower)) {
                              return false;
                            }
                            uniqueClients.add(clientLower);
                            return true;
                          });

                        return filteredClients.map((lead) => (
                          <p
                            className="p-2 hover:bg-gray-200 cursor-pointer"
                            onClick={() => {
                              setAssignedClient(lead.ClientName);
                              setIsClientOpen(false);
                            }}
                            key={lead.id}
                          >
                            {lead.ClientName}
                          </p>
                        ));
                      })()} */}
                    </div>
                  )}
                </div>


              </div>
            </div>
            <div className="w-full flex justify-center py-5 bg-white mt-2 rounded-md">
              {AssignedClient && AssignedGroup && (
                <div className="w-[5%] flex flex-col justify-end align-bottom items-end px-2">
                  <div className="bg-white p-2 rounded-md shadow-md border" onClick={handleAddRow}>
                    <AddIcon className="w-5 h-5 cursor-pointer" />
                  </div>
                </div>
              )}
              <div className="w-[90%] shadow-md rounded-md">
                <div className="flex w-full bg-black text-white rounded-t-md">
                  <div className="w-[5%]  flex justify-center align-middle items-center text-center p-1 py-3"><p>#</p></div>
                  <div className="w-[45%] border border-[#E0E7FF] flex justify-center align-middle items-center text-center p-1 py-3"><p>Rating Name</p></div>
                  <div className="w-[16.6%] border border-[#E0E7FF] flex justify-center align-middle items-center text-center p-1 py-3"><p>Scale</p></div>
                  <div className="w-[16.6%] border border-[#E0E7FF] flex justify-center align-middle items-center text-center p-1 py-3"><p>Score</p></div>
                  <div className="w-[16.8%] flex justify-center align-middle items-center text-center p-1 py-3"><p>Rating</p></div>
                </div>
                {AssignedClient && AssignedGroup ? (
                  sortedData.length > 0 ? (
                    <>
                      <div>
                        {ratings.map((rating, index) => {
                          const starConversion =
                            rating.ScaleNo && Number(rating.ScaleNo) !== 0
                              ? Number(Number(rating.ScaleNo) / 100) * 5
                              : 0;
                          const ScoreandScale = rating.ScaleNo && Number(rating.ScaleNo) !== 0
                            ? Number(Number(rating.ScoreMark) / Number(rating.ScaleNo))
                            : 0;
                          const MainRating = ScoreandScale * starConversion
                          // isNaN(calculatedRating) ? 0 : calculatedRating;

                          return (
                            <>
                              {/* <div className="">when rating available</div> */}

                              <div key={index} className="row">
                                <div className="flex w-full bg-white">
                                  <div className="w-[5%] border border-[#E0E7FF] flex justify-center items-center text-center p-1">
                                    {index + 1}
                                  </div>
                                  <div className="w-[45%] border border-[#E0E7FF] flex  items-center  p-2">
                                    {rating.RatingName === "ClientHistory" && (
                                      <div className="text-left">
                                        <p>   {AssignedClient} History = {historyWithClient === 0 ? 1 : historyWithClient} Years</p>
                                        <p> All Other Client/industry Average = {historyWithoutClient} Years</p>
                                      </div>)}
                                    {rating.RatingName === "Won & Won rec" && (
                                      <div className="text-left">
                                        <p> {AssignedClient} Won & Won Rec = ${PercentageWithClient.toFixed(0)} Per Year</p>
                                        <p> All Other Client/industry Average = ${PercentageWithoutClient.toFixed(0)} Per Year</p>
                                      </div>)}
                                    {rating.RatingName === "conversionRate" && (
                                      <div className="text-left">
                                        <p> {AssignedClient} Conversion Rate  = {assignedPercentage.toFixed(0)} % Per Year</p>
                                        <p>  All Other Client/industry Average  = {otherPercentage > 100 ? "100" : otherPercentage.toFixed(0)} % Per Year</p>
                                      </div>)}
                                    {!["ClientHistory", "Won & Won rec", "conversionRate"].includes(rating.RatingName) && (
                                      <div className=" relative  w-full">
                                        {editingIndex === index ? (
                                          <input
                                            type="text"
                                            value={tempRatingName}
                                            maxLength={30}
                                            onChange={handleInputChange}
                                            onBlur={() => handleBlur(index)}
                                            autoFocus
                                            className="bg-transparent border-b border-white outline-none w-full"
                                          />
                                        ) : (
                                          <div className="flex w-full">
                                            <p className="text-center">{rating.RatingName}</p>
                                            <MdEditSquare className="absolute right-1 w-5 h-5 cursor-pointer" onClick={() => handleEditClick(index, rating.RatingName)} />
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                  <div className="w-[16.6%] border border-[#E0E7FF] flex justify-center items-center text-center p-1">
                                    <input
                                      type="text"
                                      className="h-full w-full bg-transparent text-center"
                                      value={rating.ScaleNo}
                                      maxLength={2}
                                      onChange={(e) => {
                                        if (e.target.value.length > 2) return; // Prevent typing more than 2 digits
                                        handleChange(index, "ScaleNo", e.target.value);
                                      }} />
                                  </div>
                                  <div className="w-[16.6%] border border-[#E0E7FF] flex justify-center items-center text-center p-1">
                                    <input
                                      type="text"
                                      className="h-full w-full bg-transparent text-center"
                                      value={rating.ScoreMark}
                                      maxLength={2}
                                      onChange={(e) => {
                                        let value = e.target.value;
                                        // Remove leading zero if present
                                        if (value.startsWith("0") && value.length > 1) {
                                          value = value.slice(1);
                                        }
                                        handleChange(index, "ScoreMark", value);
                                      }} />
                                  </div>
                                  <div className="w-[16.8%] border border-[#E0E7FF] flex justify-center items-center text-center p-1">
                                    <p>{MainRating.toFixed(2)}</p>
                                  </div>
                                </div>
                              </div>

                            </>
                          );
                        })}
                      </div>
                      <div>
                        <div className="flex w-full bg-white">
                          <div className="w-[50%] border border-[#E0E7FF] flex justify-center items-center text-center p-2">
                            <div className="text-left">
                              <p>Total</p>
                            </div>
                          </div>
                          <div className="w-[16.6%] border border-[#E0E7FF] flex justify-center items-center text-center p-1">
                            100
                            {/* {totalScaleNo} */}
                          </div>
                          <div className="w-[16.6%] border border-[#E0E7FF] flex justify-center items-center text-center p-1">

                          </div>
                          <div className="w-[16.8%] border border-[#E0E7FF] flex justify-center items-center text-center p-1">
                            <p>{totalfinalRating === "NAN" ? 0 : totalfinalRating}</p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex w-full bg-white">
                          <div className="w-[50%] border flex justify-center items-center text-center p-2">
                            <div className="text-left">
                            </div>
                          </div>
                          <div className="w-[16.6%] flex justify-center items-center text-center text-red-600 text-sm p-1">
                            {totalScaleNo < 100 || totalScaleNo > 100 ? "Scale must be 100" : ""}

                          </div>
                          <div className="w-[16.6%] flex justify-center items-center text-center p-1">

                          </div>
                          <div className="w-[16.8%] flex justify-center items-center text-center p-1">
                          </div>
                        </div>
                      </div>

                      {/* <div className="flex justify-between p-1">
                          <div className="bg-white p-2 rounded-md" onClick={handleAddRow}>
                            <AddIcon className="w-5 h-5 cursor-pointer" />
                          </div>
                          <div className="bg-white p-2 rounded-md" onClick={handleRemoveRow}>
                            <RemoveIcon className="w-5 h-5 cursor-pointer" />
                          </div>
                        </div> */}
                    </>
                  ) : (
                    <>


                      {ratings.map((rating, index) => {
                        const starConversion =
                          rating.ScaleNo && Number(rating.ScaleNo) !== 0
                            ? Number(Number(rating.ScaleNo) / 100) * 5
                            : 0;
                        const ScoreandScale = rating.ScaleNo && Number(rating.ScaleNo) !== 0
                          ? Number(Number(rating.ScoreMark) / Number(rating.ScaleNo))
                          : 0;
                        const MainRating = ScoreandScale * starConversion
                        return (
                          <>
                            {/* <div className="">when rating not available</div> */}
                            <div key={index} className="row">
                              <div className="flex w-full bg-white">
                                <div className="w-[5%] border border-[#E0E7FF] flex justify-center items-center text-center p-1">
                                  {index + 1}
                                </div>
                                <div className="w-[45%] border border-[#E0E7FF] flex  items-center  p-2">
                                  {rating.RatingName === "ClientHistory" && (
                                    <div className="text-left">
                                      <p> {AssignedClient} History = {historyWithClient === 0 ? "1" : historyWithClient} Years</p>
                                      <p> All Other Client/industry Average  = {historyWithoutClient} Years</p>

                                    </div>)}
                                  {rating.RatingName === "Won & Won rec" && (
                                    <div className="text-left">
                                      <p>{AssignedClient} Won & Won Rec = ${PercentageWithClient.toFixed(0)} Per Year</p>
                                      <p>All Other Client/industry Average = ${PercentageWithoutClient.toFixed(0)} Per Year</p>
                                    </div>)}
                                  {rating.RatingName === "conversionRate" && (
                                    <div className="text-left">
                                      <p>  {AssignedClient} Conversion Rate = {assignedPercentage.toFixed(0)} % Per Year</p>
                                      <p>  All Other Client/industry Average =  {otherPercentage > 100 ? "100" : otherPercentage.toFixed(0)} % Per Year</p>
                                    </div>)}
                                  {!["ClientHistory", "Won & Won rec", "conversionRate"].includes(rating.RatingName) && (
                                    <div className=" relative  w-full">
                                      {editingIndex === index ? (
                                        <input
                                          type="text"
                                          value={tempRatingName}
                                          maxLength={30}
                                          onChange={handleInputChange}
                                          onBlur={() => handleBlur(index)}
                                          autoFocus
                                          className="bg-transparent border-b border-white outline-none w-full"
                                        />
                                      ) : (
                                        <div className="flex w-full">
                                          <p className="text-center">{rating.RatingName}</p>
                                          <MdEditSquare className="absolute right-1 w-5 h-5 cursor-pointer" onClick={() => handleEditClick(index, rating.RatingName)} />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="w-[16.6%] border border-[#E0E7FF] flex justify-center items-center text-center p-1">
                                  <input
                                    type="text"
                                    className="h-full w-full bg-transparent text-center"
                                    maxLength={2}
                                    value={rating.ScaleNo}
                                    onChange={(e) => {
                                      if (e.target.value.length > 2) return; // Prevent typing more than 2 digits
                                      handleChange(index, "ScaleNo", e.target.value);
                                    }} />
                                </div>
                                <div className="w-[16.6%] border border-[#E0E7FF] flex justify-center items-center text-center p-1">
                                  <input
                                    type="text"
                                    className="h-full w-full bg-transparent text-center"
                                    value={rating.ScoreMark}
                                    maxLength={2}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      // Remove leading zero if present
                                      if (value.startsWith("0") && value.length > 1) {
                                        value = value.slice(1);
                                      }
                                      handleChange(index, "ScoreMark", value);
                                    }} />
                                </div>
                                <div className="w-[16.8%] border border-[#E0E7FF] flex justify-center items-center text-center p-1">
                                  <p>{MainRating.toFixed(2)}</p>
                                </div>
                              </div>
                            </div>

                          </>
                        );
                      })}
                      <div>
                        <div className="flex w-full bg-white">
                          <div className="w-[50%] border border-[#E0E7FF] flex justify-center items-center text-center p-2">
                            <div className="text-left">
                              <p>Total</p>
                            </div>
                          </div>
                          <div className="w-[16.6%] border border-[#E0E7FF] flex justify-center items-center text-center p-1">
                            100
                            {/* {totalScaleNo} */}
                          </div>
                          <div className="w-[16.6%] border border-[#E0E7FF] flex justify-center items-center text-center p-1">

                          </div>
                          <div className="w-[16.8%] border border-[#E0E7FF] flex justify-center items-center text-center p-1">
                            <p>{totalfinalRating === "NAN" ? 0 : totalfinalRating}</p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex w-full bg-white">
                          <div className="w-[50%]  flex justify-center items-center text-center p-2">
                            <div className="text-left">
                            </div>
                          </div>
                          <div className="w-[16.6%]   flex justify-center items-center text-center text-red-600 text-sm p-1">

                            {totalScaleNo < 100 || totalScaleNo > 100 ? "Scale must be 100" : ""}
                          </div>
                          <div className="w-[16.6%]   flex justify-center items-center text-center p-1">

                          </div>
                          <div className="w-[16.8%]   flex justify-center items-center text-center p-1">
                          </div>
                        </div>
                      </div>
                      {/* <div className="flex justify-end py-3">
                          <button
                            onClick={Submit}
                            className=" py-2 px-7 bg-[#e32278] overflow-hidden shadow-md text-white rounded-lg"
                          >
                            Submit
                          </button>
                        </div> */}

                    </>
                  )
                ) : (
                  <p className="text-red-600 bg-white p-1">No data</p>
                )}
              </div>
              {AssignedClient && AssignedGroup && (

                <div className="w-[5%] flex flex-col justify-end align-bottom items-start px-2">
                  <div className="bg-white p-2 rounded-md shadow-md border" onClick={handleRemoveRow}>
                    <RemoveIcon className="w-5 h-5 cursor-pointer" />
                  </div>
                </div>
              )}
            </div>
            {AssignedClient && AssignedGroup && (
              <div className="flex justify-end py-3 px-14">
                <button
                  onClick={Submit}
                  className=" py-2 px-7 bg-[#e32278] overflow-hidden shadow-md text-white rounded-lg"
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default ClientRatingDashboardL3