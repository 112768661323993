import React, { createContext, useState, useEffect, useMemo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeads } from "../../../redux/slices/leadsSlice";
import { fetchEmployeeAuthData } from "../../../redux/slices/leadsSliceL3";
import { fetchFinancialData } from "../../../redux/slices/financialSlice";

// Create Context
export const L3Context = createContext();

 const L3Provider = ({ children }) => {

      const [LeadReports, setLeadReports ]  = useState([]);
      const [fetchedfinancial, setfetchedfinancial] = useState([]);
      const [groupsL3, setGroupsL3] = useState('');

       const dispatch = useDispatch();
        const financialData = useSelector((state) => state.financial.financialData);
      
        useEffect(() => {
          dispatch(fetchFinancialData());
        }, [dispatch]);
        useEffect(()=>{
       if(financialData){setfetchedfinancial(financialData)}
        },[financialData])

      const L3FinancialAllFetchedData = useMemo(() => {
        if (!fetchedfinancial.length || !groupsL3.length) return [];
      
        return fetchedfinancial.filter((financial) =>
          groupsL3.some((group) => group.newGroupName === financial.AssignedGroup)
        );
      }, [fetchedfinancial, groupsL3]);
      
      
 
  const L3filteredWonLeads = useMemo(
    () => LeadReports.filter((lead) => lead.Status === "Won"),
    [LeadReports]
  );

      
    const L3FinancialTableData = useMemo(() => {
        if (!L3filteredWonLeads.length || !L3FinancialAllFetchedData.length) return [];
      
        return L3FinancialAllFetchedData.filter((financial) =>
            L3filteredWonLeads.some((lead) => lead.ClientName === financial.ClientName)
        );
      }, [L3filteredWonLeads, L3FinancialAllFetchedData]);
      

      const {leads} = useSelector((state)=>state.leads);
        const { empGroups } = useSelector((state) => state.leadsL3);

      useEffect(()=>{
     dispatch(fetchLeads())
     dispatch(fetchEmployeeAuthData())
      },[dispatch])

    useEffect(()=>{
  if(empGroups){setGroupsL3(empGroups)}
    },[empGroups])
      useEffect(() => {
        
            if (leads) {
              const filteredData = leads.filter(lead =>
                empGroups.includes(lead.AssignedGroup) // Check if the AssignedGroup is in empGroups
              );
              setLeadReports(filteredData);
            } else {
              //  enqueueSnackbar('Failed to fetch Status', { variant: 'error' });
            }
         
      }, [ empGroups,  setLeadReports,leads]);
      
  
  

    return (
        <L3Context.Provider value={{ 
           LeadReports, L3FinancialAllFetchedData, L3filteredWonLeads, L3FinancialTableData, empGroups, fetchedfinancial,
           
           }}>
          {children}
        </L3Context.Provider>
      );
    };

    export const L3ContextData = () => useContext(L3Context);

    export default L3Provider;