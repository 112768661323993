import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  newLeadReportsL1: [],
  filteredLeadReportsL1: [],
};

const leadReportsSlice = createSlice({
  name: 'leadReports',
  initialState,
  reducers: {
    setNewLeadReportsL1: (state, action) => {
      state.newLeadReportsL1 = action.payload;
    },
    setFilteredLeadReportsL1: (state, action) => {
      state.filteredLeadReportsL1 = action.payload;

    },
  },
});

export const { setNewLeadReportsL1, setFilteredLeadReportsL1 } = leadReportsSlice.actions;
export default leadReportsSlice.reducer;
