import React, { useState, useEffect, useRef } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Document, Packer, Paragraph, Table, TableCell, TableRow } from "docx";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const HistoricalDownload = ({ historicalDownload }) => {

  const [histoDownload, setHistoDownload] = useState([])

  useEffect(() => {
    setHistoDownload(historicalDownload);
  }, [historicalDownload]);

  useEffect(() => {
    if (historicalDownload.length > 0) {
      setHistoDownload(historicalDownload);
      // setPotentialStatusDownload(historicalDownload)
    }
  }, [historicalDownload]);

  const popupRef = useRef(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible); // Toggle dropdown visibility
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsDropdownVisible(false); // Close the popup if clicked outside
    }
  };
  useEffect(() => {
    if (isDropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isDropdownVisible]);
  const formatDateExcel = (date) => {
    const d = new Date(date);
    const yy = d.getFullYear().toString().slice(-2); // Extract last 2 digits of the year
    const mm = String(d.getMonth() + 1).padStart(2, "0"); // Month (0-based)
    const dd = String(d.getDate()).padStart(2, "0"); // Day
    return `${yy}-${mm}-${dd}`;
  };

  const handleDownload = (event) => {
    const value = event.target.value;

    if (value === "pdf") {
      downloadPDF();
    } else if (value === "word") {
      downloadWord();
    } else if (value === "excel") {
      downloadExcel();
    }
    setIsDropdownVisible(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };


  const downloadExcel = () => {
    const sortedLeads = [...histoDownload].sort((a, b) => new Date(a.LeadDate) - new Date(b.LeadDate));

    const ws = XLSX.utils.json_to_sheet(
      sortedLeads.map((LeadReport, index) => ({
        "#": index + 1,
        "Lead Date": LeadReport.LeadDate ? formatDateExcel(LeadReport.LeadDate) : "N/A",
        "Client Name": LeadReport.ClientName,
        "Project Name": LeadReport.ProjectName,
        "Lead Type": LeadReport.LeadType,
        "Quoted Value": Number(LeadReport.QuotedValue), // ✅ Ensure it's a number
        "Source": LeadReport.Source,
        "Status": LeadReport.Status,
        "Group": LeadReport.AssignedGroup,
        "Follow-up Date": LeadReport.Status === "Won" || LeadReport.Status === "Lost"
          ? "N/A"
          : formatDate(LeadReport.FollowupDate),
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Lead Reports");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const excelFile = new Blob([excelBuffer], {
      bookType: "xlsx",
      type: "application/octet-stream",
    });
    saveAs(excelFile, "LeadReports.xlsx");
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [
        [
          "#",
          "Lead Date",
          "Client Name",
          "Project Name",
          "Lead Type",
          "Quoted Value",
          "Source",
          "Status",
          "Group",
          "Follow-up Date",
        ],
      ],
      body: histoDownload.map((LeadReport, index) => [
        index + 1,
        LeadReport.LeadDate ? formatDate(LeadReport.LeadDate) : "N/A",
        LeadReport.ClientName,
        LeadReport.ProjectName,
        LeadReport.LeadType,
        `$${LeadReport.QuotedValue.toString().replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        )}`,
        LeadReport.Source,
        LeadReport.Status,
        LeadReport.AssignedGroup,
        LeadReport.Status === "Won" || LeadReport.Status === "Lost"
          ? "N/A"
          : formatDate(LeadReport.FollowupDate),
      ]),
      styles: {
        fontSize: 7,
      },
      headStyles: {
        fontSize: 8,
      },
    });
    doc.save("LeadReports.pdf");
  };

  const downloadWord = () => {
    const headerRow = new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph("S.No")],
        }),
        new TableCell({
          children: [new Paragraph("Lead Date")],
        }),
        new TableCell({
          children: [new Paragraph("Client Name")],
        }),
        new TableCell({
          children: [new Paragraph("Project Name")],
        }),
        new TableCell({
          children: [new Paragraph("Lead Type")],
        }),
        new TableCell({
          children: [new Paragraph("Quoted Value")],
        }),
        new TableCell({
          children: [new Paragraph("Source")],
        }),
        new TableCell({
          children: [new Paragraph("Status")],
        }),
        new TableCell({
          children: [new Paragraph("Group")],
        }),
        new TableCell({
          children: [new Paragraph("Follow-up Date")],
        }),
      ],
    });

    const dataRows = histoDownload.map((LeadReport, index) => {
      return new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph(`${index + 1}`)],
          }),
          new TableCell({
            children: [
              new Paragraph(
                LeadReport.LeadDate ? formatDate(LeadReport.LeadDate) : "N/A"
              ),
            ],
          }),
          new TableCell({
            children: [new Paragraph(LeadReport.ClientName)],
          }),
          new TableCell({
            children: [new Paragraph(LeadReport.ProjectName)],
          }),
          new TableCell({
            children: [new Paragraph(LeadReport.LeadType)],
          }),
          new TableCell({
            children: [
              new Paragraph(
                `$${LeadReport.QuotedValue.toString().replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                )}`
              ),
            ],
          }),
          new TableCell({
            children: [new Paragraph(LeadReport.Source)],
          }),
          new TableCell({
            children: [new Paragraph(LeadReport.Status)],
          }),
          new TableCell({
            children: [new Paragraph(LeadReport.AssignedGroup)],
          }),
          new TableCell({
            children: [
              new Paragraph(
                LeadReport.Status === "Won" || LeadReport.Status === "Lost"
                  ? "N/A"
                  : formatDate(LeadReport.FollowupDate)
              ),
            ],
          }),
        ],
      });
    });

    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Table({
              rows: [headerRow, ...dataRows],
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "LeadReports.docx");
    });
  };





  return (
    <div>

      <div className="relative flex items-center text-4xl sm:text-md md:text-xl lg:text-xl xl:text-2xl 2xl:text-4xl">
        <CloudDownloadIcon
          onClick={toggleDropdown}
          className="cursor-pointer"
          // fontSize="4rem"
        />

        {isDropdownVisible && (
          <div ref={popupRef} className="absolute top-8 -left-0 shadow-lg z-50 bg-white text-base">
            <div
              className="px-2 py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleDownload({ target: { value: "pdf" } })}
            >
              PDF
            </div>
            <div
              className="px-2 py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleDownload({ target: { value: "excel" } })}
            >
              Excel
            </div>
            <div
              className="px-2 py-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleDownload({ target: { value: "word" } })}
            >
              Word
            </div>
          </div>
        )}
      </div>




    </div>
  )
}

export default HistoricalDownload