import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar, closeSnackbar } from "notistack";
import { FaUser, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import AdminLoader from "../loader/AdminLoader";
import { useDispatch, useSelector } from "react-redux";
import { logoutSession, tryLogin } from "../../redux/slices/sessionSlice";
import axiosInstance from "../../routes/axiosConfig";

const Login = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [, setLoginAttempts] = useState(0);

  const dispatch = useDispatch();
  const {  error, session } = useSelector((state) => state.session);

  const [isLoading, ] = useState(false);

  const [passwordVisible, setPasswordVisible] = useState(false); // State for toggling password visibility

  const togglePassword = () => {
    setPasswordVisible(!passwordVisible); // Toggle the password visibility
  };
  // Hover handler to show password
  const handleMouseEnter = () => {
    setPasswordVisible(true);
  };

  // Hover handler to hide password
  const handleMouseLeave = () => {
    setPasswordVisible(false);
  };

  const handleLogin = useCallback(async () => {
  
    if (!email) {
      closeSnackbar();
      enqueueSnackbar("Please Enter Email", { variant: "error" });
    }
    else if(!password){
      closeSnackbar();
    enqueueSnackbar("Please Enter Password", { variant: "error" });
    } else {
        dispatch(tryLogin({ email, password }))

           }
  }, [email, password, enqueueSnackbar, dispatch, ]);

  useEffect(() => {
    if (session?.token) {
      setLoginAttempts(0); // Reset attempts here
      localStorage.setItem("email", session.email);
      localStorage.setItem("name", session.name);
      localStorage.setItem("token", session.token);
      localStorage.setItem("url", session.dashboardUrl);
      sessionStorage.setItem("userRole", session.auth);
  
      navigate("/dashboard/"+session.auth); // Redirect to existing user page
      enqueueSnackbar("Logged in successfully!", { variant: "success", autoHideDuration: 1000 });
    } else {
      localStorage.removeItem("token");
    }
  }, [session, navigate, enqueueSnackbar]);
  
  useEffect(() => {
    if (error) {
      // Increment the login attempts only once per error occurrence
      setLoginAttempts((prevAttempts) => {
        const newAttempts = prevAttempts + 1;
  
        if (newAttempts >= 3) {
          enqueueSnackbar(
            "Too many failed attempts. Redirecting to Forgot Password...",
            { variant: "warning", autoHideDuration: 3000 }
          );
          setTimeout(() => {
            navigate("/forgotpw");
          }, 3000);
        } else {
          enqueueSnackbar("Incorrect Login Credentials!", {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
  
        return newAttempts;
      });
    }
  }, [error, enqueueSnackbar, navigate]);
  

     useEffect(() => {
          const checkUser = async () => {
              const token = localStorage.getItem("token");
              if (token?.length > 1) {
                  try {
                      const response = await axiosInstance.get("/existing/currentUser");
                      sessionStorage.setItem("userRole UserVerified",response.data.auth)
                      navigate("/dashboard/"+response?.data?.auth); // Redirect to existing user page
                  } catch (error) {
                      console.error("Token expired or invalid:", error);
                      logoutSession()
                      localStorage.removeItem("token"); // Clear invalid token
                  }
              } else {
                  navigate("/"); // Redirect if no token found
              }
          };
  
          checkUser();
      }, [navigate]);
  

  // Add event listener for Enter key
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter" && !isLoading) {
        handleLogin();
      }
    };
  
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [email, password, handleLogin, isLoading]);

  const forgotpw = () => {
    navigate("/forgotpw");
  };

  return (
    <div className="min-h-screen flex items-center bg-gray-100 justify-center">
    {isLoading ? (
      <AdminLoader isLoading={isLoading} />
    ) : (
      <div className="text-center my-20 px-48 py-12 bg-white overflow-hidden shadow-2xl rounded-lg border border-gray-200">
        <h1 className="text-7xl font-extrabold">
          <span className="text-pink-600">Q</span>
          <span className="text-teal-600">Trackr</span>
        </h1>
        <br />
        <br />
        <h1 className="font-extrabold text-2xl">Login</h1>
        <br />
        <div className="flex justify-center">
          <FaUser style={{ alignItems: "center", marginTop: "3%" }} size={24} />
          <input
            className="border ml-2 w-full border-gray-300 rounded-md p-2 overflow-hidden shadow-md outline-none"
            value={email}
            type="text"
            placeholder="Enter Email"
            onChange={(e) => setEmail(e.target.value)}
            // onKeyDown={(e) => e.key === "Enter" && handleLogin()} // Handle Enter key
            autocomplete="off"
          />
        </div>

        <br />

        <div className="flex items-center relative">
          <FaLock style={{ alignItems: "center", marginTop: "3%" }} size={24} />
          <input
            className="border ml-2 w-full border-gray-300 rounded-md p-2 overflow-hidden shadow-md outline-none"
            value={password}
            type={passwordVisible ? "text" : "password"} // Toggle input type
            placeholder="Enter Password"
            onChange={(e) => setPassword(e.target.value)}
            // onKeyDown={(e) => e.key === "Enter" && handleLogin()} // Handle Enter key
            autocomplete="off"
          />
          <div
            className="absolute right-4 cursor-pointer"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={togglePassword}
            style={{ top: "50%", transform: "translateY(-50%)" }}
          >
            {passwordVisible ? <FaEye size={20} /> : <FaEyeSlash size={20} />}

          </div>
        </div>
        <h1
          onClick={forgotpw}
          className="text-right mt-2 text-sm underline text-blue-900 hover:text-red-600 cursor-pointer"
        >
          Forgot Password?
        </h1>
        <br />
        {/* <button
          onClick={handleLogin}
          className="py-1 text-center mt-2 overflow-hidden shadow-md rounded-sm text-white px-6 bg-blue-600 hover:bg-blue-900"
        >
          Login
        </button> */}
        <button
  onClick={handleLogin}
  disabled={isLoading}  // Disable button when loading
  className={`py-1 text-center mt-2 overflow-hidden shadow-md rounded-sm text-white px-6 
    ${isLoading ? "bg-gray-400 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-900"}`}
>
  {isLoading ? "Logging in..." : "Login"}
</button>
      </div>)}
  </div>
  );
};

export default Login;
