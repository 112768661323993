import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaTrash } from "react-icons/fa";
import { MdEditSquare, MdSearch } from "react-icons/md";
import ConfirmationModal from "./ConfirmationModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AdminLoader from "../../loader/AdminLoader";
import L1Sidebar from "./L1Sidebar";
import { FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import {  useDispatch, useSelector } from "react-redux";
import { logoutSession } from "../../../redux/slices/sessionSlice";
import { addEmployee, deleteEmployee, fetchEmployees, updateEmployee } from "../../../redux/slices/employeeAdminSlice";

const Employees = () => {
  const [isLoading, SetisLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch()

  const [showLogout, setshowlogout] = useState(false);
    const popupRef = useRef(null);
    const name = localStorage.getItem("name");
  
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setshowlogout(false); // Close the popup if clicked outside
      }
    };
    useEffect(() => {
      if (showLogout) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [showLogout]);
  
    const logout = (e) => {
      e.preventDefault();
      setshowlogout(!showLogout);
    };
    const logoutUser = (e) => {
      e.preventDefault();
      dispatch(logoutSession())
      enqueueSnackbar("Successfully Logged out!", { variant: "success",autoHideDuration: 1500 });
      navigate("/");
    };
  

           
  const [EmpFirstName, setEmpFirstName] = useState("");
  const [EmpLastName, setEmpLastName] = useState("");
  const [EmpPhone, setEmpPhone] = useState("");
  const [EmpEmail, setEmpEmail] = useState("");
  const [EmpAuthLevel, setEmpAuthLevel] = useState("");
  const [EmpGroups, setEmpGroups] = useState([]);
  const [showleveldown, setShowLeveldown] = useState(false);
  
const dropdownRefLevel = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefLevel.current &&
        !dropdownRefLevel.current.contains(event.target)
      ) {
        setShowLeveldown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  

  const handleFirstName = (e) => {
    const value = e.target.value;
    
    if (/^[a-zA-Z0-9\s]*$/.test(value) && value.length <= 20) {
      setEmpFirstName(value);
    }
};

const handleLastName = (e) => { 
  const value = e.target.value;

  if (/^[a-zA-Z0-9\s]*$/.test(value) && value.length <= 20) {
    setEmpLastName(value);
  }
};

const handleEmail = (e) => { 
  const value = e.target.value;

  if (value.length <= 40) {
    setEmpEmail(value);
  }
};

  const [showDropdown, setShowDropdown] = useState(false);
  const [editingEmployee, setEditingEmployee] = useState(null);

  const [showTable, setShowTable] = useState(true); // Controls table visibility

  const editEmployee = (id) => {
    const employeeToEdit = l1employees.find((employee) => employee.id === id);
    setEditingEmployee(employeeToEdit);
    setShowTable(false); // Hide the table when editing starts

    // Ensure EmpGroups is set to employee's existing groups
    if (employeeToEdit && employeeToEdit.groups) {
      setEmpGroups(employeeToEdit.groups); 
      setShowTable(false); // Hide the table when editing starts
    } else {
       setEmpGroups([]); // Reset if no groups
     }
  };


  useEffect(() => {
    if (editingEmployee) {
      setEmpFirstName(editingEmployee.EmpFirstName);
      setEmpLastName(editingEmployee.EmpLastName);
      setEmpPhone(editingEmployee.EmpPhone);
      setEmpEmail(editingEmployee.EmpEmail);
      setEmpAuthLevel(editingEmployee.EmpAuthLevel);
      setEmpGroups(editingEmployee.EmpGroups);
    }
  }, [editingEmployee]);

  const resetForm = () => {
    setEmpFirstName("");
    setEmpLastName("");
    setEmpPhone("");
    setEmpEmail("");
    setEmpAuthLevel("");
    setEmpGroups([]);
  };

  const [l1employees, setEmployees] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const employees = useSelector((state) => state.employees.employees);
  
  useEffect(()=>{
  dispatch(fetchEmployees())
  },[dispatch])

    useEffect(() => {
  if(employees){
    setEmployees(employees);
    setFilteredEmployees(employees);
  }    
    }, [employees]);


  useEffect(() => {
  }, [filteredEmployees]);


const Submit = async (e) => {
  e.preventDefault();

  if (!EmpFirstName || !EmpLastName || !EmpPhone || !EmpEmail || !EmpAuthLevel) {
    enqueueSnackbar("Please fill all the fields!", { variant: "error" });
    return;
}

// Validation for EmpAuthLevel "L3"
if (EmpAuthLevel === "L3" && (!EmpGroups || EmpGroups.length === 0)) {
    enqueueSnackbar(" Please select at least one group for L3 employee!", { variant: "error" });
    return;
}

    try {
          SetisLoading(true);
          
          if (!editingEmployee) {
            // Dispatch addEmployee action
            await dispatch(addEmployee({
              EmpFirstName,
              EmpLastName,
              EmpPhone,
              EmpEmail,
              EmpAuthLevel,
              EmpGroups,
            })).unwrap(); // Ensure we catch errors properly
      
            enqueueSnackbar("Employee Added Successfully!", { variant: "success" });
            dispatch(fetchEmployees())
          } else {
            // Dispatch updateEmployee action
            await dispatch(updateEmployee({
              id: editingEmployee.id,
              EmpFirstName,
              EmpLastName,
              EmpPhone,
              EmpEmail,
              EmpAuthLevel,
              EmpGroups,
            })).unwrap();
      
            enqueueSnackbar("Employee Details Updated Successfully!", { variant: "success" });
            dispatch(fetchEmployees())
            setShowTable(true);
            setEditingEmployee(null);
          }
      
          // Refresh data and reset form
          // refreshData();
          resetForm();
        } catch (error) {
          console.error("Error:", error);
          enqueueSnackbar(error.message || "Connection Error!", { variant: "error" });
        } finally {
          SetisLoading(false);
      };
  
};


const handleDeleteEmployee = async (id) => {
  SetisLoading(true);
  try {
    await dispatch(deleteEmployee(id)).unwrap();
    enqueueSnackbar("Employee deleted successfully!", { variant: "success" });
    // Refresh data to update UI
    // refreshData();
    dispatch(fetchEmployees())
  } catch (error) {
    console.error("Error:", error);
    enqueueSnackbar("Failed to delete employee!", { variant: "error" });
  } finally {
    SetisLoading(false);
  }
};

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsEditing(!!editingEmployee);
  }, [editingEmployee]);

  const Cancel = (e) => {
    e.preventDefault();

      setEmpFirstName("");
      setEmpLastName("");
      setEmpPhone("");
      setEmpEmail("");
      setEmpAuthLevel("");
      setEmpGroups([]);
      setIsEditing(false);
      setShowTable(true);
  };



   const [currentPage, setCurrentPage] = useState(1); // Track the current page
   
     const [itemsPerPage] = useState(10);
   
     const totalItems = filteredEmployees.length;
     const startIdx = (currentPage - 1) * itemsPerPage;
     const endIdx = startIdx + itemsPerPage;
   
     // Function to calculate the number of pages
     const totalPages = Math.ceil(filteredEmployees.length / itemsPerPage);
   
     // Slice the filteredLeadReport based on the current page
     const currentData = filteredEmployees.slice(
       (currentPage - 1) * itemsPerPage,
       currentPage * itemsPerPage
     );
   const handleSearchInputChange = (e) => {
     setSearchQuery(e.target.value);
     filterEmployees(e.target.value);
     setCurrentPage(1);
   };
 
   const filterEmployees = (query) => {
     const filtered = l1employees.filter((employee) => {
       return (
         (employee.EmpFirstName?.toLowerCase() || "").includes(query.toLowerCase()) ||
         (employee.EmpLastName?.toLowerCase() || "").includes(query.toLowerCase()) ||
         (employee.EmpPhone || "").includes(query) ||
         (employee.EmpEmail?.toLowerCase() || "").includes(query.toLowerCase()) ||
         (employee.EmpAuthLevel?.toLowerCase() || "").includes(query.toLowerCase()) ||
         (employee.EmpGroups || "").includes(query)
       );
     });
   
     setFilteredEmployees(filtered);
   };


  const [GroupNames, setGroupNames] = useState([]);

  const {group} = useSelector((state) => state.group)

  useEffect(()=>{
    setGroupNames(group)
  },[group])


  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!dropdownRef.current?.contains(event.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [EmployeeToDelete, setEmployeeToDelete] = useState();
  const openDeleteModal = (id) => {
    setEmployeeToDelete(id);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setEmployeeToDelete(null);
  };

  const confirmDelete = async () => {
    if (EmployeeToDelete) {
      await handleDeleteEmployee(EmployeeToDelete); // Call your existing delete function
    }
    closeDeleteModal();
  };
  const handlelevelChange = (level) => {
    setEmpAuthLevel(level); // Update the selected level
  
    // Ensure dropdown closes after selection
    setShowLeveldown(false);
  
    // Reset groups if switching away from L3
    if (level !== "L3") {
      setEmpGroups([]);
      setShowDropdown(false);
    }
  };
  
  return (
    <>
    <div className=" bg-[#f3f8f8] pb-6"> 
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
      />
      <L1Sidebar />

      {isLoading ? (
        <AdminLoader isLoading={isLoading} />
      ) : (
        <div className=" ml-[21%] w-3/4 relative pt-4">
         <div className="bg-white p-2  flex justify-between align-middle items-center rounded-md">
                      <h1 className="text-xl font-semibold"> </h1>
                      <div className="relative cursor-pointer bg-transparent">
                        <FaUserCircle onClick={logout} className="text-black w-6 h-6" />
                        {showLogout && (
                          <div
                            ref={popupRef}
                            className="absolute right-0 top-full mt-1 text-black text-center px-2 w-36 shadow-2xl overflow-hidden bg-white border border-gray-400 z-50 rounded-lg"
                          >
                            <h1 className="px-6 py-2 rounded-md my-1">{name}</h1>
                            <h1
                              onClick={logoutUser}
                              className="flex items-center gap-2 px-6 py-2 rounded-md my-1 cursor-pointer hover:bg-black hover:text-white"
                            >
                              <MdLogout size={24} /> Logout
                            </h1>
                          </div>
                        )}
                      </div>
                    </div>
                    
        <div className=" p-4 bg-white  z-122 border border-gray-200 relative shadow-lg w-full justify-center top-6
         rounded-md">
          <h1 className="text-2xl mb-2 font-semibold">
            {isEditing ? "Edit Employee Details" : "Add Employee"}
          </h1>
          <hr />
          <hr />
          <br />
          <div className="flex justify-start w-full">
            <div className="mx-4 w-1/3">
              <label className="block" htmlFor="Name">
                First Name
              </label>
              <input
                className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                type="text"
                placeholder="Enter First Name"
                value={EmpFirstName}
                onChange={handleFirstName}
              />
            </div>
            <div className="mx-4 w-1/3">
              <label className="block" htmlFor="Name">
                Last Name
              </label>
              <input
                className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                type="text"
                placeholder="Enter Last Name"
                value={EmpLastName}
                onChange={handleLastName}
              />
            </div>
            <div className="mx-4 w-1/3">
              <label className="block" htmlFor="Name">
                Mobile Number
              </label>
              <PhoneInput
                country={"us"}
                value={EmpPhone}
                onChange={(phone) => {
                  setEmpPhone(phone); // Ensure value is updated properly
                }}
                  inputProps={{
                  required: true,
                  className:
                  "w-full border border-gray-400 lg:pl-14 cp:pl-7 outline-0 rounded overflow-hidden shadow-md h-10 p-2",
                  style: {
                    fontFamily:
                      "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                  },
                }}
              />
            </div>
          </div>
          <br />
          <div className="flex justify-start w-full">
            <div className="mx-4 w-1/3">
              <label className="block" htmlFor="email">
                Email ID
              </label>
              <input
                className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                type="email"
                placeholder="Enter Email ID"
                value={EmpEmail}
                onChange={handleEmail}
              />
            </div>
            <div className="mx-4 w-1/3">
              <label className="block" htmlFor="Name">
                Authorization Level
              </label>

               <div className=" ">
                                    <div
                                      ref={dropdownRefLevel}
                                      className="relative flex shadow-sm p-0 -pb-4 rounded-md border border-gray-300 align-middle items-center"
                                    >
                                      <ExpandMoreIcon
                                        color="white"
                                        className="absolute cursor-pointer right-0 m-auto bg-white"
                                        onClick={() => setShowLeveldown(!showleveldown)}
                                      />
              
                                      <button
                                        type="button"
                                        onClick={() => setShowLeveldown(!showleveldown)}
                                        className="border border-gray-300 text-left text-nowrap overflow-hidden rounded-md shadow-md outline-none p-2 w-full"
              
                                      >
                                        {EmpAuthLevel || "Select Authorization Level"}
                                      </button>
              
                                      {showleveldown && (
              
                                        <div
                                          style={{ zIndex: 9999 }}
                                          className="absolute top-full p-1 left-0 w-full cursor-pointer max-h-96 overflow-y-auto bg-gray-100 text-sm border border-gray-300 shadow-lg flex flex-col gap-2"
                                        >
              
                                          <div className="" onClick={() =>
                                            handlelevelChange("L1")
                                          }>L1</div>
                                          <div onClick={() =>
                                            handlelevelChange("L2")
                                          }>L2</div>
                                          <div onClick={() =>
                                            handlelevelChange("L3")
                                          }>L3</div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
            </div>
            <div className="mx-4 w-1/3">
              {EmpAuthLevel === "L3" && (
                <div className="w-full">
                  <label className="block" htmlFor="auth">
                    Assign Group(s)
                  </label>
                  <div
                    className="relative border w-11/12 border-gray-400 rounded-md shadow-sm p-2"
                    ref={dropdownRef}
                  >
                    {/* Trigger for dropdown */}
                    <button
                      type="button"
                      onClick={() => setShowDropdown(!showDropdown)}
                      className="w-full text-left bg-white focus:outline-none"
                    >
                      {EmpGroups.length > 0
                        ? EmpGroups.join(", ") // Display selected groups
                        : "Select Group(s)"}{" "}
                      {/* Placeholder */}
                    </button>

                    {/* Dropdown options */}
                    {showDropdown && (
                      <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-48 overflow-y-auto mt-2">
                        {GroupNames.map((group) => (
                          <label
                            key={group.id}
                            className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                          >
                            <input
                              type="checkbox"
                              value={group.newGroupName}
                              checked={EmpGroups.includes(group.newGroupName)}
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                if (e.target.checked) {
                                  setEmpGroups((prev) => [
                                    ...prev,
                                    selectedValue,
                                  ]);
                                } else {
                                  setEmpGroups((prev) =>
                                    prev.filter(
                                      (group) => group !== selectedValue
                                    )
                                  );
                                }
                              }}
                              className="mr-2"
                            />
                            {group.newGroupName}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex mt-8 ml-3 justify-start">
            <button
              onClick={Submit}
              className="mx-2 py-2 px-5 bg-[#e32278] hover:bg-[#e12298]  overflow-hidden shadow-md text-white rounded-md"
            >
              {isEditing ? "Update" : "Submit"}
            </button>
            <button
              onClick={Cancel}
              className="mx-2 py-2 px-5 bg-black hover:bg-white hover:text-black border border-black overflow-hidden shadow-md text-white rounded-md"
            >
              Cancel
            </button>
          </div>
          <br />
        </div>
        </div>
      )}

      <br />
      <br />
      {showTable && (
        <div className=" ml-[21%] p-4 bg-white  border border-gray-200 relative overflow-hidden shadow-lg w-3/4 justify-center  rounded-md">
          <div className="flex">
            <h1 className="text-2xl w-4/5 mb-2 font-semibold">Employee List</h1>
            <div className="relative flex w-1/5 justify-end">
                                              <input
                                                type="text"
                                                value={searchQuery}
                                                onChange={handleSearchInputChange}
                                                className="border  w-full border-gray-300 rounded-md mb-3 p-2 overflow-hidden shadow-md outline-none "
                                                placeholder="Search"
                                              />
                                              <MdSearch
                                                size={30}
                                                className="mt-2 absolute right-1 top-3 transform -translate-y-1/2 text-gray-500"
                                              />
                                            </div>
          </div>
          <hr />
          <hr />
          <br />
          <div className="max-h-[550px] overflow-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-black sticky top-0">
                <tr className="text-white">
                  <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                    #
                  </th>
                  <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                    First Name
                  </th>
                  <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                    Last Name
                  </th>
                  <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                    Phone
                  </th>
                  <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                    Auth Level
                  </th>
                  <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                    Group
                  </th>
                  <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                    Edit
                  </th>
                  <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                    <FaTrash size={18} />
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentData.length > 0 ? (
                currentData.map((employee, index) => (
                  <tr className="border" key={employee.id}>
                    <td className="px-6 text-center py-2">{startIdx + index  + 1}</td>
                    <td className="px-6 text-center py-2">
                      {employee.EmpFirstName}
                    </td>
                    <td className="px-6 text-center py-2">
                      {employee.EmpLastName}
                    </td>
                    <td className="px-6 text-center py-2">
                      {employee.EmpPhone}
                    </td>
                    <td className="px-6 text-center py-2">
                      {employee.EmpEmail}
                    </td>
                    <td className="px-6 text-center py-2">
                      {employee.EmpAuthLevel}
                    </td>
                    <td className="px-6 text-center py-2">
                      {Array.isArray(employee.EmpGroups) &&
                      employee.EmpGroups.length > 0
                        ? employee.EmpGroups.join(", ") // Join array items with commas
                        : ""}{" "}
                      {/* Fallback text if no groups */}
                    </td>
                    <td
                      onClick={() => editEmployee(employee.id)}
                      className="px-6 hover:bg-black cursor-pointer hover:text-white text-center py-2"
                    >
                      <MdEditSquare size={20} />
                    </td>
                    <td
                      onClick={() => openDeleteModal(employee.id)}
                      className="px-6 hover:bg-black cursor-pointer hover:text-white text-center py-2"
                    >
                      <FaTrash size={18} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                <td
                  colSpan="6"
                  className="border p-4 text-start text-red-500"
                >
  {searchQuery ? `No results found for "${searchQuery}"` : "No results found"}                   
  </td>
              </tr>
              )}
              </tbody>
            </table>
          </div>
          <div className="pagination mt-4 flex justify-between items-center">
          <div>
            {totalItems > 0 ? (
              <>
                Showing {startIdx + 1} to {Math.min(endIdx, totalItems)} of{" "}
                {totalItems} Entries
              </>
            ) : (
              <>Showing 0 Entries</>
            )}
          </div>
          {totalPages > 1 && (
            <div>
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                className="px-4 py-2 mx-2 border rounded"
                disabled={currentPage === 1}
              >
                Previous
              </button>

              {Array.from({ length: 3 }, (_, index) => {
                // Calculate the page number for the current range
                const pageNumber = currentPage - 1 + index;

                // Ensure the pageNumber stays within bounds
                if (pageNumber >= 1 && pageNumber <= totalPages) {
                  return (
                    <button
                      key={pageNumber}
                      onClick={() => setCurrentPage(pageNumber)}
                      className={`px-4 py-2 mx-2 border rounded ${
                        currentPage === pageNumber ? "bg-gray-300" : ""
                      }`}
                    >
                      {pageNumber}
                    </button>
                  );
                }

                // Return null for out-of-bounds values
                return null;
              })}

              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                className="px-4 py-2 mx-2 border rounded"
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          )}
        </div>
        </div>
      )}
      <br />
      <br />
      </div>
    </>
  );
};

export default Employees;
