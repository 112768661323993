import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { MdSearch} from "react-icons/md";
import ConfirmationModal from './ConfirmationModal';
import AdminLoader from '../../loader/AdminLoader';
import L1Sidebar from './L1Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLead, fetchLeads } from '../../../redux/slices/leadsSlice';

const ClientRatingL1 = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading,] = useState(false);

    const [LeadReports, setLeadReports] = useState([]); 
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredLeadReports, setFilteredLeadReports] = useState([]);
    const dispatch = useDispatch();
    const {leads} = useSelector((state)=> state.leads);

    useEffect(()=>{
dispatch(fetchLeads())
    },[dispatch])
    useEffect(()=>{
      setLeadReports(leads)
    },[leads])


    
   // Function to format date as "DD-MM-YYYY"
  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    filterLeadReports(query);
  };
  const filterLeadReports = (query) => {
    const lowerCaseQuery = query.toLowerCase();
  
    const filtered = LeadReports.filter((LeadReport) => {
      return Object.values(LeadReport).some((value) => {
        if (value !== null && value !== undefined) {
          let formattedValue = value.toString().toLowerCase();
  
          // If value is a Date object, format it to "DD-MM-YYYY"
          if (value instanceof Date) {
            formattedValue = formatDate(value);
          }
          // If value is a date string, try to format it properly
          else if (typeof value === "string") {
            const dateValue = parseDate(value);
            if (dateValue) {
              formattedValue = formatDate(dateValue);
            }
          }
  
          return formattedValue.includes(lowerCaseQuery);
        }
        return false;
      });
    });
  
    setFilteredLeadReports(filtered);
  };
  
  // Function to parse different date formats into Date object
  const parseDate = (dateStr) => {
    let parsedDate = new Date(dateStr);
  
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate;
    }
  
    // Try to parse manually formatted "DD-MM-YYYY" dates
    const parts = dateStr.split("-");
    if (parts.length === 3) {
      const [day, month, year] = parts.map(Number);
      return new Date(year, month - 1, day);
    }
  
    return null;
  };



    // const filterLeadReports = (query) => {
    //     const filtered = LeadReports.filter(LeadReport =>
    //         LeadReport.ClientName.toLowerCase().includes(query.toLowerCase()) ||
    //         LeadReport.ProjectName.toLowerCase().includes(query.toLowerCase()) ||
    //         LeadReport.ClientContactName.includes(query) ||
    //         LeadReport.AssignedGroup.toLowerCase().includes(query.toLowerCase()) ||
    //         LeadReport.LeadDate.toLowerCase().includes(query.toLowerCase()) ||
    //         LeadReport.LeadType.includes(query)
    //     );
    //     setFilteredLeadReports(filtered);
    // };

    // const handleSearchInputChange = (e) => {
    //     setSearchQuery(e.target.value);
    //     filterLeadReports(e.target.value);
    // };

    const [, setClientName] = useState('');
    const [, setAssignedGroup] = useState('');
    const [, setProjectName] = useState('');
    const [, setClientPhone] = useState('');
    const [, setClientContactName] = useState('');
    const [, setClientEmail] = useState('');
    const [, setStatus] = useState('');

    const [editingLeadReport,] = useState(null);
    // const editLeadReport = (id) => {
    //     const ReportToEdit = LeadReports.find(LeadReport => LeadReport.id === id);
    //     setEditingLeadReport(ReportToEdit);
    //     setShowEdit(true);
    // };
        
    useEffect(() => {
        if (editingLeadReport) {
            setClientName(editingLeadReport.ClientName);
            setClientContactName(editingLeadReport.ClientContactName);
            setClientPhone(editingLeadReport.ClientPhone);
            setClientEmail(editingLeadReport.ClientEmail);
            setProjectName(editingLeadReport.ProjectName);
            setAssignedGroup(editingLeadReport.AssignedGroup);
            setStatus(editingLeadReport.Status);
        }
    }, [editingLeadReport]);


  
    const deleteLeadReport = async (id) => {
      try {
        await dispatch(deleteLead(id)).unwrap(); // ✅ Call Redux action instead of axios
        enqueueSnackbar("Client deleted successfully!", { variant: "success" });
      } catch (error) {
        console.error("Error:", error);
        enqueueSnackbar("Failed to delete client!", { variant: "error" });
      }
    };

    useEffect(() => {
        setFilteredLeadReports(LeadReports);
    }, [LeadReports]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sourceToDelete, setsourceToDelete] = useState(null);
    
   
    
    const closeDeleteModal = () => {
        setIsModalOpen(false);
        setsourceToDelete(null);
    };
    
    const confirmDelete = async () => {
        if (sourceToDelete) {
            await deleteLeadReport(sourceToDelete);  // Call your existing delete function
        }
        closeDeleteModal();
    };

    const handleRowClick = (clientName) => {
        navigate(`/client/${clientName}`);
    };



     const [currentPage, setCurrentPage] = useState(1); // Track the current page
    
      const [itemsPerPage] = useState(10);
    
      const totalItems = filteredLeadReports.length;
      const startIdx = (currentPage - 1) * itemsPerPage;
      const endIdx = startIdx + itemsPerPage;
    
      // Function to calculate the number of pages
      const totalPages = Math.ceil(filteredLeadReports.length / itemsPerPage);
    
      // Slice the filteredLeadReport based on the current page
      const currentData = filteredLeadReports.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );


  return (
    <>
        
        <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
    />

      <L1Sidebar />

{isLoading ? (
          <AdminLoader isLoading={isLoading}/>
        ) : (
            <div className='ml-[20%] p-4 bg-white  border border-gray-200 relative overflow-hidden shadow-lg w-3/4 justify-center top-12'>
            <div className='flex'>
            <h1 className='text-2xl w-4/5 mb-2 font-semibold'>Clients Rating</h1>
                  <div className="relative flex md:w-1/5 justify-end">
                                                <input
                                                  type="text"
                                                  value={searchQuery}
                                                  onChange={handleSearchInputChange}
                                                  className="border  w-full border-gray-300 rounded-md mb-2 p-2 overflow-hidden shadow-md outline-none pl-4"
                                                  placeholder="Search"
                                                />
                                                <MdSearch
                                                  size={30}
                                                  className="mt-2 absolute right-1 top-3 transform -translate-y-1/2 text-gray-500"
                                                />
                                              </div>
            </div>  
            <hr /><hr /><br />
            <div className="max-h-[500px] overflow-auto">
            <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-black sticky top-0">
                <tr className='text-white'>
                    <th className="px-3 py-3 w-fit text-center border text-xs font-medium uppercase tracking-wider">#</th>
                    <th className="px-3 py-3 w-fit text-center border text-xs font-medium uppercase tracking-wider">Client Name</th>
                    <th className="px-3 py-3 w-fit text-center border text-xs font-medium uppercase tracking-wider">Project Name</th>
                    <th className="px-3 py-3 w-fit text-center border text-xs font-medium uppercase tracking-wider">Client Contact Person Name</th>
                    <th className="px-3 py-3 w-fit text-center border text-xs font-medium uppercase tracking-wider">Group</th>
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                { currentData.length > 0 ? (
            currentData.map((LeadReport, index) => (
                    <tr className='border text-sm cursor-pointer' key={LeadReport.id} onClick={() => handleRowClick(LeadReport.ClientName)}>
                        <td className="px-3 w-fit text-center py-2">{index + 1}</td>
                        <td className="px-3 w-fit text-center py-2">{LeadReport.ClientName}</td>
                        <td className="px-3 w-fit text-center py-2">{LeadReport.ProjectName}</td>
                        <td className="px-3 w-fit text-center py-2">{LeadReport.ClientContactName}</td>
                        <td className="px-3 w-fit text-center py-2">{LeadReport.AssignedGroup}</td>
                    </tr>    ))
                       ) : (
                        <tr>
                        <td colSpan="6" className="border p-4 text-start text-red-500">
                        {searchQuery ? `No results found for "${searchQuery}"` : "No results found"}                   
                        </td>
                      </tr>
                         )}
               
            </tbody>
        </table>
        </div>
        <div className="pagination mt-4 flex justify-between items-center">
        <div>
    {totalItems > 0 ? (
      <>
        Showing {startIdx + 1} to {Math.min(endIdx, totalItems)} of {totalItems} Entries
      </>
    ) : (
      <>Showing 0 Entries</>
    )}
  </div>
          {totalPages > 1 && (
          <div>
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              className="px-4 py-2 mx-2 border rounded"
              disabled={currentPage === 1}
            >
              Previous
            </button>

            {Array.from({ length: 3 }, (_, index) => {
              // Calculate the page number for the current range
              const pageNumber = currentPage - 1 + index;

              // Ensure the pageNumber stays within bounds
              if (pageNumber >= 1 && pageNumber <= totalPages) {
                return (
                  <button
                    key={pageNumber}
                    onClick={() => setCurrentPage(pageNumber)}
                    className={`px-4 py-2 mx-2 border rounded ${currentPage === pageNumber ? "bg-gray-300" : ""
                      }`}
                  >
                    {pageNumber}
                  </button>
                );
              }

              // Return null for out-of-bounds values
              return null;
            })}

            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              className="px-4 py-2 mx-2 border rounded"
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
      
        )}
        </div>
        </div> 
    )}
            
            <br /><br /><br /><br /><br /><br />
        </>
  )
}

export default ClientRatingL1