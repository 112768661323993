import React, { useEffect, useState, useCallback } from "react";
import L1Sidebar from "./L1Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroups } from "../../../redux/slices/groupSlice";
import { fetchHardware } from "../../../redux/slices/hardwareStoreSlice";
import { fetchLabourCost } from "../../../redux/slices/labourCostSlice";

const HardwareTrackerL1 = () => {
  const [groupFilter] = useState("");
  const [groupName, setgroupName] = useState("");
  const [GroupNames, setGroupNames] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalValue, setModalValue] = useState("");
  const [quantity] = useState("1");
  const [hardwareData, SethardwareData] = useState([]);
  const [selectedHardware, setSelectedHardware] = useState([]);
  const [laborData, SetLaborData] = useState([]);
  const [, setLoborDataHR] = useState("");
  const [, setLoborDataCost] = useState("");
  const [mergedData, setMergedData] = useState([]);

  const dispatch = useDispatch();

  const { group } = useSelector((state) => state.group);
  const { hardwareList } = useSelector((state) => state.hardwareStore);
    const { labourCosts } = useSelector((state) => state.labourCost);
  

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchLabourCost())
  }, [dispatch]);

  useEffect(() => {
    setGroupNames(group);
  }, [group]);

  useEffect(() => {
    dispatch(fetchHardware()); // Dispatch Redux action
  }, [dispatch]);

  useEffect(() => {
    if (hardwareList) {
      SethardwareData(hardwareList);
    }
  }, [hardwareList]);

  const handleInputChange = (groupFilter) => {
    setgroupName(groupFilter);
  };
 
 const handleHardwareSelect = (e) => {
    const selectedHardwareName = e.target.value;
    if (
      !selectedHardware.some((hw) => hw.hardwareName === selectedHardwareName)
    ) {
      const selectedHardwareItem = hardwareData.find(
        (hw) => hw.hardwareName === selectedHardwareName
      );
      if (selectedHardwareItem) {
        setSelectedHardware([...selectedHardware, selectedHardwareItem]);
      }
    }
    fetchLabor();
  };

  const fetchLabor = useCallback(async () => {
    
  
      if (labourCosts) {
        const filteredData = labourCosts.filter(
          (item) => item.department === "Hardware"
        );
        SetLaborData(filteredData);
        setLoborDataHR(filteredData[0]?.department || ""); // Avoid errors if filteredData is empty
        setLoborDataCost(filteredData[0]?.labourCost || 0);
      } else {
        console.error("Failed to fetch Data");
      }
   
  }, [labourCosts]); // Memoize function with dependencies

  useEffect(() => {
    fetchLabor();
  }, [fetchLabor]); // Now it only re-runs if `fetchLabor` changes

  useEffect(() => {
  }, [laborData]);

  useEffect(() => {
    const updatedData = selectedHardware.map((hardware) => ({
      ...hardware,
      ...laborData[0],
      quantity: quantity,
    }));
    setMergedData(updatedData);
  }, [selectedHardware, laborData, quantity]);

  // const [mergedData,setMergedData] = selectedHardware.map((hardware) => ({
  //   ...hardware,
  //   ...laborData[0],
  //   quantity:quantity
  // }));

  // const [quantities, setQuantities] = useState(
  //   mergedData.map((item) => item.quantity)
  // );

  // Function to update quantity
  // const handleQuantityChange = (index: number, newQuantity: number) => {
  //   const updatedQuantities = [...quantities];
  //   updatedQuantities[index] = newQuantity;
  //   setQuantities(updatedQuantities);
  // };

  const toggleQuantity = (index) => {
    setSelectedItem(!selectedItem);
  };
  // const handleModalInputChange = (e) => {
  //   const value = parseFloat(e.target.value) || 0;
  //   setModalValue(value);

  // };

  const handleSubmit = async (index) => {
    setMergedData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, quantity: modalValue } : item
      )
    );

    setSelectedItem(null); // Close modal after update
  };

  return (
    <div className="max-h-screen">
      <div>
        <L1Sidebar />

        <div className=" ml-[20%] p-4 bg-white z-55 relative  w-[80%] justify-center top-6 pb-20">
          <div></div>
          <div className="flex">
            <div className="w-1/2 flex">
              <div>
                <h1 className="text-2xl  mb-6 font-semibold">
                  Comprehensive Summary
                </h1>
              </div>
            </div>
            <div className="w-1/2"></div>
          </div>
          <div>
            <div className="flex-col">
              <div className="mb-1">
                <form className="flex flex-wrap">
                  <div className="flex mb-4 mr-4 items-start">
                    <div className="mb-4 mr-4">
                      <label className="block" htmlFor="Name">
                        Select Group
                      </label>
                      <select
                        className="border border-gray-300 rounded-md shadow-md outline-none"
                        value={groupFilter}
                        onChange={(e) => handleInputChange(e.target.value)}
                        style={{
                          backgroundColor: "#f8f5fc",
                          width: "200px",
                          height: "40px",
                          fontSize: "16px",
                          padding: "8px",
                        }}
                      >
                        <option value="">All</option>
                        {GroupNames.map((group) => (
                          <option key={group.id} value={group.newGroupName}>
                            {group.newGroupName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <br />
            <div className="max-h-[700px]  overflow-auto min-w-full -mt-6">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-black sticky top-0">
                  <tr className="text-white">
                    <th
                      className="py-2 text-center border text-xs font-medium uppercase w-1/6"
                      rowSpan={2}
                    >
                      <div className="flex items-center justify-between">
                        <select
                          className="bg-black outline-none w-full h-10 text-sm px-2"
                          value={groupFilter}
                          onChange={handleHardwareSelect}
                        >
                          <option value="">Select Hardware</option>
                          {groupName === "Sensr" &&
                            hardwareData.map((Hardware) => (
                              <option
                                key={Hardware.id}
                                value={Hardware.hardwareName}
                              >
                                {Hardware.hardwareName}
                              </option>
                            ))}
                        </select>
                      </div>
                    </th>
                    <th
                      className="py-2 text-center border text-xs font-medium uppercase w-1/6"
                      rowSpan={2}
                    >
                      <div className="flex items-center justify-center">
                        Description
                      </div>
                    </th>
                    <th
                      className="py-2 text-center border text-xs font-medium uppercase w-1/6"
                      rowSpan={2}
                    >
                      <div className="flex items-center justify-center">
                        Quantity
                      </div>
                    </th>
                    <th
                      className="py-2 text-center border text-xs font-medium uppercase w-1/6"
                      rowSpan={2}
                    >
                      <div className="flex items-center justify-center">
                        Material Cost
                      </div>
                    </th>
                    <th
                      className="py-2 text-center border text-xs font-medium uppercase w-1/6"
                      colSpan={2}
                    >
                      <div className="flex items-center justify-center">
                        Labor Cost
                      </div>
                    </th>
                    <th
                      className="py-2 text-center border text-xs font-medium uppercase w-1/6"
                      rowSpan={2}
                    >
                      <div className="flex items-center justify-center">
                        Total Cost
                      </div>
                    </th>
                  </tr>

                  <tr className="text-white">
                    <th className="py-2 text-center border text-xs font-medium uppercase w-1/12">
                      <div className="flex items-center justify-center">
                        Hours
                      </div>
                    </th>
                    <th className="py-2 text-center border text-xs font-medium uppercase w-1/12">
                      <div className="flex items-center justify-center">
                        Unit Cost ($)
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {mergedData.map((row, index) => {
                    //  const quantity = quantities[index] || 1;
                    const totalCost =
                      quantity * row.hardwareCost +
                      row.labourCost * row.hours * row.quantity;
                    return (
                      <tr key={index} className="border text-sm font-bold">
                        <td className="px-3 text-center py-2 border">
                          {row.hardwareName} {row.quantity}
                        </td>
                        <td className="px-3 text-center py-2 border">
                          description
                        </td>
                        <td className="px-3 text-center py-2 border">
                          {/* <input
                            type="number"
                            value={quantity}
                            onChange={(e) =>
                              handleQuantityChange(
                                index,
                                Number(e.target.value)
                              )
                            }
                            min="0"
                          /> */}
                          <div className="flex items-center   pl-1 bg-transparent">
                            <input
                              type="text"
                              value={quantity}
                              readOnly
                              className="text-center text-black bg-transparent outline-none w-full"
                              onClick={() => toggleQuantity(index)}
                            />
                          </div>

                          {selectedItem !== null && (
                            <div className="fixed inset-0  flex items-center justify-center">
                              <div className="bg-white p-6 rounded-lg shadow-lg w-76">
                                <h2 className="text-lg text-black font-bold mb-4 text-start">
                                  Enter Quantity
                                </h2>
                                <input
                                  type="number"
                                  placeholder="Enter Product Cost"
                                  className="w-full p-2 border border-gray-300 text-black rounded mb-4"
                                  // onChange={handleModalInputChange}
                                  onChange={(e) =>
                                    setModalValue(e.target.value)
                                  }
                                />
                                <div className="flex justify-between">
                                  <button
                                    className="bg-blue-500 hover:bg-blue-800 text-white px-4 py-2 rounded"
                                    // onClick={()=>handleSubmit(index)}
                                    onClick={() => handleSubmit(selectedItem)}
                                  >
                                    Submit
                                  </button>

                                  <button
                                    className="bg-gray-400 hover:bg-black text-white px-4 py-2 rounded "
                                    onClick={() => setSelectedItem(null)}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </td>
                        <td className="px-3 text-center py-2 border">
                          $ {row.hardwareCost}
                        </td>

                        <td className="px-3 text-center py-2 border">
                          {row.hours}
                        </td>
                        <td className="px-3 text-center py-2 border">
                          {row.labourCost}
                        </td>
                        <td className="px-3 text-center py-2 border">
                          {totalCost.toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
                  {selectedHardware.length === 0 && (
                    <tr>
                      <td
                        colSpan="5"
                        className="text-center py-4 text-gray-500 italic"
                      >
                        No hardware selected.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HardwareTrackerL1;
