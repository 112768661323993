import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import React, { useState, useCallback, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import TableChartIcon from "@mui/icons-material/TableChart";
import {  useNavigate } from "react-router-dom";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Document, Packer, Paragraph, Table, TableCell, TableRow } from "docx";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLead } from "../../../../context/LeadReport";
import { useDispatch, useSelector } from "react-redux";
import { fetchGroups } from "../../../../redux/slices/groupSlice";
import { setProbabilityFromDateReduxL2, setProbabilityGroupReduxL2, setProbabilityToDateReduxL2 } from "../../../../redux/slices/filterSlice";

const ProbabilitiStatusL2 = ({ filteredLeadReports }) => {

  const {setProbabilityReportL2_12} = useLead()
     const [probabilityDownload, setProbabilityDownload] = useState([]);
        const [showGroupDropdown, setShowGroupDropdown] = useState(false);
      
        const dropdownRefGroup = useRef(null);
        const [GroupNames, setGroupNames] = useState([]);
  const dispatch = useDispatch()

  const{group} = useSelector((state)=> state.group)

  useEffect(()=>{
dispatch(fetchGroups())
  },[dispatch]);

  useEffect(()=>{
    setGroupNames(group)
  },[group])



        useEffect(() => {
          const handleClickOutside = (event) => {
            if (
              dropdownRefGroup.current &&
              !dropdownRefGroup.current.contains(event.target)
            ) {
              setShowGroupDropdown(false);
            }
          };
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, []);
      
      
        const formatDateExcel = (date) => {
          const d = new Date(date);
          const yy = d.getFullYear().toString().slice(-2); // Extract last 2 digits of the year
          const mm = String(d.getMonth() + 1).padStart(2, "0"); // Month (0-based)
          const dd = String(d.getDate()).padStart(2, "0"); // Day
          return `${yy}-${mm}-${dd}`;
        };
      
        const handleDownload = (event) => {
          const value = event.target.value;
      
          if (value === "pdf") {
            downloadPDF();
          } else if (value === "word") {
            downloadWord();
          } else if (value === "excel") {
            downloadExcel();
          }
          setIsDropdownVisible(false);
        };
      
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
          const day = String(date.getDate()).padStart(2, "0");
          const year = date.getFullYear();
          return `${month}-${day}-${year}`;
        };
      
        const [isDropdownVisible, setIsDropdownVisible] = useState(false);
      
        const toggleDropdown = () => {
          setIsDropdownVisible(!isDropdownVisible); // Toggle dropdown visibility
        };
      
        const downloadExcel = () => {
          const sortedLeads = [...probabilityDownload].sort(
            (a, b) => new Date(a.LeadDate) - new Date(b.LeadDate)
          );
      
          const ws = XLSX.utils.json_to_sheet(
            sortedLeads.map((LeadReport, index) => ({
              "#": index + 1,
              "Lead Date": LeadReport.LeadDate
                ? formatDateExcel(LeadReport.LeadDate)
                : "N/A",
              "Client Name": LeadReport.ClientName,
              "Project Name": LeadReport.ProjectName,
              "Lead Type": LeadReport.LeadType,
              "Quoted Value": Number(LeadReport.QuotedValue), // ✅ Ensure it's a number
              Probability: LeadReport.Probability,
              Status: LeadReport.Status,
              Group: LeadReport.AssignedGroup,
              "Follow-up Date":
                LeadReport.Status === "Won" || LeadReport.Status === "Lost"
                  ? "N/A"
                  : formatDate(LeadReport.FollowupDate),
            }))
          );
      
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Lead Reports");
          const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          const excelFile = new Blob([excelBuffer], {
            bookType: "xlsx",
            type: "application/octet-stream",
          });
          saveAs(excelFile, "LeadReports.xlsx");
        };
      
        const downloadPDF = () => {
          const doc = new jsPDF();
          doc.autoTable({
            head: [
              [
                "#",
                "Lead Date",
                "Client Name",
                "Project Name",
                "Lead Type",
                "Quoted Value",
                "Status",
                "Probability",
                "Group",
                "Follow-up Date",
              ],
            ],
            body: probabilityDownload.map((LeadReport, index) => [
              index + 1,
              LeadReport.LeadDate ? formatDate(LeadReport.LeadDate) : "N/A",
              LeadReport.ClientName,
              LeadReport.ProjectName,
              LeadReport.LeadType,
              `$${LeadReport.QuotedValue.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}`,
              LeadReport.Status,
              LeadReport.Probability,
              LeadReport.AssignedGroup,
              LeadReport.Status === "Won" || LeadReport.Status === "Lost"
                ? "N/A"
                : formatDate(LeadReport.FollowupDate),
            ]),
            styles: {
              fontSize: 7, // 🔹 Reduce font size (default is 10)
            },
            headStyles: {
              fontSize: 8, // 🔹 Reduce font size for the table header
            },
          });
      
          doc.save("LeadReports.pdf");
        };
      
        const downloadWord = () => {
          const headerRow = new TableRow({
            children: [
              new TableCell({
                children: [new Paragraph("S.No")],
              }),
              new TableCell({
                children: [new Paragraph("Lead Date")],
              }),
              new TableCell({
                children: [new Paragraph("Client Name")],
              }),
              new TableCell({
                children: [new Paragraph("Project Name")],
              }),
              new TableCell({
                children: [new Paragraph("Lead Type")],
              }),
              new TableCell({
                children: [new Paragraph("Quoted Value")],
              }),
              new TableCell({
                children: [new Paragraph("Probability")],
              }),
              new TableCell({
                children: [new Paragraph("Status")],
              }),
              new TableCell({
                children: [new Paragraph("Group")],
              }),
              new TableCell({
                children: [new Paragraph("Follow-up Date")],
              }),
            ],
          });
      
          const dataRows = probabilityDownload.map((LeadReport, index) => {
            return new TableRow({
              children: [
                new TableCell({
                  children: [new Paragraph(`${index + 1}`)],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      LeadReport.LeadDate ? formatDate(LeadReport.LeadDate) : "N/A"
                    ),
                  ],
                }),
                new TableCell({
                  children: [new Paragraph(LeadReport.ClientName)],
                }),
                new TableCell({
                  children: [new Paragraph(LeadReport.ProjectName)],
                }),
                new TableCell({
                  children: [new Paragraph(LeadReport.LeadType)],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      `$${LeadReport.QuotedValue.toString().replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}`
                    ),
                  ],
                }),
                new TableCell({
                  children: [new Paragraph(LeadReport.Probability)],
                }),
                new TableCell({
                  children: [new Paragraph(LeadReport.Status)],
                }),
      
                new TableCell({
                  children: [new Paragraph(LeadReport.AssignedGroup)],
                }),
                new TableCell({
                  children: [
                    new Paragraph(
                      LeadReport.Status === "Won" || LeadReport.Status === "Lost"
                        ? "N/A"
                        : formatDate(LeadReport.FollowupDate)
                    ),
                  ],
                }),
              ],
            });
          });
      
          const doc = new Document({
            sections: [
              {
                properties: {},
                children: [
                  new Table({
                    rows: [headerRow, ...dataRows],
                  }),
                ],
              },
            ],
          });
      
          Packer.toBlob(doc).then((blob) => {
            saveAs(blob, "LeadReports.docx");
          });
        };
      
        const popupRef = useRef(null);
      
        const handleClickOutside = (event) => {
          if (popupRef.current && !popupRef.current.contains(event.target)) {
            setIsDropdownVisible(false); // Close the popup if clicked outside
          }
        };
        useEffect(() => {
          if (isDropdownVisible) {
            document.addEventListener("mousedown", handleClickOutside);
          } else {
            document.removeEventListener("mousedown", handleClickOutside);
          }
          return () => document.removeEventListener("mousedown", handleClickOutside);
        }, [isDropdownVisible]);
      
        const [probabilityTab, setProbabilityTab] = useState("graph");

        const [filteredLeadReportsLead, setFilteredLeadReportsLead] = useState([]);
      
        // useEffect(() => {
        //   const pendingLeads = filteredLeadReports.filter(
        //     (lead) => lead.Status === "Pending"
        //   );
        //   setFilteredLeadReportsLead(pendingLeads);
        // }, [filteredLeadReports]);
          useEffect(() => {
            const pendingLeads = filteredLeadReports.filter(
              (lead) =>
                lead.Status === "Pending" &&
                (lead.Probability === "High" ||
                  lead.Probability === "Medium" ||
                  lead.Probability === "Low")
            );
            setFilteredLeadReportsLead(pendingLeads);
          }, [filteredLeadReports]);

          const groupFilterReduxL2 = useSelector((state) => state.filters.groupFilterL2);
        
        const [AssignedGroup, setAssignedGroup] = useState("");
        const [probabilityStatus, setProbabilityStatus] = useState([]);
            
        const [probabilityFilterDateFrom, setProbabilityFilterDateFrom] = useState();
        const [probabilityFilterDateTo, setProbabilityFilterDateTo] = useState();
      
        const [selectedFromDate, setSelectedFromDate] = useState(null);  // Temporary state
        const [selectedToDate, setSelectedToDate] = useState(null);  // Temporary state   
        const [selectedGroup, setSelectedGroup] = useState(
          groupFilterReduxL2 === "All" ? "" : groupFilterReduxL2 || ""  );
        
      
      const handleProbabilityFromDate = (date) => {
          if (!date) return;
          setSelectedFromDate(date);
          dispatch(setProbabilityFromDateReduxL2(date));
        };
        const handleProbabilityToDate = (date) => {
          if (!date) return;
          setSelectedToDate(date);
          dispatch(setProbabilityToDateReduxL2(date));
        };

         useEffect(() => {
            if (groupFilterReduxL2 !== undefined && groupFilterReduxL2 !== null) {
              setSelectedGroup(groupFilterReduxL2);
            }else{setSelectedGroup("All")}
          }, [groupFilterReduxL2]);

        const handleProbabilityGroup = (group) => {
          if (!group) return;
          if (group === "All") {
            // Set AssignedGroup to an empty string or a special value
            setSelectedGroup(probabilityGroupFilterReduxL2);
            setShowGroupDropdown(false);
            return;
          }
          setSelectedGroup(group);
          setShowGroupDropdown(false);
          dispatch(setProbabilityGroupReduxL2(group));
        };
      
        const probabilityFilterDateFromReduxL2 = useSelector(
          (state) => state.filters.probabilityFilterDateFromL2
        );
        const probabilityFilterToFromReduxL2 = useSelector(
          (state) => state.filters.probabilityFilterToFromL2
        );
        const probabilityGroupFilterReduxL2 = useSelector(
          (state) => state.filters.probabilityGroupFilterL2
        );
      
        useEffect(() => {
          if (probabilityFilterDateFromReduxL2 !== undefined && probabilityFilterDateFromReduxL2 !== null) {
            setSelectedFromDate(probabilityFilterDateFromReduxL2);
          }else{setSelectedFromDate("")}
        }, [probabilityFilterDateFromReduxL2]);
      
        useEffect(() => {
          if (probabilityFilterToFromReduxL2 !== undefined && probabilityFilterToFromReduxL2 !== null) {
            setSelectedToDate(probabilityFilterToFromReduxL2);
          }else{setSelectedToDate("")}
        }, [probabilityFilterToFromReduxL2]);
        
        useEffect(() => {
          if (probabilityGroupFilterReduxL2) {
            setSelectedGroup(probabilityGroupFilterReduxL2);
          }
        }, [probabilityGroupFilterReduxL2]);
      
      
 const [probCalculatedCount, setProbCalculatedCount] = useState(0)
  const [calculatedPercentage, setCalculatedPercentage ] = useState(0)
  const [totalProbabilityAmount, setTotalProbabilityAmount] = useState(0);
  const [totalprobabilityCount, setTotalProbabilityCount] = useState(0);

       const aggregateProbabilityData = useCallback((data) => {
         const probMap = new Map();
         let totalProbAmount = 0;
         const totalCount = filteredLeadReportsLead.length;
         const calculatedCount = data.length;
         setProbCalculatedCount(calculatedCount);
       
         data.forEach((LeadReport) => {
           const prob = LeadReport.Probability;
           if (!prob) return;
       
           const count = probMap.has(prob) ? probMap.get(prob).count + 1 : 1;
           const amount = parseFloat(LeadReport.QuotedValue);
           totalProbAmount += amount;
       
           probMap.set(prob, {
             count,
             amount: (probMap.get(prob)?.amount || 0) + amount,
           });
         });
       
         let potentialProbabilityArray = Array.from(
           probMap,
           ([prob, { count, amount }]) => ({
             prob,
             count,
             amount,
             percentage: (count / totalCount) * 100, // Store as a number
           })
         );
       
         // Calculate the total percentage
         let totalPercentage = potentialProbabilityArray.reduce(
           (sum, prob) => sum + prob.percentage,
           0
         );
       
         // Adjust the total percentage to 100% if counts match
         if (totalCount === calculatedCount) {
           const discrepancy = 100 - totalPercentage;
           totalPercentage = 100;
       
           // Adjust the largest segment to account for the discrepancy
           if (potentialProbabilityArray.length > 0) {
             const maxIndex = potentialProbabilityArray.reduce((maxIdx, prob, idx, arr) =>
               prob.percentage > arr[maxIdx].percentage ? idx : maxIdx, 0);
             potentialProbabilityArray[maxIndex].percentage += discrepancy;
           }
         }
       
         // Convert percentages to fixed decimal places for display
         potentialProbabilityArray = potentialProbabilityArray.map(prob => ({
           ...prob,
           percentage: Number(prob.percentage).toFixed(2),
         }));
              
         setCalculatedPercentage(totalPercentage.toFixed(2));
         setProbabilityStatus(potentialProbabilityArray);
         setTotalProbabilityAmount(totalProbAmount);
         setTotalProbabilityCount(totalCount);
       }, [setProbabilityStatus, setTotalProbabilityAmount, setTotalProbabilityCount, filteredLeadReportsLead]);
       
        useEffect(() => {
          if (filteredLeadReports.length > 0) {
            const pendingLeads = filteredLeadReports.filter(
              (lead) => lead.Status === "Pending"
            );
      
            const sortedLeads = pendingLeads.sort(
              (a, b) => new Date(a.LeadDate) - new Date(b.LeadDate)
            );
      
            aggregateProbabilityData(sortedLeads);
            setProbabilityDownload(sortedLeads);
          }
        }, [filteredLeadReports, aggregateProbabilityData]);
      
        const navigate = useNavigate();      
        const probPieChart = useRef();
        const probChartRef = useRef(null);
       
        const handleSubmit = () => {
          setAssignedGroup(selectedGroup);  // Update AssignedGroup on submit
          setProbabilityFilterDateFrom(selectedFromDate);
          setProbabilityFilterDateTo(selectedToDate);
        };
    
      
        const handleFilter = useCallback(() => {
          // Run the filtering logic based on AssignedGroup (not selectedGroup)
          const filteredData = filteredLeadReportsLead.filter((LeadReport) => {
            const reportDate = new Date(LeadReport.LeadDate);
            const isWithinDateRange = (!probabilityFilterDateFrom || reportDate >= probabilityFilterDateFrom) &&
              (!probabilityFilterDateTo || reportDate <= probabilityFilterDateTo);
      
            const isWithinGroup = !AssignedGroup || AssignedGroup === "All" || LeadReport.AssignedGroup === AssignedGroup;
      
            return isWithinDateRange && isWithinGroup;
          });
                aggregateProbabilityData(filteredData);
          setProbabilityDownload(filteredData);
        }, [filteredLeadReportsLead, probabilityFilterDateFrom, probabilityFilterDateTo, AssignedGroup, aggregateProbabilityData]);
      
        useEffect(() => {
          handleFilter(); // Automatically runs when AssignedGroup updates
        }, [handleFilter]);
      
        useEffect(() => {
          // Call handleFilter whenever AssignedGroup is updated after submit
          if (AssignedGroup || probabilityFilterDateFrom || probabilityFilterDateTo) {
            handleFilter();
      
          }
        }, [AssignedGroup, probabilityFilterDateFrom, probabilityFilterDateTo, handleFilter]);
      

           const probabilityNavigationl2 = useCallback((clickedProb) => {
              const filteredDataprobabilityl1 = probabilityDownload.filter(
                (item) => item.Probability === clickedProb
              );
              setProbabilityReportL2_12(filteredDataprobabilityl1)
              navigate("/dashboard/L2/lead-report12");
            }, [probabilityDownload, navigate,setProbabilityReportL2_12]);
      
        useEffect(() => {
          if (probabilityStatus.length > 0 && probabilityTab === "graph") {
            const ctxProb = probPieChart.current;
      
            if (probChartRef.current) {
              probChartRef.current.destroy();
            }
      
            probChartRef.current = new Chart(ctxProb, {
              type: "pie",
              data: {
                labels: probabilityStatus.map((item) => item.prob),
                datasets: [
                  {
                    label: "Amount",
                    data: probabilityStatus.map((item) => item.count.toFixed(2)),
                    backgroundColor: [
                      "#15803d",
      
                      "orange",
                      "#b91c1c",
                    ],
                  },
                ],
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    yAlign: "bottom", // Moves tooltip higher
                    callbacks: {
                      title: () => "",
                      label: function (context) {
                        const label = context.label || "";
                        return `${label}`;
                      },
                    },
                    displayColors: false,
                    backgroundColor: "rgba(255, 255, 255, 0)", // Fully transparent background
                    titleColor: "black",
                    bodyColor: "black",
                    borderWidth: 0,
                    boxPadding: 0,
                    bodyFont: {
                      size: 14, // Font size
                      family: "Arial", // Font family
                      weight: "bold", // Font weight
                    },
                  },
      
                  datalabels: {
                    formatter: (value, context) => {
                      const total = totalprobabilityCount;
                      const percentage = ((value / total) * 100).toFixed(2);
                      return `${percentage}%`; // Show percentage inside pie chart
                    },
                    color: "black", // Text color
                    font: {
                      size: 14, // Font size
                      family: "Arial", // Font family
                      weight: "bold", // Font weight
                    },
                  },
                },
                layout: {
                  padding: {
                    bottom: 10,
                  },
                },
                cutout: "50%",
                onClick: (event, elements) => {
                  if (elements.length > 0) {
                    const index = elements[0].index;
                    const clickedItem = probabilityStatus[index];
                    probabilityNavigationl2(clickedItem.prob);
                  }
                },
              },
              plugins: [ChartDataLabels], // Enable ChartDataLabels plugin
            });
          }
      
          return () => {
            if (probChartRef.current) {
              probChartRef.current.destroy();
            }
          };
        }, [
          probabilityStatus,
          probabilityNavigationl2,
          totalprobabilityCount,
          probabilityTab,
        ]);
  return (
 <div className="h-full">
       <div className="h-[20%]">
         <div className="flex flex-wrap justify-start items-center xl:flex xl:justify-between md:justify-between md:flex-nowrap ">
           <div>
             <h1 className="text-2xl font-semibold sm:text-sm md:text-sm lg:text-xl">
               PROBABILITY STATUS
             </h1>
           </div>
 
           <div className="md:flex lg:ml-8 lg:flex lg:items-center lg:justify-end xl:flex xl:justify-end ">
             <div className="show-potential-download lg:justify-end ">
               <div className=" inline-block relative px-3 mt-2">
                 <div className="flex items-center text-4xl sm:text-md md:text-xl lg:text-xl xl:text-2xl 2xl:text-4xl">
                   <CloudDownloadIcon
                     onClick={toggleDropdown}
                     className="cursor-pointer"
                   // fontSize="4rem"
                   />
 
                   {isDropdownVisible && (
                     <div
                       ref={popupRef}
                       className="absolute top-8 -left-0 shadow-lg z-50 bg-white text-base"
                     >
                       <div
                         className="px-2 py-2 cursor-pointer hover:bg-gray-100"
                         onClick={() =>
                           handleDownload({ target: { value: "pdf" } })
                         }
                       >
                         PDF
                       </div>
                       <div
                         className="px-2 py-2 cursor-pointer hover:bg-gray-100"
                         onClick={() =>
                           handleDownload({ target: { value: "excel" } })
                         }
                       >
                         Excel
                       </div>
                       <div
                         className="px-2 py-2 cursor-pointer hover:bg-gray-100"
                         onClick={() =>
                           handleDownload({ target: { value: "word" } })
                         }
                       >
                         Word
                       </div>
                     </div>
                   )}
                 </div>
               </div>
             </div>
             <div className="show-potential-download lg:justify-end">
               <div className=" flex items-center justify-between text-3xl sm:text-xl md:text-md">
                 {probabilityTab === "table" && (
                   <span title="Graph">
                     <SignalCellularAltIcon
                       className="cursor-pointer"
                       onClick={() => setProbabilityTab("graph")}
                       fontSize="3rem"
                     />
                   </span>
                 )}
                 {probabilityTab === "graph" && (
                   <span title="Table">
                     <TableChartIcon
                       className="cursor-pointer"
                       onClick={() => setProbabilityTab("table")}
                       fontSize="3rem"
                     />
                   </span>
                 )}
               </div>
             </div>
           </div>
         </div>
 
         <hr />
         <hr />
 
 
 
         <div className="flex w-full gap-3 items-center justify-between pt-1 lg:flex-nowrap ">
           <div className="flex justify-between align-middle items-center w-full gap-1">
            <div className="w-[25%] flex align-middle p-1 border border-[#e3eaff] rounded-md ">
              <div className="pl-1 pt-1.5 text-xs">
                <p >
                  From-
                </p>

              </div>
              <div className="h-full w-full ">
                <DatePicker
                  selected={selectedFromDate}
                  onChange={handleProbabilityFromDate}
                  dateFormat="MM-dd-yyyy"
                  className=" w-full h-full text-xs  m-0  outline-none date-picker-placeholder"
                  placeholderText="MM-DD-YYYY"
                  required

                />
              </div>
            </div>
            |
            <div className="w-[25%] flex align-middle  p-1 border border-[#e3eaff] rounded-md ">
              <div className=" pl-1 pt-1.5 text-xs">
                <p >
                  To-
                </p>

              </div>
              <div className="h-full w-full">
                <DatePicker
                  selected={selectedToDate}
                  onChange={handleProbabilityToDate}
                  dateFormat="MM-dd-yyyy"
                  className=" w-full h-full text-xs m-0  outline-none date-picker-placeholder"
                  placeholderText="MM-DD-YYY"
                  required
                  minDate={
                    probabilityFilterDateFrom
                      ? new Date(probabilityFilterDateFrom.getTime() + 86400000)
                      : null
                  }
                />
              </div>
            </div>

            
 
 
             <div className="relative flex align-middle items-center w-[25%]">
               {/* <div className="text-xs h-full bg-[#6b7280] text-white p-0.5 flex align-middle items-center text-center">
                           Select
                         </div> */}
               <div className="absolute cursor-pointer right-0 border-r border-[#e3eaff] bg-white z-20">
                 <ExpandMoreIcon
                   color="white"
                   className=""
                   onClick={() =>
                     setShowGroupDropdown(!showGroupDropdown)
                   }
                 />
               </div>
               <div
                 ref={dropdownRefGroup}
                 className="relative border w-full border-[#e3eaff]  rounded-md"
               >
                 <button
                   type="button"
                   onClick={() =>
                     setShowGroupDropdown(!showGroupDropdown)
                   }
                   className=" flex justify-start items-center align-middle w-full h-full p-2 text-nowrap truncate focus:outline-none text-xs"
                 >
                   {selectedGroup || groupFilterReduxL2 || "All"}
                 </button>
 
                 {showGroupDropdown && (
                   <div className="absolute flex flex-col w-28 p-0.5 gap-2 z-50  bg-white text-xs border border-gray-300 overflow-y-auto shadow-lg max-h-48  mt-0.5">
 
                     <label htmlFor="All" className="p-1 hover:bg-gray-300"
                       onClick={() => {
                        handleProbabilityGroup("All");
                       }
                       }
                     >All</label>
 
 {groupFilterReduxL2 && groupFilterReduxL2 !== "All" && (
                      <div
                        className="p-1 bg-gray-300 font-semibold cursor-pointer text-xs"
                        onClick={() => handleProbabilityGroup(groupFilterReduxL2)}
                      >
                        {groupFilterReduxL2}
                      </div>
                    )}
                     {GroupNames.filter(
                      (group) => group.newGroupName !== groupFilterReduxL2 && group.newGroupName !== "All"
                    ).map((group) => (
                       <div
                         className="p-1 hover:bg-gray-200 cursor-pointer"
                         onClick={() =>
                          handleProbabilityGroup(group.newGroupName)
                         }
                         key={group.id}
                         value={group.newGroupName}
                       >
                         {group.newGroupName}
                       </div>
                     ))}
                   </div>
                 )}
               </div>
             </div>
 
             <div className="w-[25%] flex justify-end ">
               <button
                 className="text-white bg-[#e32278] p-2 px-4 text-xs rounded-md max-w-24 shadow-md"
                 onClick={handleSubmit}
               >
                 SUBMIT
               </button>
             </div>
           </div>
 
 
         </div>
       </div>
 
       <div className="h-[80%] flex ">
         {probabilityTab === "table" ? (
           probabilityStatus.length > 0 ? (
             <div className="w-full pt-3">
               <div className="overflow-auto">
                 <table className="w-full divide-y divide-gray-200">
                   <thead className="bg-black sticky top-0">
                     <tr className="text-white">
                       <th className="px-4 py-3 text-left border text-sm font-medium uppercase tracking-wider">
                         Probability
                       </th>
                       <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                         Count
                       </th>
                       <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                         %
                       </th>
                       <th className="px-4 py-3 text-right border text-sm font-medium uppercase tracking-wider">
                         Amount
                       </th>
                     </tr>
                   </thead>
                   <tbody className="bg-white divide-y divide-gray-200">
                     {probabilityStatus
                       .sort((a, b) => {
                         const probOrder = ["HIGH", "MEDIUM", "LOW"];
                         return (
                           probOrder.indexOf(a.prob.toUpperCase()) -
                           probOrder.indexOf(b.prob.toUpperCase())
                         );
                       })
                       .map((item, index) => (
                         <tr className="border text-md" key={index}>
                           <td
                             onClick={() =>
                              probabilityNavigationl2(
                                 item.prob,
                          
                               )
                             }
                             style={{ cursor: "pointer" }}
                             className="px-3 text-left py-2 font-thin"
                           >
                             {item.prob.toUpperCase()}
                           </td>
                           <td className="px-3 text-center py-2">{item.count}</td>
                           <td className="px-3 text-center py-2">
                             {item.percentage} %
                           </td>
                           <td className="px-3 text-right py-2">
                             ${item.amount
                               .toFixed(2)
                               .toString()
                               .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                           </td>
                         </tr>
                       ))}
                     {/* Display total row */}
                     <tr className="border bg-gray-200 font-bold text-md">
                       <td className="px-3 text-left py-2">TOTAL :</td>
                       {/* <td className="px-3 text-center py-2">{totalprobabilityCount}</td> */}
                       <td className="px-3 text-center py-2">{probCalculatedCount}</td>
                       
                       <td className="px-3 text-center py-2">
  {calculatedPercentage === "99.99" ? 100 : calculatedPercentage}%
</td>
                       <td className="px-3 text-right py-2">
                         ${totalProbabilityAmount
                           .toFixed(2)
                           .toString()  
                           .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                       </td>
                     </tr>
                   </tbody>
                 </table>
               </div>
             </div>
           ) : (
            <div className="flex justify-center align-middle items-center text-center mb-5  w-full">
            <p className="text-red-500 text-lg font-semibold ">
          No data available
        </p> </div>
           )
         ) : probabilityStatus.length > 0 ? (
           <div className="h-[90%] w-full p-9 ">
             <canvas id="probPieChart" width="60%" ref={probPieChart}></canvas>
           </div>
         ) : (
          <div className="flex justify-center align-middle items-center text-center mb-5  w-full">
          <p className="text-red-500 text-lg font-semibold ">
        No data available
      </p> </div>
         )}
       </div>
 
     </div>
  )
}

export default ProbabilitiStatusL2