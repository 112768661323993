import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../routes/api";

const BackendURL = config.backendUrl
const API_URL = `${BackendURL}/clientRatingWeitage`;

// Thunks for API calls
export const fetchRatings = createAsyncThunk("ratings/fetchRatings", async () => {
  const response = await axios.get(API_URL);
  return response.data;
});

export const addRating = createAsyncThunk(
  "ratings/addRating",
  async ({ WeitageName, WeitageCount }) => {
    const response = await axios.post(API_URL, { WeitageName, WeitageCount });
    return response.data;
  }
);

export const updateRating = createAsyncThunk(
  "ratings/updateRating",
  async ({ id, WeitageName, WeitageCount }) => {
    const response = await axios.put(`${API_URL}/${id}`, { WeitageName, WeitageCount });
    return response.data;
  }
);

export const deleteRating = createAsyncThunk("ratings/deleteRating", async (id) => {
  await axios.delete(`${API_URL}/${id}`);
  return id;
});

// Slice
const ratingSlice = createSlice({
  name: "ratings",
  initialState: {
    ratings: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRatings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRatings.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.ratings = action.payload;
      })
      .addCase(fetchRatings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addRating.fulfilled, (state, action) => {
        state.ratings.push(action.payload);
      })
      .addCase(updateRating.fulfilled, (state, action) => {
        const index = state.ratings.findIndex((r) => r.id === action.payload.id);
        if (index !== -1) {
          state.ratings[index] = action.payload;
        }
      })
      .addCase(deleteRating.fulfilled, (state, action) => {
        state.ratings = state.ratings.filter((r) => r.id !== action.payload);
      });
  },
});

export default ratingSlice.reducer;
