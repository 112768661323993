import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";

// Async thunk for login
export const tryLogin = createAsyncThunk("session/tryLogin", async ({ email, password }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post("/user/login", { email, password });    
    return response.data; // Return user session data
  } catch (error) {
    return rejectWithValue(error.response?.data || "Login failed");
  }
});


export const logoutSession = (e) => (dispatch) => {
  if (e) e.preventDefault(); // Prevent default if event exists
  localStorage.removeItem("token"); // Remove token from localStorage
  localStorage.removeItem("url"); // Remove url from localStorage groupAssigned
  localStorage.removeItem("groupAssigned"); // Remove url from localStorage  authLevel
  localStorage.removeItem("authLevel"); // Remove url from localStorage 
  sessionStorage.clear() 
  dispatch(sessionSlice.actions.logout());
};


// Redux slice
const sessionSlice = createSlice({
  name: "session",
  initialState: { session: null, loading: false, error: null },
  reducers: {
    logout: (state) => {
      state.session = null;
    },
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(tryLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(tryLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.session = action.payload;
      })
      .addCase(tryLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default sessionSlice.reducer;
