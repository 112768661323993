import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useRole } from "../../../context/RoleContext";
import loram from "../../Assets/loram.png";
import Q from "../../Assets/finallogo.png";
import { IoIosArrowDown, IoIosArrowUp, IoIosMail } from "react-icons/io";
import { SiPivotaltracker } from "react-icons/si";
import { MdDashboard, MdContacts } from "react-icons/md";
import { MdAddBox } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";
import ReviewsIcon from "@mui/icons-material/Reviews";
// import { enqueueSnackbar } from "notistack";

// import QTracker from "../../Assets/Qtrackr1.png";

const L3Sidebar = () => {
  // const { setRole } = useRole();
  const navigate = useNavigate();

  // const [leadTracker, setLeadTracker] = useState(true);
  //     const [Contact, setshowcontact] = useState(false);
  const [showLogout, setshowlogout] = useState(false);

  const toggleContact = (e) => {
    e.preventDefault();
    setshowcontact(!Contact);
    setLeadTracker(false);
  };

 const ImageNavigate = ()=>{
         navigate('/dashboard/L3')
 }

  // const name = localStorage.getItem("name");
  const [modalOpen, ] = useState(true);

  const [Contact, setshowcontact] = useState(false);
  const [leadTracker, setLeadTracker] = useState(true);
  const [activeButton, setActiveButton] = useState(""); // State for active button

  const menuItems = useMemo(
    () => [
      {
        name: "Dashboard",
        icon: <MdDashboard size={24} />,
        path: "/dashboard/L3",
      },
      { 
        name: "Add Lead",
         icon: <MdAddBox size={24} />,
          path: "/dashboard/L3/add-lead"
         },
      {
        name: "Lead Reports",
        icon: <BiSolidReport size={24} />,
        path: "/dashboard/L3/lead-report",
      },
    ],
    []
  );

  const ClientRating = (e) => {
    e.preventDefault();
    navigate("/dashboard/L3/client-rating");
    setActiveButton("ClientRating")
  };

  // This useEffect will ensure the active button is set based on the URL when the page loads
  useEffect(() => {
    // Check the current path from the browser
    const currentPath = window.location.pathname;
    const activeItem = menuItems.find((item) => item.path === currentPath);
    if (activeItem) {
      setActiveButton(activeItem.name); // Set active button based on path
      setLeadTracker(true); // Activate leadTracker when the path matches
    }
  }, [menuItems]); // Run the effect when menuItems changes

  const handleNavigation = (item) => {
    setActiveButton(item.name); // Set active button on click
    setLeadTracker(true); // Activate leadTracker
    sessionStorage.setItem("activeButton", item.name); // Store active button in sessionStorage
    navigate(item.path); // Navigate to the clicked path
  };

  const toggleLeadTracker = () => {
    setLeadTracker((prev) => !prev);
  };

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setshowlogout(false); // Close the popup if clicked outside
    }
  };
  useEffect(() => {
    if (showLogout) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showLogout]);

  return (
    <>
      <div className="relative">
        <div className="grid grid-rows-[auto_1fr] h-screen absolute bg-white">
          {/* <div className="w-screen absolute flex justify-between py-3  overflow-hidden text-white z-50">
            <div>
              <div
                className="pl-4 cursor-pointer flex gap-x-2 items-center"
                onClick={() => setModalOpen(true)}
              >
                <MenuIcon className="text-black" sx={{ fontSize: "30px" }} />
                <p className="text-black"></p>
              </div>
            </div>

            <div className="cursor-pointer mr-[2%] bg-transparent">
              <FaUserCircle onClick={logout} size={28} className="text-black" />
            </div>
            {showLogout && (
              <>
                <div
                  ref={popupRef}
                  className="fixed right-0 text-black text-center px-2 w-1/12 shadow-2xl overflow-hidden bg-white border border-gray-400 mr-1 mt-10 z-50 justify-end rounded-lg"
                >
                  <h1 className="px-6 py-2 rounded-md my-1">{name}</h1>
                  <h1
                    onClick={logoutUser}
                    className="flex px-6 py-2 rounded-md my-1 cursor-pointer hover:bg-black hover:text-white"
                  >
                    <MdLogout size={24} /> Logout
                  </h1>
                </div>
              </>
            )}
          </div> */}

          {modalOpen && (
            <div
              id="menu-bar"
              className={`row-span-1 p-0 col-span-1 bg-white  fixed shadow-2xl flex flex-col transition-all duration-300 ease-in-out top-0 bottom-0`}
              style={{ width: "17%" }}
            >
              <div className="flex justify-center items-center align-middle w-full ">
                <div onClick={ImageNavigate} className="w-full flex justify-center align-middle items-center p-2 cursor-pointer">
                  <img onClick={ImageNavigate} src={Q} alt="Q-Trackr Logo" className=' w-full cursor-pointer' />


                  {/* <img src={QTracker} alt="Q-Trackr Logo" className='w-24 h-8 ' /> */}

                </div>



              </div>

              <h1
                onClick={toggleLeadTracker}
                className="flex items-center justify-between p-2 border-b-2 border-[#ecefff] cursor-pointer rounded-md  bg-[#f9faff]"
              >
                <div className="flex items-center">
                  <SiPivotaltracker size={24} />
                  &nbsp;Lead Tracker
                </div>
                {leadTracker ? (
                  <IoIosArrowUp />
                ) : (
                  <IoIosArrowDown />
                )}
              </h1>

              {/* {leadTracker && (
                        <div className="ml-3">
                            <h1 onClick={dash} className="p-1 cursor-pointer bg-white mx-3 flex rounded-md mb-1 text-black">
                                <MdDashboard size={24} />
                                &nbsp;Dashboard
                            </h1>
                            <h1 onClick={addLead} className="p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md mb-1 hover:text-black">
                                <MdAddBox size={24} />
                                &nbsp;Add Lead
                            </h1>
                            <h1 onClick={leadReport} className="p-1 cursor-pointer hover:bg-white mx-3 flex rounded-md mb-1 hover:text-black">
                                <BiSolidReport size={24} />
                                &nbsp;Lead Reports
                            </h1>
                        </div>
                    )} */}

              {leadTracker && (
                <div className="ml-3">
                  {menuItems.map((item, index) => (
                    <h1
                      key={index}
                      onClick={() => handleNavigation(item)}
                      className={`py-1 pl-9 text-[1rem] border-b-2 border-[#ecefff] cursor-pointer flex align-middle items-center rounded-md mb-1 ${activeButton === item.name
                        ? "bg-[#fff7fb] text-black"
                        : " hover:text-black"
                        }`}
                    >
                      {item.icon}&nbsp;{item.name}
                    </h1>
                  ))}
                </div>
              )}

              {/* <h1
                onClick={Financial}
                className="flex items-center justify-between p-2 mx-3 cursor-pointer hover:bg-white rounded-md my-1 hover:text-black"
              >
                <div
                  className="flex items-center"
                  style={{ paddingLeft: "5px" }}
                >
                  <CurrencyExchangeIcon size={24} className="-ml-2" />
                  &nbsp;Financial Tracker
                </div>
              </h1> */}

<h1
              onClick={ClientRating}
                className="flex items-center justify-between border-b-2 border-[#ecefff] p-2 bg-[#f9faff] cursor-pointer rounded-md "
              >
                <div className="flex items-center">
                <ReviewsIcon size={24} className="mr-1" />

                  &nbsp;Clients Rating
                </div>
                
              </h1>

              <h1
                onClick={toggleContact}
                className="flex items-center justify-between border-b-2 border-[#ecefff] p-2 bg-[#f9faff] cursor-pointer rounded-md "
                >
                <div className="flex items-center">
                  <MdContacts size={24} />
                  &nbsp;Contact Us
                </div>
                {Contact ? (
                  <IoIosArrowUp />
                ) : (
                  <IoIosArrowDown />
                )}
              </h1>
              {Contact && (
                <div className="ml-3">
                  <button className="cursor-pointer w-full border-b-2 p-2 border-[#ecefff] flex items-center rounded-md hover:text-black">
                    <div className="flex items-center">
                      <IoIosMail size={24} />
                    </div>
                    <p className="overflow-hidden break-words text-wrap w-full">admin@qtrackr.com</p>

                  </button>

                </div>
              )}

              <div className="bg-white absolute bottom-0 w-full">
                <img className="p-4 mt-auto" src={loram} alt="Loram Logo" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default L3Sidebar;
