import React, { useEffect, useMemo, useState } from "react";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useLead } from "../../../../context/LeadReport";
import { useNavigate } from "react-router-dom";

const HistoricaltableL3 = ({ historicalDownloadL3, selectedStatus }) => {
  const navigate = useNavigate();
  const { setHistoricalTableL3 } = useLead();

  const [nonResponsive, setNonResponsive] = useState([]);
  useEffect(() => {
    setNonResponsive(selectedStatus);
  }, [selectedStatus]);

  const [historicalTableL3, setHistoricalTableDataL3] = useState([]);

  useEffect(() => {
    setHistoricalTableDataL3(historicalDownloadL3);
  }, [historicalDownloadL3]);

  useEffect(() => {
    if (historicalDownloadL3.length > 0) {
      setHistoricalTableDataL3(historicalDownloadL3);
    }
  }, [historicalDownloadL3]);

  //  const handleTdClickL3 = (year, status) => {

  //   const filteredLeads = historicalTableL3.filter((lead) => {
  //     let leadYear;

  //     try {
  //       const parsedDate = new Date(lead.LeadDate);
  //       leadYear = parsedDate.getFullYear(); // Extract year
  //     } catch (error) {
  //       console.error("Invalid Date Format:", lead.LeadDate);
  //       return false; // Skip invalid date formats
  //     }

  //     return leadYear === year && lead.Status === status;
  //   });

  //   // Store in state
  //   setHistoricalTableL3(filteredLeads);
  //   navigate("/leadreport_13")
  // };


  //   fetchStatus();
  // }, []);

  // const handleNavigation4 = useCallback((year) => {
  //     const currentState = locationData.state || {};
  //     navigate("/leadreport_11", {
  //       state: {
  //         ...currentState,
  //         clickedYear: year, // Pass the clicked year
  //       },
  //     });
  //   },
  //   [locationData.state, navigate] // Add dependencies if needed
  // );

  // const currentYear = new Date().getFullYear();

  const handleTdClickL3 = (year, status = null) => {
    const filteredLeads = historicalTableL3.filter((lead) => {
      let leadYear;

      try {
        const parsedDate = new Date(lead.LeadDate);
        leadYear = parsedDate.getFullYear(); // Extract year
      } catch (error) {
        console.error("Invalid Date Format:", lead.LeadDate);
        return false; // Skip invalid date formats
      }

      if (status) {
        return leadYear === year && lead.Status === status;
      } else {
        return leadYear === year;
      }
    });

    // Store in state
    setHistoricalTableL3(filteredLeads);
    navigate("/dashboard/L3/lead-report13");
  };

  const { groupedData, totalLeads, statusWiseTotals } = useMemo(() => {
    const twoYearsAgo = new Date();
    twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);
    const grouped = historicalTableL3.reduce((acc, report) => {
      const year = new Date(report.LeadDate).getFullYear();
      const status = report.Status;
      const quotedValue = report.QuotedValue || 0;
      // const leadDate = new Date(report.LeadDate);

      if (!acc[year]) {
        acc[year] = {
          year,
          totalAmount: 0,
          totalLeads: 0,
          statusWise: {},
        };
      }

      if (!acc[year].statusWise[status]) {
        acc[year].statusWise[status] = { count: 0, totalQuotedValue: 0 };
      }

      // Accumulate data for given status
      acc[year].totalAmount += quotedValue;
      acc[year].totalLeads += 1;
      acc[year].statusWise[status].count += 1;
      acc[year].statusWise[status].totalQuotedValue += quotedValue;

      return acc;
    }, {});

    // Add "Non Responsive" Status with count and totalQuotedValue per year
    Object.keys(grouped).forEach((year) => {
      let nonResponsiveCount = 0;
      let nonResponsiveTotalQuotedValue = 0;

      // Loop through filtered leads and count "Pending" ones older than 2 years
      historicalTableL3.forEach((report) => {
        const reportYear = new Date(report.LeadDate).getFullYear();
        if (
          reportYear === Number(year) &&
          report.Status === "Pending" &&
          new Date(report.LeadDate) < twoYearsAgo
        ) {
          nonResponsiveCount += 1;
          nonResponsiveTotalQuotedValue += report.QuotedValue || 0;
        }
      });

      // Add to statusWise object
      grouped[year].statusWise["Non Responsive"] = {
        count: nonResponsiveCount,
        totalQuotedValue: nonResponsiveTotalQuotedValue,
      };
    });

    // Compute grand totals
    const totalLeads = historicalTableL3.length;
    const totalAmount = historicalTableL3.reduce(
      (sum, report) => sum + (report.QuotedValue || 0),
      0
    );

    // Transform data to array
    // const groupedData = Object.values(grouped);
    const groupedData = Object.values(grouped).map((yearData) => {
      // Initialize an empty object to hold the filtered statusWise data
      let filteredStatusWise = {};

      // Check if nonResponsive is set and corresponds to a key in statusWise
      if (nonResponsive && yearData.statusWise[nonResponsive]) {
        // Include only the specified status in the filteredStatusWise object
        filteredStatusWise[nonResponsive] = yearData.statusWise[nonResponsive];
      } else {
        // If nonResponsive is not set or doesn't match any key, include all statuses
        filteredStatusWise = { ...yearData.statusWise };
      }

      // Return the updated yearData with the filtered statusWise object
      return { ...yearData, statusWise: filteredStatusWise };
    });

    // Extract status-wise totals for all years
    const statusWiseTotals = groupedData.reduce((acc, { year, statusWise }) => {
      Object.entries(statusWise).forEach(([status, { totalQuotedValue }]) => {
        if (!acc[status]) acc[status] = {};
        acc[status][year] = totalQuotedValue;
      });
      return acc;
    }, {});

    return { groupedData, totalLeads, totalAmount, statusWiseTotals };
  }, [historicalTableL3, nonResponsive]);

  const [groupedArray, setGroupedArray] = useState([]);
  useEffect(() => {
    setGroupedArray(Object.values(groupedData));
  }, [groupedData]);

  const [, setRefreshTable] = useState(false);

  const numericSort = (order) => {
    order === "az"
      ? groupedArray.sort((a, b) => Number(a.totalLeads) - Number(b.totalLeads))
      : groupedArray.sort(
          (a, b) => Number(b.totalLeads) - Number(a.totalLeads)
        );

    setRefreshTable((prev) => !prev);
  };

  const WonnumericSort = (order) => {
    groupedArray.sort((a, b) => {
      const valA = Number(a.statusWise["Won"]?.totalQuotedValue) || 0;
      const valB = Number(b.statusWise["Won"]?.totalQuotedValue) || 0;

      return order === "az" ? valA - valB : valB - valA;
    });

    setRefreshTable((prev) => !prev);
  };

  // const WonnumericSort = (order) => {
  //   order === "az"
  //     ? groupedArray.sort(
  //         (a, b) =>
  //           Number(a.statusWise["Won"]?.totalQuotedValue) -
  //           Number(b.statusWise["Won"]?.totalQuotedValue)
  //       )
  //     : groupedArray.sort(
  //         (a, b) =>
  //           Number(b.statusWise["Won"]?.totalQuotedValue) -
  //           Number(a.statusWise["Won"]?.totalQuotedValue)
  //       );

  //   setRefreshTable((prev) => !prev);
  // };

  const WonRecurringSort = (order) => {
    groupedArray.sort((a, b) => {
      const valA = Number(a.statusWise["Won Recurring"]?.totalQuotedValue) || 0;
      const valB = Number(b.statusWise["Won Recurring"]?.totalQuotedValue) || 0;

      return order === "az" ? valA - valB : valB - valA;
    });

    setRefreshTable((prev) => !prev);
  };

  // const WonRecurringSort = (order) => {
  //   order === "az"
  //     ? groupedArray.sort(
  //         (a, b) =>
  //           Number(a.statusWise["Won Recurring"]?.totalQuotedValue) -
  //           Number(b.statusWise["Won Recurring"]?.totalQuotedValue)
  //       )
  //     : groupedArray.sort(
  //         (a, b) =>
  //           Number(b.statusWise["Won Recurring"]?.totalQuotedValue) -
  //           Number(a.statusWise["Won Recurring"]?.totalQuotedValue)
  //       );

  //   setRefreshTable((prev) => !prev);
  // };

  const PendingSort = (order) => {
    groupedArray.sort((a, b) => {
      const valA = Number(a.statusWise["Pending"]?.totalQuotedValue) || 0;
      const valB = Number(b.statusWise["Pending"]?.totalQuotedValue) || 0;

      return order === "az" ? valA - valB : valB - valA;
    });

    setRefreshTable((prev) => !prev);
  };

  // const PendingSort = (order) => {
  //   order === "az"
  //     ? groupedArray.sort(
  //         (a, b) =>
  //           Number(a.statusWise["Pending"]?.totalQuotedValue) -
  //           Number(b.statusWise["Pending"]?.totalQuotedValue)
  //       )
  //     : groupedArray.sort(
  //         (a, b) =>
  //           Number(b.statusWise["Pending"]?.totalQuotedValue) -
  //           Number(a.statusWise["Pending"]?.totalQuotedValue)
  //       );

  //   setRefreshTable((prev) => !prev);
  // };

  // const LostSort = (order) => {
  //   order === "az"
  //     ? groupedArray.sort(
  //         (a, b) =>
  //           Number(a.statusWise["Lost"]?.totalQuotedValue) -
  //           Number(b.statusWise["Lost"]?.totalQuotedValue)
  //       )
  //     : groupedArray.sort(
  //         (a, b) =>
  //           Number(b.statusWise["Lost"]?.totalQuotedValue) -
  //           Number(a.statusWise["Lost"]?.totalQuotedValue)
  //       );

  //   setRefreshTable((prev) => !prev);
  // };

  const LostSort = (order) => {
    groupedArray.sort((a, b) => {
      const valA = Number(a.statusWise["Lost"]?.totalQuotedValue) || 0;
      const valB = Number(b.statusWise["Lost"]?.totalQuotedValue) || 0;

      return order === "az" ? valA - valB : valB - valA;
    });

    setRefreshTable((prev) => !prev);
  };

  const NonResponsiveSort = (order) => {
    order === "az"
      ? groupedArray.sort(
          (a, b) =>
            Number(a.statusWise["Non Responsive"]?.totalQuotedValue) -
            Number(b.statusWise["Non Responsive"]?.totalQuotedValue)
        )
      : groupedArray.sort(
          (a, b) =>
            Number(b.statusWise["Non Responsive"]?.totalQuotedValue) -
            Number(a.statusWise["Non Responsive"]?.totalQuotedValue)
        );

    setRefreshTable((prev) => !prev);
  };
  return (
    <div>
      <div className="max-h-[550px] overflow-auto mt-2">
        <table className="w-full divide-y divide-gray-200">
          <thead className="bg-black sticky top-0">
            <tr className="text-white">
              {/*<th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">#</th>*/}
              <th className="px-4 py-3 text-start border text-sm font-medium uppercase tracking-wider">
                Year
              </th>
              <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                <div className="flex items-center justify-center ">
                  Total Leads
                  <div className="flex flex-col gap-y-1 ml-3">
                    <div
                      className="cursor-pointer"
                      onClick={() => numericSort("za")}
                    >
                      <ArrowDropUp color="white" sx={{ fontSize: "22px" }} />
                    </div>

                    <div
                      className="cursor-pointer -mt-2"
                      onClick={() => numericSort("az")}
                    >
                      <ArrowDropDown color="white" sx={{ fontSize: "22px" }} />
                    </div>
                  </div>
                </div>
              </th>
              <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                <div className="flex items-center justify-center">
                  Won
                  <div className="flex flex-col gap-y-1 ml-3">
                    <div
                      className="cursor-pointer"
                      onClick={() => WonnumericSort("za")}
                    >
                      <ArrowDropUp color="white" sx={{ fontSize: "22px" }} />
                    </div>

                    <div
                      className="cursor-pointer -mt-2"
                      onClick={() => WonnumericSort("az")}
                    >
                      <ArrowDropDown color="white" sx={{ fontSize: "22px" }} />
                    </div>
                  </div>
                </div>
              </th>
              <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                <div className="flex items-center justify-center">
                  Won Recurring
                  <div className="flex flex-col gap-y-1 ml-3">
                    <div
                      className="cursor-pointer"
                      onClick={() => WonRecurringSort("za")}
                    >
                      <ArrowDropUp color="white" sx={{ fontSize: "22px" }} />
                    </div>

                    <div
                      className="cursor-pointer -mt-2"
                      onClick={() => WonRecurringSort("az")}
                    >
                      <ArrowDropDown color="white" sx={{ fontSize: "22px" }} />
                    </div>
                  </div>
                </div>
              </th>
              <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                <div className="flex items-center justify-center">
                  Pending
                  <div className="flex flex-col gap-y-1 ml-3">
                    <div
                      className="cursor-pointer"
                      onClick={() => PendingSort("za")}
                    >
                      <ArrowDropUp color="white" sx={{ fontSize: "22px" }} />
                    </div>

                    <div
                      className="cursor-pointer -mt-2"
                      onClick={() => PendingSort("az")}
                    >
                      <ArrowDropDown color="white" sx={{ fontSize: "22px" }} />
                    </div>
                  </div>
                </div>
              </th>
              <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                <div className="flex items-center justify-center">
                  Lost
                  <div className="flex flex-col gap-y-1 ml-3">
                    <div
                      className="cursor-pointer"
                      onClick={() => LostSort("za")}
                    >
                      <ArrowDropUp color="white" sx={{ fontSize: "22px" }} />
                    </div>

                    <div
                      className="cursor-pointer -mt-2"
                      onClick={() => LostSort("az")}
                    >
                      <ArrowDropDown color="white" sx={{ fontSize: "22px" }} />
                    </div>
                  </div>
                </div>
              </th>
              <th className="px-4 py-3 text-right border text-sm font-medium uppercase tracking-wider">
                <div className="flex items-center text-center justify-center">
                  Non Responsive
                  <div className="flex flex-col gap-y-1 ml-3">
                    <div
                      className="cursor-pointer"
                      onClick={() => NonResponsiveSort("za")}
                    >
                      <ArrowDropUp color="white" sx={{ fontSize: "22px" }} />
                    </div>

                    <div
                      className="cursor-pointer -mt-2"
                      onClick={() => NonResponsiveSort("az")}
                    >
                      <ArrowDropDown color="white" sx={{ fontSize: "22px" }} />
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>

          <tbody className="bg-white divide-y divide-gray-200">
            {groupedArray.length > 0 ? (
              groupedArray.map(
                ({ year, totalLeads, totalAmount, statusWise }) => (
                  <tr key={year} className="border text-md">
                    <td
                      className="px-3 text-start py-2 cursor-pointer "
                      onClick={() => handleTdClickL3(year)}
                    >
                      {year}
                    </td>
                    <td
                      // onClick={() => handleNavigation4(year)} // Pass the year
                      className="px-3 text-center py-2 font-semibold "
                    >
                      {totalLeads}
                    </td>
                    <td
                      className={`px-3 text-center py-2 ${
                        statusWise["Won"]?.totalQuotedValue
                          ? "cursor-pointer"
                          : ""
                      }`}
                      onClick={
                        statusWise["Won"]?.totalQuotedValue
                          ? () => handleTdClickL3(year, "Won")
                          : undefined
                      }
                    >
                      {statusWise["Won"]?.totalQuotedValue
                        ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(statusWise["Won"].totalQuotedValue)
                        : "0"}
                    </td>
                    <td
                      className={`px-3 text-center py-2 ${
                        statusWise["Won Recurring"]?.totalQuotedValue
                          ? "cursor-pointer"
                          : ""
                      }`}
                      onClick={
                        statusWise["Won Recurring"]?.totalQuotedValue
                          ? () => handleTdClickL3(year, "Won Recurring")
                          : undefined
                      }
                    >
                      {statusWise["Won Recurring"]?.totalQuotedValue
                        ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(
                            statusWise["Won Recurring"].totalQuotedValue
                          )
                        : "0"}
                    </td>
                    <td
                      className={`px-3 text-center py-2 ${
                        statusWise["Pending"]?.totalQuotedValue
                          ? "cursor-pointer"
                          : ""
                      }`}
                      onClick={
                        statusWise["Pending"]?.totalQuotedValue
                          ? () => handleTdClickL3(year, "Pending")
                          : undefined
                      }
                    >
                      {statusWise["Pending"]?.totalQuotedValue
                        ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(statusWise["Pending"].totalQuotedValue)
                        : "0"}
                    </td>
                    <td
                      className={`px-3 text-center py-2 ${
                        statusWise["Lost"]?.totalQuotedValue
                          ? "cursor-pointer"
                          : ""
                      }`}
                      onClick={
                        statusWise["Lost"]?.totalQuotedValue
                          ? () => handleTdClickL3(year, "Lost")
                          : undefined
                      }
                    >
                      {statusWise["Lost"]?.totalQuotedValue
                        ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(statusWise["Lost"].totalQuotedValue)
                        : "0"}
                    </td>
                    <td
                      className={`px-3 text-center py-2 ${
                        statusWise["Pending"]?.totalQuotedValue &&
                        year < new Date().getFullYear() - 1
                          ? "cursor-pointer"
                          : ""
                      }`}
                      onClick={
                        statusWise["Pending"]?.totalQuotedValue &&
                        year < new Date().getFullYear() - 1
                          ? () => handleTdClickL3(year, "Pending")
                          : undefined
                      }
                    >
                      {statusWise["Non Responsive"]?.totalQuotedValue
                        ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(
                            statusWise["Non Responsive"].totalQuotedValue
                          )
                        : "0"}
                    </td>
                  </tr>
                )
              )
            ) : (
              <tr>
                <td colSpan="3" className="text-center py-2">
                  No data available
                </td>
              </tr>
            )}

            <tr className="border bg-gray-200 font-bold text-md">
              <td className="px-3 text-left py-2">TOTAL :</td>
              <td className="px-3 text-center py-2">{totalLeads}</td>
              <td className="px-3 text-center py-2">
                {Object.values(statusWiseTotals["Won"] || {})
                  .reduce((sum, val) => sum + val, 0)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
              </td>
              <td className="px-3 text-center py-2">
                {Object.values(statusWiseTotals["Won Recurring"] || {})
                  .reduce((sum, val) => sum + val, 0)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
              </td>
              <td className="px-3 text-center py-2">
                {Object.values(statusWiseTotals["Pending"] || {})
                  .reduce((sum, val) => sum + val, 0)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
              </td>
              <td className="px-3 text-center py-2">
                {Object.values(statusWiseTotals["Lost"] || {})
                  .reduce((sum, val) => sum + val, 0)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
              </td>
              <td className="px-3 text-center py-2">
                {Object.values(statusWiseTotals["Non Responsive"] || {})
                  .reduce((sum, val) => sum + val, 0)
                  .toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}{" "}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HistoricaltableL3;
