import React, { useState, useRef, useEffect,useCallback } from "react";
import { ConversionValidDate } from "../../../Utils/DateUtils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchLeads } from "../../../../redux/slices/leadsSlice";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);
const ConversionRateL1 = ({newLeadReports}) => {
  // const dispatch = useDispatch()
  const [LeadReports, setLeadReports] = useState([]);
  const dropdownRef = useRef(null);
  const MonthdropdownRef = useRef(null);
  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [, setShowMonthDropdown] = useState(false);
  const [, setIsLoading] = useState(true);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [wonLeads, setwonLeads] = useState([]);
  const [filteredWonLeads, setFilteredWonLeads] = useState([]);
  const [wonRecurring, setWonRecurring] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [mainData, setMainData] = useState([]);

  // const {leads} = useSelector((state)=> state.leads)

  useEffect(()=>{
    setMainData(newLeadReports)
  },[newLeadReports])



  useEffect(() => {
   
        if (mainData) {
          const formattedData = mainData.map((lead) => ({
            ...lead,
            LeadDate: ConversionValidDate(lead.LeadDate), // Apply ValidDate function
          }));

          const wonRecurringLeads = formattedData.filter(
            (lead) => lead.Status?.trim() === "Won Recurring"
          );
          const wonLeadsData = formattedData.filter(
            (lead) => lead.Status?.trim() === "Won"
          );

          setLeadReports(formattedData);
          setWonRecurring(wonRecurringLeads);
          setwonLeads(wonLeadsData);
        }
   
  }, [mainData]);
  const getWonPercentageByYear = (wonLeads, LeadReports) => {

    const wonLeadsByYear = {};
    const totalLeadsByYear = {};

    // Count Won Leads
    wonLeads.forEach((lead) => {
        const year = lead.LeadDate.split("-")[2]; // Extract year (YYYY)
        if (!wonLeadsByYear[year]) wonLeadsByYear[year] = 0;
        wonLeadsByYear[year]++;
    });

    // Count Total Leads
    LeadReports.forEach((lead) => {
        const year = lead.LeadDate.split("-")[2]; // Extract year (YYYY)
        if (!totalLeadsByYear[year]) totalLeadsByYear[year] = 0;
        totalLeadsByYear[year]++;
    });

    // Calculate Won % for each year
    const wonPercentageByYear = {};
    Object.keys(totalLeadsByYear).forEach((year) => {
        const wonCount = wonLeadsByYear[year] || 0; // If no won leads, set to 0
        wonPercentageByYear[year] = ((wonCount / totalLeadsByYear[year]) * 100).toFixed(2);
    });

    return wonPercentageByYear;
};

  // Get past 5 years dynamically
  const getWonrecurrngPercentageByYear = (wonRecurring, LeadReports) => {
    const wonRecurringLeadsByYear = {};
    const totalLeadsByYear = {};

    // Count Won Recurring Leads
    wonRecurring.forEach((lead) => {
        const year = lead.LeadDate.split("-")[2]; // Extract year (YYYY)
        if (!wonRecurringLeadsByYear[year]) wonRecurringLeadsByYear[year] = 0;
        wonRecurringLeadsByYear[year]++;
    });

    // Count Total Leads
    LeadReports.forEach((lead) => {
        const year = lead.LeadDate.split("-")[2]; // Extract year (YYYY)
        if (!totalLeadsByYear[year]) totalLeadsByYear[year] = 0;
        totalLeadsByYear[year]++;
    });

    // Calculate Won % for each year
    const wonPercentageByYear = {};
    Object.keys(totalLeadsByYear).forEach((year) => {
        const wonCount = wonRecurringLeadsByYear[year] || 0; // Default to 0 if no won leads
        wonPercentageByYear[year] = ((wonCount / totalLeadsByYear[year]) * 100).toFixed(2);
    });

    return wonPercentageByYear;
};
  const pastFiveYears = Array.from({ length: 5 }, (_, i) =>
    (currentYear - 4 + i).toString()
  );
  // Example API Data (replace with actual API response)
  const wonPercentageByYear = getWonPercentageByYear(wonLeads, LeadReports);
  const wonRurringPercentageByYear = getWonrecurrngPercentageByYear(
    wonRecurring,
    LeadReports
  );
  // Calculate % Increase/Decrease
  const percentageChange = pastFiveYears.map((year, index) => {
    const prevYear = (parseInt(year) - 1).toString();
    if (wonPercentageByYear[prevYear] && wonPercentageByYear[year]) {
      return (
        wonPercentageByYear[year] - wonPercentageByYear[prevYear]
      ).toFixed(2);
    }

    return 0; // No previous data
  });
  const percentageChangeWonRecurring = pastFiveYears.map((year, index) => {
    const prevYear = (parseInt(year) - 1).toString();
    if (
      wonRurringPercentageByYear[prevYear] &&
      wonRurringPercentageByYear[year]
    ) {
      return (
        wonRurringPercentageByYear[year] - wonRurringPercentageByYear[prevYear]
      ).toFixed(2);
    }
    return 0; // No previous data
  });
  const selectedYearChange =
    percentageChange[pastFiveYears.indexOf(selectedYear.toString())] || "0";
  const selectedYearChangeWonRecurring =
    percentageChangeWonRecurring[
      pastFiveYears.indexOf(selectedYear.toString())
    ] || "0";

  useEffect(() => {
    const wonRecurringLeads = LeadReports.filter(
      (lead) => lead.Status?.trim() === "Won Recurring"
    );
    const wonLeadsData = LeadReports.filter(
      (lead) => lead.Status?.trim() === "Won"
    );

    setWonRecurring(wonRecurringLeads);
    setwonLeads(wonLeadsData);
  }, [LeadReports]);
  useEffect(() => {
    if (selectedYear) {
      const filterWonLeadsByYear = wonLeads.filter((lead) => {
        if (!lead.LeadDate) return false; // Ensure LeadDate exists

        const leadYear = lead.LeadDate.split("-")[2]; // Extract year

        return leadYear === selectedYear.toString(); // Convert selectedYear to string for comparison
      });
      setFilteredWonLeads(filterWonLeadsByYear);
    }
  }, [selectedYear, wonLeads]);

  useEffect(() => {
  }, [wonLeads, wonRecurring, chartData, filteredWonLeads]);
  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowYearDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        MonthdropdownRef.current &&
        !MonthdropdownRef.current.contains(event.target)
      ) {
        setShowMonthDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (LeadReports.length > 0) {
      setIsLoading(false);
    }
  }, [LeadReports]);
  // Get distinct years from the LeadReports
  const years = [
    currentYear,
    currentYear - 1,
    currentYear - 2,
    currentYear - 3,
    currentYear - 4,
  ];


  useEffect(() => {
    const processData = () => {
      const monthlyCounts: { [key: string]: number } = {};

      filteredWonLeads.forEach((lead) => {
        if (!lead.LeadDate) {
          console.warn("Missing LeadDate for lead:", lead);
          return;
        }

        // Convert MM-DD-YYYY to YYYY-MM-DD (safe format)
        const [month, day, year] = lead.LeadDate.split("-");
        const formattedDate = new Date(`${year}-${month}-${day}`);

        if (isNaN(formattedDate.getTime())) {
          console.warn("Invalid date format:", lead.LeadDate);
          return;
        }

        // Extract month (e.g., "Jan", "Feb", "Mar")
        const monthName = formattedDate.toLocaleString("en-US", {
          month: "short",
        });

        if (!monthlyCounts[monthName]) {
          monthlyCounts[monthName] = 0;
        }
        monthlyCounts[monthName]++;
      });

      const totalLeads = filteredWonLeads.length;
      const formattedData = Object.keys(monthlyCounts).map((month) => ({
        month,
        percentage: ((monthlyCounts[month] / totalLeads) * 100).toFixed(2),
      }));

      setChartData(formattedData);
    };

    if (filteredWonLeads.length > 0) {
      processData();
    }
  }, [filteredWonLeads]);

//   const data = linedata.map((item) => {
//     const found = chartData.find((entry) => entry.month === item.name);
//     return {
//       ...item,
//       value: found ? parseFloat(found.percentage) : 0, // Convert percentage to number
//     };
//   });


 

  const chartRef1 = useRef(null);
const chartRef2 = useRef(null);
const [chartLoaded, setChartLoaded] = useState(false);

const [hasDataChart1, setHasDataChart1] = useState(true);
const [hasDataChart2, setHasDataChart2] = useState(true);

useEffect(() => {
  if (!window.google) {
    const script = document.createElement("script");
    script.src = "https://www.gstatic.com/charts/loader.js";
    script.async = true;
    script.onload = () => {
      window.google.charts.load("current", { packages: ["corechart", "line"] });
      window.google.charts.setOnLoadCallback(() => setChartLoaded(true));
    };
    document.body.appendChild(script);
  } else {
    window.google.charts.load("current", { packages: ["corechart", "line"] });
    window.google.charts.setOnLoadCallback(() => setChartLoaded(true));
  }
}, []);

const drawChart = useCallback((chartRef, dataValues, label, color, setHasData) => {
  if (!chartLoaded || !window.google || !window.google.visualization || !chartRef.current) {
    console.error("Google Charts is not available yet.");
    return;
  }

  const fixedYears = [2020, 2021, 2022, 2023, 2024, 2025];

  const data = new window.google.visualization.DataTable();
  data.addColumn("number", "Year");
  data.addColumn("number", label);

  const rows = fixedYears.map(year => [
    parseInt(year.toString().slice(-2)), 
    dataValues?.[year] !== undefined ? parseFloat(dataValues[year]) : 0 
  ]);
  const hasValidData = rows.some(row => row[1] > 0);

  setHasData(hasValidData); // Update state to track if data exists

  if (!hasValidData) {
    chartRef.current.innerHTML = `<div style="display: flex; align-items: center; justify-content: center; height: 100%; color: #ef4450; font-size: 18px; ">No data available</div>`;
    return;
  }
  
  data.addRows(rows);

  const options = {
    legend: "none",
    colors: [color],
    fontName: "Arial",
    hAxis: {
      baselineColor: "#000",
      gridlines: { color: "transparent", count: 0 },
      minorGridlines: { color: "transparent" },
      textStyle: { color: "#000", bold: true, fontSize: 14 },
      ticks: fixedYears.map(year => parseInt(year.toString().slice(-2))),
      slantedText: false,
      baselineWidth: 12,
    },
    vAxis: {
      textPosition: "none",
      gridlines: { color: "#000000", count: 1 },
      baselineColor: "transparent",
    },
    backgroundColor: { fill: "transparent" },
    chartArea: { backgroundColor: "transparent" },
    pointSize: 0,
    tooltip: { trigger: "none" },
  };

  const chart = new window.google.visualization.LineChart(chartRef.current);
  chart.draw(data, options);
}, [chartLoaded]);


useEffect(() => {
  if (chartLoaded) {
    drawChart(chartRef1, wonPercentageByYear, "Won %", "#008631", setHasDataChart1);
    drawChart(chartRef2, wonRurringPercentageByYear, "Won Recurring %", "#82b229", setHasDataChart2);
  }
}, [chartLoaded, drawChart, wonPercentageByYear, wonRurringPercentageByYear]);

  return (
    <div className="w-full h-full ">
      <div className="h-[20%]"> 
      <div className="flex flex-wrap h-12 items-center justify-between md:gap-x-2 md:flex-nowrap xl:flex xl:justify-between">
        <div>
          <h1 className="text-2xl font-semibold sm:text-sm md:text-sm lg:text-xl">
            LEAD CONVERSION RATE
          </h1>
        </div>
      </div>
      <hr />
      <hr />
        <div className="flex p-2">
          <div className="flex rounded-lg">
            <div className="text-xs h-full border rounded-l-md border-[#e3eaff] py-2 px-1 flex align-middle items-center text-center">Select Year</div>
            <div
              ref={dropdownRef}
              className="relative border w-24 rounded-r-md bg-[#e0e7ff]"
            >
             <div className="absolute cursor-pointer right-0 border-r border-[#e3eaff]  z-20">
              <ExpandMoreIcon
                color="white"
                className=""
                onClick={() => setShowYearDropdown(!showYearDropdown)}
              />
            </div>
              <button
                type="button"
                onClick={() => setShowYearDropdown(!showYearDropdown)}
                className=" text-left h-full w-full focus:outline-none py-2 px-1 text-xs"
              >
                {selectedYear}
              </button>

              {showYearDropdown && (
                <div className="absolute z-50 w-24 bg-white text-xs border border-gray-300 overflow-y-auto shadow-lg max-h-48  mt-0.5">
                  {years
                    .slice()
                    .sort((a, b) => b - a) // Sort years in descending order
                    .map((year) => (
                      <label
                        key={year}
                        className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          value={year}
                          checked={selectedYear === year}
                          onChange={(e) =>
                            setSelectedYear(parseInt(e.target.value))
                          }
                          className="mr-2"
                        />
                        {year}
                      </label>
                    ))}
                </div>
              )}
            </div>
          </div>
      
        </div>
      </div>
      {/* <div className=" xs:hidden cp:flex"> */}
      <div className="h-[80%] relative">
        <div className=" py-2 xl:py-2">
          <div className="flex">
          <div className="flex flex-col w-[70%] justify-center items-center gap-2">
            <button className="bg-[#fbbe62] text-left text-xs px-1.5 py-1.5 rounded-sm w-[50%] ">
              <h3>Won = {wonPercentageByYear[selectedYear] || 0}%</h3>
            </button>
            <button className="bg-[#fbbe62] text-left text-xs px-1.5 py-1.5 rounded-sm w-[50%] ">
              <p> % Change = {selectedYearChange}%</p>
            </button>
          </div>
          <div className="flex flex-col w-[70%] justify-center  items-center gap-2">
          <button className="bg-[#fbbe62] text-left text-xs px-1.5 py-1.5 rounded-sm w-[50%] ">
          <h3>
                Won Rec = {wonRurringPercentageByYear[selectedYear] || 0}%
              </h3>
            </button>
            <button className="bg-[#fbbe62] text-left text-xs px-1.5 py-1.5 rounded-sm w-[50%] ">
              <p> % Change = {selectedYearChangeWonRecurring}%</p>
            </button>
          </div>
          </div>
          <div className="flex w-full py-5">
            <div className="w-[50%] text-center text-xs">Historical Trend - Won</div>
            <div className="w-[50%] text-center text-xs">Historical Trend - Won recurring</div>
          </div>
        </div>
        {/* <div ref={chartRef} className="canvas" /> */}
        <div className="absolute dm:-bottom-3 lg:bottom-2 xl:-bottom-4 cp:-bottom-6 w-full h-[80%]">
          
          <div className="flex h-full relative">
            <div className="w-full flex absolute bottom-8 cp:bottom-10 font-bold">
              
              <div className="w-[50%]">       
                 {hasDataChart1 && <p className="text-[0.8rem] cp:text-[0.6rem]">Year</p>}
              </div>

             
              <div className="w-[50%]">
              {hasDataChart2 && <p className="text-[0.8rem] cp:text-[0.6rem]">Year</p>}
              </div>
            </div>
            <div ref={chartRef1} className="canvas  " style={{ width: "50%", height: "100%" }} />
            <div ref={chartRef2} className="canvas  " style={{ width: "50%", height: "100%" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversionRateL1;
