import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FaLock, FaEye, FaEyeSlash, FaCheck } from "react-icons/fa";
import axios from 'axios';
import config from '../../routes/api';

const ResetPassword = () => {

  const BackendURL = config.backendUrl;

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [authlevel, setAuthlevel] = useState('');


  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailFromURL = queryParams.get('email');
    const nameFromURL = queryParams.get('name');
    const authlevelFromURL = queryParams.get('authlevel');

    setEmail(emailFromURL);
    setName(nameFromURL);
    setAuthlevel(authlevelFromURL);
  }, [location.search, navigate, enqueueSnackbar]);

  const handleConfirm = async (e) => {
    e.preventDefault();

    if (newPassword !== newPasswordAgain) {
      enqueueSnackbar('Passwords do not match', { variant: 'error' });
      return;
    }
    if(newPassword.length < 8 || newPasswordAgain.length  < 8){
      enqueueSnackbar('Password must be 8 character', { variant: 'error' });
      return;
    }

    try {
      const response = await axios.post(`${BackendURL}/pass/reset-password`, {
        email,
        name,
        authlevel,
        newPassword,
        newPasswordAgain
      });
      // const response = await axios.post(`//localhost:9000/pass/reset-password`, {
      //       email,
      //       name,
      //       authlevel,
      //       newPassword,
      //       newPasswordAgain
      //   });

      if (response.status === 200) {
        enqueueSnackbar('Password set successfully!', { variant: 'success' });
        navigate('/'); // Redirect to home page or login page
      } else {
        enqueueSnackbar('Failed to Reset Password and Register User', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error during password reset and user registration:', error);
      enqueueSnackbar('An Error Occurred! Please Try Again', { variant: 'error' });
    }
  };

  const [passwordVisible, setPasswordVisible] = useState(false); // State for toggling password visibility

  const togglePassword1 = () => {
    setPasswordVisible(!passwordVisible); // Toggle the password visibility
  };
  // Hover handlers to show password
  const handleMouseEnterPassword = () => {
    setPasswordVisible(true); // Show password
  };

  const handleMouseLeavePassword = () => {
    setPasswordVisible(false); // Hide password
  };

  //   const [passwordAgainVisible, SetpasswordAgainVisible] = useState(false)

  const [hoverPasswordAgainVisible, SetHoverPasswordAgainVisible] = useState(false);

  const togglePasswordAgain = () => {
    SetHoverPasswordAgainVisible(!hoverPasswordAgainVisible)
  }

  const handleMouseEnterPasswordAgain = () => {
    SetHoverPasswordAgainVisible(true); // Show second password
  };

  const handleMouseLeavePasswordAgain = () => {
    SetHoverPasswordAgainVisible(false); // Hide second password
  };

  const [passwordStrength, setPasswordStrength] = useState(0); // Password strength
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleHeadlineClick = () => {
    setIsDropdownVisible(!isDropdownVisible); // Toggle dropdown on click
  };

  const [passwordLengthValid, setPasswordLengthValid] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [allowedcharacter, setallowedcharacter] = useState(false)

  const evaluatePasswordStrength = (password) => {
    const lowercasePattern = /[a-z]/;
    const uppercasePattern = /[A-Z]/;
    const numberPattern = /\d/;
    const allowedSpecialCharPattern = /[!@#$%*_-]/;

    setHasLowercase(lowercasePattern.test(password));
    setHasUppercase(uppercasePattern.test(password));
    setHasNumber(numberPattern.test(password));

    // Ensure the password has at least one special character
    setallowedcharacter(allowedSpecialCharPattern.test(password));
    setPasswordLengthValid(password.length >= 8);

    // Password Strength Calculation
    let strength = 0;
    if (lowercasePattern.test(password)) strength += 20;
    if (uppercasePattern.test(password)) strength += 20;
    if (numberPattern.test(password)) strength += 20;
    if (allowedSpecialCharPattern.test(password)) strength += 20;
    if (password.length >= 8) strength += 20;

    setPasswordStrength(strength);

  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    const validPattern = /^[a-zA-Z0-9!@#$%*_-]*$/;

   
    if (validPattern.test(password)) {
      setNewPassword(password);
      evaluatePasswordStrength(password);
    }

    // Allow only a-z, A-Z, 0-9, and !@#$%*-_
    const sanitizedPassword = password.replace(/[^a-zA-Z0-9!@#$%*_-]/g, "");
    setNewPassword(sanitizedPassword);  // Update input field only with allowed characters
    evaluatePasswordStrength(sanitizedPassword);


    // Show dropdown only if password has characters
    if (sanitizedPassword.length > 0) {
      setIsDropdownVisible(true);
    } else {
      setIsDropdownVisible(false); // Hide dropdown when input is empty
    }

    // Hide dropdown if all conditions are met
    if (hasLowercase && hasUppercase && hasNumber && allowedcharacter) {
      setIsDropdownVisible(false);
    }
  };



  const handleMouseEnter = () => {
    setIsDropdownVisible(true); // Show dropdown on hover
  };

  const handleMouseLeave = () => {
    setIsDropdownVisible(false); // Hide dropdown when mouse leaves
  };


  return (
    <div className="min-h-screen flex items-center bg-gray-100 justify-center">
      <div className="text-center my-20 px-56 py-20 bg-white overflow-hidden shadow-2xl rounded-lg border border-gray-200">
        <h1 className="text-7xl font-extrabold">
          <span className="text-pink-600">Q</span>
          <span className="text-teal-600">Trackr</span>
        </h1>
        <br />
        <br />
        <h1 className="font-extrabold text-2xl">Set New Password </h1>
        <br />
        <br />


        <div className="flex relative w-full">
          <FaLock style={{ alignItems: 'center', marginTop: '3%' }} size={24} />
          <input
            className="border ml-2 w-full border-gray-300 rounded-md p-2 pr-10 overflow-hidden shadow-md outline-none"
            onCopy={(e) => e.preventDefault()}
            style={{ userSelect: 'none' }}
            value={newPassword}
            type={passwordVisible ? "text" : "password"}
            placeholder="Enter Password"
            onChange={handlePasswordChange}
          />
          <button
            className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-transparent border-none"
            onClick={() => togglePassword1(!passwordVisible)}
            onMouseEnter={handleMouseEnterPassword} // Show password on hover
            onMouseLeave={handleMouseLeavePassword} // Hide password when mouse leaves
            type="button"
          >
            {passwordVisible ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
          </button>
        </div>

        <div className="relative w-full">
          {/* Password strength indicator */}
          <div className="flex">
            {newPassword && (
              <div className="mt-2 px-9">
                <div className="relative w-full h-2 bg-gray-300 rounded-md overflow-hidden">
                  <div
                    className={`h-2 transition-all duration-300 ${passwordStrength === 100
                        ? 'bg-blue-600'
                        : passwordStrength <= 33
                          ? 'bg-red-600'
                          : 'bg-yellow-500'
                      }`}
                    style={{ width: `${passwordStrength}%` }}
                  ></div>
                </div>
                <p
                  className="text-sm mt-1 font-semibold text-left"
                  onClick={handleHeadlineClick}
                  onMouseEnter={handleMouseEnter} // Show dropdown on hover
                  onMouseLeave={handleMouseLeave} // Hide dropdown when mouse leaves
                >
                  {passwordStrength === 100
                    ? 'Very Strong Password'
                    : passwordStrength <= 33
                      ? 'Weak'
                      : 'Medium'}
                </p>
              </div>
            )}
          </div>

          {/* Dropdown with Password Requirements */}
          {isDropdownVisible && (
            <div className="absolute top-8 left-8  shadow-lg z-50 bg-white mt-3 text-base rounded-md w-[100%]">
              <div className="w-auto flex px-2 py-1 cursor-pointer hover:bg-gray-100">
                <FaCheck
                  className={`mr-2 mt-1 ${hasLowercase ? 'text-green-500' : 'text-red-500'}`}
                />
                At least one lowercase letter
              </div>
              <div className="w-auto flex px-2 py-1 cursor-pointer hover:bg-gray-100">
                <FaCheck
                  className={`mr-2 mt-1 ${hasUppercase ? 'text-green-500' : 'text-red-500'}`}
                />
                At least one uppercase letter
              </div>
              <div className="w-auto flex px-2 py-1 cursor-pointer hover:bg-gray-100">
                <FaCheck
                  className={`mr-2 mt-1 ${hasNumber ? 'text-green-500' : 'text-red-500'}`}
                />
                At least one number
              </div>
              <div className="w-auto flex px-2 py-1 cursor-pointer hover:bg-gray-100">
                <FaCheck
                  className={`mr-2 mt-1 ${allowedcharacter ? 'text-green-500' : 'text-red-500'}`}
                />
                At least one special character
              </div>
              <div className="w-auto flex px-2 py-1 cursor-pointer hover:bg-gray-100">
                {/* <FaCheck
              className={`mr-2 mt-1 ${ allowedcharacter ? "text-green-500" : "text-red-500"}`}
            /> */}
                Allowed Special Characters !@#$%*_-
              </div>
              <div className="w-auto flex px-2 py-1 cursor-pointer hover:bg-gray-100">
                <FaCheck
                  className={`mr-2 mt-1 ${passwordLengthValid ? 'text-green-500' : 'text-red-500'}`}
                />
                At least 8 characters
              </div>
            </div>
          )}

        </div>






        <br />
        <div className="flex relative w-full">
          <FaLock style={{ alignItems: 'center', marginTop: '3%' }} size={24} />
          <input
            className="border ml-2 w-full border-gray-300 rounded-md p-2 pr-10 overflow-hidden shadow-md outline-none"
            value={newPasswordAgain}
            type={hoverPasswordAgainVisible ? "text" : "password"}
            placeholder="Re-Enter Password"
            onChange={(e) => setNewPasswordAgain(e.target.value)}
          />
          <button
            className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-transparent border-none"
            onClick={() => togglePasswordAgain(!hoverPasswordAgainVisible)}
            onMouseEnter={handleMouseEnterPasswordAgain} // Show second password on hover
            onMouseLeave={handleMouseLeavePasswordAgain} // Hide second password when mouse leaves
            type="button"
          >
            {hoverPasswordAgainVisible ? <FaEye size={20} /> : <FaEyeSlash size={20} />}
          </button>
        </div>
        <br />
        <br />
        <button
          onClick={handleConfirm}
          className="py-1 text-center overflow-hidden shadow-md rounded-sm text-white px-6 bg-blue-600 hover:bg-blue-900"
        >
          Confirm
        </button>
      </div>
    </div>


  );
};

export default ResetPassword;

