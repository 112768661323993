import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";

// ** Async Thunks **

// Fetch Departments
export const fetchDepartments = createAsyncThunk(
  "departments/fetchDepartments",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/departments/getdepartments");
      return response.data;
    } catch (error) {
      console.error("Error fetching departments:", error);
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Add a Department
export const addDepartment = createAsyncThunk(
  "departments/savedepartments",
  async (departmentName, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/departments/savedepartments", {
        departmentName,
      });
      return response.data;
    } catch (error) {
      console.error("Error adding department:", error);
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Edit a Department
export const editDepartment = createAsyncThunk(
  "departments/editDepartment",
  async ({ id, departmentName }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/departments/editdepartments/${id}`, {
        departmentName,
      });
      return response.data;
    } catch (error) {
      console.error("Error editing department:", error);
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// Delete a Department
export const deleteDepartment = createAsyncThunk(
  "departments/deleteDepartment",
  async (id, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/departments/deletedepartments/${id}`);
      return id; // Return the deleted department's ID
    } catch (error) {
      console.error("Error deleting department:", error);
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

// ** Redux Slice **
const departmentSlice = createSlice({
  name: "departments",
  initialState: {
    departments: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartments.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.loading = false;
        state.departments = action.payload;
      })
      .addCase(fetchDepartments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addDepartment.fulfilled, (state, action) => {
        state.departments.push(action.payload);
      })
      .addCase(editDepartment.fulfilled, (state, action) => {
        const index = state.departments.findIndex((dept) => dept.id === action.payload.id);
        if (index !== -1) {
          state.departments[index] = action.payload;
        }
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        state.departments = state.departments.filter((dept) => dept.id !== action.payload);
      });
  },
});

export default departmentSlice.reducer;
