import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { fetchFinancialData } from "../../../redux/slices/financialSlice";
import { useDispatch, useSelector } from "react-redux";

const ExpIncome = () => {
  const [fetchedfinancial, setfetchedfinancial] = useState([]);

  const dispatch = useDispatch();
  const financialData = useSelector((state) => state.financial.financialData);

  useEffect(() => {
    dispatch(fetchFinancialData());
  }, [dispatch]);

  useEffect(()=>{
    if(financialData){setfetchedfinancial(financialData)}
  },[financialData])

  const [groupExpense, setgroupExpense] = useState(""); // Track selected group
  const [profitYear, setprofitYear] = useState(""); // Manage selected year
  // Handle group selection change
  const handleGroupExpense = (e) => {
    setgroupExpense(e.target.value);
  };
  const handleProfitchange = (e) => {
    setprofitYear(e.target.value);
  };

  const getTotalProductCost = () => {
    const filteredData = groupExpense
      ? fetchedfinancial.filter((lead) => lead.AssignedGroup === groupExpense) // Filter by selected group
      : fetchedfinancial; // If no group is selected, include all groups

    const yearFilteredData = profitYear
      ? filteredData.filter((lead) => {
        const dateStr = lead.leadDate?.trim(); // Trim whitespace
        let year = "";

        if (!dateStr) return false; // Ignore empty values

        // Normalize the date string using the Date constructor
        const dateObj = new Date(dateStr);

        if (isNaN(dateObj)) return false; // If invalid date, return false

        // Extract year from the normalized Date object
        year = dateObj.getFullYear();

        // Ensure both the year and profitYear are compared as strings for consistency
        return String(year) === String(profitYear); // Ensure both are strings before comparing
      })
      : filteredData; // If no year is selected, include all years

    // Calculate total product cost for filtered data
    return yearFilteredData.reduce(
      (total, lead) => total + (parseFloat(lead.productCost) || 0),
      0
    );
  };

  const getTotalNetProfitInDollar = () => {
    // First, filter by the selected group
    const filteredData = groupExpense
      ? fetchedfinancial.filter((lead) => lead.AssignedGroup === groupExpense) // Filter by selected group
      : fetchedfinancial; // If no group is selected, include all groups

    // Now, filter by the selected year
    const yearFilteredData = profitYear
      ? filteredData.filter((lead) => {
        const dateStr = lead.leadDate?.trim(); // Trim whitespace
        let year = "";

        if (!dateStr) return false; // Ignore empty values

        // Normalize the date string using the Date constructor
        const dateObj = new Date(dateStr);

        if (isNaN(dateObj)) return false; // If invalid date, return false

        // Extract year from the normalized Date object
        year = dateObj.getFullYear();

        // Ensure both the year and profitYear are compared as strings for consistency
        return String(year) === String(profitYear); // Ensure both are strings before comparing
      })
      : filteredData; // If no year is selected, include all years

    // Calculate total net profit in dollars for the filtered data and round to 2 decimal places
    const totalProfit = yearFilteredData.reduce(
      (total, lead) => total + (parseFloat(lead.netProfitInDollar) || 0),
      0
    );

    return totalProfit.toFixed(2); // Format to 2 decimal places
  };

  // Prepare chart data
  const chartData = {
    labels: ["Total Expenses", "Total Profit in Dollar"], // X-axis labels
    datasets: [
      {
        label: "Group Data", // Label for the chart
        data: [
          getTotalProductCost(), // Total Product Cost data
          getTotalNetProfitInDollar(), // Total Net Profit data
        ],
        backgroundColor: ["orange", "yellowgreen"], // Colors for the bars
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"], // Border color for the bars
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `Data for Group : ${groupExpense || "All Groups"
          } and Year : ${profitYear}`,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `$ ${tooltipItem.raw} `, // Custom label format
        },
      },
    },
    scales: {
      x: {
        grid: { display: false },
      },
      y: {
        grid: { display: false },

        beginAtZero: true,
        ticks: {
          callback: (value) => `${value}`, // Show the value without suffix (optional)
        },
      },
    },
  };

  return (
    <div className="">
      <div className="flex">
        <div
          className="mb-4 mr-4 align flex flex-col items-start justify-start text-start"
          style={{ flex: "" }}
        >
          <label className="block" htmlFor="Name">
            Select Group
          </label>
          <select
            className="flex items-center justify-between border border-gray-400 rounded-md p-2 shadow-md w-28"
            required
            name="group"
            value={groupExpense}
            onChange={handleGroupExpense}
          >
            <option value="">All Group</option>
            {fetchedfinancial
              .map((lead) => lead.AssignedGroup)
              .filter((value, index, self) => self.indexOf(value) === index)
              .map((group, index) => (
                <option key={index} value={group}>
                  {group}
                </option>
              ))}
          </select>
        </div>
        <div className="mb-4 mr-4">
          <label className="block" htmlFor="Name">
            Select Year
          </label>

          <select
            className="flex items-center justify-between border border-gray-400 rounded-md p-2 shadow-md w-28"
            required
            name="year"
            value={profitYear} // Set the value of the select to the state
            onChange={handleProfitchange}
          >
            <option value="">All Years</option>{" "}
            {[
              ...new Set(
                fetchedfinancial
                  .map((lead) => {
                    const dateStr = lead.leadDate?.trim(); // Trim whitespace
                    let year = "";

                    if (!dateStr) return null; // Ignore empty values

                    if (dateStr.includes("T")) {
                      // ISO Format: "YYYY-MM-DDTHH:mm:ss.sssZ"
                      year = new Date(dateStr).getFullYear();
                    } else if (dateStr.match(/^\d{2}-\d{2}-\d{4}$/)) {
                      // Format: "DD-MM-YYYY"
                      year = dateStr.split("-")[2]; // Extract the year
                    }

                    return year ? String(year) : null; // Ensure it's a string
                  })
                  .filter(Boolean) // Remove null/undefined values
              ),
            ] // Convert Set back to array to remove duplicates
              .sort((a, b) => b - a) // Sort years in descending order
              .map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
          </select>
        </div>
      </div>

      <div className="">
        <h2 className="text-start text-xl font-semibold mb-3">
          Income & Expenses
        </h2>
      </div>

      <div className="flex w-[90%] lg:w-[80%]">
        <div className="w-1/3 justify-end"></div>

        <div className="w-1/3 lg:w-1/2 justify-start">
          <h2 className="text-end mr-6 text-xl font-semibold lg:text-start xl:text-md">{`$ ${getTotalProductCost()}`}</h2>
          <h3 className="text-end text-red-600 lg:text-start">Max Expenses</h3>
        </div>
        <div className="w-1/3 lg:w-1/2 text-start justify-start">
          <h2 className="text-end text-xl font-semibold lg:text-start xl:text-md">{`$ ${getTotalNetProfitInDollar()}`}</h2>
          <h3 className="text-green-600 text-end lg:text-start">Max Income</h3>
        </div>
      </div>

      <div className="mt-20 lg:mt-8 w-full flex justify-center">
        <div className="w-full max-w-[1600px] xl:max-w-[2000px] 2xl:max-w-[2500px]">
          <Bar data={chartData} options={chartOptions} className="w-full h-auto" />
        </div>
      </div>
    </div>

  );
};

export default ExpIncome;
