import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FaTrash } from "react-icons/fa";
import { MdEditSquare } from "react-icons/md";
import ConfirmationModal from "./ConfirmationModal";
import AdminLoader from "../../loader/AdminLoader";
import L1Sidebar from "./L1Sidebar";
import { FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import {
  createGroup,
  deleteGroup,
  fetchGroups,
  updateGroup,
} from "../../../redux/slices/groupSlice";
import { useDispatch, useSelector } from "react-redux";
import { logoutSession } from "../../../redux/slices/sessionSlice";

const AddGroup = () => {
  const [isLoading, SetisLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [showLogout, setshowlogout] = useState(false);
  const popupRef = useRef(null);
  const name = localStorage.getItem("name");

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setshowlogout(false); // Close the popup if clicked outside
    }
  };
  useEffect(() => {
    if (showLogout) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showLogout]);

  const logout = (e) => {
    e.preventDefault();
    setshowlogout(!showLogout);
  };
  const logoutUser = (e) => {
    e.preventDefault();
    dispatch(logoutSession())
    enqueueSnackbar("Successfully Logged out!", {
      variant: "success",
      autoHideDuration: 1500,
    });
    navigate("/");
  };


  const [newGroupName, setNewGroupName] = useState("");
  const [editingGroupNameId, setEditingGroupNameId] = useState(null);
  const [GroupNames, setGroupNames] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);

  const { group } = useSelector((state) => state.group);

  useEffect(()=>{
   dispatch(fetchGroups())
  },[dispatch])

  useEffect(() => {
    setGroupNames(group);
  }, [group]);

  const Submit = async (e) => {
    e.preventDefault();
    if (!newGroupName) {
      enqueueSnackbar("Please fill all the fields!", { variant: "error" });
      return;
    }

    try {
      SetisLoading(true);

      if (!editingGroupNameId) {
        // 🟢 Create a new group using Redux action
        dispatch(createGroup({ name: newGroupName }))
          .unwrap()
          .then((response) => {
            enqueueSnackbar("Group Name Added Successfully!", {
              variant: "success",
            });
            setNewGroupName("");
          })
          .catch((error) => {
            enqueueSnackbar(error?.message || "Failed to add GroupName", {
              variant: "error",
            });
          });
      } else {
        // 🟡 Update an existing group using Redux action
        dispatch(updateGroup({ id: editingGroupNameId, name: newGroupName }))
          .unwrap()
          .then((response) => {
            enqueueSnackbar("Group Name Updated Successfully!", {
              variant: "success",
            });
            setNewGroupName("");
            setEditingGroupNameId(null);
          })
          .catch((error) => {
            enqueueSnackbar(error?.message || "Failed to update GroupName", {
              variant: "error",
            });
          });
      }
    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    } finally {
      SetisLoading(false);
    }
  };

  const Cancel = (e) => {
    e.preventDefault();
    setNewGroupName("");
    setEditingGroupNameId(false);
  };

  const editGroupName = (id, GroupName) => {
    setEditingGroupNameId(id);
    setNewGroupName(GroupName.newGroupName);
  };

  

  const deleteGroupName = async (id) => {
    try {
      const resultAction = await dispatch(deleteGroup(id));

      if (deleteGroup.fulfilled.match(resultAction)) {
        enqueueSnackbar("Group Name Deleted Successfully!", {
          variant: "success",
        });

        // Optionally update local state if needed
        setGroupNames(GroupNames.filter((group) => group.id !== id));
      } else {
        enqueueSnackbar("Failed to delete Group Name", { variant: "error" });
      }
    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar("Connection Error!", { variant: "error" });
    }
  };

  const openDeleteModal = (id) => {
    setGroupToDelete(id);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setGroupToDelete(null);
  };

  const confirmDelete = async () => {
    if (groupToDelete) {
      await deleteGroupName(groupToDelete); // Call your existing delete function
    }
    closeDeleteModal();
  };

  return (
    <>
      <div className=" bg-[#f3f8f8] pb-6 h-full">
        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={closeDeleteModal}
          onConfirm={confirmDelete}
        />
        <L1Sidebar />

        <div className="ml-[20%] w-3/4 relative">
          {isLoading ? (
            <AdminLoader isLoading={isLoading} />
          ) : (
            <div className=" w-full relative pt-4">
              <div className="bg-white p-2  flex justify-between align-middle items-center rounded-md">
                <h1 className="text-xl font-semibold"> </h1>
                <div className="relative cursor-pointer bg-transparent">
                  <FaUserCircle
                    onClick={logout}
                    className="text-black w-6 h-6"
                  />
                  {showLogout && (
                    <div
                      ref={popupRef}
                      className="absolute right-0 top-full mt-1 text-black text-center px-2 w-36 shadow-2xl overflow-hidden bg-white border border-gray-400 z-50 rounded-lg"
                    >
                      <h1 className="px-6 py-2 rounded-md my-1">{name}</h1>
                      <h1
                        onClick={logoutUser}
                        className="flex items-center gap-2 px-6 py-2 rounded-md my-1 cursor-pointer hover:bg-black hover:text-white"
                      >
                        <MdLogout size={24} /> Logout
                      </h1>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="flex pt-6">
            <div className="  p-4 h-fit w-1/2 bg-white border border-gray-200 overflow-hidden shadow-lg rounded-md">
              <h1 className="text-2xl mb-2 font-semibold">
                {editingGroupNameId ? "Edit Group" : "Add Group"}
              </h1>
              <hr />
              <hr />
              <br />
              <div className="mx-4">
                <label className="block" htmlFor="Name">
                  Group Name
                </label>
                <input
                  className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                  type="text"
                  placeholder="Enter Group Name"
                  value={newGroupName}
                  onChange={(e) => {
                    const newValue = e.target.value
                      .replace(/[^a-zA-Z0-9\s-]/g, "")
                      .slice(0, 30);
                    setNewGroupName(newValue);
                  }}
                />
              </div>
              <div className="mx-4 mt-8">
                <button
                  onClick={Submit}
                  className="mr-2 py-1 px-5 bg-[#e32278] hover:bg-[#e12298] overflow-hidden shadow-md text-white rounded-md"
                >
                  {editingGroupNameId ? "Update" : "Submit"}
                </button>
                <button
                  onClick={Cancel}
                  className="ml-2 py-1 px-5 bg-black hover:bg-white hover:text-black border border-black overflow-hidden shadow-md text-white rounded-md"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className=" ml-8 w-1/2 h-fit p-4 bg-white border border-gray-200  shadow-lg overflow-auto rounded-md">
              <h1 className="text-2xl mb-2 font-semibold">Group Name</h1>
              <hr />
              <hr />
              <br />
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-black text-white">
                  <tr>
                    <th className="border px-6 py-3 text-center tracking-wider text-sm font-medium uppercase">
                      #
                    </th>
                    <th className="border px-6 py-3 text-center tracking-wider text-sm font-medium uppercase">
                      Group Name
                    </th>
                    <th className="border px-3 py-3 text-center tracking-wider text-sm font-medium uppercase">
                      Edit
                    </th>
                    <th className="border px-3 py-3 text-center tracking-wider text-sm font-medium uppercase">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {GroupNames.map((GroupName, index) => (
                    <tr className="border" key={index}>
                      <td className="px-6 text-center py-2">{index + 1}</td>
                      <td className="px-6 text-center py-2">
                        {GroupName.newGroupName}
                      </td>
                      <td
                        onClick={() => editGroupName(GroupName.id, GroupName)}
                        className="px-3 text-center py-2 hover:bg-black hover:text-white cursor-pointer"
                      >
                        <div className="flex justify-center items-center">
                          <MdEditSquare size={20} />
                        </div>
                      </td>
                      <td
                        onClick={() => openDeleteModal(GroupName.id)}
                        className="px-3 text-center py-2 hover:bg-black hover:text-white cursor-pointer"
                      >
                        <div className="flex justify-center items-center">
                          <FaTrash size={18} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddGroup;
