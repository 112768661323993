import React, { useState, useRef, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import "react-phone-input-2/lib/style.css";
import Loader from "../../loader/Loader";
import { FaTrash } from "react-icons/fa";
import { MdEditSquare } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import L3Sidebar from "./L3Sidebar";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { MdSearch } from "react-icons/md";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { deleteLead } from "../../../redux/slices/leadsSlice";
import { fetchServiceTypes } from "../../../redux/slices/serviceTypesSlice";
import ConfirmationModal from './../L1/ConfirmationModal';
import { fetchSources } from './../../../redux/slices/sourceSlice';
import { fetchStatus } from './../../../redux/slices/statusSlice';
import { fetchLeadTypes } from './../../../redux/slices/leadTypeSlice';
import { deleteFileByIdName,downloadFileByIdName, fetchFilesByLeadId, uploadFileInReport  } from './../../../redux/slices/fileSlice';
import { fetchEmployeeAuthData, fetchLeadsByGroups } from "../../../redux/slices/leadsSliceL3";
import { setFromDateLeadReportReduxL3, setGroupLeadReportReduxL3, setStatusLeadReportReduxL3, setToDateLeadReportReduxL3 } from "../../../redux/slices/filterSlice";
import { logoutSession } from "../../../redux/slices/sessionSlice";

const LeadReport = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();


  const [LeadReports, setLeadReports] = useState([]);
  const [GroupNames, setGroupNames] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredLeadReports, setFilteredLeadReports] = useState([]);

  const dispatch = useDispatch();

  const {  leadReports } = useSelector((state) => state.leadsL3);
  const { source } = useSelector((state) => state.source);
  const { status } = useSelector((state) => state.status);
  const { leadTypesRedux } = useSelector((state) => state.leadTypesRedux);
  const { serviceTypesRedux } = useSelector((state) => state.serviceTypesRedux);
  const { empGroups } = useSelector((state) => state.leadsL3);

  useEffect(()=>{
dispatch(fetchLeadTypes());
dispatch(fetchEmployeeAuthData());
dispatch(fetchLeadsByGroups());
dispatch(fetchSources());
dispatch(fetchLeadTypes());
dispatch(fetchServiceTypes());
  },[dispatch])

  useEffect(() => {
    if (status.length === 0) {
      dispatch(fetchStatus());
    }
  }, [dispatch, status]);

  useEffect(() => {
    setGroupNames(empGroups);
    setStatuses(status);
  }, [empGroups, source, status, leadTypesRedux, serviceTypesRedux]);


   useEffect(() => {
      if (leadReports.length > 0 && Array.isArray(empGroups) && empGroups.length > 0) {
        const filteredData = leadReports.filter(lead =>
          empGroups.includes(lead.AssignedGroup)
        );
        setLeadReports(filteredData);
        setFilteredLeadReports(filteredData); // Optional, if you want to maintain both
      } else {
        console.error("empGroups not available or leadReports empty");
      }
    }, [empGroups, leadReports]);


const handleFromDateChange = (date) => {
    setDateRange({ ...dateRange, fromDate: date });
    dispatch(setFromDateLeadReportReduxL3(date)); //  Dispatch fromDate update
  };
  
  const handleToDateChange = (date) => {
    setDateRange({ ...dateRange, toDate: date });
    dispatch(setToDateLeadReportReduxL3(date)); //  Dispatch toDate update
  };
  
  const handleAssignedGroupChange = (groupName) => {
    setAssignedGroup(groupName); // Update assigned group
    setIsGroupOpen(false); // Close dropdown
    dispatch(setGroupLeadReportReduxL3(groupName))
  };

    const fromDateLeadReportReduxL3 = useSelector((state) => state.filters.fromDateLeadReportL3)
    const toDateLeadReportReduxL3 = useSelector((state) => state.filters.toDateLeadReportL3);
    const statusLeadReportReduxL3 = useSelector((state) => state.filters.statusLeadReportL3);
    const GroupLeadReportReduxL3 = useSelector((state) => state.filters.GroupLeadReportL3);    
  
    useEffect(()=>{
if(GroupLeadReportReduxL3){setAssignedGroup(GroupLeadReportReduxL3)}
    },[GroupLeadReportReduxL3])

 useEffect(() => {
          setDateRange((prev) => {
            if (prev.fromDate !== fromDateLeadReportReduxL3) {
              return { ...prev, fromDate: fromDateLeadReportReduxL3 || "" };
            }
            return prev; // Prevent unnecessary state updates
          });
        }, [fromDateLeadReportReduxL3]);

    useEffect(() => {
           setDateRange((prev) => {
             if (prev.toDate !== toDateLeadReportReduxL3) {
               return { ...prev, toDate: toDateLeadReportReduxL3 || "" };
             }
             return prev; // Prevent unnecessary state updates
           });
         }, [toDateLeadReportReduxL3]);





  const dropdownRef = useRef(null);

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const groupdownRef = useRef(null);

  const handleClickOutsidegroup = (e) => {
    if (groupdownRef.current && !groupdownRef.current.contains(e.target)) {
      setIsGroupOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsidegroup);
    return () => {
      document.removeEventListener("click", handleClickOutsidegroup);
    };
  }, []);

  const [AssignedGroup, setAssignedGroup] = useState("All");
  const [, setTodaysDate] = useState("");

  const [showLogout, setshowlogout] = useState(false);
  const popupRef = useRef(null);
  const names = localStorage.getItem("name");

  const handleClickOutsides = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setshowlogout(false); // Close the popup if clicked outside
    }
  };
  useEffect(() => {
    if (showLogout) {
      document.addEventListener("mousedown", handleClickOutsides);
    } else {
      document.removeEventListener("mousedown", handleClickOutsides);
    }
    return () => document.removeEventListener("mousedown", handleClickOutsides);
  }, [showLogout]);

  const logout = (e) => {
    e.preventDefault();
    setshowlogout(!showLogout);
  };
  const logoutUser = (e) => {
    e.preventDefault();
    dispatch(logoutSession());
    enqueueSnackbar("Successfully Logged out!", {
      variant: "success",
      autoHideDuration: 1500,
    });
    navigate("/");
  };

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setTodaysDate(today);
  }, []);


  const ResetSearch = (e) => {
    e.preventDefault();

    handleFromDateChange("");
    setDateRange("");
    setSelectedStatuses([]);
    setAssignedGroup("All");
        dispatch(setFromDateLeadReportReduxL3("")); //  clear fromDate 
        dispatch(setToDateLeadReportReduxL3("")); //  clear toDate 
        dispatch(setStatusLeadReportReduxL3([]));
        dispatch(setGroupLeadReportReduxL3("All"))
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const deleteLeadReport = async (id) => {
    try {
      const resultAction = await dispatch(deleteLead(id)); // Dispatch Redux thunk

      if (deleteLead.fulfilled.match(resultAction)) {
        enqueueSnackbar("Leadreport deleted successfully!", { variant: "success" });
        setFilteredLeadReports((prevReports) =>
          prevReports.filter((report) => report.id !== id)
        );
        dispatch(fetchLeadsByGroups());        
      } else {
        enqueueSnackbar("Failed to delete leadreport!", { variant: "error" });
      }
    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar("Connection Error!", { variant: "error" });
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [LeadToDelete, setLeadToDelete] = useState(null);
  const openDeleteModal = (id) => {
    setLeadToDelete(id);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setLeadToDelete(null);
  };

  const confirmDelete = async () => {
    if (LeadToDelete) {
      await deleteLeadReport(LeadToDelete); // Call your existing delete function
    }
    closeDeleteModal();
  };

  const customOrder = ["Won", "Won Recurring", "Pending", "Lost"];

  const sortedStatuses = [...statuses].sort((a, b) => {
    const indexA = customOrder.indexOf(a.status);
    const indexB = customOrder.indexOf(b.status);

    // If both are in customOrder, sort by order in customOrder
    if (indexA !== -1 && indexB !== -1) return indexA - indexB;

    // If only one is in customOrder, it should come first
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    // If neither is in customOrder, sort alphabetically
    return a.status.localeCompare(b.status);
  });


  const [dateRange, setDateRange] = useState({
    fromDate: "",
    toDate: "",
  });

 
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isGroupOpen, setIsGroupOpen] = useState(false);

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    setSelectedStatuses((prevStatuses) => {
      let updatedStatuses;
      if (prevStatuses.includes(value)) {
        // Remove if already selected
        updatedStatuses = prevStatuses.filter((status) => status !== value);
      } else {
        // Add if not selected
        updatedStatuses = [...prevStatuses, value];
      }
  
      // Dispatch the updated statuses to Redux store
      dispatch(setStatusLeadReportReduxL3(updatedStatuses));
  
      return updatedStatuses;
    });
  };

  useEffect(()=>{
    if(statusLeadReportReduxL3){setSelectedStatuses(statusLeadReportReduxL3)}
  },[statusLeadReportReduxL3])

  // Convert selected statuses to a comma-separated string
  const selectedStatusesString = selectedStatuses.join(", ");

  const filteredLeadReport = filteredLeadReports.filter((LeadReport) => {
    // If no filters are applied, return all data

    if (
      !dateRange.fromDate &&
      !dateRange.toDate && // No date filter
      selectedStatuses.length === 0 && // No status filter
      AssignedGroup === "All" // No group filter
    ) {
      return true; // No filters, so include all data
    }

    // Check if the LeadDate is within the selected date range
    const leadDate = new Date(LeadReport.LeadDate);
    const isWithinDateRange =
      (!dateRange.fromDate && !dateRange.toDate) ||
      (dateRange.fromDate &&
        dateRange.toDate &&
        leadDate >= dateRange.fromDate &&
        leadDate <= dateRange.toDate) ||
      (dateRange.fromDate &&
        !dateRange.toDate &&
        leadDate >= dateRange.fromDate) ||
      (!dateRange.fromDate && dateRange.toDate && leadDate <= dateRange.toDate);

    // If no date filter is applied, include all dates
    if (!isWithinDateRange) return false;

    // Check if the LeadReport matches the selected statuses
    const matchesStatus =
      selectedStatuses.length === 0 ||
      selectedStatuses.includes(LeadReport.Status);

    // If no status filter is applied, include all statuses
    if (!matchesStatus) return false;

    // Check if the LeadReport matches the selected group
    const matchesGroup =
      AssignedGroup === "All" || LeadReport.AssignedGroup === AssignedGroup;

    // If no group filter is applied, include all groups
    if (!matchesGroup) return false;

    // If all conditions are met, include the LeadReport
    return true;
  });

  const [currentPage, setCurrentPage] = useState(1); // Track the current page

  const [itemsPerPage] = useState(10);

  const totalItems = filteredLeadReport.length;
  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;

  // Function to calculate the number of pages
  const totalPages = Math.ceil(filteredLeadReport.length / itemsPerPage);

  // Slice the filteredLeadReport based on the current page
  const currentData = filteredLeadReport.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearchInputChange = (e) => {
    const query = e.target.value; // Remove leading/trailing spaces
    setSearchQuery(query);
    filterLeadReports(query);
    setCurrentPage(1); // ✅ Reset to first page when searching
  };

  const filterLeadReports = (query) => {
    const lowerCaseQuery = query.toLowerCase().trim(); // Normalize query

    const filtered = LeadReports.filter((LeadReport) =>
      Object.entries(LeadReport).some(([key, value]) => {
        if (
          key === "comments" ||
          key === "Reference" ||
          key === "ClientContactName" ||
          key === "TypeService" ||
          key === "Probability" ||
          key === "ClientPhone" ||
          key === "ClientEmail"
        )
          return false;
        if (value !== null && value !== undefined) {
          let formattedValue = value.toString().trim().toLowerCase(); // Trim spaces & normalize

          // ✅ Handle Date Formatting (if value is Date object)
          if (value instanceof Date) {
            formattedValue = formatDate(value).trim().toLowerCase();
          }
          // ✅ Handle Date String Formatting (if stored as string)
          else if (typeof value === "string") {
            const dateValue = parseDate(value);
            if (dateValue) {
              formattedValue = formatDate(dateValue).trim().toLowerCase();
            }
          }

        
          return formattedValue.includes(lowerCaseQuery);
        }
        return false;
      })
    );

    setFilteredLeadReports(filtered);
  };

  // Function to parse different date formats into Date object
  const parseDate = (dateStr) => {
    let parsedDate = new Date(dateStr);

    if (!isNaN(parsedDate.getTime())) {
      return parsedDate;
    }

    // Try to parse manually formatted "DD-MM-YYYY" dates
    const parts = dateStr.split("-");
    if (parts.length === 3) {
      const [day, month, year] = parts.map(Number);
      return new Date(year, month - 1, day);
    }

    return null;
  };

  const extractDateTime = (timestamp) => {
    const dateObj = new Date(timestamp);
    // Extract and format the date as DD/MM/YYYY in local time
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = dateObj.getFullYear();
    const date = `${day}/${month}/${year}`;
    const dateValue = date === "NaN/NaN/NaN" ? "" : date;

    // Extract and format the time as HH:MM AM/PM in local time
    let hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const amPm = hours >= 12 ? "PM" : "AM";
    const time = `${formattedHours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm}`;
    const timeValue = time === "NaN:NaN AM" ? "" : time;

    return { dateValue, timeValue };
  };

  const location = useLocation();

  const [popupVisibleId, setPopupVisibleId] = useState(null);
  const [ uploadedFiles, setUploadedFiles ] = useState([]);
  const [fetchedfiles, setFetchedFiles] = useState([]);
  const [uploadLoader] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(null);
  const [leadId, SetLeadId] = useState("")

  const handleCombineAction = (leadId) => {
    togglePopup(leadId); // Function to toggle the popup
    SetLeadId(leadId); // Function to fetch files
  };

  const togglePopup = (id) => {
    setPopupVisibleId(id === popupVisibleId ? null : id);
  };

  const fetchFiles = useCallback(async (leadId) => {
    if (!leadId) return; // Prevent calling API if leadId is empty 
    setUploadedFiles([]);
    setFetchedFiles([]); 
    try {
      const response = await dispatch(fetchFilesByLeadId(leadId)).unwrap(); // Dispatch Redux action
      setUploadedFiles(response); // Save files in state
      setFetchedFiles(response);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  }, [dispatch, setUploadedFiles, setFetchedFiles]); 
  
   useEffect(() => {
     if (!leadId) {
       setUploadedFiles([]);
       setFetchedFiles([]);
       return;
     }
     fetchFiles(leadId);
   }, [leadId, fetchFiles,setUploadedFiles]);
  

  const [currentFileName, setCurrentFileName] = useState("No File Selected");

  const handleFileUpload = async (event, id) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    if (!file) return;
  
    let fileName = file.name;
    const isFileNameExists = (name) =>
      fetchedfiles.some((uploadedFile) => uploadedFile.fileName === name);
    let fileType = file.type; // Get MIME type
    let fileExtension = fileName.split(".").pop().toLowerCase();
  
    if (file.size > 2 * 1024 * 1024) {
      enqueueSnackbar("File size must be less than 2MB.", {
        variant: "warning",
      });
      event.target.value = "";
      return;
    }
  
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const allowedExtensions = ["pdf", "doc", "docx"];
  
    if (!allowedTypes.includes(fileType) || !allowedExtensions.includes(fileExtension)) {
      enqueueSnackbar("Only PDF and DOC/DOCX files are allowed.", { variant: "error" });
      event.target.value = "";
      return;
    }
  
    if (isFileNameExists(fileName)) {
      enqueueSnackbar("This file has already been uploaded. Please upload a different file.", {
        variant: "warning",
      });
      fileInput.value = null;
      return;
    }
  
    setCurrentFileName(fileName);
  
    const formData = new FormData();
    formData.append("file", file, fileName);
    formData.append("leadId", id);
    formData.append("uploadDate", new Date());
  
    try {
      await dispatch(uploadFileInReport(formData)).unwrap();
  
      enqueueSnackbar("File uploaded successfully", { variant: "success" });
      fetchFiles(leadId);  
      fileInput.value = null;
      setCurrentFileName("No File Selected");
    } catch (error) {
      enqueueSnackbar("Error Occurred While uploading file", { variant: "error" });
      console.error("Error uploading file:", error.response?.data?.error || error.message);
    }
  };
  
  const downloadFile = async (filename, id) => {
    setDownloadLoader(true);
    try {
      // Dispatch Redux action
      const response = await dispatch(downloadFileByIdName({ id, filename })).unwrap();
  
      // Create a Blob URL and trigger the download
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
  
      enqueueSnackbar("File downloaded successfully", { variant: "success" });
    } catch (error) {
      console.error("Error downloading the file:", error);
    } finally {
      setDownloadLoader(false);
    }
  };
  
  const deleteFile = async (fileName, leadId) => {
    setDeleteLoader(true);
    try {
      // Dispatch Redux action to delete file
      await dispatch(deleteFileByIdName({ leadId, fileName })).unwrap();
  
      enqueueSnackbar("File deleted successfully", { variant: "success" });
  
      // Update the state to remove the deleted file
      setUploadedFiles((prevFiles) =>
        prevFiles.filter(
          (file) => !(file.fileName === fileName && file.LeadReport?.id === leadId)
        )
      );
      fetchFiles(leadId)
      // Fetch updated file list after deletion
      dispatch(fetchFilesByLeadId(leadId));
    } catch (error) {
      console.error(
        "Error deleting the file:",
        error.response?.data || error.message
      );
      enqueueSnackbar("File failed to delete", { variant: "error" });
    } finally {
      setDeleteLoader(false);
    }
  };
  
  const [, setRefreshTable] = useState(false);

  const sortAlphabetically = (targetToSort, order) => {
    const key = `${targetToSort}-${order}`;

    // const sortedReports = [...filteredLeadReports];

    switch (key) {
      case "business-az":
        filteredLeadReports.sort((a, b) =>
          a.AssignedGroup.localeCompare(b.AssignedGroup)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "business-za":
        filteredLeadReports.sort((a, b) =>
          b.AssignedGroup.localeCompare(a.AssignedGroup)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "client-az":
        filteredLeadReports.sort((a, b) =>
          a.ClientName.localeCompare(b.ClientName)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "client-za":
        filteredLeadReports.sort((a, b) =>
          b.ClientName.localeCompare(a.ClientName)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "project-az":
        filteredLeadReports.sort((a, b) =>
          a.ProjectName.localeCompare(b.ProjectName)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "project-za":
        filteredLeadReports.sort((a, b) =>
          b.ProjectName.localeCompare(a.ProjectName)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "leadtype-az":
        filteredLeadReports.sort((a, b) =>
          a.LeadType.localeCompare(b.LeadType)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "leadtype-za":
        filteredLeadReports.sort((a, b) =>
          b.LeadType.localeCompare(a.LeadType)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "leadstatus-az":
        filteredLeadReports.sort((a, b) => a.Status.localeCompare(b.Status));
        setRefreshTable((prev) => !prev);
        break;

      case "leadstatus-za":
        filteredLeadReports.sort((a, b) => b.Status.localeCompare(a.Status));
        setRefreshTable((prev) => !prev);
        break;

      case "leadate-az": // Oldest to Latest
      filteredLeadReports.sort(
          (a, b) => new Date(a.LeadDate) - new Date(b.LeadDate)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "leadate-za": // Latest to Oldest
      filteredLeadReports.sort(
          (a, b) => new Date(b.LeadDate) - new Date(a.LeadDate)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "source-az":
        filteredLeadReports.sort((a, b) => a.Source.localeCompare(b.Source));
        setRefreshTable((prev) => !prev);
        break;

      case "source-za":
        filteredLeadReports.sort((a, b) => b.Source.localeCompare(a.Source));
        setRefreshTable((prev) => !prev);
        break;

      case "followupdate-az": // Oldest to Latest, N/A at end
      filteredLeadReports.sort((a, b) => {
          if (!a.FollowupDate) return 1; // Move N/A to the end
          if (!b.FollowupDate) return -1;
          return new Date(a.FollowupDate) - new Date(b.FollowupDate);
        });
        setRefreshTable((prev) => !prev);
        break;

      case "followupdate-za": // Latest to Oldest, N/A at end
      filteredLeadReports.sort((a, b) => {
          if (!a.FollowupDate) return 1; // Move N/A to the end
          if (!b.FollowupDate) return -1;
          return new Date(b.FollowupDate) - new Date(a.FollowupDate);
        });
        setRefreshTable((prev) => !prev);
        break;

      default:
        console.error("Invalid Order");
    }
  };

  const numericSort = (order) => {
    // const sortedReports = [...filteredLeadReports];

    order === "az"
      ? filteredLeadReports.sort(
          (a, b) => Number(a.QuotedValue) - Number(b.QuotedValue)
        )
      : filteredLeadReports.sort(
          (a, b) => Number(b.QuotedValue) - Number(a.QuotedValue)
        );

    setRefreshTable((prev) => !prev);
  };

  return (
    <div className=" bg-[#f3f8f8]">
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
      />
      <L3Sidebar />
     
      <div className=" ml-[18%] p-4 relative  scroll-visible justify-center top-0">
        <div className="flex-col p-">
          <div className="flex bg-white p-2 rounded-md justify-between align-middle items-center">
            <h1 className="text-xl w-4/5 font-semibold">All Lead Reports</h1>
            <div className="relative cursor-pointer bg-transparent">
              <FaUserCircle onClick={logout} className="text-black w-6 h-6" />
              {showLogout && (
                <div
                  ref={popupRef}
                  className="absolute right-0 top-full mt-1 text-black text-center px-2 w-36 shadow-2xl overflow-hidden bg-white border border-gray-400 z-50 rounded-lg"
                >
                  <h1 className="px-6 py-2 rounded-md my-1">{names}</h1>
                  <h1
                    onClick={logoutUser}
                    className="flex items-center gap-2 px-6 py-2 rounded-md my-1 cursor-pointer hover:bg-black hover:text-white"
                  >
                    <MdLogout size={24} /> Logout
                  </h1>
                </div>
              )}
            </div>
          </div>

          <div className="flex gap-1 justify-between bg-white mt-2 rounded-md p-1">
            <div className="flex gap-3 p-1">
              <div className="flex gap-2 justify-center align-middle items-center">
                <label
                  htmlFor="fromDate"
                  className="block text-sm font-semibold text-gray-700 "
                >
                  From Date
                </label>
                <DatePicker
                  selected={dateRange.fromDate}
                  onChange={handleFromDateChange}
                  dateFormat="MM-dd-yyyy"
                  className="w-24 outline-none date-picker-placeholder items-center justify-between border border-[#e0e7ff] rounded-md p-2 text-xs"
                  placeholderText="MM-DD-YYYY"
                  maxDate={new Date()}
                />
              </div>

              <div className="flex gap-2 justify-center align-middle items-center">
                <label
                  htmlFor="toDate"
                  className="block text-sm font-semibold text-gray-700 text-nowrap"
                >
                  To Date
                </label>
                <div className="w-full">
                  <DatePicker
                    selected={dateRange.toDate}
                    onChange={handleToDateChange}
                    dateFormat="MM-dd-yyyy"
                    className="w-24 outline-none date-picker-placeholder items-center justify-between border border-[#e0e7ff] rounded-md p-2 text-xs"
                    placeholderText="MM-DD-YYYY"
                    minDate={
                      dateRange.fromDate
                        ? new Date(dateRange.fromDate.getTime() + 86400000)
                        : null
                    }
                    maxDate={new Date()} // Restrict future dates
                  />
                </div>
              </div>
              <div
                className="relative flex gap-2 justify-center align-middle items-center"
                ref={dropdownRef}
              >
                <label
                  htmlFor="selectedStatus"
                  className="block text-sm font-semibold text-gray-700 "
                >
                  Status
                </label>
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsDropdownOpen(!isDropdownOpen);
                  }}
                  className="w-24 relative outline-none date-picker-placeholder flex text-nowrap overflow-hidden justify-start border border-[#e0e7ff] rounded-md p-2 text-xs"
                >
                  <ExpandMoreIcon
                    color="white"
                    className="absolute cursor-pointer right-1 top-1 bg-white"
                  />
                  {selectedStatusesString || "All"}
                </button>

                {isDropdownOpen && (
                  <div className="absolute left-0 top-full w-full text-xs border border-gray-400 bg-white shadow-md rounded-md z-50">
                    {sortedStatuses.map((statuse) => (
          
                      <div
                        key={statuse.id}
                        className="flex items-center p-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() =>
                          handleCheckboxChange({
                            target: { value: statuse.status },
                          })
                        } //  Select checkbox on div click
                      >
                        <input
                          type="checkbox"
                          id={statuse.id}
                          value={statuse.status}
                          onChange={handleCheckboxChange}
                          checked={selectedStatuses.includes(statuse.status)}
                          className="mr-2 cursor-pointer"
                          onClick={(e) => e.stopPropagation()} //  Prevent double triggering
                        />
                        <label htmlFor={statuse.id} className="cursor-pointer">
                          {statuse.status}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div
                className="relative flex gap-2 justify-center align-middle items-center"
                ref={groupdownRef}
              >
                <label
                  htmlFor="selectedStatus"
                  className="block text-sm font-semibold text-gray-700 "
                >
                  Group
                </label>

                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsGroupOpen(!isGroupOpen);
                  }}
                  className="w-24 relative outline-none date-picker-placeholder flex justify-start border border-[#e0e7ff] rounded-md p-2 text-xs"
                >
                  <span className="truncate min-w-0 flex-1 text-left">
                    {AssignedGroup}
                  </span>
                  <ExpandMoreIcon className="absolute cursor-pointer right-1 top-1 bg-white" />
                </button>

                {isGroupOpen && (
                  <div className="absolute left-0 top-full w-full text-xs border overflow-hidden text-wrap border-gray-400 bg-white shadow-md rounded-md z-50">
                    <div
                      className={`flex items-center p-2 hover:bg-gray-200 cursor-pointer ${
                        AssignedGroup === "All" ? "bg-gray-300" : ""
                      }`}
                      onClick={() => {
                        handleAssignedGroupChange("All"); // Select All
                      }}
                    >
                      <label>All Groups</label>
                    </div>

                    {GroupNames.map((group) => (
                      <div
                        key={group.id}
                        className={`flex items-center p-2 hover:bg-gray-200 cursor-pointer ${
                          AssignedGroup === group
                            ? "bg-gray-300"
                            : ""
                        }`}
                        onClick={() => {
                          handleAssignedGroupChange(group); // Select individual group
                        }}
                      >
                        <label>{group}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className=" w-16 flex align-middle justify-center">
                <label
                  htmlFor="selectedStatus"
                  className="block text-gray-700"
                ></label>
                <button
                  type="button"
                  onClick={ResetSearch}
                  className="w-24 relative outline-none date-picker-placeholder flex justify-start border border-[#e0e7ff] rounded-md p-2 text-xs"
                >
                  Reset{" "}
                </button>
              </div>
            </div>

            <div className="flex justify-end items-center ">
      
              <div className="flex gap-2 p-1 align-middle items-center bg-[#e0e7ff] rounded-full">
                <MdSearch className=" w-5 h-5 text-gray-500" />
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  placeholder="Search"
                  className="bg-[#e0e7ff] outline-none w-[80%]"
                />
              </div>
            </div>
          </div>

          <br />
          <div className="max-h-[580px]  overflow-auto -mt-3 ">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-black sticky top-0">
                <tr className="text-white">
                  <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                    #
                  </th>
                  <th className="px-8 w-24 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                    <div className="flex items-center justify-center gap-x-4">
                      Lead Date
                      <div className="flex flex-col gap-y-1">
                        <div
                          className="cursor-pointer "
                          onClick={() => sortAlphabetically("leadate", "az")}
                        >
                          <ArrowDropUp
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>

                        <div
                          className="cursor-pointer -mt-2"
                          onClick={() => sortAlphabetically("leadate", "za")}
                        >
                          <ArrowDropDown
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                    <div className="flex items-center justify-center gap-x-4">
                      Client Name
                      <div className="flex flex-col gap-y-1">
                        <div
                          className="cursor-pointer "
                          onClick={() => sortAlphabetically("client", "az")}
                        >
                          <ArrowDropUp
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>

                        <div
                          className="cursor-pointer -mt-2"
                          onClick={() => sortAlphabetically("client", "za")}
                        >
                          <ArrowDropDown
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                    <div className="flex items-center justify-center gap-x-4">
                      Project Name
                      <div className="flex flex-col gap-y-1">
                        <div
                          className="cursor-pointer"
                          onClick={() => sortAlphabetically("project", "az")}
                        >
                          <ArrowDropUp
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>

                        <div
                          className="cursor-pointer -mt-2"
                          onClick={() => sortAlphabetically("project", "za")}
                        >
                          <ArrowDropDown
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                    <div className="flex items-center justify-center gap-x-4">
                      Lead type
                      <div className="flex flex-col gap-y-1">
                        <div
                          className="cursor-pointer "
                          onClick={() => sortAlphabetically("leadtype", "az")}
                        >
                          <ArrowDropUp
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>

                        <div
                          className="cursor-pointer -mt-2"
                          onClick={() => sortAlphabetically("leadtype", "za")}
                        >
                          <ArrowDropDown
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                    <div className="flex items-center justify-center">
                      Quoted Value
                      <div className="flex flex-col gap-y-1">
                        <div
                          className="cursor-pointer"
                          onClick={() => numericSort("za")}
                        >
                          <ArrowDropUp
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>

                        <div
                          className="cursor-pointer -mt-2"
                          onClick={() => numericSort("az")}
                        >
                          <ArrowDropDown
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                    <div className="flex items-center justify-center gap-x-4">
                      Status
                      <div className="flex flex-col gap-y-1">
                        <div
                          className="cursor-pointer "
                          onClick={() => sortAlphabetically("leadstatus", "az")}
                        >
                          <ArrowDropUp
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>

                        <div
                          className="cursor-pointer -mt-2"
                          onClick={() => sortAlphabetically("leadstatus", "za")}
                        >
                          <ArrowDropDown
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                    <div className="flex items-center justify-center">
                      Source
                      <div className="flex flex-col gap-y-1">
                        <div
                          className="cursor-pointer"
                          onClick={() => sortAlphabetically("source", "az")}
                        >
                          <ArrowDropUp
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>

                        <div
                          className="cursor-pointer -mt-2"
                          onClick={() => sortAlphabetically("source", "za")}
                        >
                          <ArrowDropDown
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                    <div className="flex items-center justify-center">
                      Group
                      <div className="flex flex-col gap-y-1">
                        <div
                          className="cursor-pointer"
                          onClick={() => sortAlphabetically("business", "az")}
                        >
                          <ArrowDropUp
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>

                        <div
                          className="cursor-pointer -mt-2"
                          onClick={() => sortAlphabetically("business", "za")}
                        >
                          <ArrowDropDown
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </th>

                  <th className="px-6 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                    <div className="flex items-center justify-center">
                      Follow-up Date
                      <div className="flex flex-col gap-y-1">
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            sortAlphabetically("followupdate", "az")
                          }
                        >
                          <ArrowDropUp
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>

                        <div
                          className="cursor-pointer -mt-2"
                          onClick={() =>
                            sortAlphabetically("followupdate", "za")
                          }
                        >
                          <ArrowDropDown
                            color="white"
                            sx={{ fontSize: "22px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                  <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                    Attachments
                  </th>
                  <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                    Edit
                  </th>
                  <th className="px-8 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                    <FaTrash size={18} />
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentData.length > 0 ? (
                  currentData.map((LeadReport, index) => (
                    <tr className="border text-sm" key={LeadReport.id}>
                      <td className="px-3 text-center py-2">
                        {index + 1 + (currentPage - 1) * 10}
                      </td>
                      <td className="px-3 text-center py-2">
                        {formatDate(LeadReport.LeadDate)}
                      </td>
                      <td className="px-3 text-center py-2">
                        {LeadReport.ClientName}
                      </td>
                      <td className="px-3 text-center py-2">
                        {LeadReport.ProjectName}
                      </td>
                      <td className="px-3 text-center py-2">
                        {LeadReport.LeadType}
                      </td>
                      <td className="px-3 text-center py-2">
                        $
                        {LeadReport.QuotedValue.toString().replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </td>
                      <td className="px-3 text-center py-2">
                        {LeadReport.Status}
                      </td>
                      <td className="px-3 text-center py-2">
                        {LeadReport.Source}
                      </td>
                      <td className="px-3 text-center py-2">
                        {LeadReport.AssignedGroup}
                      </td>
                      <td className="px-3 text-center py-3">
                        {LeadReport.Status === "Won" ||
                        LeadReport.Status === "Lost"
                          ? "N/A"
                          : formatDate(LeadReport.FollowupDate)}
                      </td>

                      <td className="px-3 relative  py-3">
                   
                        <AttachFileIcon
                          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-12 cursor-pointer"
                          onClick={() => handleCombineAction(LeadReport.id)}
                        />

                        {popupVisibleId === LeadReport.id && (
                          <div
                            id="file-upload-popup"
                            className="fixed inset-0 flex justify-center items-center z-50 bg-opacity-50 bg-black"
                          >
                            <div className="max-w-5xl bg-white shadow-lg rounded-lg p-6">
                              <div className="flex justify-between items-center mb-6">
                                <h2 className="text-lg font-bold">
                                  Attachments
                                </h2>
                                <button
                                  className="text-gray-500 hover:text-black"
                                  onClick={() => togglePopup(LeadReport.id)} // Close popup
                                >
                                  Close
                                </button>
                              </div>

                              {/* File Upload Section */}
                              <div className="flex align-middle items-center py-3 gap-4">
                                {uploadLoader ? (
                                  <Loader isLoading={uploadLoader} />
                                ) : (
                                  <div className="flex items-center">
                                    <input
                                      type="file"
                                      id={`file-upload-${LeadReport.id}`}
                                      className="hidden"
                                      onChange={(e) =>
                                        handleFileUpload(e, LeadReport.id)
                                      } // Use your predefined function
                                    />
                                    <label
                                      htmlFor={`file-upload-${LeadReport.id}`}
                                      className="bg-gray-200 text-sm px-4 py-2 rounded-md cursor-pointer hover:bg-gray-300"
                                    >
                                      Upload File
                                    </label>
                                    <span className="">{currentFileName}</span>
                                  </div>
                                )}

                                <div className="flex gap-2 text-red-500">
                                  <h4 className="font-bold text-md">*</h4>
                                  <p>Please Upload .pdf and .docx files only</p>
                                </div>
                              </div>

                              {/* Uploaded Files Table */}
                              <div className="max-h-[20rem] overflow-auto">
                                <table className="w-full text-left border-collapse">
                                  <thead>
                                    <tr className="border-b">
                                      <th className="py-2 px-4">Sr. No.</th>
                                      <th className="py-2 px-4">File Name</th>
                                      <th className="py-2 px-4">Date</th>
                                      <th className="py-2 px-4 text-center">
                                        Time
                                      </th>
                                      <th className="py-2 px-4 text-right">
                                        Download
                                      </th>
                                      <th className="py-2 px-4 text-right">
                                        Delete
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {deleteLoader || downloadLoader ? (
                                      <Loader
                                        isLoading={
                                          deleteLoader || downloadLoader
                                        }
                                      />
                                    ) : (
                                      uploadedFiles
                                        .filter(
                                          (file) =>
                                            file.leadId === LeadReport.id
                                        ) // Filter files for the given Id
                                        .map((file, index) => (
                                          <tr
                                            key={file.fileName}
                                            className="border-b"
                                          >
                                            <td className="py-2 px-4">
                                              {index + 1}
                                            </td>
                                            <td className="py-2 px-4">
                                              {file.fileName}
                                            </td>

                                            <td className="py-2 px-4">
                                              {
                                                extractDateTime(file.uploadDate)
                                                  .dateValue
                                              }
                                            </td>

                                            <td className="py-2 px-4 text-center">
                                              {
                                                extractDateTime(file.uploadDate)
                                                  .timeValue
                                              }
                                            </td>

                                            <td className="py-2 px-4 text-center space-x-2">
                                              <button
                                                className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-700"
                                                onClick={() =>
                                                  downloadFile(
                                                    file.fileName,
                                                    LeadReport.id
                                                  )
                                                } // Use your predefined function
                                              >
                                                <CloudDownloadIcon />
                                              </button>
                                            </td>
                                            <td className="py-2 px-4 text-center space-x-2">
                                              <button
                                                className="bg-red-500 text-white px-2 py-2 rounded hover:bg-red-700"
                                                onClick={() =>
                                                  deleteFile(
                                                    file.fileName,
                                                    LeadReport.id
                                                  )
                                                }
                                              >
                                                <FaTrash size={18} />
                                              </button>
                                            </td>
                                          </tr>
                                        ))
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                      </td>

                      <td
                        onClick={() =>
                          navigate(`/dashboard/L3/edit-lead/${LeadReport.id}`, {
                            state: { from: location.pathname },
                          })
                        }
                        className="px-4 hover:bg-black cursor-pointer hover:text-white text-center py-2"
                      >
                        <MdEditSquare className="w-4 h-4" />
                      </td>
                      <td
                        onClick={() => openDeleteModal(LeadReport.id)}
                        className="px-8 hover:bg-black cursor-pointer hover:text-white text-center py-2"
                      >
                        <FaTrash className="w-4 h-4" />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      className="border p-4 text-start text-red-500"
                    >
                      {searchQuery
                        ? `No results found for "${searchQuery}"`
                        : "No results found"}{" "}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="pagination mt-4 flex justify-between items-center bg-white rounded-md p-1">
            <div>
              {totalItems > 0 ? (
                <>
                  Showing {startIdx + 1} to {Math.min(endIdx, totalItems)} of{" "}
                  {totalItems} Entries
                </>
              ) : (
                <>Showing 0 Entries</>
              )}
            </div>
            {totalPages > 1 && (
              <div>
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  className="px-4 py-2 mx-2 border rounded"
                  disabled={currentPage === 1}
                >
                  Previous
                </button>

                {Array.from({ length: 3 }, (_, index) => {
                  // Calculate the page number for the current range
                  const pageNumber = currentPage - 1 + index;

                  // Ensure the pageNumber stays within bounds
                  if (pageNumber >= 1 && pageNumber <= totalPages) {
                    return (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={`px-4 py-2 mx-2 border rounded ${
                          currentPage === pageNumber ? "bg-gray-300" : ""
                        }`}
                      >
                        {pageNumber}
                      </button>
                    );
                  }

                  // Return null for out-of-bounds values
                  return null;
                })}

                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  className="px-4 py-2 mx-2 border rounded"
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default LeadReport;
