import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";

// Async Thunk to Fetch Client Rating Data
export const fetchClientRatingData = createAsyncThunk("clientRating/fetch",async ({ AssignedClient, AssignedGroup }, { rejectWithValue }) => {
  console.log("this is the data in group & client==",AssignedClient) 
  try {
      const response = await axiosInstance.get(`/clientRating/${AssignedClient}/${AssignedGroup}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch data");
    }
  }
);

// Async Thunk to Update Client Rating Data
export const updateClientRatingData = createAsyncThunk("clientRating/update",async (ratings, { rejectWithValue }) => {
  console.log("this is therating==>",ratings)
    try {
      const response = await axiosInstance.put("/clientRating", ratings);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to update data");
    }
  }
);


export const getClientRatingData = createAsyncThunk("clientRating/get",async ({ clientName, groupName }, { rejectWithValue }) => {
  try {
      const response = await axiosInstance.get(`/clientRating/${clientName}/${groupName}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch data");
    }
  }
);
export const getsingleClientRatingData = createAsyncThunk("clientRating/fetched",async ( clientName , { rejectWithValue }) => {
  try {
      const response = await axiosInstance.get(`/clientRating/getclient/${clientName}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch data");
    }
  }
);

// Slice
const clientRatingSlice = createSlice({
  name: "clientRating",
  initialState: {
    ratingData: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Client Rating Data
      .addCase(fetchClientRatingData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClientRatingData.fulfilled, (state, action) => {
        state.loading = false;
        state.ratingData = action.payload;
      })
      .addCase(fetchClientRatingData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update Client Rating Data
      .addCase(updateClientRatingData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateClientRatingData.fulfilled, (state, action) => {
        state.loading = false;
        state.ratingData = action.payload;
      })
      .addCase(updateClientRatingData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getClientRatingData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getClientRatingData.fulfilled, (state, action) => {
        state.loading = false;
        state.ratingData = action.payload;
      })
      .addCase(getClientRatingData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getsingleClientRatingData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getsingleClientRatingData.fulfilled, (state, action) => {
        state.loading = false;
        state.ratingData = action.payload;
      })
      .addCase(getsingleClientRatingData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default clientRatingSlice.reducer;
