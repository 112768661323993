import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { FaUserCircle, FaTrash } from "react-icons/fa";
import { SiPivotaltracker } from "react-icons/si";
import { MdLogout, MdEditSquare, MdSearch } from "react-icons/md";

import loram from "../Assets/loram.png";
import Q from ".././Assets/finallogo.png"
import ConfirmationModal from '../pages/L1/ConfirmationModal';
import AdminLoader from "../loader/AdminLoader"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { logoutSession } from "../../redux/slices/sessionSlice";
import { fetchGroups } from "../../redux/slices/groupSlice";
import { addEmployee, deleteEmployee, fetchEmployees, updateEmployee } from "../../redux/slices/employeeAdminSlice";


const Admin = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showLogout, setshowlogout] = useState(false);
  const [showleveldown, setShowLeveldown] = useState(false);
  const [GroupNames, setGroupNames] = useState([]);


  const {group} = useSelector((state)=> state.group)

  useEffect(()=>{
dispatch(fetchGroups())
  },[dispatch])
  
  useEffect(()=>{
setGroupNames(group)
  },[group])

  const [isLoading, SetisLoading] = useState(false);
  const dropdownRefGroup = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefGroup.current &&
        !dropdownRefGroup.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const dropdownRefLevel = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefLevel.current &&
        !dropdownRefLevel.current.contains(event.target)
      ) {
        setShowLeveldown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
 

  const logout = (e) => {
    e.preventDefault();
    setshowlogout(!showLogout);
  };
  const logoutUser = (e) => {
    e.preventDefault();
    dispatch(logoutSession());
    enqueueSnackbar("Successfully Logged out!", { variant: "success",autoHideDuration: 1000, });
    navigate("/");
  };

  const [EmpFirstName, setEmpFirstName] = useState("");
  const [EmpLastName, setEmpLastName] = useState("");
  const [EmpPhone, setEmpPhone] = useState("");
  const [EmpEmail, setEmpEmail] = useState("");
  const [EmpAuthLevel, setEmpAuthLevel] = useState("");
  const [EmpGroups, setEmpGroups] = useState("");
  const [, SetDepartment] = useState("");
  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setshowlogout(false); // Close the popup if clicked outside
    }
  };
  useEffect(() => {
    if (showLogout) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showLogout]);

  const [editingEmployee, setEditingEmployee] = useState(null);
  const [showTable, setShowTable] = useState(true);

  const editEmployee = (id) => {
    const employeeToEdit = employeesList.find((employee) => employee.id === id);
    setEditingEmployee(employeeToEdit);
    setShowTable(false);
    // Ensure EmpGroups is set to employee's existing groups
    if (employeeToEdit && employeeToEdit.groups) {
      setEmpGroups(employeeToEdit.groups);
      setShowTable(false);
    } else {
      setEmpGroups([]); // Reset if no groups
    }
  };

  useEffect(() => {
    if (editingEmployee) {
      setEmpFirstName(editingEmployee.EmpFirstName);
      setEmpLastName(editingEmployee.EmpLastName);
      setEmpPhone(editingEmployee.EmpPhone);
      setEmpEmail(editingEmployee.EmpEmail);
      setEmpAuthLevel(editingEmployee.EmpAuthLevel);
      setEmpGroups(editingEmployee.EmpGroups);
      SetDepartment(editingEmployee.department)
    }
  }, [editingEmployee]);

// Function to reset form fields
const resetForm = () => {
  setEmpFirstName("");
  setEmpLastName("");
  setEmpPhone("");
  setEmpEmail("");
  setEmpAuthLevel("");
  setEmpGroups([]);
};

const employees = useSelector((state) => state.employees.employees);

useEffect(()=>{
dispatch(fetchEmployees())
},[dispatch])


const [employeesList, setEmployeesList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  useEffect(() => {
if(employees){
  setEmployeesList(employees);
  setFilteredEmployees(employees);
}    
  }, [employees]);


  const Submit = async (e) => {
    e.preventDefault();
  
    if (!EmpFirstName || !EmpLastName || !EmpPhone || !EmpEmail || !EmpAuthLevel) {
      enqueueSnackbar("Please fill all the fields!", { variant: "error" });
      return;
    }
  
    if (EmpAuthLevel === "L3" && (!EmpGroups || EmpGroups.length === 0)) {
      enqueueSnackbar(" Please select at least one group for L3 employee!", { variant: "error" });
      return;
    }
  
    try {
      SetisLoading(true);
      
      if (!editingEmployee) {
        // Dispatch addEmployee action
        await dispatch(addEmployee({
          EmpFirstName,
          EmpLastName,
          EmpPhone,
          EmpEmail,
          EmpAuthLevel,
          EmpGroups,
        })).unwrap(); // Ensure we catch errors properly
  
        enqueueSnackbar("Employee Added Successfully!", { variant: "success" });
        dispatch(fetchEmployees())
        
      } else {
        // Dispatch updateEmployee action
        await dispatch(updateEmployee({
          id: editingEmployee.id,
          EmpFirstName,
          EmpLastName,
          EmpPhone,
          EmpEmail,
          EmpAuthLevel,
          EmpGroups,
        })).unwrap();
  
        enqueueSnackbar("Employee Details Updated Successfully!", { variant: "success" });
        setShowTable(true);
        setEditingEmployee(null);
      }
  
      // Refresh data and reset form
      dispatch(fetchEmployees())
      resetForm();
    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar(error.message || "Connection Error!", { variant: "error" });
    } finally {
      SetisLoading(false);
  };
    
  };

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsEditing(!!editingEmployee);
  }, [editingEmployee]);

  const Cancel = (e) => {
    e.preventDefault();
  
    setEmpFirstName("");
    setEmpLastName("");
    setEmpPhone("");
    setEmpEmail("");
    setEmpAuthLevel("");
    setEmpGroups([]); // Use an empty array for multiple selections
    SetDepartment("");
    setShowTable(true);
    setIsEditing(false);
   // Fetch Updated Employee Data
    dispatch(fetchEmployees())
   
  };
  

  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  
    const [itemsPerPage] = useState(10);
  
    const totalItems = filteredEmployees.length;
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
  
    // Function to calculate the number of pages
    const totalPages = Math.ceil(filteredEmployees.length / itemsPerPage);
  
    // Slice the filteredLeadReport based on the current page
    const currentData = filteredEmployees.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
    filterEmployees(e.target.value);
    setCurrentPage(1);
  };

  const filterEmployees = (query) => {
    const filtered = employeesList.filter((employee) => {
      return (
        (employee.EmpFirstName?.toLowerCase() || "").includes(query.toLowerCase()) ||
        (employee.EmpLastName?.toLowerCase() || "").includes(query.toLowerCase()) ||
        (employee.EmpPhone || "").includes(query) ||
        (employee.EmpEmail?.toLowerCase() || "").includes(query.toLowerCase()) ||
        (employee.EmpAuthLevel?.toLowerCase() || "").includes(query.toLowerCase()) ||
        (employee.EmpGroups || "").includes(query)
      );
    });
  
    setFilteredEmployees(filtered);
  };
  

  const handleDeleteEmployee = async (id) => {
    SetisLoading(true);
    try {
      // Dispatch Redux action
      await dispatch(deleteEmployee(id)).unwrap();
  
      enqueueSnackbar("Employee deleted successfully!", { variant: "success" });
  
      // Refresh data to update UI
      dispatch(fetchEmployees())
    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar("Failed to delete employee!", { variant: "error" });
    } finally {
      SetisLoading(false);
    }
  };

  const dash = (e) => {
    e.preventDefault();
    navigate("/dashboard/L1");
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [EmployeeToDelete, setEmployeeToDelete] = useState(null);
  const openDeleteModal = (id) => {
    setEmployeeToDelete(id);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setEmployeeToDelete(null);
  };

  const confirmDelete = async () => {
    if (EmployeeToDelete) {
      await handleDeleteEmployee(EmployeeToDelete);  // Call your existing delete function
    }
    closeDeleteModal();
  };


  // const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  // const handlelevelChange = (level) => {
  //   setEmpAuthLevel(level); // Close the popup if clicked outside
  //   setShowLeveldown(false);
  // };
  const handlelevelChange = (level) => {
    setEmpAuthLevel(level); // Update the selected level

    // Ensure dropdown closes after selection
    setShowLeveldown(false);

    // Reset groups if switching away from L3
    if (level !== "L3") {
      setEmpGroups([]);
      setShowDropdown(false);
    }
  };

  return (
    <>
      <div className="relative">
        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={closeDeleteModal}
          onConfirm={confirmDelete}
        />


        <div
          className="fixed z-20 flex shadow-2xl overflow-hidden flex-col min-h-screen w-1/6 bg-white "
          style={{ width: "17%" }}>
          <div className="flex justify-center items-center align-middle w-full ">
            <div className="w-full flex justify-center align-middle items-center p-2">
              <img src={Q} alt="Q-Trackr Logo" className=' w-full ' />


              {/* <img src={QTracker} alt="Q-Trackr Logo" className='w-24 h-8 ' /> */}

            </div>



          </div>
          <hr /><hr />
          <h1
            className="flex items-center bg-[#f9faff] border-b-2 border-[#ecefff] justify-start pl-5  p-2 cursor-pointer  rounded-md ">
            <SiPivotaltracker size={24} />
            &nbsp;Manager Users
          </h1>
          <h1
            onClick={dash}
            className="flex items-center bg-[#f9faff] border-b-2 border-[#ecefff] justify-start pl-5 p-2 cursor-pointer  rounded-md ">

            <SiPivotaltracker size={24} />
            &nbsp;View Dashboard
          </h1>
          <img className="p-4 mt-auto bg-white" src={loram} alt="Loram Logo" />
        </div>
        <div className=" flex justify-end  overflow-hidden z-10 py-3 w-full text-white bg-white ">
          {/* <div className="cursor-pointer mr-4 bg-black">
          <FaUserCircle onClick={logout} size={28} />
        </div> */}

          <div className='cursor-pointer mr-[2%] bg-transparent'>
            <FaUserCircle onClick={logout} size={28} className='text-black' />
          </div>

          {showLogout && (
            <>
              <div ref={popupRef}
                className="fixed text-black text-center px-2 w-1/12 shadow-2xl overflow-hidden bg-white border border-gray-400 mr-1 mt-10 z-50 justify-end rounded-lg">
                <h1 className="px-6 py-2 rounded-md my-1">Admin</h1>
                <h1
                  onClick={logoutUser}
                  className="flex px-6 py-2 rounded-md my-1 cursor-pointer hover:bg-black hover:text-white"
                >
                  <MdLogout size={24} /> Logout
                </h1>
              </div>
            </>
          )}
        </div>
        <div className="ml-[21%] p-4 bg-white  border border-gray-200 relative shadow-lg w-3/4 justify-center top-0">
          {isLoading ? (
            <AdminLoader isLoading={isLoading} />
          ) : (
            <>

              <h1 className="text-2xl mb-2 font-semibold">
                {isEditing ? "Edit Employee Details" : "Add Employee"}
              </h1>
              <hr />
              <hr />
              <br />
              <div className="flex justify-start w-full">
                <div className="mx-4 w-1/3">
                  <label className="block" htmlFor="Name">
                    First Name:
                  </label>
                  <input
                    className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                    type="text"
                    placeholder="Enter First Name"
                    value={EmpFirstName}
                    onChange={(e) => setEmpFirstName(e.target.value)}
                  />
                </div>
                <div className="mx-4 w-1/3">
                  <label className="block" htmlFor="Name">
                    Last Name:
                  </label>
                  <input
                    className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                    type="text"
                    placeholder="Enter Last Name"
                    value={EmpLastName}
                    onChange={(e) => setEmpLastName(e.target.value)}
                  />
                </div>
                <div className="mx-4 w-1/3">
                  <label className="block" htmlFor="Name">
                    Mobile Number:
                  </label>
                  <PhoneInput
                    country={"us"}
                    value={EmpPhone}
                    onChange={(phone) => {
                      setEmpPhone(phone); // Ensure value is updated properly
                    }}
                      inputProps={{
                      required: true,
                      className:
                        "w-full border border-gray-400 lg:pl-14 cp:pl-7 outline-0 rounded overflow-hidden shadow-md h-10 p-2",
                      style: {
                        fontFamily:
                          "'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",
                      },
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="flex justify-start w-full">
                <div className="mx-4 w-1/3">
                  <label className="block" htmlFor="email">
                    Email ID:
                  </label>
                  <input
                    className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                    type="email"
                    placeholder="Enter Email ID"
                    value={EmpEmail}
                    onChange={(e) => setEmpEmail(e.target.value)}
                  />
                </div>
                <div className="mx-4 w-1/3">
                  <label className="block" htmlFor="Name">
                    Authorization Level:
                  </label>

                  <div className=" ">
                    <div
                      ref={dropdownRefLevel}
                      className="relative flex shadow-sm p-0 -pb-4 rounded-md border border-gray-300 align-middle items-center"
                    >
                      <ExpandMoreIcon
                        color="white"
                        className="absolute cursor-pointer right-0 m-auto bg-white"
                        onClick={() => setShowLeveldown(!showleveldown)}
                      />

                      <button
                        type="button"
                        onClick={() => setShowLeveldown(!showleveldown)}
                        className="border border-gray-300 text-left text-nowrap overflow-hidden rounded-md shadow-md outline-none p-2 w-full"

                      >
                        {EmpAuthLevel || "Select Authorization Level"}
                      </button>

                      {showleveldown && (

                        <div
                          style={{ zIndex: 9999 }}
                          className="absolute top-full p-1 left-0 w-full cursor-pointer max-h-96 overflow-y-auto bg-gray-100 text-sm border border-gray-300 shadow-lg flex flex-col gap-2"
                        >

                          <div className="" onClick={() =>
                            handlelevelChange("L1")
                          }>L1</div>
                          <div onClick={() =>
                            handlelevelChange("L2")
                          }>L2</div>
                          <div onClick={() =>
                            handlelevelChange("L3")
                          }>L3</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mx-4 w-1/3">
                  {EmpAuthLevel === 'L3' && (
                    <div className="w-full">
                      <label className="block" htmlFor="auth">Assign Group(s):</label>
                    

                      <div className=" ">
                        <div
                          ref={dropdownRefGroup}
                          className="relative flex shadow-sm p-0 -pb-4 rounded-md border border-gray-300 align-middle items-center"
                        >
                          <ExpandMoreIcon
                            color="white"
                            className="absolute cursor-pointer right-0 m-auto bg-white"
                            onClick={() => setShowDropdown(!showDropdown)}
                          />

                          <button
                            type="button"
                            onClick={() => setShowDropdown(!showDropdown)}
                            className="border border-gray-300 text-left text-nowrap overflow-hidden rounded-md shadow-md outline-none p-2 w-full"

                          >
                            {EmpGroups.length > 0
                              ? EmpGroups.join(", ") // Display selected groups
                              : "Select Group(s)"}
                          </button>

                          {showDropdown && (

                            <div
                              style={{ zIndex: 9999 }}
                              className="absolute top-full left-0 w-full cursor-pointer max-h-96 overflow-y-auto bg-gray-100 text-sm border border-gray-300 shadow-lg flex flex-col gap-2"
                            >

                              {GroupNames.map((group) => (
                                <label
                                  key={group.id}
                                  className="flex items-center px-2 py-1 hover:bg-gray-100 cursor-pointer"
                                >
                                  <input
                                    type="checkbox"
                                    value={group.newGroupName}
                                    checked={EmpGroups.includes(group.newGroupName)}
                                    onChange={(e) => {
                                      const selectedValue = e.target.value;
                                      if (e.target.checked) {
                                        setEmpGroups((prev) => [...prev, selectedValue]);
                                      } else {
                                        setEmpGroups((prev) =>
                                          prev.filter((group) => group !== selectedValue)
                                        );
                                      }
                                    }}
                                    className="mr-2"
                                  />
                                  {group.newGroupName}

                                </label>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>

                    </div>
                  )}
                </div>
              </div>
              <div className="flex mt-8 ml-3 justify-start">
                <button
                  onClick={Submit}
                  className="mx-2 py-2 px-5 bg-[#e32278] hover:bg-[#e12298]   overflow-hidden shadow-md text-white rounded-md"
                >
                  {isEditing ? "Update" : "Submit"}
                </button>
                <button
                  onClick={Cancel}
                  className="mx-2 py-2 px-5 bg-black hover:bg-white hover:text-black border border-black overflow-hidden shadow-md text-white rounded-md"
                >
                  Cancel
                </button>
              </div>
            </>
          )}
          <br />
        </div>

        <br />
        <br />
        {isLoading ? (
          <AdminLoader isLoading={isLoading} />
        ) : (

          <>
            {showTable && (
              <div className="ml-[21%] p-4 bg-white  border border-gray-200 relative overflow-hidden shadow-lg w-3/4 justify-center mt-8">
                <div className="flex">
                  <h1 className="text-2xl w-4/5 mb-2 font-semibold">Employee List</h1>
                 
                   <div className="relative flex w-1/5 justify-end">
                                    <input
                                      type="text"
                                      value={searchQuery}
                                      onChange={handleSearchInputChange}
                                      className="border  w-full border-gray-300 rounded-md mb-3 p-2 overflow-hidden shadow-md outline-none "
                                      placeholder="Search"
                                    />
                                    <MdSearch
                                      size={30}
                                      className="mt-2 absolute right-1 top-3 transform -translate-y-1/2 text-gray-500"
                                    />
                                  </div>
                </div>
                <hr />
                <hr />
                <br />
                <div className="max-h-[550px] overflow-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-black sticky top-0">
                      <tr className="text-white">
                        <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                          #
                        </th>
                        <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                          First Name
                        </th>
                        <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                          Last Name
                        </th>
                        <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                          Phone
                        </th>
                        <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                          Email
                        </th>
                        <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                          Auth Level
                        </th>
                        <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                          Group
                        </th>
                        <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                          Edit
                        </th>
                        <th className="px-6 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                          <FaTrash size={18} />
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      { currentData.length > 0 ? (
                      currentData.map((employee, index) => (
                        <tr className="border" key={employee.id}>
                          <td className="px-6 text-center py-2">{ startIdx + index + 1}</td>
                          <td className="px-6 text-center py-2">{employee.EmpFirstName}  </td>
                          <td className="px-6 text-center py-2"> {employee.EmpLastName} </td>
                          <td className="px-6 text-center py-2">{employee.EmpPhone}</td>
                          <td className="px-6 text-center py-2">{employee.EmpEmail}</td>
                          <td className="px-6 text-center py-2">
                            {employee.EmpAuthLevel}
                          </td>
                          <td className="px-6 text-center py-2">
                            {employee.EmpGroups}
                          </td>
                          <td
                            onClick={() => editEmployee(employee.id)}
                            className="px-6 hover:bg-black cursor-pointer hover:text-white text-center py-2"
                          >
                            <MdEditSquare size={20} />
                          </td>
                          <td
                            onClick={() => openDeleteModal(employee.id)}
                            className="px-6 hover:bg-black cursor-pointer hover:text-white text-center py-2"
                          >
                            <FaTrash size={18} />
                          </td>
                        </tr>
                      ))
                      ) : (
                        <tr>
                        <td
                          colSpan="6"
                          className="border p-4 text-start text-red-500"
                        >
                          {searchQuery ?  `No result found for "${searchQuery}"`: "No result found"}
                        </td>
                      </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="pagination mt-4 flex justify-between items-center">
          <div>
            {totalItems > 0 ? (
              <>
                Showing {startIdx + 1} to {Math.min(endIdx, totalItems)} of{" "}
                {totalItems} Entries
              </>
            ) : (
              <>Showing 0 Entries</>
            )}
          </div>
          {totalPages > 1 && (
            <div>
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                className="px-4 py-2 mx-2 border rounded"
                disabled={currentPage === 1}
              >
                Previous
              </button>

              {Array.from({ length: 3 }, (_, index) => {
                // Calculate the page number for the current range
                const pageNumber = currentPage - 1 + index;

                // Ensure the pageNumber stays within bounds
                if (pageNumber >= 1 && pageNumber <= totalPages) {
                  return (
                    <button
                      key={pageNumber}
                      onClick={() => setCurrentPage(pageNumber)}
                      className={`px-4 py-2 mx-2 border rounded ${
                        currentPage === pageNumber ? "bg-gray-300" : ""
                      }`}
                    >
                      {pageNumber}
                    </button>
                  );
                }

                // Return null for out-of-bounds values
                return null;
              })}

              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                className="px-4 py-2 mx-2 border rounded"
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          )}
        </div>
              </div>)}
          </>
        )}
        <br />
        <br />
      </div>
    </>
  );
};

export default Admin;