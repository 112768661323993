import React, { useEffect } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import ProtectedRoute from "./protectedRoutes"; // Protect certain routes
import UnAuthorized from "../components/pages/error/Unauthorized";
import NotFoundPage from "../components/pages/error/404";

// Authentication Pages
import Login from "../components/pages/Login";
import Forgotpw from "../components/pages/Forgotpw";
import ResetPassword from "../components/pages/ResetPassword";
import ForgotResetPassword from "../components/pages/ForgotResetpw";

// Admin Dashboard
import Admin from "../components/pages/Admin";

// L1 User Routes
import Dashboard from "../components/pages/Dashboard";
import AddLead from "../components/pages/L1/AddLead";
import LeadReport from "../components/pages/L1/LeadReport";
// import LeadReport11 from "../components/pages/L1/LeadReport11";
import Employees from "../components/pages/L1/Employees";
// import ServiceType from "../components/pages/L1/ServiceType";
// import LeadType from "../components/pages/L1/LeadType";
// import Status from "../components/pages/L1/Status";
// import Source from "../components/pages/L1/Source";
// import AuthGroup from "../components/pages/L1/AuthGroup";
// import ClientMaster from "../components/pages/L1/ClientMaster";
// import AddGroup from "../components/pages/L1/AddGroup";
// import EditLead from "../components/pages/L1/EditLead";
import Departments from "../components/pages/L1/Departments";
// import Roles from "../components/pages/L1/Roles";
import FinancialL1 from "../components/pages/L1/FinancialL1";
import HardwareTrackerL1 from "../components/pages/L1/HardwareTrackerL1";
import InstallationL1 from "../components/pages/L1/InstallationL1";
import ClientRatingL1 from "../components/pages/L1/ClientRatingL1";
import ClientRatingDashboardL1 from "../components/pages/L1/ClientRatingDashboardL1";

// L2 User Routes
import Dashboard1 from "../components/pages/Dashboard1";
import AddLead1 from "../components/pages/L2/AddLead1";
import LeadReport1 from "../components/pages/L2/LeadReport1";
// import LeadReport12 from "../components/pages/L2/LeadReport12";
import EditLead1 from "../components/pages/L2/EditLead1";
import FinancialL2 from "../components/pages/L2/FinancialL2";

// L3 User Routes
import Dashboard2 from "../components/pages/Dashboard2";
import AddLead2 from "../components/pages/L3/AddLead2";
import LeadReport2 from "../components/pages/L3/LeadReport2";
// import LeadReport13 from "../components/pages/L3/LeadReport13";
import EditLead2 from "../components/pages/L3/EditLead2";
import FinancialL3 from "../components/pages/L3/FinancialL3";
import AuthCheck from "./authCheck";
import ServiceType from "../components/pages/L1/ServiceType";
import Status from "../components/pages/L1/Status";
import Source from "../components/pages/L1/Source";
import ClientMaster from "../components/pages/L1/ClientMaster";
import AddGroup from "../components/pages/L1/AddGroup";
import EditLead from "../components/pages/L1/EditLead";
import LeadReport12 from "../components/pages/L2/LeadReport12";
import LeadReportl3 from "../components/pages/L3/LeadReport13";
import LeadType from "../components/pages/L1/LeadType";
import { logoutSession } from "../redux/slices/sessionSlice";
import { useNavigate } from "react-router-dom";
import ClientRatingDashboardL3 from './../components/pages/L3/ClientRatingDashboardL3';
import LeadReport11 from "../components/pages/L1/LeadReport11";


const AppRoutes = () => {
  // const { role: userRoleValue } = useRole();
  const navigate = useNavigate()
  const userRole =  sessionStorage.getItem("userRole");

    useEffect(()=>{
  const token = localStorage.getItem("token")
  if(!token){
    logoutSession()
  }
    },[navigate])

  return (
    <Routes>

      {/* Public Routes */}
      <Route path="/" element={<Outlet />}>
        <Route index element={<Login />} />
        <Route path="/forgotpw" element={<Forgotpw />} />
      </Route>

      <Route path="/setpw" element={<ResetPassword />} />
      <Route path="/resetpw" element={<ForgotResetPassword />} />
      <Route path="/unauthorized" element={<UnAuthorized />} />

      {/* Protected Routes */}
      <Route path="/dashboard" element={<Outlet />}>


<Route index element={<AuthCheck />} />

        <Route
          path="L0"
          element={
            <ProtectedRoute allowedRoles={["L0"]} userRole={userRole}>
              <Admin />
            </ProtectedRoute>
          }
        />

<Route
        path="L1"
        element={
          <ProtectedRoute allowedRoles={["L0", "L1"]} userRole={userRole}>
            <Outlet />
          </ProtectedRoute>
        }
      >
        <Route index element={<Dashboard />} />   
        <Route path="add-lead" element={<AddLead />} />
        <Route path="edit-lead/:id" element={<EditLead />} />
        <Route path="lead-report" element={<LeadReport />} />
        <Route path="leadreport-11" element={<LeadReport11 />} />
        <Route path="employees" element={<Employees />} />
        <Route path="lead-types" element={<LeadType />} />
        <Route path="service-types" element={<ServiceType />} />
        <Route path="status" element={<Status />} />
        <Route path="source" element={<Source />} />
        <Route path="client-master" element={<ClientMaster />} />
        <Route path="add-group" element={<AddGroup />} />

        <Route path="departments" element={<Departments />} />
        <Route path="financial" element={<FinancialL1 />} />
        <Route path="hardware-tracker" element={<HardwareTrackerL1 />} />
        <Route path="installation" element={<InstallationL1 />} />
        <Route path="client-rating" element={<ClientRatingL1 />} />
        <Route path="client-rating/:clientName" element={<ClientRatingDashboardL1 />}/>

      </Route>



      <Route
        path="L2"
        element={
          <ProtectedRoute allowedRoles={["L2"]} userRole={userRole}>
            <Outlet />
          </ProtectedRoute>
        }> 
        <Route index element={<Dashboard1 />} />   
        <Route path="add-lead" element={<AddLead1 />} />
        <Route path="lead-report" element={<LeadReport1 />} />
        <Route path="lead-report12" element={<LeadReport12 />} />
        <Route path="edit-lead/:id" element={<EditLead1 />} />
        <Route path="financial" element={<FinancialL2 />} />

      </Route>

      {/* Sub-Routes for L3 */}
      <Route
        path="L3"
        element={
          <ProtectedRoute allowedRoles={["L3"]} userRole={userRole}>
            < Outlet/>
          </ProtectedRoute>
        }
      >
        <Route index element={< Dashboard2 />} />   
        <Route path="add-lead" element={<AddLead2 />} />
        <Route path="lead-report" element={<LeadReport2 />} />
        <Route path="lead-report13" element={<LeadReportl3 />} />
        <Route path="edit-lead/:id" element={<EditLead2 />} />
        <Route path="client-rating" element={<ClientRatingDashboardL3 />} />

        <Route path="financial" element={<FinancialL3 />} />

      </Route>

      </Route>
    

      {/* 404 Page */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
