import React, { useState, useRef, useEffect, useCallback } from "react";
// import ReactECharts from "echarts-for-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ConversionValidDate } from "../../../Utils/DateUtils";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeads } from "../../../../redux/slices/leadsSlice";
import { fetchEmployeeAuthData } from "../../../../redux/slices/leadsSliceL3";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const ConversionRateL3 = ({newLeadReports}) => {

  const [LeadReports, setLeadReports ]  = useState([]);

  const dispatch = useDispatch();

  const { empGroups } = useSelector((state) => state.leadsL3);
    const { leads } = useSelector((state) => state.leads);

    
  useEffect(() => {  
    dispatch(fetchEmployeeAuthData());
    dispatch(fetchLeads())
  }, [dispatch]);

  useEffect(() => {
    if (leads) {
      const filteredData = leads.filter(lead =>
        empGroups.includes(lead.AssignedGroup)
      );
      setLeadReports(filteredData);
    } else {
      console.error("empGroups not available or leadReports empty");
    }
  }, [empGroups, leads]);
    
  const dropdownRef = useRef(null);
  const MonthdropdownRef = useRef(null);
  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [, setShowMonthDropdown] = useState(false);
  const [, setIsLoading] = useState(true);
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  // const [selectedMonth, setSelectedMonth] = useState([]);
  const [wonLeads, setwonLeads] = useState([]);
  const [filteredWonLeads, setFilteredWonLeads] = useState([]);
  const [wonRecurring, setWonRecurring] = useState([]);
  const [chartData, ] = useState([]);
  // const [arrowIcon, setArrowIcon] = useState(null);
  const [l3Data, setl3Data] = useState([]);

  useEffect(() => {
    setl3Data(newLeadReports)
   
}, [newLeadReports]);

  useEffect(() => {
    
          const formattedData = l3Data.map((lead) => ({
            ...lead,
            LeadDate: ConversionValidDate(lead.LeadDate), // Apply ValidDate function
          }));

          const wonRecurringLeads = formattedData.filter(
            (lead) => lead.Status?.trim() === "Won Recurring"
          );
          const wonLeadsData = formattedData.filter(
            (lead) => lead.Status?.trim() === "Won"
          );

          // setLeadReports(formattedData);
          setWonRecurring(wonRecurringLeads);
          setwonLeads(wonLeadsData);         

  
  }, [l3Data]);

  const getWonPercentageByYear = (wonLeads, LeadReports) => {

    const wonLeadsByYear = {};
    const totalLeadsByYear = {};

    // Helper function to extract year from different date formats
    const extractYear = (dateString) => {
        const date = new Date(dateString);
        return date.getFullYear().toString(); // Ensure it's a string for object keys
    };

    // Count Won Leads
    wonLeads.forEach((lead) => {
        const year = extractYear(lead.LeadDate);
        if (!wonLeadsByYear[year]) wonLeadsByYear[year] = 0;
        wonLeadsByYear[year]++;
    });

    // Count Total Leads
    LeadReports.forEach((lead) => {
        const year = extractYear(lead.LeadDate);
        if (!totalLeadsByYear[year]) totalLeadsByYear[year] = 0;
        totalLeadsByYear[year]++;
    });

    // Calculate Won % for each year
    const wonPercentageByYear = {};
    Object.keys(totalLeadsByYear).forEach((year) => {
        const wonCount = wonLeadsByYear[year] || 0; // If no won leads, set to 0
        wonPercentageByYear[year] = ((wonCount / totalLeadsByYear[year]) * 100).toFixed(2);
    });

    return wonPercentageByYear;
};


//   const getWonPercentageByYear = (wonLeads, LeadReports) => {

//     const wonLeadsByYear = {};
//     const totalLeadsByYear = {};

//     // Count Won Leads
//     wonLeads.forEach((lead) => {
//         const year = lead.LeadDate.split("-")[2]; // Extract year (YYYY)
//         if (!wonLeadsByYear[year]) wonLeadsByYear[year] = 0;
//         wonLeadsByYear[year]++;
//     });

//     // Count Total Leads
//     LeadReports.forEach((lead) => {
//         const year = lead.LeadDate.split("-")[2]; // Extract year (YYYY)
//         if (!totalLeadsByYear[year]) totalLeadsByYear[year] = 0;
//         totalLeadsByYear[year]++;
//     });

//     // Calculate Won % for each year
//     const wonPercentageByYear = {};
//     Object.keys(totalLeadsByYear).forEach((year) => {
//         const wonCount = wonLeadsByYear[year] || 0; // If no won leads, set to 0
//         wonPercentageByYear[year] = ((wonCount / totalLeadsByYear[year]) * 100).toFixed(2);
//     });

//     return wonPercentageByYear;
// };

  // const getWonrecurrngPercentageByYear = (wonRecurring, LeadReports) => {
  //   const wonRecurringLeadsByYear = {};
  //   const totalLeadsByYear = {};

  //   wonRecurring.forEach((lead) => {
  //     const year = lead.LeadDate.split("-")[2]; // Extract year (YYYY)

  //     // Count Won Leads
  //     if (!wonRecurringLeadsByYear[year]) wonRecurringLeadsByYear[year] = 0;
  //     wonRecurringLeadsByYear[year]++;

  //     // Count Total Leads
  //     if (!totalLeadsByYear[year]) totalLeadsByYear[year] = 0;
  //     totalLeadsByYear[year]++;
  //   });

  //   LeadReports.forEach((lead) => {
  //     const year = lead.LeadDate.split("-")[2]; // Extract year
  //     if (!totalLeadsByYear[year]) totalLeadsByYear[year] = 0;
  //     totalLeadsByYear[year]++;
  //   });

  //   // Calculate Won % for each year
  //   const wonPercentageByYear = {};
  //   Object.keys(wonRecurringLeadsByYear).forEach((year) => {
  //     wonPercentageByYear[year] = (
  //       (wonRecurringLeadsByYear[year] / totalLeadsByYear[year]) *
  //       100
  //     ).toFixed(2);
  //   });

  //   return wonPercentageByYear;
  // };

  const getWonrecurrngPercentageByYear = (wonRecurring, LeadReports) => {
    const wonRecurringLeadsByYear = {};
    const totalLeadsByYear = {};

    // Helper function to extract the year from any date format
    const extractYear = (dateString) => {
        const date = new Date(dateString);
        return date.getFullYear().toString(); // Convert to string for object keys
    };

    // Count Won Recurring Leads
    wonRecurring.forEach((lead) => {
        const year = extractYear(lead.LeadDate);
        if (!wonRecurringLeadsByYear[year]) wonRecurringLeadsByYear[year] = 0;
        wonRecurringLeadsByYear[year]++;
    });

    // Count Total Leads
    LeadReports.forEach((lead) => {
        const year = extractYear(lead.LeadDate);
        if (!totalLeadsByYear[year]) totalLeadsByYear[year] = 0;
        totalLeadsByYear[year]++;
    });

    // Calculate Won % for each year
    const wonPercentageByYear = {};
    Object.keys(totalLeadsByYear).forEach((year) => {
        const wonCount = wonRecurringLeadsByYear[year] || 0; // Default to 0 if no won leads
        wonPercentageByYear[year] = ((wonCount / totalLeadsByYear[year]) * 100).toFixed(2);
    });

    return wonPercentageByYear;
};

  // Get past 5 years dynamically
  const pastFiveYears = Array.from({ length: 5 }, (_, i) =>
    (currentYear - 4 + i).toString()
  );

  // Example API Data (replace with actual API response)

  const wonPercentageByYear = getWonPercentageByYear(wonLeads, LeadReports);
  const wonRurringPercentageByYear = getWonrecurrngPercentageByYear(
    wonRecurring,
    LeadReports
  );

  // Calculate % Increase/Decrease
  const percentageChange = pastFiveYears.map((year, index) => {
    const prevYear = (parseInt(year) - 1).toString();
    if (wonPercentageByYear[prevYear] && wonPercentageByYear[year]) {
      return (
        wonPercentageByYear[year] - wonPercentageByYear[prevYear]
      ).toFixed(2);
    }

    return 0; // No previous data
  });
  const percentageChangeWonRecurring = pastFiveYears.map((year, index) => {
    const prevYear = (parseInt(year) - 1).toString();
    if (
      wonRurringPercentageByYear[prevYear] &&
      wonRurringPercentageByYear[year]
    ) {
      return (
        wonRurringPercentageByYear[year] - wonRurringPercentageByYear[prevYear]
      ).toFixed(2);
    }

    return 0; // No previous data
  });

  const selectedYearChange =
    percentageChange[pastFiveYears.indexOf(selectedYear.toString())] || "0";
  const selectedYearChangeWonRecurring =
    percentageChangeWonRecurring[
      pastFiveYears.indexOf(selectedYear.toString())
    ] || "0";


  useEffect(() => {
    const wonRecurringLeads = LeadReports.filter(
      (lead) => lead.Status?.trim() === "Won Recurring"
    );
    const wonLeadsData = LeadReports.filter(
      (lead) => lead.Status?.trim() === "Won"
    );

    setWonRecurring(wonRecurringLeads);
    setwonLeads(wonLeadsData);
  }, [LeadReports]);

  useEffect(() => {
    if (selectedYear) {
      const filterWonLeadsByYear = wonLeads.filter((lead) => {
        if (!lead.LeadDate) return false; // Ensure LeadDate exists

        const leadYear = lead.LeadDate.split("-")[2]; // Extract year

        return leadYear === selectedYear.toString(); // Convert selectedYear to string for comparison
      });

      setFilteredWonLeads(filterWonLeadsByYear);
    }
  }, [selectedYear, wonLeads]);

  useEffect(() => {
    
  }, [wonLeads, wonRecurring, chartData, filteredWonLeads]);

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowYearDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const [showMonthDropdown, setShowMonthDropdown] = useState(false);

  // Handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        MonthdropdownRef.current &&
        !MonthdropdownRef.current.contains(event.target)
      ) {
        setShowMonthDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (LeadReports.length > 0) {
      setIsLoading(false);
    }
  }, [LeadReports]);

  // Get distinct years from the LeadReports
  const years = [
    currentYear,
    currentYear - 1,
    currentYear - 2,
    currentYear - 3,
    currentYear - 4,
  ];

  //  const years = [
  //   ...new Set(
  //     LeadReports.map((lead) => new Date(lead.LeadDate).getFullYear())
  //   ),
  // ];
  // const months = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];

  // const linedata = [
  //   { name: "Jan", value: 0 },
  //   { name: "Feb", value: 0 },
  //   { name: "Mar", value: 0 },
  //   { name: "Apr", value: 0 },
  //   { name: "May", value: 0 },
  //   { name: "Jun", value: 0 },
  //   { name: "Jul", value: 0 },
  //   { name: "Aug", value: 0 },
  //   { name: "Sep", value: 0 },
  //   { name: "Oct", value: 0 },
  //   { name: "Nov", value: 0 },
  //   { name: "Dec", value: 0 },
  // ];

  // useEffect(() => {
  //   const processData = () => {
  //     const monthlyCounts: { [key: string]: number } = {};

  //     filteredWonLeads.forEach((lead) => {
  //       if (!lead.LeadDate) {
  //         console.warn("Missing LeadDate for lead:", lead);
  //         return;
  //       }

  //       // Convert MM-DD-YYYY to YYYY-MM-DD (safe format)
  //       const [month, day, year] = lead.LeadDate.split("-");
  //       const formattedDate = new Date(`${year}-${month}-${day}`);

  //       if (isNaN(formattedDate.getTime())) {
  //         console.warn("Invalid date format:", lead.LeadDate);
  //         return;
  //       }

  //       // Extract month (e.g., "Jan", "Feb", "Mar")
  //       const monthName = formattedDate.toLocaleString("en-US", {
  //         month: "short",
  //       });

  //       if (!monthlyCounts[monthName]) {
  //         monthlyCounts[monthName] = 0;
  //       }
  //       monthlyCounts[monthName]++;
  //     });

  //     const totalLeads = filteredWonLeads.length;
  //     const formattedData = Object.keys(monthlyCounts).map((month) => ({
  //       month,
  //       percentage: ((monthlyCounts[month] / totalLeads) * 100).toFixed(2),
  //     }));

  //     setChartData(formattedData);
  //   };

  //   if (filteredWonLeads.length > 0) {
  //     processData();
  //   }
  // }, [filteredWonLeads]);

  // const data = linedata.map((item) => {
  //   const found = chartData.find((entry) => entry.month === item.name);
  //   return {
  //     ...item,
  //     value: found ? parseFloat(found.percentage) : 0, // Convert percentage to number
  //   };
  // });


  // const lastPoint = data[data.length - 1]; // Get the last data point

  // const arrowPlugin = {
  //   id: "arrowPlugin",
  //   afterDatasetDraw(chart, args) {
  //     if (!arrowIcon) return; // Ensure image is loaded

  //     const { ctx } = chart;
  //     const datasetMeta = args.meta;
  //     if (!datasetMeta || datasetMeta.data.length < 2) return;

  //     const lastPoint = datasetMeta.data[datasetMeta.data.length - 1]; // Last data point
  //     if (!lastPoint) return;

  //     // Draw the icon at the last point
  //     const iconSize = 24; // Adjust size as needed
  //     ctx.drawImage(arrowIcon, lastPoint.x - iconSize / 2, lastPoint.y - iconSize / 2, iconSize, iconSize);
  //   },
  // };

  // const pastFiveYears = Array.from({ length: 5 }, (_, i) => (currentYear - 4 + i).toString().slice(-2));

  // const newdata = {
  //   labels: pastFiveYears,
  //   datasets: [
  //     {
  //       label: "Sales Trend",
  //       data: [10, 40, 0, 60, 50, 80], // Sample data
  //       borderColor: "blue",
  //       borderWidth: 3,
  //       pointRadius: 0, // Hide points
  //       pointHoverRadius: 0,
  //     },
  //   ],
  // };

  // // Chart Options
  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: { display: false },
  //     tooltip: { enabled: true },
  //     arrowPlugin, // Add the custom plugin
  //   },
  // };

  const axisData = ["21", "22", "23", "24", "25"];

  const wondata = axisData.map((item, i) => ({
    name: item,
    value: pastFiveYears.map((year) => wonPercentageByYear[year] || 0), // Assign X & Y coordinates
  }));

  const links = wondata.map((item, i) => ({
    source: i,
    target: i + 1,
  }));
  links.pop();

  // const wonoptions = {
  //   xAxis: {
  //     type: "category",
  //     data: pastFiveYears.map((year) => `'${year.slice(-2)}`), // Show last two digits
  //     axisLabel: {
  //       formatter: (value) => `${value}`, // Keep last two digits
  //       color: "black", 
  //       fontWeight: "bold"
  //     },
  //   },
  //   yAxis: {
  //     show: false,
  //     min: 0,  // Set lower limit closer to zero
  //     max: 60,
  //   },
  //   series: pastFiveYears.slice(1).map((year, index) => {
  //     const prevYear = pastFiveYears[index]; // Get previous year
  //     const prevValue = parseFloat(wonPercentageByYear[prevYear]) || null;
  //     const currValue = parseFloat(wonPercentageByYear[year]) || null;
  //     const lineColor = currValue >= prevValue ? "#008631" : "red"; // Determine color

  //     const isLastSegment = index === pastFiveYears.length - 2; // Check if last segment
  
  //     let arrowRotation = 0;
  //     if (isLastSegment) {
  //       const lastPrevYear = pastFiveYears[pastFiveYears.length - 2];
  //       const lastCurrYear = pastFiveYears[pastFiveYears.length - 1];
  
  //       const lastPrevValue = parseFloat(wonPercentageByYear[lastPrevYear]) || 0;
  //       const lastCurrValue = parseFloat(wonPercentageByYear[lastCurrYear]) || 0;
  
  //       const x1 = pastFiveYears.length - 2; // Index of lastPrevYear
  //       const x2 = pastFiveYears.length - 2; // Index of lastCurrYear
  
  //       // Correctly calculate arrow rotation based on segment direction
  //       arrowRotation = Math.atan2(lastCurrValue - lastPrevValue, x2 - x1) * (-180 / Math.PI);
  //     }
  //     return {
  //       name: "Won %",
  //       type: "line",
  //       data: [
  //         [index, prevValue], // Correct x position
  //         [index + 1, currValue], // Move to the next x position
  //       ],
  //       lineStyle: {
  //         color: currValue >= prevValue ? "#008631" : "red", // Color logic
  //         width: 3,
  //       },
  //       // itemStyle: {
  //       //   color: lineColor,
  //       // },
  //       symbol: isLastSegment ? "none" : "circle", // Hide symbol for last segment
  //       symbolSize: isLastSegment ? 0 : 2,
  //       // itemStyle: {
  //       //   color: currValue >= prevValue ? "#008631" : "red", // Match line color
  //       // },
  //       markPoint: isLastSegment
  //         ? {
  //             data: [
  //               {
  //                 symbol: "arrow",
  //                 symbolSize: [12, 12], // Size of the arrow
  //                 symbolRotate: arrowRotation, // Dynamically set arrow direction
  //                 coord: [
  //                   pastFiveYears.length - 1,
  //                   wonPercentageByYear[
  //                     pastFiveYears[pastFiveYears.length - 1]
  //                   ],
  //                 ], // Position at the last data point
  //                 itemStyle: {
  //                   color: lineColor, // Apply last segment's color to arrow
  //                 },
  //               },
  //             ],
  //           }
  //         : undefined,
  //     };
  //   }),
  // };



  // const wonRecurringoptions = {
  //   xAxis: {
  //     type: "category",
  //     data: pastFiveYears.map((year) => `'${year.slice(-2)}`), 
  //     axisLabel: {
  //       formatter: (value) => `${value}`, 
  //       color: "black", 
  //       fontWeight: "bold"
  //     },
  //   },
  //   yAxis: {
  //     show: false,
  //     min: 0,  // Set lower limit closer to zero
  //     max: 60,
  //   },
  //   series: pastFiveYears.slice(1).map((year, index) => {
  //     const prevYear = pastFiveYears[index]; // Get previous year
  //     const prevValue = parseFloat(wonRurringPercentageByYear[prevYear]) || null;
  //     const currValue = parseFloat(wonRurringPercentageByYear[year]) || null;
  //     const lineColor = currValue >= prevValue ? "#00d24c" : "red"; // Determine color
  //     const arrowRotation = 0; // Set rotation
  
  //     const isLastSegment = index === pastFiveYears.length - 2; // Check if last segment
  
  //     // Assign last segment rotation to the final arrow
  //     let slope = 0;
  //   if (isLastSegment) {
  //     const lastPrevYear = pastFiveYears[pastFiveYears.length - 2];
  //     const lastCurrYear = pastFiveYears[pastFiveYears.length - 1];

  //     const lastPrevValue = parseFloat(wonRurringPercentageByYear[lastPrevYear]) || 0;
  //     const lastCurrValue = parseFloat(wonRurringPercentageByYear[lastCurrYear]) || 0;

  //     const x1 = pastFiveYears.length - 2; // Index of lastPrevYear
  //     const x2 = pastFiveYears.length - 1; // Index of lastCurrYear

  //     slope = (lastCurrValue - lastPrevValue) / (x2 - x1); // Slope calculation
  //   }
  //     return {
  //       name: "Won %",
  //       type: "line",
  //       smooth: true, // Add smoothness
  //       smoothMonotone: "x",
  //       data: [
  //         [index, prevValue], // Correct x position
  //         [index + 1, currValue], // Move to the next x position
  //       ],
  //       lineStyle: {
  //         color: currValue >= prevValue ? "#008631" : "red", // Color logic
  //         width: 3,
  //       },
  //       symbol: isLastSegment ? "none" : "circle", // Hide symbol for last segment
  //       symbolSize: isLastSegment ? 0 : 2,
  //       itemStyle: {
  //         color: currValue >= prevValue ? "#008631" : "red", // Match line color
  //     },
  //       markPoint: isLastSegment
  //         ? {
  //             data: [
  //               {
  //                 symbol:"arrow", // Chevron shape
  //                 symbolSize: [12, 12], // Size of the arrow
  //                 symbolRotate: Math.atan2(lastCurrValue - lastPrevValue, x2 - x1) * (180 / Math.PI),
  //                 coord: [
  //                   pastFiveYears.length - 1,
  //                   wonRurringPercentageByYear[
  //                     pastFiveYears[pastFiveYears.length - 1]
  //                   ],
  //                 ], // Position at the last data point
  //                 itemStyle: {
  //                   color: lineColor, // Apply last segment's color to arrow
  //                 },
  //               },
  //             ],
  //           }
  //         : undefined, // Add markPoint only for the last segment
  //     };
  //   }),
  // };
  // const wonoptions = {
  //   xAxis: {
  //     type: "category",
  //     data: pastFiveYears.map((year) => `'${year.slice(-2)}'`), // ✅ Fixed backticks and extra parenthesis
  //     axisLabel: {
  //       formatter: (value) => `${value}`, // ✅ Fixed backticks
  //       color: "black",
  //       fontWeight: "bold",
  //     },
  //   },
  //   yAxis: {
  //     show: false,
  //     min: 0,
  //     max: 60,
  //   },
  //   series: pastFiveYears.slice(1).map((year, index) => {
  //     const prevYear = pastFiveYears[index]; 
  //     const prevValue = parseFloat(wonPercentageByYear[prevYear]) || null;
  //     const currValue = parseFloat(wonPercentageByYear[year]) || null;
  //     const lineColor = currValue >= prevValue ? "#008631" : "red"; 
  
  //     const isLastSegment = index === pastFiveYears.length - 2;
  //     let arrowRotation = 0;
  
  //     if (isLastSegment) {
  //       const lastPrevYear = pastFiveYears[pastFiveYears.length - 2];
  //       const lastCurrYear = pastFiveYears[pastFiveYears.length - 1];
      
  //       const lastPrevValue = parseFloat(wonPercentageByYear[lastPrevYear]) || 0;
  //       const lastCurrValue = parseFloat(wonPercentageByYear[lastCurrYear]) || 0;
      
  //       const x1 = pastFiveYears.length - 2; // Index of lastPrevYear
  //       const x2 = pastFiveYears.length - 1; // Index of lastCurrYear
      
  //       let slope = (lastCurrValue - lastPrevValue) / (x2 - x1);
      
  //       // Define fixed slope values
  //       const FIXED_UPWARD_SLOPE = 30;
  //       const FIXED_DOWNWARD_SLOPE = -30;
      
  //       // Assign fixed slopes based on the trend
  //       if (slope > 0) {
  //         slope = FIXED_UPWARD_SLOPE;
  //         arrowRotation = -90; // Fixed arrow angle for upward trend
  //       } else if (slope < 0) {
  //         slope = FIXED_DOWNWARD_SLOPE;
  //         arrowRotation = -120; // Fixed arrow angle for downward trend
  //       } else {
  //         arrowRotation = 0; // If slope is zero, keep it neutral
  //       }
  //     }
    
  
  //     return {
  //       name: "Won %",
  //       type: "line",
  //       data: [
  //         [index, prevValue], 
  //         [index + 1, currValue], 
  //       ],
  //       lineStyle: {
  //         color: lineColor,
  //         width: 4,
  //       },
  //       symbol: isLastSegment ? "none" : "circle",
  //       symbolSize: isLastSegment ? 0 : 3,
  //       itemStyle: {
  //         color: lineColor,
  //       },
  //       markPoint: isLastSegment
  //         ? {
  //           data: [
  //             {
  //               symbol: "arrow",
  //               symbolSize: [12, 12],
  //               symbolRotate: arrowRotation,
  //               coord: [index + 1, currValue], // Fixes alignment issue

  //               itemStyle: {
  //                 color: lineColor,
  //               },
  //             },
  //           ],
  //         }
  //         : undefined,
  //     };
  //   }),
  // };
  
 

  // const wonRecurringoptions = {
  //   xAxis: {
  //     type: "category",
  //     data: pastFiveYears.map((year) => `'${year.slice(-2)}`), 
  //     axisLabel: {
  //       formatter: (value) => `${value}`, 
  //       color: "black", 
  //       fontWeight: "bold"
  //     },
  //   },
  //   yAxis: {
  //     show: false,
  //     min: 0,  
  //     max: 60,
  //   },
  //   series: pastFiveYears.slice(1).map((year, index) => {
  //     const prevYear = pastFiveYears[index]; 
  //     const prevValue = parseFloat(wonRurringPercentageByYear[prevYear]) || null;
  //     const currValue = parseFloat(wonRurringPercentageByYear[year]) || null;
  //     const lineColor = currValue >= prevValue ? "#00d24c" : "red"; 
  
  //     const isLastSegment = index === pastFiveYears.length - 2; // Last segment check
  
  //     let arrowRotation = 0;
  //     if (isLastSegment) {
  //       const lastPrevYear = pastFiveYears[pastFiveYears.length - 2];
  //       const lastCurrYear = pastFiveYears[pastFiveYears.length - 1];
      
  //       const lastPrevValue = parseFloat(wonRurringPercentageByYear[lastPrevYear]) || 0;
  //       const lastCurrValue = parseFloat(wonRurringPercentageByYear[lastCurrYear]) || 0;
      
  //       const x1 = pastFiveYears.length - 2; // Index of lastPrevYear
  //       const x2 = pastFiveYears.length - 1; // Index of lastCurrYear
      
  //       let slope = (lastCurrValue - lastPrevValue) / (x2 - x1);
      
  //       // Define fixed slope values
  //       const FIXED_UPWARD_SLOPE = 30;
  //       const FIXED_DOWNWARD_SLOPE = -30;
      
  //       // Assign fixed slopes based on the trend
  //       if (slope > 0) {
  //         slope = FIXED_UPWARD_SLOPE;
  //         arrowRotation = -90; // Fixed arrow angle for upward trend
  //       } else if (slope < 0) {
  //         slope = FIXED_DOWNWARD_SLOPE;
  //         arrowRotation = -120; // Fixed arrow angle for downward trend
  //       } else {
  //         arrowRotation = 0; // If slope is zero, keep it neutral
  //       }
  //     }
      
      
  
  //     return {
  //       name: "Won %",
  //       type: "line",
  //       smooth: true,
  //       smoothMonotone: "x",
  //       data: [
  //         [index, prevValue], 
  //         [index + 1, currValue], 
  //       ],
  //       lineStyle: {
  //         color: currValue >= prevValue ? "#008631" : "red",
  //         width: 4,
  //       },
  //       symbol: isLastSegment ? "none" : "circle",
  //       symbolSize: isLastSegment ? 0 : 3,
  //       itemStyle: {
  //         color: currValue >= prevValue ? "#008631" : "red",
  //       },
  //       markPoint: isLastSegment
  //         ? {
  //           data: [
  //             {
  //               symbol: "arrow",
  //               symbolSize: [12, 12],
  //               symbolRotate: arrowRotation, // Corrected rotation logic
  //               coord: [index + 1, currValue], // Fixes alignment issue

  //               itemStyle: {
  //                 color: lineColor,
  //               },
  //             },
  //           ],
  //         }
  //         : undefined,
  //     };
  //   }),
  // };

  const chartRef1 = useRef(null);
const chartRef2 = useRef(null);
const [chartLoaded, setChartLoaded] = useState(false);
const [hasDataChart1, setHasDataChart1] = useState(true);
const [hasDataChart2, setHasDataChart2] = useState(true);

useEffect(() => {
  if (!window.google) {
    const script = document.createElement("script");
    script.src = "https://www.gstatic.com/charts/loader.js";
    script.async = true;
    script.onload = () => {
      window.google.charts.load("current", { packages: ["corechart", "line"] });
      window.google.charts.setOnLoadCallback(() => setChartLoaded(true));
    };
    document.body.appendChild(script);
  } else {
    window.google.charts.load("current", { packages: ["corechart", "line"] });
    window.google.charts.setOnLoadCallback(() => setChartLoaded(true));
  }
}, []);

const drawChart = useCallback((chartRef, dataValues, label, color,setHasData) => {
  if (!chartLoaded || !window.google || !window.google.visualization || !chartRef.current) {
    console.error("Google Charts is not available yet.");
    return;
  }

  const fixedYears = [2020, 2021, 2022, 2023, 2024, 2025];

  const data = new window.google.visualization.DataTable();
  data.addColumn("number", "Year");
  data.addColumn("number", label);

  const rows = fixedYears.map(year => [
    parseInt(year.toString().slice(-2)), 
    dataValues[year] ? parseFloat(dataValues[year]) : 0
  ]);

  // Check if all values are 0 or no valid data
  const hasValidData = rows.some(row => row[1] > 0);
  setHasData(hasValidData); // Update state to track if data exists

  if (!hasValidData) {
    // Show "No Data Available" message
    chartRef.current.innerHTML = `<div style="display: flex; align-items: center; justify-content: center; height: 100%; color: #ef4450; font-size: 18px;">No data available</div>`;
    return;
  }

  data.addRows(rows);

  const options = {
    legend: "none",
    colors: [color],
    // curveType: "function",
    hAxis: {
      
      baselineColor: "#000", // Ensure X-axis line is visible
      gridlines: { color: "transparent", count: 0 },
      minorGridlines: { color: "transparent" },
      textStyle: { color: "#000", bold: true, fontSize: 14 }, // Make labels bold
      ticks: fixedYears.map(year => parseInt(year.toString().slice(-2))),
      slantedText: false,
      baselineWidth: 12,
    },
    vAxis: {
      textPosition: "none",
      gridlines: { color: "#000000", count: 1 },
      baselineColor: "transparent",
    },
    backgroundColor: { fill: "transparent" },
    chartArea: { backgroundColor: "transparent" },
    pointSize: 0,
    tooltip: { trigger: "none" },
  };

  const chart = new window.google.visualization.LineChart(chartRef.current);
  chart.draw(data, options);
}, [chartLoaded]);

useEffect(() => {
  if (chartLoaded) {
    drawChart(chartRef1, wonPercentageByYear, "Won %", "#008631",setHasDataChart1);
    drawChart(chartRef2, wonRurringPercentageByYear, "Won Recurring %", "#82b229",setHasDataChart2);
  }
}, [chartLoaded, drawChart, wonPercentageByYear, wonRurringPercentageByYear]);



  //   const chartRef = useRef(null);
  // const [chartLoaded, setChartLoaded] = useState(false);

  // useEffect(() => {
  //   if (!window.google) {
  //     const script = document.createElement("script");
  //     script.src = "https://www.gstatic.com/charts/loader.js";
  //     script.async = true;
  //     script.onload = () => {
  //       window.google.charts.load("current", { packages: ["corechart", "line"] });
  //       window.google.charts.setOnLoadCallback(() => setChartLoaded(true));
  //     };
  //     document.body.appendChild(script);
  //   } else {
  //     window.google.charts.load("current", { packages: ["corechart", "line"] });
  //     window.google.charts.setOnLoadCallback(() => setChartLoaded(true));
  //   }
  // }, []);

  // const drawChart = useCallback(() => {
  //   if (!chartLoaded || !window.google || !window.google.visualization || !chartRef.current) {
  //     console.error("Google Charts is not available yet.");
  //     return;
  //   }

  //   if (!wonPercentageByYear || !wonRurringPercentageByYear) {
  //     console.error("Data is not available yet.");
  //     return;
  //   }

  //   const allYears = [...new Set([
  //     ...Object.keys(wonPercentageByYear || {}),
  //     // ...Object.keys(wonRurringPercentageByYear || {})
  //   ])].map(Number).sort((a, b) => a - b);

  //   const data = new window.google.visualization.DataTable(); // Ensure google.visualization is loaded
  //   data.addColumn("number", "Year");
  //   data.addColumn("number", "Won %");
  //   // data.addColumn("number", "Won Recurring %");

  //   const rows = allYears.map(year => [
  //     year % 100,
  //     wonPercentageByYear[year] ? parseFloat(wonPercentageByYear[year]) : 0
  //     // wonRurringPercentageByYear[year] ? parseFloat(wonRurringPercentageByYear[year]) : 0
  //   ]);

  //   data.addRows(rows);

  //   const options = {
  //     legend: { position: "top",alignment: "center" },
  //         colors: ["#a52714", "#097138"],
  //     hAxis: {
  //       gridlines: { color: "transparent" },
  //       ticks: allYears.map(year => Number(year.toString().slice(-2))),
  //     },
  //     vAxis: {
  //       gridlines: { color: "transparent" }
  //     },
  //     backgroundColor: { fill: "transparent" }, // Set background to transparent
  //     chartArea: { backgroundColor: "transparent" },
  //     pointSize: 0,
  //     tooltip: { trigger: "none" },
  //   };

  //   const chart = new window.google.visualization.LineChart(chartRef.current);
  //   chart.draw(data, options);
  // }, [chartLoaded, wonPercentageByYear, wonRurringPercentageByYear]);

  // useEffect(() => {
  //   if (chartLoaded) {
  //     drawChart();
  //   }
  // }, [chartLoaded, drawChart]);



  return (
    <div className="w-full h-full ">
      <div className="h-[20%]">
        <div className="flex flex-wrap h-12 items-center justify-between md:gap-x-2 md:flex-nowrap xl:flex xl:justify-between">
          <div>
            <h1 className="text-2xl font-semibold sm:text-sm md:text-sm lg:text-xl">
              LEAD CONVERSION RATE
            </h1>
          </div>
          {/* <div className="lg:ml-32 flex lg:items-center lg:justify-end cp:hidden ">
          <div className="show-potential-download"></div>
          <div className="show-potential-download">
            <div className="flex items-center mb-1 justify-between text-3xl sm:text-xl md:text-md">
              {leadTab === "WonRecurring" && (
                <span title="Won">
                  <button
                    className="cursor-pointer text-white bg-green-600 py-2 px-3 text-sm rounded-2xl w-full shadow-md lg:text-xs xl:text-sm z-50"
                    onClick={() => setLeadTab("Won")}
                  >
                    Won
                  </but
ton>
                </span>
              )}
              {leadTab === "Won" && (
                <span title="Won Recurring">
                  <button
                    className="cursor-pointer text-white bg-green-600 py-2 px-3 text-sm rounded-2xl w-full shadow-md lg:text-xs xl:text-sm z-50"
                    onClick={() => setLeadTab("WonRecurring")}
                  >
                    Won Recurring
                  </button>
                </span>
              )}
            </div>
          </div>
        </div> */}
        </div>
        <hr />
        <hr />
        <div className="flex p-2">
          <div className="flex rounded-lg">
            <div className="text-xs h-full border rounded-l-md border-[#e3eaff] py-2 px-1 flex align-middle items-center text-center">Select Year</div>
            <div
              ref={dropdownRef}
              className="relative border w-24 rounded-r-md bg-[#e0e7ff]"
            >
              <div className="absolute cursor-pointer right-0 border-r border-[#e3eaff]  z-20">
                <ExpandMoreIcon
                  color="white"
                  className=""
                  onClick={() => setShowYearDropdown(!showYearDropdown)}
                />
              </div>
              <button
                type="button"
                onClick={() => setShowYearDropdown(!showYearDropdown)}
                className=" text-left h-full w-full focus:outline-none py-2 px-1 text-xs"
              >
                {selectedYear}
              </button>

              {showYearDropdown && (
                <div className="absolute z-50 w-24 bg-white text-xs border border-gray-300 overflow-y-auto shadow-lg max-h-48  mt-0.5">
                  {years
                    .slice()
                    .sort((a, b) => b - a) // Sort years in descending order
                    .map((year) => (
                      <label
                        key={year}
                        className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          value={year}
                          checked={selectedYear === year}
                          onChange={(e) =>
                            setSelectedYear(parseInt(e.target.value))
                          }
                          className="mr-2"
                        />
                        {year}
                      </label>
                    ))}
                </div>
              )}
            </div>
          </div>


          {/* <div className="relative flex align-middle items-center w-[25%]">
                       <div className="text-xs h-full bg-[#6b7280] text-white p-0.5 flex align-middle items-center text-center">
                          Select
                        </div> 
            <div className="absolute cursor-pointer right-0 border-r border-[#e3eaff] bg-white z-20">
              <ExpandMoreIcon
                color="white"
                className=""
                onClick={() => setShowYearDropdown(!showYearDropdown)}
              />
            </div>
            <div
              ref={dropdownRef}
              className="relative border w-full border-[#e3eaff]  rounded-md"
            >
              <button
                type="button"
                onClick={() => setShowYearDropdown(!showYearDropdown)}
                className=" flex justify-start items-center align-middle w-full h-full p-2 text-nowrap truncate focus:outline-none text-xs"
              >
                {selectedYear}
              </button>

              {showYearDropdown && (
                <div className="absolute flex flex-col w-28 p-0.5 gap-2 z-50  bg-white text-xs border border-gray-300 overflow-y-auto shadow-lg max-h-48  mt-0.5">
                  {years
                    .slice()
                    .sort((a, b) => b - a) // Sort years in descending order
                    .map((year) => (
                      <label
                        key={year}
                        className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          value={year}
                          checked={selectedYear === year}
                          onChange={(e) =>
                            setSelectedYear(parseInt(e.target.value))
                          }
                          className="mr-2"
                        />
                        {year}
                      </label>
                    ))}
                </div>
              )}
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="cp:hidden">
        {leadTab === "Won" && (
          <div className="flex flex-col gap-0 h-full">
            <div className="flex justify-center py-3 items-center gap-3">
              <button className="bg-[#f4bd22] py-2 px-5 rounded-md">
                <h3>Won = {wonPercentageByYear[selectedYear] || 0}%</h3>
              </button>
              <button className="bg-[#f4bd22] py-2 px-5 rounded-md">
                <p> % Increase = {selectedYearChange}%</p>
              </button>
            </div>

            <div className="w-full h-[350px] pt-12">
            <ReactECharts option={wonoptions} />


            </div>
          </div>
        )}
        {leadTab === "WonRecurring" && (
          <div className="flex flex-col gap-0 h-full">
            <div className="flex justify-center py-3 items-center gap-3">
              <button className="bg-[#f4bd22] py-2 px-5 rounded-md">
                <h3>
                  Won Recurring ={" "}
                  {wonRurringPercentageByYear[selectedYear] || 0}%
                </h3>
              </button>
              <button className="bg-[#f4bd22] py-2 px-5 rounded-md">
                <p> % Increase = {selectedYearChangeWonRecurring}%</p>
              </button>
            </div>

            <div className="w-full h-[350px]  pt-12">
            <ReactECharts option={wonRecurringoptions} />

            </div>
          </div>
        )}
      </div> */}
      {/* <div className=" xs:hidden cp:flex"> */}
      <div className="h-[80%] relative">
        <div className=" py-2 xl:py-2">
          <div className="flex w-full">
          <div className="flex flex-col w-[50%] justify-center items-center gap-2">
            <button className="bg-[#fbbe62] text-left text-xs px-1.5 py-1.5 rounded-sm w-[50%] ">
              <h3>Won = {wonPercentageByYear[selectedYear] || 0}%</h3>
            </button>
            <button className="bg-[#fbbe62] text-left text-xs px-1.5 py-1.5 rounded-sm w-[50%] ">
              <p> % Change = {selectedYearChange}%</p>
            </button>
          </div>
          <div className="flex flex-col w-[50%] justify-center  items-center gap-2">
          <button className="bg-[#fbbe62] text-left text-xs px-1.5 py-1.5 rounded-sm w-[50%] ">
          <h3>
                Won Rec = {wonRurringPercentageByYear[selectedYear] || 0}%
              </h3>
            </button>
            <button className="bg-[#fbbe62] text-left text-xs px-1.5 py-1.5 rounded-sm w-[50%] ">
              <p> % Change = {selectedYearChangeWonRecurring}%</p>
            </button>
          </div>
          </div>
          <div className="flex w-full py-2">
            <div className="w-[50%] text-center text-xs">Historical Trend - Won</div>
            <div className="w-[50%] text-center text-xs">Historical Trend - Won recurring</div>
          </div>
        </div>
        {/* <div ref={chartRef} className="canvas" /> */}
        <div className="absolute dm:-bottom-3 lg:bottom-2 xl:-bottom-4 cp:-bottom-6 w-full h-[80%]">
          
          <div className="flex h-full relative">
          <div className="w-full flex absolute bottom-8 cp:bottom-10 dm:bottom-9 font-bold">
              <div className="w-[50%]">
              {hasDataChart1 && <p className="text-[0.8rem] cp:text-[0.6rem]">Year</p>}
                </div>
              <div className="w-[50%]">
              {hasDataChart2 && <p className="text-[0.8rem] cp:text-[0.6rem]">Year</p>}
                </div>
            </div>
            <div ref={chartRef1} className="canvas" style={{ width: "50%", height: "100%" }} />
            <div ref={chartRef2} className="canvas" style={{ width: "50%", height: "100%" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversionRateL3;
