import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";

// **1. Fetch Financial Data (GET)**
export const fetchFinancialData = createAsyncThunk("financial/fetch",async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/financial/getfinancial");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch data");
    }
  }
);

// **2. Update Financial Data (PUT)**
export const updateFinancialData = createAsyncThunk("financial/update",async ({ leadID, formattedData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/financial/updatefinancial/${leadID}`,
        formattedData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to update data");
    }
  }
);

// **3. Save Financial Data (POST)**
export const saveFinancialData = createAsyncThunk("financial/save",async (formattedData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/financial/savefinancial",
        formattedData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to save data");
    }
  }
);

// **Redux Slice**
const financialSlice = createSlice({
  name: "financial",
  initialState: {
    financialData: null,
    status: "idle",
    error: null,
  },
  reducers: {}, // Add sync reducers if needed
  extraReducers: (builder) => {
    builder
      // Fetch Data Cases
      .addCase(fetchFinancialData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFinancialData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.financialData = action.payload;
      })
      .addCase(fetchFinancialData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      // Update Data Cases
      .addCase(updateFinancialData.fulfilled, (state, action) => {
        state.financialData = action.payload; // Update data in store
      })

      // Save Data Cases
      .addCase(saveFinancialData.fulfilled, (state, action) => {
        state.financialData = action.payload;
      });
  },
});

export default financialSlice.reducer;
