import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosMail,
  IoIosPeople,
} from "react-icons/io";
import { SiPivotaltracker } from "react-icons/si";
import {
  MdDashboard,
  MdContacts,
  MdCategory,
  MdPending,
  MdGroupAdd,
} from "react-icons/md";
import { MdAddBox } from "react-icons/md";
import { BiSolidReport } from "react-icons/bi";
import { MdFolderCopy } from "react-icons/md";
import { FaServicestack } from "react-icons/fa6";
import { RiMastercardFill } from "react-icons/ri";
import { DiOpensource } from "react-icons/di";
import loram from "../../Assets/loram.png";
import Q from "../../Assets/finallogo.png";

const L1Sidebar = () => {
  const navigate = useNavigate();
  const [showLogout, setshowlogout] = useState(false);
  const [modalOpen, ] = useState(true);
  const [Contact, setContact] = useState(false);
  const [leadTracker, setLeadTracker] = useState(true);
  const [activeButton, setActiveButton] = useState(""); // State for active button

  const menuItems = useMemo(
    () => [
      {
        name: "Dashboard",
        icon: <MdDashboard />,
        path: "/dashboard/L1",
      },
      { name: "Add Lead",
        icon: <MdAddBox />,
        path: "/dashboard/L1/add-lead" },
      {
        name: "Lead Reports",
        icon: <BiSolidReport />,
        path: "/dashboard/L1/lead-report",
      },
    ],
    []
  );

  // This useEffect will ensure the active button is set based on the URL when the page loads
  useEffect(() => {
    // Check the current path from the browser
    const currentPath = window.location.pathname;
    const activeItem = menuItems.find((item) => item.path === currentPath);
    if (activeItem) {
      setActiveButton(activeItem.name); // Set active button based on path
      setLeadTracker(true); // Activate leadTracker when the path matches
    }
  }, [menuItems]); // Run the effect when menuItems changes

  const handleNavigation = (item) => {
    setActiveButton(item.name); // Set active button on click
    setLeadTracker(true); // Activate leadTracker
    sessionStorage.setItem("activeButton", item.name); // Store active button in sessionStorage
    navigate(item.path); // Navigate to the clicked path
  };

  const toggleMasters = () => {
    
    setMasters((prev) => !prev);
    setLeadTracker(false); // Close leadTracker when opening Masters
  };

  const toggleLeadTracker = () => {
    setLeadTracker((prev) => !prev);
    setMasters(false); // Close Masters when opening LeadTracker
  };

  const [masters, setMasters] = useState(false); // Dropdown visibility state
  const [activeMasterButton, setActiveMasterButton] = useState(""); // Active button state
  const [dropdownOpen, setDropdownOpen] = useState(null); // Dropdown state

  const mastersMenuItems = useMemo(
    () => [
      {
        name: "Employees",
        icon: <IoIosPeople />,
        path: "/dashboard/L1/employees",
      },
      {
        name: "Service Type",
        icon: <FaServicestack />,
        path: "/dashboard/L1/service-types",
      },
      {
        name: "Lead Type",
        icon: <MdCategory />,
        path: "/dashboard/L1/lead-types",
      },
      { name: "Status",
         icon: <MdPending />,
         path: "/dashboard/L1/status"
         },
      { name: "Source", 
        icon: <DiOpensource />, 
        path: "/dashboard/L1/source"
       },
      {
        name: "Client Master",
        icon: <RiMastercardFill />,
        path: "/dashboard/L1/client-master",
      },
      {
        name: "Add Group",
        icon: <MdGroupAdd />,
        path: "/dashboard/L1/add-group",
      },
      // {
      //   name: "Hardware Store",
      //   icon: <MdGroupAdd />,
      //   path: "#",
      // },
      // {
      //   name: "Labor Cost",
      //   icon: <MdGroupAdd />,
      //   path: "#",
      // },
         // {
      //   name: "Rating Weightage",
      //   icon: <StarOutlineIcon />,
      //   path: "/weightage",
      // },
    ],
    []
  );
  // const dataTrackeritems = useMemo(
  //   () => [
  //     {
  //       name: "Baseline",
  //       icon: <LinearScaleIcon />,
  //       path: "#",
  //     },
  //     {
  //       name: "Data Charges",
  //       icon: <AccountBalanceWalletIcon />,
  //       path: "#",
  //     },
  //   ],
  //   []
  // );
  const toggleContact = () => setContact(!Contact);

  const ImageNavigate = ()=>{
    navigate('/dashboard/L1')
  }

  const handleMasterNavigation = (buttonName, path) => {
    setLeadTracker(false)
    setActiveMasterButton(buttonName); // Set active button state
    setDropdownOpen(dropdownOpen === buttonName ? null : buttonName); // Toggle dropdown open/close
    sessionStorage.setItem("MasterActiveButton", buttonName); // Store active button in sessionStorage
    navigate(path); // Perform navigation after the state is updated
  };

  useEffect(() => {
    // Get the current path from the browser's URL
    const currentPath = window.location.pathname;
    // Find the menu item whose path matches the current URL
    const activeItem = mastersMenuItems.find(
      (item) => item.path === currentPath
    );
    if (activeItem) {
      setActiveMasterButton(activeItem.name); // Set the active button based on the current path
      setMasters(true); // Ensure dropdown is open if needed
      setLeadTracker(false)
    }
  }, [mastersMenuItems]);

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setshowlogout(false); // Close the popup if clicked outside
    }
  };

  useEffect(() => {
    if (showLogout) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showLogout]);

  return (
    <>
      <div className="relative">
        <div className="grid bg-white h-screen absolute">

          {modalOpen && (
            <>
              <style>
                {`
        #menu-bar::-webkit-scrollbar {
          width: 8px; 
        }

        #menu-bar::-webkit-scrollbar-track {
          border-radius: 10px;
        }

        #menu-bar::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 10px;
        }

        #menu-bar::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}
              </style>
              <div
                id="menu-bar"
                className={`row-span-1 p-0 col-span-1 bg-white overflow-y-scroll fixed shadow-2xl flex flex-col transition-all duration-300 ease-in-out top-0 bottom-0`}
                style={{ width: modalOpen ? "17%" : "0%" }}
              >
                {/* Logo Section */}
                <div className="flex justify-center items-center align-middle w-full ">
                  <div onClick={ImageNavigate} className="w-full flex justify-center align-middle items-center p-2 cursor-pointer">
                    <img onClick={ImageNavigate} src={Q} alt="Q-Trackr Logo" className=' w-full cursor-pointer' />
                 
                  
                    {/* <img src={QTracker} alt="Q-Trackr Logo" className='w-24 h-8 ' /> */}

                  </div>



                </div>
                <hr /><hr />

                {/* Scrollable Menu Section */}
                <div className="relative flex flex-col h-screen md:w-[99.5%] lg:w-[99.5%]">
                  {/* Scrollable Content */}
                  <div
                    className=" flex-grow pb-20 mb-14"
                    
                  >
                    <h1
                      onClick={toggleLeadTracker}
                      className="flex items-center justify-between p-2 border-b-2 border-[#ecefff] cursor-pointer rounded-md  bg-[#f9faff]"
                    >
                      <div
                        className="flex items-center"
                        style={{ paddingLeft: "5px" }}
                      >
                        <SiPivotaltracker size={24} />
                        &nbsp;{" "}
                        <p className="text-[1rem]">
                          Lead Tracker
                        </p>
                      </div>
                      {leadTracker ? (
                        <IoIosArrowUp  />
                      ) : (
                        <IoIosArrowDown  />
                      )}
                    </h1>
                    <div className="">
                      {leadTracker && (
                        <div className=" overflow-y-auto">
                          {menuItems.map((item, index) => (
                            <h1
                              key={index}
                              onClick={() => handleNavigation(item)}
                              className={`py-1 pl-9 text-[1rem] border-b-2 border-[#ecefff] cursor-pointer flex align-middle items-center rounded-md mb-1 ${activeButton === item.name
                                ? "bg-[#fff7fb] text-black"
                                : " hover:text-black"
                                }`}
                            >
                              {item.icon}&nbsp;{item.name}
                            </h1>
                          ))}
                        </div>
                      )}
                    </div>
                    {/* <h1
                      onClick={Financial}
                      className="flex items-center justify-between p-2 cursor-pointer hover:bg-white rounded-md my-2 hover:text-black"
                    >
                      <div
                        className="flex items-center"
                        style={{ paddingLeft: "5px" }}
                      >
                        <CurrencyExchangeIcon size={24} className="mr-1" />
                        &nbsp;
                        <p className="text-[1rem]">
                          Financial Tracker
                        </p>
                      </div>
                    </h1> */}

                    {/* <h1 className="flex items-center justify-between p-2 cursor-pointer hover:bg-white rounded-md my-2 hover:text-black">
                      <div
                        className="flex items-center"
                        style={{ paddingLeft: "5px" }}
                      >
                        <LibraryBooksIcon size={24} className="mr-1" />
                        &nbsp;Operation Tracker
                      </div>
                    </h1> */}
                    {/* <h1
                      onClick={ClientRating}
                      className="flex items-center justify-between p-2 cursor-pointer hover:bg-white rounded-md my-2 hover:text-black"
                    >
                      <div
                        className="flex items-center"
                        style={{ paddingLeft: "5px" }}
                      >
                        <ReviewsIcon size={24} className="mr-1" />
                        &nbsp; Clients Rating
                      </div>
                    </h1> */}

                    <h1
                      onClick={toggleMasters}
                      className="flex items-center bg-[#f9faff] border-b-2 border-[#ecefff] justify-between p-2 cursor-pointer   rounded-md "
                    >
                      <div className="flex items-center">
                        <MdFolderCopy size={24} className="" />
                        &nbsp;
                        <p className="text-md">Settings</p>
                      </div>
                      {masters ? (
                        <IoIosArrowUp />
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </h1>

                    {masters && (
                      <div className="ml-3">
                        {mastersMenuItems.map((item) => (
                          <h1
                            key={item.name}
                            onClick={() =>
                              handleMasterNavigation(item.name, item.path)
                            }
                            className={`p-1 cursor-pointer mx-3 border-b-2 border-[#ecefff] flex rounded-md mb-1 ${activeMasterButton === item.name
                                ? "bg-[#fff7fb] text-black"
                                : "hover:bg-white hover:text-black"
                              }`}
                          >
                            {item.icon}&nbsp;{item.name}
                          </h1>
                        ))}
                      </div>
                    )}

                    <h1
                      onClick={toggleContact}
                      className="flex items-center justify-between border-b-2 border-[#ecefff] p-2 bg-[#f9faff] cursor-pointer  rounded-md "
                    >
                      <div className="flex items-center">
                        <MdContacts size={24} className="" />
                        &nbsp;
                        <p className="text-[1rem]">Contact Us</p>
                      </div>
                      {Contact ? (
                        <IoIosArrowUp />
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </h1>

                    {Contact && (
                      <div className="ml-3">
                        <button className="p-1 cursor-pointer border-b-2 w-full border-[#ecefff]  flex align-middle items-center rounded-md hover:text-black ">
                          <IoIosMail size={24} />
                          &nbsp;admin@qtrackr.com
                        </button>
                      </div>
                    )}
                    {/* <h1
                      onClick={hardware}
                      className="flex items-center justify-between p-2 cursor-pointer hover:bg-white rounded-md my-2 hover:text-black"
                    >
                      <div
                        className="flex items-center"
                        style={{ paddingLeft: "5px" }}
                      >
                        <PhonelinkIcon size={24} className="mr-1" />
                        &nbsp;
                        <p className="text-[1rem]">Hardware </p>
                      </div>
                    </h1> */}
                    {/* <h1
                      onClick={toggleData}
                      className="flex items-center justify-between p-2 cursor-pointer hover:bg-white mx-1 rounded-md my-2 hover:text-black"
                    >
                      <div className="flex items-center">
                        <InsightsIcon size={22} />
                        &nbsp;
                        <p className="text-[1rem]">Data </p>
                      </div>
                      {dataDropDown ? (
                        <IoIosArrowUp size={22} />
                      ) : (
                        <IoIosArrowDown size={22} />
                      )}
                    </h1>
                    {dataDropDown && (
                      <div className="ml-3">
                        {dataTrackeritems.map((item) => (
                          <h1
                            key={item.name}
                            onClick={() =>
                              handleMasterNavigation(item.name, item.path)
                            }
                            className={`p-1 cursor-pointer mx-3 flex rounded-md mb-1 ${activeMasterButton === item.name
                                ? "bg-gray-100 text-black"
                                : "hover:bg-white hover:text-black"
                              }`}
                          >
                            {item.icon}&nbsp;{item.name}
                          </h1>
                        ))}
                      </div>
                    )} */}

                    {/* <h1
                      onClick={Installation}
                      className="flex items-center justify-between p-2 cursor-pointer hover:bg-white rounded-md my-2 hover:text-black"
                    >
                      <div
                        className="flex items-center"
                        style={{ paddingLeft: "5px" }}
                      >
                        <WifiProtectedSetupIcon size={24} className="mr-1" />
                        &nbsp;
                        <p className="text-[1rem]">
                          Installation
                        </p>
                      </div>
                    </h1> */}
                  </div>

                  {/* Fixed Logo at Bottom */}
                  <div className="w-full p-4 bg-white relative bottom-1 md:text-md lg:text-lg">
                    <img className="px-4" src={loram} alt="Loram Logo" />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default L1Sidebar;
