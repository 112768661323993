import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";

// **1. Fetch Files by ID**
export const fetchFileById = createAsyncThunk("files/fetchById", async (id) => {
  const response = await axiosInstance.get(`/api/files/${id}`);
  return response.data;
});

// **2. Fetch Files by Lead ID**
export const fetchFilesByLeadId = createAsyncThunk("files/fetchByLeadId", async (leadId) => {
  if (!leadId) return;
  const response = await axiosInstance.get(`/api/files/${leadId}`);
  return response.data;
});

// **3. Download File**
export const downloadFileByIdName = createAsyncThunk("files/download", async ({ id, filename }) => {
  const response = await axiosInstance.get(`/api/files/download/${id}/${filename}`, { responseType: "blob" });
  return response.data;
});

// **4. Upload File**
export const uploadFileInReport = createAsyncThunk("files/upload", async (formData) => {
  const response = await axiosInstance.post("/api/files", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
});

// **5. Delete File**
export const deleteFileByIdName = createAsyncThunk("files/delete", async ({ leadId, fileName }) => {
  await axiosInstance.delete(`/api/files/${leadId}/${fileName}`);
  return { leadId, fileName };
});

// **Slice Setup**
const fileSlice = createSlice({
  name: "files",
  initialState: {
    files: [],
    selectedFile: null,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      // Fetch File by ID
      .addCase(fetchFileById.pending, (state) => { state.loading = true; })
      .addCase(fetchFileById.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedFile = action.payload;
      })
      .addCase(fetchFileById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Files by Lead ID
      .addCase(fetchFilesByLeadId.pending, (state) => { state.loading = true; })
      .addCase(fetchFilesByLeadId.fulfilled, (state, action) => {
        state.loading = false;
        state.files = action.payload;
      })
      .addCase(fetchFilesByLeadId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Download File
      .addCase(downloadFileByIdName.pending, (state) => { state.loading = true; })
      .addCase(downloadFileByIdName.fulfilled, (state) => { state.loading = false; })
      .addCase(downloadFileByIdName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Upload File
      .addCase(uploadFileInReport.pending, (state) => { state.loading = true; })
      .addCase(uploadFileInReport.fulfilled, (state, action) => {
        state.loading = false;
        state.files.push(action.payload);
      })
      .addCase(uploadFileInReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Delete File
      .addCase(deleteFileByIdName.pending, (state) => { state.loading = true; })
      .addCase(deleteFileByIdName.fulfilled, (state, action) => {
        state.loading = false;
        state.files = state.files.filter(file => file.fileName !== action.payload.fileName);
      })
      .addCase(deleteFileByIdName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default fileSlice.reducer;
