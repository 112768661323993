import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FaTrash } from "react-icons/fa";
import { MdSearch, MdEditSquare } from "react-icons/md";
import ConfirmationModal from "./ConfirmationModal";
import AdminLoader from "../../loader/AdminLoader";
import L1Sidebar from "./L1Sidebar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaUserCircle } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteLead,
  updateLead,
} from "../../../redux/slices/clientMasterSlice";
import { logoutSession } from "../../../redux/slices/sessionSlice";
import { fetchLeads } from "../../../redux/slices/leadsSlice";

const ClientMaster = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showGroupDropdown, setShowGroupDropdown] = useState(false);

  const [isLoading, SetisLoading] = useState(false);

  const [showLogout, setshowlogout] = useState(false);
  const popupRef = useRef(null);
  const name = localStorage.getItem("name");

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setshowlogout(false); // Close the popup if clicked outside
    }
  };
  useEffect(() => {
    if (showLogout) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showLogout]);

  const logout = (e) => {
    e.preventDefault();
    setshowlogout(!showLogout);
  };
  const logoutUser = (e) => {
    e.preventDefault();
    dispatch(logoutSession())
    enqueueSnackbar("Successfully Logged out!", {
      variant: "success",
      autoHideDuration: 1500,
    });
    navigate("/");
  };



  const [LeadReports, setLeadReports] = useState([]);
  const [GroupNames, setGroupNames] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredLeadReports, setFilteredLeadReports] = useState([]);

  const dispatch = useDispatch(); // Initialize dispatch

  const { leads } = useSelector((state) => state.leads);
  const { group } = useSelector((state) => state.group);

  useEffect(()=>{
dispatch(fetchLeads())
  },[dispatch])

  useEffect(() => {
    setGroupNames(group);
  }, [group]);

  useEffect(() => {
    setLeadReports(leads);
  }, [leads]);

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    filterLeadReports(query);
    setCurrentPage(1);
  };
  const filterLeadReports = (query) => {
    const lowerCaseQuery = query.toLowerCase().trim(); // Normalize query
    if (!query.trim()) {
      setFilteredLeadReports(LeadReports); // Restore original data
      return;
    }

    const filtered = LeadReports.filter((LeadReport) =>
      Object.entries(LeadReport).some(([key, value]) => {
        if (
          key === "comments" ||
          key === "Reference" ||
          key === "QuotedValue" ||
          key === "Status" ||
          key === "FollowupDate" ||
          key === "TypeService" ||
          key === "Probability" ||
          key === "Source" ||
          key === "LeadType" ||
          key === "LeadDate"
        )
          return false;
        if (value !== null && value !== undefined) {
          let formattedValue = value.toString().trim().toLowerCase(); // Trim spaces & normalize
          return formattedValue.includes(lowerCaseQuery);
        }
        return false;
      })
    );

    setFilteredLeadReports(filtered);
  };

  const [ClientName, setClientName] = useState("");
  const [AssignedGroup, setAssignedGroup] = useState("");
  const [ProjectName, setProjectName] = useState("");
  const [ClientPhone, setClientPhone] = useState("");
  const [ClientContactName, setClientContactName] = useState("");
  const [ClientEmail, setClientEmail] = useState("");
  const [Status, setStatus] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [editid, setEditid] = useState("");

  const [editingLeadReport, setEditingLeadReport] = useState(null);
  const editLeadReport = (id) => {
    const ReportToEdit = LeadReports.find((LeadReport) => LeadReport.id === id);
    setEditingLeadReport(ReportToEdit);
    setShowEdit(true);
    setShowTable(false)
    setEditid(id);
  };

  useEffect(() => {
    if (editingLeadReport) {
      setClientName(editingLeadReport.ClientName);
      setClientContactName(editingLeadReport.ClientContactName);
      setClientPhone(editingLeadReport.ClientPhone);
      setClientEmail(editingLeadReport.ClientEmail);
      setProjectName(editingLeadReport.ProjectName);
      setAssignedGroup(editingLeadReport.AssignedGroup);
      setStatus(editingLeadReport.Status);
    }
  }, [editingLeadReport]);


  const Submit = async (e) => {
    e.preventDefault();

    if (
      !ClientName ||
      !AssignedGroup ||
      !ClientPhone ||
      !ClientContactName ||
      !Status ||
      !ProjectName
    ) {
      enqueueSnackbar("Please fill all the fields!", { variant: "error" });
      return;
    }

    SetisLoading(true);
    try {
      await dispatch(
        updateLead({
          id: editid,
          ClientName,
          ClientEmail,
          ProjectName,
          ClientContactName,
          AssignedGroup,
          ClientPhone,
        })
      ).unwrap(); // Ensures errors are caught properly

      enqueueSnackbar("Client Details Updated Successfully!", {
        variant: "success",
      });
      dispatch(fetchLeads())
      // Reset form fields
      setClientName("");
      setClientPhone("");
      setClientContactName("");
      setStatus("");
      setClientEmail("");
      setProjectName("");
      setAssignedGroup("");
      setShowEdit(false);
      setShowTable(true)
    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar(error || "Failed to update client details!", {
        variant: "error",
      });
    } finally {
      SetisLoading(false);
    }
  };

  const Cancel = (e) => {
    e.preventDefault();

    e.preventDefault();
    setClientName("");
    setClientEmail("");
    setClientPhone("");
    setClientContactName("");
    setStatus("");
    setProjectName("");
    setAssignedGroup("");
    setShowEdit(false);
    setShowTable(true)
  };

  const deleteLeadReport = async (id) => {
    try {
      await dispatch(deleteLead(id)).unwrap(); // Dispatch Redux action

      enqueueSnackbar("Client deleted successfully!", { variant: "success" });
     dispatch(fetchLeads())
      // No need to reload the page, Redux will update state automatically
    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar(error || "Failed to delete client!", {
        variant: "error",
      });
    }
  };


  useEffect(() => {
    setFilteredLeadReports(LeadReports);
  }, [LeadReports]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sourceToDelete, setsourceToDelete] = useState(null);

  const openDeleteModal = (id) => {
    setsourceToDelete(id);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setsourceToDelete(null);
  };

  const confirmDelete = async () => {
    if (sourceToDelete) {
      await deleteLeadReport(sourceToDelete); // Call your existing delete function
    }
    closeDeleteModal();
  };
  const dropdownRefGroup = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefGroup.current &&
        !dropdownRefGroup.current.contains(event.target)
      ) {
        setShowGroupDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (group) => {
    setAssignedGroup(group);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const [itemsPerPage] = useState(10);
  const totalItems = filteredLeadReports.length;
  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;

  // Function to calculate the number of pages
  const totalPages = Math.ceil(filteredLeadReports.length / itemsPerPage);

  //   // Slice the filteredLeadReport based on the current page
  const currentData = filteredLeadReports.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <div className=" bg-[#f3f8f8] pb-6 h-full">
        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={closeDeleteModal}
          onConfirm={confirmDelete}
        />

        <L1Sidebar />

        {isLoading ? (
          <AdminLoader isLoading={isLoading} />
        ) : (
          <>
            {showEdit && (
              <div className="ml-[20%] p-4 bg-white mb-16  border border-gray-200 relative  shadow-lg w-3/4 justify-center top-12">
                <h1 className="text-2xl mb-2 font-semibold">
                  Edit Client Details
                </h1>
                <hr />
                <hr />
                <br />
                <div className="flex justify-start w-full">
                  <div className="mx-3 w-full">
                    <label className="block" htmlFor="Name">
                      Client Name:
                    </label>
                    <input
                      className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                      type="text"
                      placeholder="Enter Name"
                      value={ClientName}
                      onChange={(e) => setClientName(e.target.value)}
                    />
                  </div>
                  <div className="mx-3 w-full">
                    <label className="block" htmlFor="Name">
                      Project Name:
                    </label>
                    <input
                      className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                      type="text"
                      placeholder="Enter Project Name"
                      value={ProjectName}
                      onChange={(e) => setProjectName(e.target.value)}
                    />
                  </div>
                  <div className="mx-3 w-full">
                    <label className="block" htmlFor="Name">
                      Client Contact Person Name:
                    </label>
                    <input
                      className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                      type="text"
                      placeholder="Enter Name"
                      value={ClientContactName}
                      onChange={(e) => setClientContactName(e.target.value)}
                    />
                  </div>
                </div>
                <br />
                <div className="flex justify-start w-full">
                  <div className="mx-3 w-full">
                    <label className="block" htmlFor="email">
                      Email ID:
                    </label>
                    <input
                      className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                      type="text"
                      placeholder="Enter Email ID"
                      value={ClientEmail}
                      onChange={(e) => setClientEmail(e.target.value)}
                    />
                  </div>
                  <div className="mx-3 w-full">
                    <label className="block" htmlFor="email">
                      Phone No.:
                    </label>
                    <input
                      className="border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none"
                      type="number"
                      placeholder="Enter Phone No."
                      value={ClientPhone}
                      onChange={(e) => setClientPhone(e.target.value)}
                    />
                  </div>

                  <div className="mx-3 w-full">
                    <label className="block" htmlFor="Name">
                      Group:
                    </label>
                    {/* <select className='border w-full border-gray-400 rounded-md p-2 overflow-hidden shadow-md outline-none' value={AssignedGroup} onChange={(e) => setAssignedGroup(e.target.value)} name="auth" id="auth">
                                        <option value="" disabled>Select Group</option>
                                        {GroupNames.map(group => (
                                            <option key={group.id} value={group.newGroupName}>{group.newGroupName}</option>
                                        ))}
                                    </select> */}
                    <div className=" ">
                      <div
                        ref={dropdownRefGroup}
                        className="relative flex shadow-sm p-0 -pb-4 rounded-md border border-gray-300 align-middle items-center"
                      >
                        <ExpandMoreIcon
                          color="white"
                          className="absolute cursor-pointer right-2 m-auto bg-white"
                          onClick={() =>
                            setShowGroupDropdown(!showGroupDropdown)
                          }
                        />

                        <button
                          type="button"
                          onClick={() =>
                            setShowGroupDropdown(!showGroupDropdown)
                          }
                          className="border border-gray-300 text-left rounded-md shadow-md outline-none p-1 w-full pl-2"
                        >
                          {AssignedGroup ? AssignedGroup : <p>Select Group</p>}
                        </button>

                        {showGroupDropdown && (
                          <div
                            style={{ zIndex: 9999 }}
                            className="absolute top-full left-0 w-full cursor-pointer max-h-96 overflow-y-auto bg-gray-100 text-sm border border-gray-300 shadow-lg flex flex-col gap-2"
                          >
                            <div
                              className="p-2 hover:bg-gray-200 cursor-pointer"
                              onClick={() => handleInputChange("All")}
                            >
                              All
                            </div>
                            {GroupNames.map((group) => (
                              <div
                                className="p-2 hover:bg-gray-200 cursor-pointer"
                                onClick={() =>
                                  handleInputChange(group.newGroupName)
                                }
                                key={group.id}
                                value={group.newGroupName}
                              >
                                {group.newGroupName}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="flex mt-14 justify-center">
                  <button
                    onClick={Submit}
                    className="mx-2 py-2 px-5 bg-blue-600 hover:bg-blue-900 overflow-hidden shadow-md text-white rounded-sm"
                  >
                    Confirm
                  </button>
                  <button
                    onClick={Cancel}
                    className="mx-2 py-2 px-5 bg-black hover:bg-white hover:text-black border border-black overflow-hidden shadow-md text-white rounded-sm"
                  >
                    Cancel
                  </button>
                </div>
                <br />
              </div>
            )}
          </>
        )}

        <div className="ml-[21%] w-3/4 relative pt-4">
          <div className="bg-white p-2  flex justify-between align-middle items-center rounded-md">
            <h1 className="text-xl font-semibold"> </h1>
            <div className="relative cursor-pointer bg-transparent">
              <FaUserCircle onClick={logout} className="text-black w-6 h-6" />
              {showLogout && (
                <div
                  ref={popupRef}
                  className="absolute right-0 top-full mt-1 text-black text-center px-2 w-36 shadow-2xl overflow-hidden bg-white border border-gray-400 z-50 rounded-lg"
                >
                  <h1 className="px-6 py-2 rounded-md my-1">{name}</h1>
                  <h1
                    onClick={logoutUser}
                    className="flex items-center gap-2 px-6 py-2 rounded-md my-1 cursor-pointer hover:bg-black hover:text-white"
                  >
                    <MdLogout size={24} /> Logout
                  </h1>
                </div>
              )}
            </div>
          </div>
          {showTable && ( 
          <div className=" p-4  bg-white py-5 border border-gray-200 relative shadow-lg w-full justify-center top-6 rounded-md">
         
            <div className="flex bg-white">
              <h1 className="text-2xl w-4/5 mb-2 font-semibold">
                All Clients{" "}
              </h1>
              <div className="relative flex w-64 justify-end">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  className="border  w-full border-gray-300 rounded-md mb-2 p-2 overflow-hidden shadow-md outline-none pl-4"
                  placeholder="Search"
                />
                <MdSearch
                  size={30}
                  className="mt-2 absolute right-1 top-3 transform -translate-y-1/2 text-gray-500"
                />
              </div>
            </div>
            <hr />
            <hr />
            <br />
            <div className="h-[30rem] overflow-y-scroll">
             
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-black sticky top-0">
                  <tr className="text-white">
                    <th className="px-3 py-3 w-fit text-center border text-xs font-medium uppercase tracking-wider">
                      #
                    </th>
                    <th className="px-3 py-3 w-fit text-center border text-xs font-medium uppercase tracking-wider">
                      Client Name
                    </th>
                    <th className="px-3 py-3 w-fit text-center border text-xs font-medium uppercase tracking-wider">
                      Project Name
                    </th>
                    <th className="px-3 py-3 w-fit text-center border text-xs font-medium uppercase tracking-wider">
                      Client Contact Person Name
                    </th>
                    <th className="px-3 py-3 w-fit text-center border text-xs font-medium uppercase tracking-wider">
                      Client Phone
                    </th>
                    <th className="px-3 py-3 w-fit text-center border text-xs font-medium uppercase tracking-wider">
                      Client Email
                    </th>
                    <th className="px-3 py-3 w-fit text-center border text-xs font-medium uppercase tracking-wider">
                      Group
                    </th>
                    <th className="px-6 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                      Edit
                    </th>
                    <th className="px-6 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                      <FaTrash size={18} />
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentData.length > 0 ? (
                    currentData.map((LeadReport, index) => (
                      <tr className="border text-sm" key={LeadReport.id}>
                        <td className="px-3 w-fit text-center py-2">
                          {index + 1}
                        </td>
                        <td className="px-3 w-fit text-center py-2">
                          {LeadReport.ClientName}
                        </td>
                        <td className="px-3 w-fit text-center py-2">
                          {LeadReport.ProjectName}
                        </td>
                        <td className="px-3 w-fit text-center py-2">
                          {LeadReport.ClientContactName}
                        </td>
                        <td className="px-3 w-fit text-center py-2">
                          +{LeadReport.ClientPhone}
                        </td>
                        <td className="px-3 w-fit text-center py-2">
                          {LeadReport.ClientEmail}
                        </td>
                        <td className="px-3 w-fit text-center py-2">
                          {LeadReport.AssignedGroup}
                        </td>
                        <td
                          onClick={() => editLeadReport(LeadReport.id)}
                          className="px-6 hover:bg-black cursor-pointer hover:text-white text-center py-2"
                        >
                          <MdEditSquare size={20} />
                        </td>
                        <td
                          onClick={() => openDeleteModal(LeadReport.id)}
                          className="px-6 hover:bg-black cursor-pointer hover:text-white text-center py-2"
                        >
                          <FaTrash size={18} />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td
                      colSpan="6"
                      className="border p-4 text-start text-red-500"
                    >
                      {searchQuery
                        ? `No results found for "${searchQuery}"`
                        : "No results found"}
                    </td>
                  )}
                </tbody>
              </table>
           
            </div>
            <div className="pagination mt-4 flex justify-between items-center">
              <div>
                {totalItems > 0 ? (
                  <>
                    Showing {startIdx + 1} to {Math.min(endIdx, totalItems)} of{" "}
                    {totalItems} Entries
                  </>
                ) : (
                  <>Showing 0 Entries</>
                )}
              </div>
              {totalPages > 0 && (
                <div>
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    className="px-4 py-2 mx-2 border rounded"
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>

                  {Array.from({ length: 3 }, (_, index) => {
                    // Calculate the page number for the current range
                    const pageNumber = currentPage - 1 + index;

                    // Ensure the pageNumber stays within bounds
                    if (pageNumber >= 1 && pageNumber <= totalPages) {
                      return (
                        <button
                          key={pageNumber}
                          onClick={() => setCurrentPage(pageNumber)}
                          className={`px-4 py-2 mx-2 border rounded ${
                            currentPage === pageNumber ? "bg-gray-300" : ""
                          }`}
                        >
                          {pageNumber}
                        </button>
                      );
                    }

                    // Return null for out-of-bounds values
                    return null;
                  })}

                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    className="px-4 py-2 mx-2 border rounded"
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </div> )}
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default ClientMaster;
