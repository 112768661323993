import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";

// Async Thunks
export const fetchHardware = createAsyncThunk("hardwareStore/fetchHardware", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get("/hardwareStore/hardware");
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message);
  }
});

export const addHardware = createAsyncThunk("hardwareStore/addHardware", async ({ hardwareName, hardwareCost }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post("/hardwareStore/hardwareStore", { hardwareName, hardwareCost });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message);
  }
});

export const updateHardware = createAsyncThunk("hardwareStore/updateHardware", async ({ hardwareid, hardwareName, hardwareCost }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.put(`/hardwareStore/hardware/${hardwareid}`, { hardwareName, hardwareCost });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message);
  }
});

export const deleteHardware = createAsyncThunk("hardwareStore/deleteHardware", async (id, { rejectWithValue }) => {
  try {
    await axiosInstance.delete(`/hardwareStore/hardware/${id}`);
    return id;
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message);
  }
});

// Slice
const hardwareStoreSlice = createSlice({
  name: "hardwareStore",
  initialState: {
    hardwareList: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHardware.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHardware.fulfilled, (state, action) => {
        state.loading = false;
        state.hardwareList = action.payload;
      })
      .addCase(fetchHardware.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addHardware.fulfilled, (state, action) => {
        state.hardwareList.push(action.payload);
      })
      .addCase(updateHardware.fulfilled, (state, action) => {
        const index = state.hardwareList.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.hardwareList[index] = action.payload;
        }
      })
      .addCase(deleteHardware.fulfilled, (state, action) => {
        state.hardwareList = state.hardwareList.filter(item => item.id !== action.payload);
      });
  }
});

export default hardwareStoreSlice.reducer;
