import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";

// **🔹 Update a Lead**
export const updateLead = createAsyncThunk(
  "leads/updateLead",
  async ({ id, ClientName, ClientEmail, ProjectName, ClientContactName, AssignedGroup, ClientPhone }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/leads/${id}`, {
        ClientName,
        ClientEmail,
        ProjectName,
        ClientContactName,
        AssignedGroup,
        ClientPhone,
      });
      return response.data;
    } catch (error) {
      console.error("Error updating lead:", error);
      return rejectWithValue(error.response?.data || "Failed to update lead");
    }
  }
);

// **🔹 Delete a Lead**
export const deleteLead = createAsyncThunk(
  "leads/deleteLead",
  async (id, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/leads/${id}`);
      return id; // Return the deleted lead ID
    } catch (error) {
      console.error("Error deleting lead:", error);
      return rejectWithValue(error.response?.data || "Failed to delete lead");
    }
  }
);

// **🔹 Lead Slice**
const leadSlice = createSlice({
  name: "leads",
  initialState: {
    leads: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // **Handle Update Lead**
      .addCase(updateLead.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateLead.fulfilled, (state, action) => {
        state.loading = false;
        state.leads = state.leads.map((lead) =>
          lead.id === action.payload.id ? action.payload : lead
        );
      })
      .addCase(updateLead.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // **Handle Delete Lead**
      .addCase(deleteLead.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteLead.fulfilled, (state, action) => {
        state.loading = false;
        state.leads = state.leads.filter((lead) => lead.id !== action.payload);
      })
      .addCase(deleteLead.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default leadSlice.reducer;
