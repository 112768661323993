import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FaTrash } from "react-icons/fa";
import { MdSearch, MdEditSquare } from "react-icons/md";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Loader from "../../../loader/Loader";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ConfirmationModal from "../../../pages/L1/ConfirmationModal";
import { deleteFileByIdName, downloadFileByIdName, fetchFilesByLeadId, uploadFileInReport } from "../../../../redux/slices/fileSlice";
import { useDispatch } from "react-redux";
import { deleteLead, fetchLeads } from "../../../../redux/slices/leadsSlice";

const FollowupTableL2 = ({ filteredLeadReports }) => {

  const [filteredLeadReportsLead, setFilteredLeadReports] = useState([]);
  const [LeadReports, setLeadReports] = useState([]);
  const [tableLeadReports, setTableLeadReports] = useState([]);

  const dispatch = useDispatch()

  useEffect(() => {
    setFilteredLeadReports(filteredLeadReports);
    setLeadReports(filteredLeadReports)
    setTableLeadReports(filteredLeadReports)
  }, [filteredLeadReports]);

  useEffect(() => {
    if (filteredLeadReports.length > 0) {
      setFilteredLeadReports(filteredLeadReports);
    }
  }, [filteredLeadReports]);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [uploadLoader, ] = useState(null);
  // const [uploadLoader, setUploadLoader] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(null);


  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    filterLeadReports(query);
    setCurrentPage(1);
  };

  const filterLeadReports = (query) => {
    const lowerCaseQuery = query.toLowerCase().trim(); // Normalize query
  
    const filtered = LeadReports.filter((LeadReport) =>
      Object.entries(LeadReport).some(([key, value]) => {
        if (key === "comments" || key === "Reference" || key === "ClientContactName"
           || key === "TypeService" || key === "Probability" || key === "ClientPhone" || key==="ClientEmail") return false; 
  
        if (value !== null && value !== undefined) {
          let formattedValue = value.toString().trim().toLowerCase(); // Trim spaces & normalize
  
          // ✅ Handle Date Formatting (if value is Date object)
          if (value instanceof Date) {
            formattedValue = formatDate(value).trim().toLowerCase();
          }
          // ✅ Handle Date String Formatting (if stored as string)
          else if (typeof value === "string") {
            const dateValue = parseDate(value);
            if (dateValue) {
              formattedValue = formatDate(dateValue).trim().toLowerCase();
            }
          }
  
          // ✅ Debugging (Check what is being searched)
  
          return formattedValue.includes(lowerCaseQuery);
        }
        return false;
      })
    );
  
    setTableLeadReports(filtered);
  };

  const parseDate = (dateStr) => {
    let parsedDate = new Date(dateStr);

    if (!isNaN(parsedDate.getTime())) {
      return parsedDate;
    }

    // Try to parse manually formatted "DD-MM-YYYY" dates
    const parts = dateStr.split("-");
    if (parts.length === 3) {
      const [day, month, year] = parts.map(Number);
      return new Date(year, month - 1, day);
    }

    return null;
  };


  // const { totalItems, totalPages, startIdx, endIdx } = useBackup("");
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = startIdx + itemsPerPage;

  const getRowClass = (followUpDate) => {
    if (!followUpDate) return ""; // No color if date is not present
    const today = new Date();
    const followDate = new Date(followUpDate);
    const diffInMonths =
      (followDate.getFullYear() - today.getFullYear()) * 12 +
      (followDate.getMonth() - today.getMonth());

    if (diffInMonths < 2) return "bg-red-600"; // Less than 2 months: Red
    if (diffInMonths >= 2 && diffInMonths < 4) return "bg-orange-500"; // 2-4 months: Orange
    if (diffInMonths >= 4) return "bg-green-500"; // More than 4 months: Green

    return "bg-green-500"; // Default to green
  };

  const getColorPriority = (date) => {
    if (!date) return 3; // Default lowest priority
    const today = new Date();
    const followDate = new Date(date);
    const diffInMonths =
      (followDate.getFullYear() - today.getFullYear()) * 12 +
      (followDate.getMonth() - today.getMonth());

    if (diffInMonths < 2) return 1; // Red has highest priority
    if (diffInMonths >= 2 && diffInMonths < 4) return 2; // Orange is second
    if (diffInMonths >= 4) return 3; // Green is last
    return 3;
  };

  const pageItems = tableLeadReports
    .filter((LeadReport) => {
      // Get current date and subtract 2 years
      const twoYearsAgo = new Date();
      twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);

      // Convert LeadDate string to Date object
      const leadDate = new Date(LeadReport.LeadDate);

      // Apply both filters
      return (
        leadDate >= twoYearsAgo && // Latest 2 years filter
        (LeadReport.Status === "Pending" ||
          LeadReport.Status === "Won Recurring") // Status filter
      );
    })
    .sort(
      (a, b) =>
        getColorPriority(a.FollowupDate) - getColorPriority(b.FollowupDate)
    );

  const currentItems = pageItems.slice(startIdx, endIdx);

  const totalItems = pageItems.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const extractDateTime = (timestamp) => {
    const dateObj = new Date(timestamp);

    // Extract and format the date as DD/MM/YYYY in local time
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = dateObj.getFullYear();
    const date = `${day}/${month}/${year}`;
    const dateValue = date === "NaN/NaN/NaN" ? "" : date;

    // Extract and format the time as HH:MM AM/PM in local time
    let hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const amPm = hours >= 12 ? "PM" : "AM";
    const time = `${formattedHours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm}`;
      const timeValue = time === "NaN:NaN AM" ? "" : time;

    // Return the formatted date and time in local timezone (GMT+)
    return { dateValue, timeValue };
  };


  const [popupVisibleId, setPopupVisibleId] = useState(null); // Tracks which popup is visible
  const [ uploadedFiles, setUploadedFiles ] = useState([]);
  const [fetchedfiles, setFetchedFiles] = useState([])
  const [currentFileName, setCurrentFileName] = useState("No File Selected");
    const [leadId, SetLeadId] = useState("")
  
    const handleCombineAction = (leadId) => {
      togglePopup(leadId); // Function to toggle the popup
      SetLeadId(leadId); // Function to fetch files
    };
  
  // Toggle the popup
  const togglePopup = (id) => {
    setPopupVisibleId(id === popupVisibleId ? null : id);
  };

const fetchFiles = useCallback(async (leadId) => {
    if (!leadId) return; // Prevent calling API if leadId is empty 
    setUploadedFiles([]);
    setFetchedFiles([]); 
    try {
      const response = await dispatch(fetchFilesByLeadId(leadId)).unwrap(); // Dispatch Redux action
      setUploadedFiles(response); // Save files in state
      setFetchedFiles(response);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  }, [dispatch, setUploadedFiles, setFetchedFiles]); 
  
   useEffect(() => {
     if (!leadId) {
       setUploadedFiles([]);
       setFetchedFiles([]);
       return;
     }
     fetchFiles(leadId);
   }, [leadId, fetchFiles,setUploadedFiles]);

   const handleFileUpload = async (event, id) => {
      const fileInput = event.target;
      const file = fileInput.files[0];
      if (!file) return;
    
      let fileName = file.name;
      const isFileNameExists = (name) =>
        fetchedfiles.some((uploadedFile) => uploadedFile.fileName === name);
      let fileType = file.type; // Get MIME type
      let fileExtension = fileName.split(".").pop().toLowerCase();
    
      if (file.size > 2 * 1024 * 1024) {
        enqueueSnackbar("File size must be less than 2MB.", {
          variant: "warning",
        });
        event.target.value = "";
        return;
      }
    
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      const allowedExtensions = ["pdf", "doc", "docx"];
    
      if (!allowedTypes.includes(fileType) || !allowedExtensions.includes(fileExtension)) {
        enqueueSnackbar("Only PDF and DOC/DOCX files are allowed.", { variant: "error" });
        event.target.value = "";
        return;
      }
    
      if (isFileNameExists(fileName)) {
        enqueueSnackbar("This file has already been uploaded. Please upload a different file.", {
          variant: "warning",
        });
        fileInput.value = null;
        return;
      }
    
      setCurrentFileName(fileName);
    
      const formData = new FormData();
      formData.append("file", file, fileName);
      formData.append("leadId", id);
      formData.append("uploadDate", new Date());
    
      try {
        await dispatch(uploadFileInReport(formData)).unwrap();
    
        enqueueSnackbar("File uploaded successfully", { variant: "success" });
        fetchFiles(leadId);
        fileInput.value = null;
        fetchFiles();
        setCurrentFileName("No File Selected");
      } catch (error) {
        enqueueSnackbar("Error Occurred While uploading file", { variant: "error" });
        console.error("Error uploading file:", error.response?.data?.error || error.message);
      }
    };

   const downloadFile = async (filename, id) => {
      setDownloadLoader(true);
      try {
        // Dispatch Redux action
        const response = await dispatch(downloadFileByIdName({ id, filename })).unwrap();
    
        // Create a Blob URL and trigger the download
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
    
        enqueueSnackbar("File downloaded successfully", { variant: "success" });
      } catch (error) {
        console.error("Error downloading the file:", error);
      } finally {
        setDownloadLoader(false);
      }
    };
   const deleteFile = async (fileName, leadId) => {
     setDeleteLoader(true);
     try {
       // Dispatch Redux action to delete file
       await dispatch(deleteFileByIdName({ leadId, fileName })).unwrap();
   
       enqueueSnackbar("File deleted successfully", { variant: "success" });
   
       // Update the state to remove the deleted file
       setUploadedFiles((prevFiles) =>
         prevFiles.filter(
           (file) => !(file.fileName === fileName && file.LeadReport?.id === leadId)
         )
       );
       fetchFiles(leadId)
       // Fetch updated file list after deletion
       dispatch(fetchFilesByLeadId(leadId));
       dispatch(fetchLeads())
     } catch (error) {
       console.error(
         "Error deleting the file:",
         error.response?.data || error.message
       );
       enqueueSnackbar("File failed to delete", { variant: "error" });
     } finally {
       setDeleteLoader(false);
     }
   };



 

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [LeadToDelete, setLeadToDelete] = useState(null);

  const openDeleteModal = (id) => {
    setLeadToDelete(id);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setLeadToDelete(null);
  };

  const confirmDelete = async () => {
    if (LeadToDelete) {
      await deleteLeadReport(LeadToDelete); // Call your existing delete function
    }
    closeDeleteModal();
  };

   const deleteLeadReport = async (id) => {
     try {
       const resultAction = await dispatch(deleteLead(id)); // Dispatch Redux thunk
 
       if (deleteLead.fulfilled.match(resultAction)) {
         enqueueSnackbar("Leadreport deleted successfully!", { variant: "success" });
         setFilteredLeadReports((prevReports) =>
           prevReports.filter((report) => report.id !== id)
         );
       } else {
         enqueueSnackbar("Failed to delete leadreport!", { variant: "error" });
       }
     } catch (error) {
       console.error("Error:", error);
       enqueueSnackbar("Connection Error!", { variant: "error" });
     }
   };

  const [, setRefreshTable] = useState(false);

  const sortAlphabetically = (targetToSort, order) => {
    const key = `${targetToSort}-${order}`;
    switch (key) {
      case "business-az":
        filteredLeadReportsLead.sort((a, b) =>
          a.AssignedGroup.localeCompare(b.AssignedGroup)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "business-za":
        filteredLeadReportsLead.sort((a, b) =>
          b.AssignedGroup.localeCompare(a.AssignedGroup)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "client-az":
        filteredLeadReportsLead.sort((a, b) =>
          a.ClientName.localeCompare(b.ClientName)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "client-za":
        filteredLeadReportsLead.sort((a, b) =>
          b.ClientName.localeCompare(a.ClientName)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "project-az":
        filteredLeadReportsLead.sort((a, b) =>
          a.ProjectName.localeCompare(b.ProjectName)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "project-za":
        filteredLeadReportsLead.sort((a, b) =>
          b.ProjectName.localeCompare(a.ProjectName)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "leadtype-az":
        filteredLeadReportsLead.sort((a, b) =>
          a.LeadType.localeCompare(b.LeadType)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "leadtype-za":
        filteredLeadReportsLead.sort((a, b) =>
          b.LeadType.localeCompare(a.LeadType)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "leadstatus-az":
        filteredLeadReportsLead.sort((a, b) =>
          a.Status.localeCompare(b.Status)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "leadstatus-za":
        filteredLeadReportsLead.sort((a, b) =>
          b.Status.localeCompare(a.Status)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "leadate-az": // Oldest to Latest
        filteredLeadReportsLead.sort(
          (a, b) => new Date(a.LeadDate) - new Date(b.LeadDate)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "leadate-za": // Latest to Oldest
        filteredLeadReportsLead.sort(
          (a, b) => new Date(b.LeadDate) - new Date(a.LeadDate)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "followupdate-az": // Oldest to Latest, N/A at end
        filteredLeadReportsLead.sort((a, b) => {
          if (!a.FollowupDate) return 1; // Move N/A to the end
          if (!b.FollowupDate) return -1;
          return new Date(a.FollowupDate) - new Date(b.FollowupDate);
        });
        setRefreshTable((prev) => !prev);
        break;

      case "followupdate-za": // Latest to Oldest, N/A at end
        filteredLeadReportsLead.sort((a, b) => {
          if (!a.FollowupDate) return 1; // Move N/A to the end
          if (!b.FollowupDate) return -1;
          return new Date(b.FollowupDate) - new Date(a.FollowupDate);
        });
        setRefreshTable((prev) => !prev);
        break;

      default:
    }
  };

  const numericSort = (order) => {

    order === "az"
      ? filteredLeadReportsLead.sort(
          (a, b) => Number(a.QuotedValue) - Number(b.QuotedValue)
        )
      : filteredLeadReportsLead.sort(
          (a, b) => Number(b.QuotedValue) - Number(a.QuotedValue)
        );

    setRefreshTable((prev) => !prev);
  };

  return (
    <div>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={closeDeleteModal}
        onConfirm={confirmDelete}
      />
        <div className="w-full bg-white rounded-md p-3">
            <div className="flex py-2 align-middle">
              <h1 className="text-2xl w-4/5 mb-2 font-semibold">
                Follow-up Table
              </h1>

              <div className="flex gap-2 p-1 align-middle items-center bg-[#e0e7ff] rounded-full md:w-1/5">
                <MdSearch
                  className=" w-5 h-5 text-gray-500"
                />
                <input type="text" value={searchQuery}
                  onChange={handleSearchInputChange}
                  placeholder="Search"
                  className="bg-[#e0e7ff] outline-none w-[80%]"
                />
              </div>
            </div>
            <div className="min-h-[25rem] overflow-auto">
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className=" bg-black  sticky top-0">
                  <tr className=" text-white">
                    <th className=" px-4 py-3 text-center border border-white text-sm font-medium uppercase tracking-wider">
                      #
                    </th>
                    <th className=" px-4 py-3 border border-white text-center text-sm font-medium uppercase tracking-wider">
                      <div className="flex items-center justify-center gap-x-4">
                        Lead Date
                        <div className="flex flex-col gap-y-1">
                          <div
                            className="cursor-pointer "
                            onClick={() => sortAlphabetically("leadate", "az")}
                          >
                            <ArrowDropUp
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>

                          <div
                            className="cursor-pointer -mt-2"
                            onClick={() => sortAlphabetically("leadate", "za")}
                          >
                            <ArrowDropDown
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="px-4 py-3 text-center border-white border text-sm font-medium uppercase tracking-wider">
                      <div className="flex items-center justify-center gap-x-4">
                        Client Name
                        <div className="flex flex-col gap-y-1">
                          <div
                            className="cursor-pointer "
                            onClick={() => sortAlphabetically("client", "az")}
                          >
                            <ArrowDropUp
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>

                          <div
                            className="cursor-pointer -mt-2"
                            onClick={() => sortAlphabetically("client", "za")}
                          >
                            <ArrowDropDown
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="px-4 py-3 text-center border border-white text-sm font-medium uppercase tracking-wider">
                      <div className="flex items-center justify-center gap-x-4">
                        Project Name
                        <div className="flex flex-col gap-y-1">
                          <div
                            className="cursor-pointer"
                            onClick={() => sortAlphabetically("project", "az")}
                          >
                            <ArrowDropUp
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>

                          <div
                            className="cursor-pointer -mt-2"
                            onClick={() => sortAlphabetically("project", "za")}
                          >
                            <ArrowDropDown
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="px-4 py-3 text-center border border-white text-sm font-medium uppercase tracking-wider">
                      <div className="flex items-center justify-center gap-x-4">
                        Lead type
                        <div className="flex flex-col gap-y-1">
                          <div
                            className="cursor-pointer "
                            onClick={() => sortAlphabetically("leadtype", "az")}
                          >
                            <ArrowDropUp
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>

                          <div
                            className="cursor-pointer -mt-2"
                            onClick={() => sortAlphabetically("leadtype", "za")}
                          >
                            <ArrowDropDown
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="px-4 py-3 text-center border border-white text-sm font-medium uppercase tracking-wider">
                      <div className="flex items-center justify-center">
                        Quoted Value
                        <div className="flex flex-col gap-y-1">
                          <div
                            className="cursor-pointer"
                            onClick={() => numericSort("za")}
                          >
                            <ArrowDropUp
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>

                          <div
                            className="cursor-pointer -mt-2"
                            onClick={() => numericSort("az")}
                          >
                            <ArrowDropDown
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="px-4 py-3 text-center border border-white text-sm font-medium uppercase tracking-wider">
                      <div className="flex items-center justify-center gap-x-4">
                        Status
                        <div className="flex flex-col gap-y-1">
                          <div
                            className="cursor-pointer "
                            onClick={() =>
                              sortAlphabetically("leadstatus", "az")
                            }
                          >
                            <ArrowDropUp
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>

                          <div
                            className="cursor-pointer -mt-2"
                            onClick={() =>
                              sortAlphabetically("leadstatus", "za")
                            }
                          >
                            <ArrowDropDown
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="px-4 py-3 text-center border border-white text-sm font-medium uppercase tracking-wider">
                      <div className="flex items-center justify-center">
                        Group
                        <div className="flex flex-col gap-y-1">
                          <div
                            className="cursor-pointer"
                            onClick={() => sortAlphabetically("business", "az")}
                          >
                            <ArrowDropUp
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>

                          <div
                            className="cursor-pointer -mt-2"
                            onClick={() => sortAlphabetically("business", "za")}
                          >
                            <ArrowDropDown
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="px-4 py-3 text-center border border-white text-sm font-medium uppercase tracking-wider">
                      <div className="flex items-center justify-center">
                        Follow-up Date
                        <div className="flex flex-col gap-y-1">
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              sortAlphabetically("followupdate", "az")
                            }
                          >
                            <ArrowDropUp
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>

                          <div
                            className="cursor-pointer -mt-2"
                            onClick={() =>
                              sortAlphabetically("followupdate", "za")
                            }
                          >
                            <ArrowDropDown
                              color="white"
                              sx={{ fontSize: "22px" }}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                    <th className="px-1 py-3 text-center border border-white text-sm font-medium uppercase tracking-wider">
                      Attachments
                    </th>
                    <th className="px-4 py-3 text-center border border-white text-sm font-medium uppercase tracking-wider">
                      Edit
                    </th>
                    <th className="px-8 py-3 text-center border border-white text-sm font-medium uppercase tracking-wider">
                      <FaTrash size={18} />
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentItems.length > 0 ? (
                    currentItems.map((LeadReport, index) => (
                      <tr
                        className={`border text-sm  ${getRowClass(
                          LeadReport.FollowupDate
                        )}`}
                      >
                        <td className="px-3 text-center py-2 border-b border-[#e5e7eb] ">
                          {index + startIdx + 1}
                        </td>
                        <td className="px-3 text-center py-2 border-b border-[#e5e7eb]">
                          {LeadReport.LeadDate
                            ? formatDate(LeadReport.LeadDate)
                            : "N/A"}
                        </td>
                        <td className="px-3 text-center py-2 border-b border-[#e5e7eb]">
                          {LeadReport.ClientName}
                        </td>
                        <td className="px-3 text-center py-2 border-b border-[#e5e7eb]">
                          {LeadReport.ProjectName}
                        </td>
                        <td className="px-3 text-center py-2 border-b border-[#e5e7eb]">
                          {LeadReport.LeadType}
                        </td>
                        <td className="px-3 text-center py-2 border-b border-[#e5e7eb]">
                          $
                          {LeadReport.QuotedValue.toString().replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </td>
                        <td className="px-3 text-center py-2 border-b border-[#e5e7eb]">
                          {LeadReport.Status}
                        </td>
                        <td className="px-3 text-center py-2 border-b border-[#e5e7eb]">
                          {LeadReport.AssignedGroup}
                        </td>
                        <td className="px-3 text-center py-3 border-b border-[#e5e7eb]">
                          {LeadReport.Status === "Won" ||
                            LeadReport.Status === "Lost"
                            ? "N/A"
                            : formatDate(LeadReport.FollowupDate)}
                        </td>

                        <td className="px-3 relative  py-3 border-b border-[#e5e7eb]">
                          <AttachFileIcon
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-12 cursor-pointer"
                            onClick={() => handleCombineAction(LeadReport.id)}
                          />


                          {popupVisibleId === LeadReport.id && (
                            <div
                              id="file-upload-popup"
                              className="fixed inset-0 flex justify-center items-center z-50 bg-opacity-50 bg-black"
                            >
                              <div className="max-w-5xl bg-white shadow-lg rounded-lg p-6">
                                <div className="flex justify-between items-center mb-6">
                                  <h2 className="text-lg font-bold">
                                    Attachments
                                  </h2>
                                  <button
                                    className="text-gray-500 hover:text-black"
                                    onClick={() => togglePopup(LeadReport.id)} // Close popup
                                  >
                                    Close
                                  </button>
                                </div>

                                <div className="flex items-start gap-4">
                                  {uploadLoader ? (
                                    <Loader isLoading={uploadLoader} />
                                  ) : (
                                    <div className="flex items-center mb-6">
                                      <input
                                        type="file"
                                        id={`file-upload-${LeadReport.id}`}
                                        className="hidden"
                                        onChange={(e) =>
                                          handleFileUpload(e, LeadReport.id)
                                        } // Use your predefined function
                                      />
                                      <label
                                        htmlFor={`file-upload-${LeadReport.id}`}
                                        className="bg-gray-200 text-sm px-4 py-2 rounded-md cursor-pointer hover:bg-gray-300"
                                      >
                                        Upload File
                                      </label>
                                      <span className="">{currentFileName}</span>
                                    </div>
                                  )}

                                  <div className="flex gap-2 text-red-500">
                                    <h4 className="font-bold text-md">*</h4>
                                    <p>
                                      Please Upload .pdf and .docx files only
                                    </p>
                                  </div>
                                </div>

                                <div className="max-h-[20rem] overflow-auto">
                                  <table className="w-full text-left border-collapse max-h-[20rem] overflow-auto">
                                    <thead>
                                      <tr className="border-b">
                                        <th className="py-2 px-4">Sr. No.</th>
                                        <th className="py-2 px-4">File Name</th>
                                        <th className="py-2 px-4">Date</th>
                                        <th className="py-2 px-4 text-center">
                                          Time
                                        </th>
                                        <th className="py-2 px-4 text-right">
                                          Download
                                        </th>
                                        <th className="py-2 px-4 text-right">
                                          Delete
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {deleteLoader || downloadLoader ? (
                                        <Loader
                                          isLoading={
                                            deleteLoader || downloadLoader
                                          }
                                        />
                                      ) : (
                                        uploadedFiles
                                          .filter(
                                            (file) =>
                                              file.leadId === LeadReport.id
                                          ) // Filter files for the given Id
                                          .map((file, index) => (
                                            <tr
                                              key={file.fileName}
                                              className="border-b"
                                            >
                                              <td className="py-2 px-4">
                                                {index + 1}
                                              </td>
                                              <td className="py-2 px-4">
                                                {file.fileName}
                                              </td>

                                              <td className="py-2 px-4">
                                                {
                                                  extractDateTime(
                                                    file.uploadDate
                                                  ).dateValue
                                                }
                                              </td>

                                              <td className="py-2 px-4 text-center">
                                                {
                                                  extractDateTime(
                                                    file.uploadDate
                                                  ).timeValue
                                                }
                                              </td>

                                              <td className="py-2 px-4 text-center space-x-2">
                                                <button
                                                  className="bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-700"
                                                  onClick={() =>
                                                    downloadFile(
                                                      file.fileName,
                                                      LeadReport.id
                                                    )
                                                  } // Use your predefined function
                                                >
                                                  <CloudDownloadIcon />
                                                </button>
                                              </td>
                                              <td className="py-2 px-4 text-center space-x-2">
                                                <button
                                                  className="bg-red-500 text-white px-2 py-2 rounded hover:bg-red-700"
                                                  onClick={() =>
                                                    deleteFile(
                                                      file.fileName,
                                                      LeadReport.id
                                                    )
                                                  }
                                                >
                                                  <FaTrash size={18} />
                                                </button>
                                              </td>
                                            </tr>
                                          ))
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )}
                        </td>

                        <td
                          onClick={() => navigate(`/dashboard/L2/edit-lead/${LeadReport.id}`)}
                          className="px-4 hover:bg-black cursor-pointer hover:text-white text-center py-2 border-b border-[#e5e7eb]"
                        >
                          <span title="Edit">
                            <MdEditSquare className="w-5 h-5" />
                          </span>
                        </td>
                        <td
                          onClick={() => openDeleteModal(LeadReport.id)}
                          className="px-8 hover:bg-black cursor-pointer hover:text-white text-center py-2 border-b border-[#e5e7eb]"
                        >
                          <span title="Delete">
                            <FaTrash className="w-5 h-5" />
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan="9" className="p-4 text-red-500 text-center font-semibold h-[20rem] text-[17px]">
                    {searchQuery ? `No result found fot "${searchQuery}"`:"No result found"}
                  </td>
                  )}
                </tbody>
              </table>


            </div>
            
               <div className="pagination mt-4 flex justify-between items-center bg-white rounded-md p-1">
            <div>
              {totalItems > 0 ? (
                <>
                  Showing {startIdx + 1} to {Math.min(endIdx, totalItems)} of{" "}
                  {totalItems} Entries
                </>
              ) : (
                <>Showing 0 Entries</>
              )}
            </div>
            {totalPages > 1 && (
              <div>
                <button
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  className="px-4 py-2 mx-2 border rounded"
                  disabled={currentPage === 1}
                >
                  Previous
                </button>

                {Array.from({ length: 3 }, (_, index) => {
                  // Calculate the page number for the current range
                  const pageNumber = currentPage - 1 + index;

                  // Ensure the pageNumber stays within bounds
                  if (pageNumber >= 1 && pageNumber <= totalPages) {
                    return (
                      <button
                        key={pageNumber}
                        onClick={() => setCurrentPage(pageNumber)}
                        className={`px-4 py-2 mx-2 border rounded ${currentPage === pageNumber ? "bg-gray-300" : ""
                          }`}
                      >
                        {pageNumber}
                      </button>
                    );
                  }

                  // Return null for out-of-bounds values
                  return null;
                })}

                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  className="px-4 py-2 mx-2 border rounded"
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            )}
          </div>

          </div>
    </div>
  );
};

export default FollowupTableL2;
