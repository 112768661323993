import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";

// Define async thunks to fetch data
export const fetchStatus = createAsyncThunk("data/fetchStatus", async () => {
  const response = await axiosInstance.get("/status");
  return response.data;
});

export const fetchSource = createAsyncThunk("data/fetchSource", async () => {
  const response = await axiosInstance.get("/source");
  return response.data;
});

export const fetchServiceTypes = createAsyncThunk("data/fetchServiceTypes", async () => {
  const response = await axiosInstance.get("/serviceTypes");
  return response.data;
});

export const fetchLeadTypes = createAsyncThunk("data/fetchLeadTypes", async () => {
  const response = await axiosInstance.get("/leadTypes");
  return response.data;
});

// Create the slice
const dataSliceL3 = createSlice({
  name: "data",
  initialState: {
    status: [],
    source: [],
    serviceTypes: [],
    leadTypes: [],
    loading: false,
    error: null,
  },
  reducers: {}, // No manual reducers needed since we're using async thunks
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatus.pending, (state) => { state.loading = true; })
      .addCase(fetchStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.status = action.payload;
      })
      .addCase(fetchStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      
      .addCase(fetchSource.pending, (state) => { state.loading = true; })
      .addCase(fetchSource.fulfilled, (state, action) => {
        state.loading = false;
        state.source = action.payload;
      })
      .addCase(fetchSource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchServiceTypes.pending, (state) => { state.loading = true; })
      .addCase(fetchServiceTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceTypes = action.payload;
      })
      .addCase(fetchServiceTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(fetchLeadTypes.pending, (state) => { state.loading = true; })
      .addCase(fetchLeadTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.leadTypes = action.payload;
      })
      .addCase(fetchLeadTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default dataSliceL3.reducer;
