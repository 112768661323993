import React, { useState } from "react";
import { useParams } from "react-router-dom";
// import axios from "axios";
import AdminLoader from "../../loader/AdminLoader";
import L1Sidebar from "./L1Sidebar";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import StarsIcon from "@mui/icons-material/Stars";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import SportsVolleyballIcon from "@mui/icons-material/SportsVolleyball";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

const ClientRatingDashboardL1 = () => {
  const navigate = useNavigate();
  const { clientName } = useParams();
  const [isLoading] = useState(false);

  const [clientData,] = useState({
    totalWorkHistory: "",
    avgContractAmount: "",
    avgConversionRate: "",
    avgEaseOfBusiness: "",
    totalInteractions: 0,
    starRating: "",
  });

  
  interface StarRatingProps {
    rating: number;
  }

  const StarRating: React.FC<StarRatingProps> = ({ rating }) => {
    const safeRating = isNaN(rating) || rating < 0 ? 0 : rating; // Ensure valid rating
    const fullStars = Math.floor(safeRating);
    const hasHalfStar = safeRating % 1 >= 0.25 && safeRating % 1 <= 0.75;
    const emptyStars = Math.max(5 - fullStars - (hasHalfStar ? 1 : 0), 0); // Prevent negative array length

    return (
      <div className="flex items-center text-yellow-500">
        {[...Array(fullStars)].map((_, i) => (
          <FaStar key={i} />
        ))}
        {hasHalfStar && <FaStarHalfAlt />}
        {[...Array(emptyStars)].map((_, i) => (
          <FaRegStar key={i} />
        ))}
      </div>
    );
  };

  return (
    <>
      <L1Sidebar />

      {isLoading ? (
        <AdminLoader isLoading={isLoading} />
      ) : (
        <div className="ml-[18%] p-4 relative justify-center top-12 bg-slate-100 border border-gray-200 rounded-lg shadow-sm mr-5">
          <ArrowBackIcon
            className="w-12 h-9 mb-2 cursor-pointer"
            onClick={() => navigate(-1)} // Navigates back
          />
          <div className=" flex justify-between align-middle items-center">
            <h1 className="text-2xl w-4/5 mb-2 font-semibold">
              Clients Rating
            </h1>
          </div>
          <div className="flex gap-2 align-middle items-center border-b-2 border-gray-300 py-3">
            <button className="flex py-2 px-4 gap-2 bg-white border border-slate-400 rounded-md">
              <h1>Name:</h1>
              <h1> {clientName}</h1>
            </button>
            <div className="stars">
              <StarRating rating={clientData.starRating} />
            </div>
          </div>
          <div className="w-full flex gap-2 py-2">
            <div
              className="bg-[#0000e6] text-white  w-[20%] px-3 py-5  border border-gray-200 rounded-lg shadow-lg"
              style={{
                boxshadow:
                  "0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 8px 8px rgba(0,0,0,0.11), 0 16px 16px rgba(0,0,0,0.11), 0 32px 32px rgba(0,0,0,0.11)",
              }}
            >
              <AlarmOnIcon className="w-12 h-9 mb-2" />
              <p className="font-semibold mb-2 text-sm">Work History</p>
              <p className="font-semibold text-xl">
                {clientData.totalWorkHistory}
              </p>
            </div>
            <div
              className="bg-[#007300] text-white shadow-lg w-[20%] rounded-md px-3 py-5 border"
              style={{
                boxshadow:
                  "0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 8px 8px rgba(0,0,0,0.11), 0 16px 16px rgba(0,0,0,0.11), 0 32px 32px rgba(0,0,0,0.11)",
              }}
            >
              <StarsIcon className="w-12 h-9 mb-2" />
              <p className="font-semibold mb-2 text-sm">Contract Amount</p>
              <p className="font-semibold text-xl">
                {clientData.avgContractAmount}
              </p>
            </div>
            <div
              className="bg-[#ff9c00] text-white shadow-lg w-[20%] rounded-md px-3 py-5 border"
              style={{
                boxshadow:
                  "0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 8px 8px rgba(0,0,0,0.11), 0 16px 16px rgba(0,0,0,0.11), 0 32px 32px rgba(0,0,0,0.11)",
              }}
            >
              <MoveDownIcon className="w-12 h-9 mb-2" />
              <p className="font-semibold mb-2 text-sm">Conversion Rate</p>
              <p className="font-semibold text-xl">
                {clientData.avgConversionRate}%
              </p>
            </div>
            <div
              className="bg-red-700 text-white  shadow-lg w-[20%] rounded-md px-3 py-5 border"
              style={{
                boxshadow:
                  "0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 8px 8px rgba(0,0,0,0.11), 0 16px 16px rgba(0,0,0,0.11), 0 32px 32px rgba(0,0,0,0.11)",
              }}
            >
              <SportsVolleyballIcon className="w-12 h-9 mb-2" />
              <p className="font-semibold mb-2 text-sm">EaseOfBusiness</p>
              <p className="font-semibold text-xl">
                {" "}
                {clientData.avgEaseOfBusiness}
              </p>
            </div>
            <div
              className="bg-[#730073] text-white  shadow-lg w-[20%] rounded-md px-3 py-5 border"
              style={{
                boxshadow:
                  "0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 8px 8px rgba(0,0,0,0.11), 0 16px 16px rgba(0,0,0,0.11), 0 32px 32px rgba(0,0,0,0.11)",
              }}
            >
              <SportsVolleyballIcon className="w-12 h-9 mb-2" />
              <p className="font-semibold mb-2 text-sm">Interactions</p>
              <p className="font-semibold text-xl">
                {" "}
                {clientData.totalInteractions}
              </p>
            </div>
          </div>

          <div></div>
        </div>
      )}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default ClientRatingDashboardL1;
