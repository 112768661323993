import React, { useCallback, useEffect, useRef, useState } from "react";
import TableChartIcon from "@mui/icons-material/TableChart";
import Chart from "chart.js/auto";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Document, Packer, Paragraph, Table, TableCell, TableRow } from "docx";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLead } from "../../../../context/LeadReport";
import { useDispatch, useSelector } from "react-redux";
import { fetchSources } from './../../../../redux/slices/sourceSlice';
import { setSourceFromDateReduxL2, setSourceReduxL2, setSourceToDateReduxL2 } from "../../../../redux/slices/filterSlice";

const LeadSourceL2 = ({ filteredLeadReports }) => {

  const {setLeadSourceReportL2_12} = useLead()
    const [leadsourceDownload, setLeadSourceDownload] = useState([]);
    const navigate = useNavigate();
  
    const [leadTab, setLeadTab] = useState("graph");
    const sourceChartRef = useRef(null);
    const sourcePieChart = useRef();
  
   
    const [selectedSourcefinal] = useState("All");
    const [potentialSource, setPotentialSource] = useState([]);
    const [Sources, setSources] = useState([]);
const dispatch = useDispatch()
const {source} = useSelector((state)=> state.source);

useEffect(()=>{
dispatch(fetchSources())
},[dispatch])

useEffect(()=>{
  setSources(source)
},[source])

const [sourceFilterDateFrom, setSourceFilterDateFrom] = useState();
const [sourceFilterDateTo, setSourceFilterDateTo] = useState();
const [selectedSource, setSelectedSource] = useState("All");

const handleSourceDateFrom = (date)=>{
  if(!date) return;
  setSourceFilterDateFrom(date);
  dispatch(setSourceFromDateReduxL2(date))
}
const handleSourceDateTo = (date)=>{
  if(!date) return;
  setSourceFilterDateTo(date);
  dispatch(setSourceToDateReduxL2(date))
}
const handleSource = (source)=>{
  if(!source) return;
  setSelectedSource(source)
  dispatch(setSourceReduxL2(source))
  setshowsourceDropdown(false); // Close dropdown on selection
}

  const sourceFilterDateFromReduxL2 = useSelector((state) => state.filters.sourceFilterDateFromL2);
  const sourceFilterDateToReduxL2 = useSelector((state) => state.filters.sourceFilterToDateL2);
  const sourceFilterReduxL2 = useSelector((state) => state.filters.sourceFilterL2);

useEffect(()=>{
if(sourceFilterDateFromReduxL2 !== undefined && sourceFilterDateFromReduxL2 !== null){
  setSourceFilterDateFrom(sourceFilterDateFromReduxL2)
}else{setSourceFilterDateFrom("")}
},[sourceFilterDateFromReduxL2])

useEffect(()=>{
  if(sourceFilterDateToReduxL2 !== undefined && sourceFilterDateToReduxL2 !== null){
    setSourceFilterDateTo(sourceFilterDateToReduxL2)
  }else{setSourceFilterDateTo("")}
  },[sourceFilterDateToReduxL2])

  useEffect(()=>{
    if(sourceFilterReduxL2 !== undefined && sourceFilterReduxL2 !== null){
      setSelectedSource(sourceFilterReduxL2)
    }else{setSelectedSource("All")}
    },[sourceFilterReduxL2])



    const StatusdropdownRef = useRef(null);
    const [showsourseDropdown, setshowsourceDropdown] = useState(false);
    // Handle clicks outside the dropdown to close it
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          StatusdropdownRef.current &&
          !StatusdropdownRef.current.contains(event.target)
        ) {
          setshowsourceDropdown(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
    const formatDateExcel = (date) => {
      const d = new Date(date);
      const yy = d.getFullYear().toString().slice(-2); // Extract last 2 digits of the year
      const mm = String(d.getMonth() + 1).padStart(2, "0"); // Month (0-based)
      const dd = String(d.getDate()).padStart(2, "0"); // Day
      return `${yy}-${mm}-${dd}`;
    };
  
    const handleDownload = (event) => {
      const value = event.target.value;
  
      if (value === "pdf") {
        downloadPDF();
      } else if (value === "word") {
        downloadWord();
      } else if (value === "excel") {
        downloadExcel();
      }
      setIsDropdownVisible(false);
    };
  
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
      return `${month}-${day}-${year}`;
    };
  
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  
    const toggleDropdown = () => {
      setIsDropdownVisible(!isDropdownVisible); // Toggle dropdown visibility
    };
  
    const downloadExcel = () => {
      const sortedLeads = [...leadsourceDownload].sort(
        (a, b) => new Date(a.LeadDate) - new Date(b.LeadDate)
      );
  
      const ws = XLSX.utils.json_to_sheet(
        sortedLeads.map((LeadReport, index) => ({
          "#": index + 1,
          "Lead Date": LeadReport.LeadDate
            ? formatDateExcel(LeadReport.LeadDate)
            : "N/A",
          "Client Name": LeadReport.ClientName,
          "Project Name": LeadReport.ProjectName,
          "Lead Type": LeadReport.LeadType,
          "Quoted Value": Number(LeadReport.QuotedValue), // ✅ Ensure it's a number
          Source: LeadReport.Source,
          Status: LeadReport.Status,
          Group: LeadReport.AssignedGroup,
          "Follow-up Date":
            LeadReport.Status === "Won" || LeadReport.Status === "Lost"
              ? "N/A"
              : formatDate(LeadReport.FollowupDate),
        }))
      );
  
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Lead Reports");
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const excelFile = new Blob([excelBuffer], {
        bookType: "xlsx",
        type: "application/octet-stream",
      });
      saveAs(excelFile, "LeadReports.xlsx");
    };
  
    const downloadPDF = () => {
      const doc = new jsPDF();
      doc.autoTable({
        head: [
          [
            "#",
            "Lead Date",
            "Client Name",
            "Project Name",
            "Lead Type",
            "Quoted Value",
            "Source",
            "Status",
            "Group",
            "Follow-up Date",
          ],
        ],
        body: leadsourceDownload.map((LeadReport, index) => [
          index + 1,
          LeadReport.LeadDate ? formatDate(LeadReport.LeadDate) : "N/A",
          LeadReport.ClientName,
          LeadReport.ProjectName,
          LeadReport.LeadType,
          `$${LeadReport.QuotedValue.toString().replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          )}`,
          LeadReport.Source,
          LeadReport.Status,
          LeadReport.AssignedGroup,
          LeadReport.Status === "Won" || LeadReport.Status === "Lost"
            ? "N/A"
            : formatDate(LeadReport.FollowupDate),
        ]),
        styles: {
          fontSize: 7,
        },
        headStyles: {
          fontSize: 8,
        },
      });
      doc.save("LeadReports.pdf");
    };
  
    const downloadWord = () => {
      const headerRow = new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph("S.No")],
          }),
          new TableCell({
            children: [new Paragraph("Lead Date")],
          }),
          new TableCell({
            children: [new Paragraph("Client Name")],
          }),
          new TableCell({
            children: [new Paragraph("Project Name")],
          }),
          new TableCell({
            children: [new Paragraph("Lead Type")],
          }),
          new TableCell({
            children: [new Paragraph("Quoted Value")],
          }),
          new TableCell({
            children: [new Paragraph("Source")],
          }),
          new TableCell({
            children: [new Paragraph("Status")],
          }),
          new TableCell({
            children: [new Paragraph("Group")],
          }),
          new TableCell({
            children: [new Paragraph("Follow-up Date")],
          }),
        ],
      });
  
      const dataRows = leadsourceDownload.map((LeadReport, index) => {
        return new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph(`${index + 1}`)],
            }),
            new TableCell({
              children: [
                new Paragraph(
                  LeadReport.LeadDate ? formatDate(LeadReport.LeadDate) : "N/A"
                ),
              ],
            }),
            new TableCell({
              children: [new Paragraph(LeadReport.ClientName)],
            }),
            new TableCell({
              children: [new Paragraph(LeadReport.ProjectName)],
            }),
            new TableCell({
              children: [new Paragraph(LeadReport.LeadType)],
            }),
            new TableCell({
              children: [
                new Paragraph(
                  `$${LeadReport.QuotedValue.toString().replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )}`
                ),
              ],
            }),
            new TableCell({
              children: [new Paragraph(LeadReport.Source)],
            }),
            new TableCell({
              children: [new Paragraph(LeadReport.Status)],
            }),
            new TableCell({
              children: [new Paragraph(LeadReport.AssignedGroup)],
            }),
            new TableCell({
              children: [
                new Paragraph(
                  LeadReport.Status === "Won" || LeadReport.Status === "Lost"
                    ? "N/A"
                    : formatDate(LeadReport.FollowupDate)
                ),
              ],
            }),
          ],
        });
      });
  
      const doc = new Document({
        sections: [
          {
            properties: {},
            children: [
              new Table({
                rows: [headerRow, ...dataRows],
              }),
            ],
          },
        ],
      });
  
      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, "LeadReports.docx");
      });
    };
  
    const popupRef = useRef(null);
  
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsDropdownVisible(false); // Close the popup if clicked outside
      }
    };
    useEffect(() => {
      if (isDropdownVisible) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [isDropdownVisible]);
  
    const [filteredLeadReportsLead, setFilteredLeadReportsLead] = useState([]);
  
    useEffect(() => {
      setFilteredLeadReportsLead(filteredLeadReports);
    }, [filteredLeadReports]);
  
    useEffect(() => {
      if (filteredLeadReports.length > 0) {
        // aggregateLeadSourceData(filteredLeadReports);
        setLeadSourceDownload(filteredLeadReports);
      }
    }, [filteredLeadReports]);
  
    // useEffect(() => {
    //   const fetchSources = async () => {
    //     try {
    //       const response = await axios.get("/Source");
    //       if (response.status === 200) {
    //         setSources(response.data);
    //       } else {
    //         //   enqueueSnackbar("Failed to fetch sources", { variant: "error" });
    //       }
    //     } catch (error) {
    //       console.error("Error fetching sources:", error);
    //       // enqueueSnackbar("Connection Error!", { variant: "error" });
    //     }
    //   };
    //   fetchSources();
    // }, []);
  
 
  const [totalSourceAmount, setTotalSourceAmount] = useState(0);
      const [totalPercentage ,setTotalPercentage] = useState(0)
    const [totalGraphCount, setTotalGraphCount] = useState(0)
      const [totalSourcCount, setTotalSourcCount] = useState(0);

    const aggregateLeadSourceData = useCallback(   (filteredData) => {
  
        if (filteredLeadReportsLead.length === 0) {
  
          return;
        }
  
        const totalSourceAmount = filteredData.reduce(
          (sum, lead) => sum + (lead.QuotedValue || 0),
          0
        );
        const totalSourceCount = filteredLeadReportsLead.length;
        setTotalGraphCount(totalSourceCount)
        const totalCalculatedCount = filteredData.length
  
        const groupedData = filteredData.reduce((acc, lead) => {
          const source = lead.Source; // Assuming each lead has a status property
          if (!acc[source]) {
            acc[source] = [];
          }
          acc[source].push(lead);
          return acc;
        }, {});
  
        const sourceData = Object.keys(groupedData).map((source) => {
          const leadsForSource = groupedData[source];
  
          const totalQuotedAmount = leadsForSource.reduce(
            (sum, lead) => sum + (lead.QuotedValue || 0),
            0
          );
          const count = leadsForSource.length;
          const percentage = ((count / filteredLeadReports.length) * 100).toFixed(
            2
          );
  
          return {
            amount: totalQuotedAmount,
            count,
            percentage,
            source,
          };
        });
  
        const totalPercentage = sourceData.reduce(
          (sum, source) => sum + parseFloat(source.percentage),
          0
        ).toFixed(2);
  
        setTotalPercentage(totalPercentage)
        setPotentialSource(sourceData);
        setTotalSourceAmount(totalSourceAmount);
        setTotalSourcCount(totalCalculatedCount)
      },
      [filteredLeadReportsLead,
        filteredLeadReports,
        setPotentialSource,
        setTotalSourceAmount,
      ]
    );
  
    // useEffect(() => {
    //   setFilteredLeadReportsLead(filteredLeadReports);
  
    // }, [filteredLeadReports]);
  
    useEffect(() => {
      if (filteredLeadReportsLead.length > 0) {
        aggregateLeadSourceData(filteredLeadReports);
      }
    }, [filteredLeadReports, aggregateLeadSourceData,filteredLeadReportsLead]);
  
    const handleLeadingSourceFilter = () => {
      // Filter data based on date range and status selection
      const filteredData = filteredLeadReports.filter((item) => {
        const itemDate = new Date(item.LeadDate);
        const isWithinDateRange =
          (!sourceFilterDateFrom || itemDate >= sourceFilterDateFrom) &&
          (!sourceFilterDateTo || itemDate <= sourceFilterDateTo);
  
        const isMatchingSource =
          !selectedSource ||
          selectedSource === "All" ||
          item.Source === selectedSource;
        return isWithinDateRange && isMatchingSource;
      });
      // Store the sorted data for the chart
      setPotentialSource(filteredData);
      aggregateLeadSourceData(filteredData);
      setLeadSourceDownload(filteredData);
    };

         const leadSourceNavigationl2 = useCallback((clickedSource) => {
            const filteredDataLeadSourcel2 = leadsourceDownload.filter(
              (item) => item.Source === clickedSource
            );
            setLeadSourceReportL2_12(filteredDataLeadSourcel2)
            navigate("/dashboard/L2/lead-report12");
          }, [leadsourceDownload, navigate,setLeadSourceReportL2_12]);
  
    useEffect(() => {
      const renderSourceChart = () => {
        if (potentialSource.length > 0 && leadTab === "graph") {
          const ctxSource = sourcePieChart.current;
          if (sourceChartRef.current) {
            sourceChartRef.current.destroy();
          }
  
          const customLabelPlugin = {
            id: "customLabelPlugin",
            afterDatasetsDraw(chart) {
              const { ctx, data } = chart;
              ctx.save();
              ctx.font = "bold 14px Arial";
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
  
              data.datasets.forEach((dataset, datasetIndex) => {
                dataset.data.forEach((value, index) => {
                  if (value === "" || value === 0) return;
                  const meta = chart.getDatasetMeta(datasetIndex);
                  const bar = meta.data[index];
  
                  if (bar) {
                    const { x, y, base } = bar.tooltipPosition();
                    const barHeight = base - y;
                    const percentage = Number(value);
                    const padding = 6;
                    const adjustedY = y - padding;
                    ctx.fillStyle = barHeight > 30 ? "white" : "black";
                    if (percentage !== 0) {
                      ctx.fillText(`${percentage}%`, x, adjustedY);
                    }                                  }
                });
              });
  
              ctx.restore();
            },
          };
  
          // Prepare data for all sources
          const orderedData = Sources.map((source) => {
            const item = potentialSource.find(
              (p) => p.source === source.newSource
            );
            return {
              source: source.newSource,
              count: item ? item.count : 0,
            };
          });
  
          // Fix: Ensure all bars have values by default
          const filteredData = orderedData.map((item) => {
            if (selectedSourcefinal === "All") {
              return ((item.count / totalGraphCount) * 100).toFixed(2); // Show all values
            } else {
              return item.source === selectedSourcefinal
                ? ((item.count / totalGraphCount) * 100).toFixed(2) // Show selected source
                : ""; // Keep others visible but empty
            }
          });
  
          sourceChartRef.current = new Chart(ctxSource, {
            type: "bar",
            data: {
              labels: orderedData.map((item) => item.source),
              datasets: [
                {
                  label: "Percentage",
                  data: filteredData,
                  borderRadius: 8,
                  backgroundColor: [
                    "#15803d",
                    "#89AC46",
                    "orange",
                    "#b91c1c",
                  ],
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: { display: false },
                tooltip: { enabled: false },
              },
              onClick: (event, elements) => {
                if (elements.length > 0) {
                  const clickedElementIndex = elements[0].index;
                  const clickedSource = orderedData[clickedElementIndex].source;
                  leadSourceNavigationl2(clickedSource);
                }
              },
              scales: {
                x: {
                  ticks: {
                    color: "black",
                    font: {
                      weight: "bold",
                      size: 14,
                    },
                  },
                   grid: { display: false } },
                y: {
                  display: false,
                  grid: { display: false },
                  ticks: { display: false },
                  suggestedMin: 0,
                  suggestedMax:
                    Math.max(...filteredData.map((v) => parseFloat(v))) + 10, // Adjust for better scaling
                },
              },
            },
            plugins: [customLabelPlugin],
          });
        }
      };
      renderSourceChart();
    }, [leadTab, potentialSource, selectedSourcefinal, Sources, leadSourceNavigationl2, totalGraphCount]);
  
  
  
  return (
    <div className="h-full">
    <div
      className="h-[20%]"

    >
      <div className="flex flex-wrap h-12 items-center justify-between md:gap-x-2 md:flex-nowrap xl:flex xl:justify-between">
        <div>
          <h1 className="text-2xl font-semibold sm:text-sm md:text-sm lg:text-xl">
            LEAD SOURCE
          </h1>
        </div>
        <div className="flex lg:ml-32  lg:items-center lg:justify-end  ">
          <div className="flex show-potential-download">
            <div className="show-potential-download lg:justify-end ">
              <div className=" inline-block relative px-3 mt-2">
                <div className="flex items-center text-4xl sm:text-md md:text-xl lg:text-xl xl:text-2xl 2xl:text-4xl">
                  <CloudDownloadIcon
                    onClick={toggleDropdown}
                    className="cursor-pointer"
                    // fontSize="4rem"
                  />

                  {isDropdownVisible && (
                    <div
                      ref={popupRef}
                      className="absolute top-8 -left-0 shadow-lg z-50 bg-white text-base"
                    >
                      <div
                        className="px-2 py-2 cursor-pointer hover:bg-gray-100"
                        onClick={() =>
                          handleDownload({ target: { value: "pdf" } })
                        }
                      >
                        PDF
                      </div>
                      <div
                        className="px-2 py-2 cursor-pointer hover:bg-gray-100"
                        onClick={() =>
                          handleDownload({ target: { value: "excel" } })
                        }
                      >
                        Excel
                      </div>
                      <div
                        className="px-2 py-2 cursor-pointer hover:bg-gray-100"
                        onClick={() =>
                          handleDownload({ target: { value: "word" } })
                        }
                      >
                        Word
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between text-3xl sm:text-xl md:text-md">
              {leadTab === "table" && (
                <span title="Graph">
                  <SignalCellularAltIcon
                    className="cursor-pointer"
                    onClick={() => setLeadTab("graph")}
                    fontSize="3rem"
                  />
                </span>
              )}
              {leadTab === "graph" && (
                <span title="Table">
                  <TableChartIcon
                    className="cursor-pointer"
                    onClick={() => setLeadTab("table")}
                    fontSize="3rem"
                  />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      <hr />
      <hr />

  
      <div className="flex gap-3 items-center w-full justify-between pt-1 lg:flex-nowrap lg:gap-2">
                <div className="flex justify-between align-middle items-center w-full gap-1">
                  <div className="w-[25%] flex align-middle p-1 border border-[#e3eaff] rounded-md ">
                                <div className="pl-1 pt-1.5 text-xs">
                                  <p >
                                    From-
                                  </p>
                  
                                </div>
                                <div className="h-full w-full ">
                                  <DatePicker
                                    selected={sourceFilterDateFrom}
                                    onChange={handleSourceDateFrom}
                                    dateFormat="MM-dd-yyyy"
                                    className=" w-full h-full text-xs  m-0  outline-none date-picker-placeholder"
                                    placeholderText="MM-DD-YYYY"
                                    required
                  
                                  />
                                </div>
                              </div>
                  |
                   <div className="w-[25%] flex align-middle p-1 border border-[#e3eaff] rounded-md ">
                                <div className=" pl-1 pt-1.5 text-xs">
                                  <p >
                                    To-
                                  </p>
                  
                                </div>
                                <div className="h-full w-full ">
                                  <DatePicker
                                    selected={sourceFilterDateTo}
                                    onChange={handleSourceDateTo}
                                    dateFormat="MM-dd-yyyy"
                                    className=" w-full h-full text-xs m-0  outline-none date-picker-placeholder"
                                    placeholderText="MM-DD-YYYY"
                                    required
                                    minDate={
                                      sourceFilterDateFrom
                                        ? new Date(sourceFilterDateFrom.getTime() + 86400000)
                                        : null
                                    }
                                  />
                                </div>
                              </div>
      
                  <div className="relative flex align-middle items-center w-[25%]">
                    {/* <div className="text-xs h-full bg-[#6b7280] text-white p-0.5 flex align-middle items-center text-center">
                      Select
                    </div> */}
                    <div className="absolute cursor-pointer right-0 border-r border-[#e3eaff] bg-white z-20"> 
                    <ExpandMoreIcon
                      color="white"
                      className=""
                      onClick={() =>
                        setshowsourceDropdown(!showsourseDropdown)
                      }
                    />
                    </div>
                    <div
                      ref={StatusdropdownRef}
                      className="relative border w-full border-[#e3eaff]  rounded-md"
                    >
                      <button
                        type="button"
                        onClick={() => setshowsourceDropdown(!showsourseDropdown)}
                        className=" flex justify-start items-center align-middle w-full h-full p-2 text-nowrap truncate focus:outline-none text-xs"
                      >
                        {selectedSource}
                      </button>
      
                      {showsourseDropdown && (
                        <div className="absolute flex flex-col w-28 p-0.5 gap-2 z-50  bg-white text-xs border border-gray-300 overflow-y-auto shadow-lg max-h-48  mt-0.5">
      
                          <label htmlFor="All" className="p-1 hover:bg-gray-300"
                            onClick={() => {
                              setSelectedSource("All");
                            }}
                          >All</label>
      
      {Sources.map((Sourc) => (
                    <label
                      // onClick={() => setSelectedSource(Sourc.newSource)}
                      onClick={() => {
                        handleSource(Sourc.newSource);
                      }}
                      key={Sourc.id}
                      value={Sourc.newSource}
                      className="p-1 hover:bg-gray-300"
                    >
                      {Sourc.newSource}
                    </label>
                  ))}
                        </div>
                      )}
                    </div>
                  </div>
      
                  <div className="w-[25%] flex justify-end">
                    <button
                      className="text-white bg-[#e32278] p-2 px-4 text-xs rounded-md max-w-24 shadow-md"
                      onClick={handleLeadingSourceFilter}
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
      
              
            </div>
    </div>

    <div className="h-[80%] flex ">
        {leadTab === "table" ? (
          potentialSource.length > 0 ? (
          <div className="w-full pt-3">
            <div className="">
              <table className="w-full divide-y divide-gray-200">
                <thead className="bg-black">
                  <tr className="text-white">
                    <th className="px-4 py-3 text-left border text-sm font-medium uppercase tracking-wider">
                      Source
                    </th>
                    <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                      Count
                    </th>
                    <th className="px-4 py-3 text-center border text-sm font-medium uppercase tracking-wider">
                      %
                    </th>
                    <th className="px-4 py-3 text-right border text-sm font-medium uppercase tracking-wider">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {potentialSource.map((item, index) => (
                    <tr className="border text-md" key={index}>
                      <td
                        onClick={() =>
                          leadSourceNavigationl2(
                            item.source,
                 
                          )
                        }
                        style={{ cursor: "pointer" }}
                        className="px-3 text-left py-2 font-thin"
                      >
                        {item.source.toUpperCase()}
                      </td>
                      <td className="px-3 text-center py-2">{item.count}</td>
                      <td className="px-3 text-center py-2">
                        {item.percentage} %
                      </td>
                      <td className="px-3 text-right py-2">
                        $
                        {item.amount
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                    </tr>
                  ))}
                  {/* Display total row */}

                  <tr className="border bg-gray-200 font-bold text-md">
                    <td className="px-3 text-left py-2">TOTAL :</td>
                    <td className="px-3 text-center py-2">
                      {totalSourcCount}
                    </td>
                    <td className="px-3 text-center py-2">
  {totalPercentage === "99.99" ? 100 : totalPercentage}%
</td>                      
                    <td className="px-3 text-right py-2">
                      {" "}
                      $
                      {totalSourceAmount
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                  </tr>
                </tbody>

              </table>
            </div>
          </div>
          ) : (
<div className="flex justify-center align-middle items-center text-center mb-5  w-full">
            <p className="text-red-500 text-lg font-semibold ">
          No data available
        </p> </div>          )
        ) : potentialSource.length > 0 ? (
          <canvas
            id="sourcePieChart"
            ref={sourcePieChart}
            width="300"
            height="300"
            className=""
          ></canvas>
        ) : (
          <div className="flex justify-center align-middle items-center text-center mb-5  w-full">
            <p className="text-red-500 text-lg font-semibold ">
          No data available
        </p> </div>
        )}
      </div>

  </div>
  )
}

export default LeadSourceL2