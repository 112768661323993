import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientRatings: {}, 
  clientRatingsL2: {},
  clientRatingsL3: {},
};

const ratingSlice = createSlice({
  name: "ratingsfilter",
  initialState,
  reducers: {
    setClientRating(state, action) {
      state.clientRatings = action.payload;;
    },
    setClientRatingL2(state, action) {
      state.clientRatingsL2 = action.payload;;
    },
    setClientRatingL3(state, action) {
      state.clientRatingsL2 = action.payload;;
    },
  },
});

export const { setClientRating ,setClientRatingL2 ,setClientRatingL3} = ratingSlice.actions;
export default ratingSlice.reducer;
