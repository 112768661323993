import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";

// Fetch all groups
export const fetchGroups = createAsyncThunk("groups/fetchGroups", async () => {
  const response = await axiosInstance.get("/groups");
  return response.data;
});

// Create a new group
export const createGroup = createAsyncThunk("groups/createGroup",async (groupData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/groups", groupData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update a group
export const updateGroup = createAsyncThunk("groups/updateGroup",async ({ id, name }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/groups/${id}`, { name });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete a group
export const deleteGroup = createAsyncThunk("groups/deleteGroup",async (id, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/groups/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const groupSlice = createSlice({
  name: "group",
  initialState: {
    group: [],
    loading: false,
    error: null,
  },
  reducers: {}, // No synchronous reducers needed
  extraReducers: (builder) => {
    builder
      // Fetch groups
      .addCase(fetchGroups.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.loading = false;
        state.group = action.payload;
      })
      .addCase(fetchGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Create group
      .addCase(createGroup.fulfilled, (state, action) => {
        state.group.push(action.payload);
      })

      // Update group
      .addCase(updateGroup.fulfilled, (state, action) => {
        const index = state.group.findIndex((g) => g.id === action.payload.id);
        if (index !== -1) state.group[index] = action.payload;
      })

      // Delete group
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.group = state.group.filter((group) => group.id !== action.payload);
      });
  },
});

export default groupSlice.reducer;
