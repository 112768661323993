import React, { useEffect, useMemo, useState } from "react";
import L1Sidebar from "./L1Sidebar";
import { useBackup } from "../../../context/Backupdata";
import TableChartIcon from "@mui/icons-material/TableChart";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { MdEditSquare } from "react-icons/md";
import FinancialPiechart from "./FinancialPiechart";
import ExpIncome from "./ExpIncome";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { fetchFinancialData, saveFinancialData, updateFinancialData } from "../../../redux/slices/financialSlice";

const FinancialL1 = () => {
  const [selectedTab, setSelectedTab] = useState("table"); // State for tab selection
  const [summary, setSummary] = useState("");
  const [refresh, setRefresh] = useState(false);

  const { LeadReports } = useBackup();
  const filteredWonLeads = useMemo(
    () => LeadReports.filter((lead) => lead.Status === "Won"),
    [LeadReports]
  );

  const [productCost, setProductCost] = useState({});
  const [calculatedData, setCalculatedData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalValue, setModalValue] = useState("");

  const openModal = (Wonlead) => {
    setSelectedItem(Wonlead);
    setModalValue(productCost[Wonlead.id] || Wonlead.productCost || "");
  };

  const handleModalInputChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setModalValue(value);

    if (selectedItem) {
      setProductCost((prev) => ({
        ...prev,
        [selectedItem.id]: value,
      }));

      // Update only the selected item's fields in calculatedData
      setCalculatedData((prevData) => {
        return prevData.map((lead) => {
          if (lead.id !== selectedItem.id) return lead; // Skip unmodified leads

          const quotedValue = parseFloat(lead.QuotedValue) || 0;
          const cost = parseFloat(value) || 0;

          if (!cost || cost <= 0) {
            return {
              ...lead,
              productCost: "",
              grossRevenue: "",
              grossRevenuePercentage: "",
              generalAdminCostDollar: "",
              incomeBeforeTaxDollar: "",
              incomeBeforeTaxPercentage: "",
              taxInDollar: "",
              netProfitInDollar: "",
              netProfitInPercentage: "",
            };
          }

          // Perform calculations only for this lead
          const grossRevenueDollar = quotedValue - cost;
          const grossRevenuePercentage =
            quotedValue > 0 ? (grossRevenueDollar / quotedValue) * 100 : 0;
          const generalAdminCostDollar = grossRevenueDollar * 0.15;
          const incomeBeforeTaxDollar =
            grossRevenueDollar - generalAdminCostDollar;
          const incomeBeforeTaxPercentage =
            quotedValue > 0 ? (incomeBeforeTaxDollar / quotedValue) * 100 : 0;
          const taxInDollar = incomeBeforeTaxDollar * 0.275;
          const netProfitInDollar = incomeBeforeTaxDollar - taxInDollar;
          const netProfitInPercentage =
            quotedValue > 0 ? (netProfitInDollar / quotedValue) * 100 : 0;

          return {
            ...lead,
            productCost: Number(cost.toFixed(2)),
            grossRevenue: grossRevenueDollar,
            grossRevenuePercentage: Number(grossRevenuePercentage.toFixed(2)),
            generalAdminCostDollar: Number(generalAdminCostDollar.toFixed(2)),
            incomeBeforeTaxDollar: Number(incomeBeforeTaxDollar.toFixed(2)),
            incomeBeforeTaxPercentage: Number(
              incomeBeforeTaxPercentage.toFixed(2)
            ),
            taxInDollar: Number(taxInDollar.toFixed(2)),
            netProfitInDollar: Number(netProfitInDollar.toFixed(2)),
            netProfitInPercentage: Number(netProfitInPercentage.toFixed(2)),
          };
        });
      });
    }
  };

  const [fetchedfinancial, setfetchedfinancial] = useState([]);

  // const [chartdata] = useState([]);

  const mergedData = calculatedData;

  const handleSubmit = async () => {
    if (!selectedItem) return;

    // ✅ Ensure updated `productCost` is applied
    setProductCost((prev) => ({
      ...prev,
      [selectedItem.id]: modalValue, // Update `productCost` with latest  value
    }));

    setRefresh(!refresh);

    await handleSaveFinancialData(); // ✅ Call API
    setSelectedItem(null);
  };

  const dispatch = useDispatch();
  const financialData = useSelector((state) => state.financial.financialData);

  useEffect(() => {
    dispatch(fetchFinancialData());
  }, [dispatch]);

  useEffect(() => {
  
        const fetchedData = [...financialData];
        // Create a map for quick lookup of leadID in fetchedData
        const fetchedMap = new Map(
          fetchedData.map((item) => [item.leadID, item])
        );

        // Get only those leads from filteredWonLeads that are NOT in fetchedData
        const additionalLeads = filteredWonLeads.filter(
          (lead) => !fetchedMap.has(lead.id)
        );

        // Final array: Backend data first, then non-matching filteredWonLeads
        const updatedData = [...fetchedData, ...additionalLeads];

        setfetchedfinancial(fetchedData);
        setCalculatedData(updatedData);
    
  }, [filteredWonLeads,financialData]);


  const handleSaveFinancialData = async () => {
    if (!selectedItem) {
      return;
    }

    const matchedLead = calculatedData.find(
      (lead) => lead.id === selectedItem.id
    );
    if (!matchedLead) {
      return;
    }

    const formattedData = {
      leadID: matchedLead.leadID || matchedLead.id,
      AssignedGroup: matchedLead.AssignedGroup,
      ClientName: matchedLead.ClientName,
      ProjectName: matchedLead.ProjectName,
      QuotedValue: matchedLead.QuotedValue,
      productCost: Number(productCost[matchedLead.id] || 0),
      grossRevenue: matchedLead.grossRevenue,
      grossRevenuePercentage: matchedLead.grossRevenuePercentage,
      generalAdminCostDollar: matchedLead.generalAdminCostDollar,
      generalCost2: 15,
      incomeBeforeTaxDollar: matchedLead.incomeBeforeTaxDollar,
      incomeBeforeTaxPercentage: matchedLead.incomeBeforeTaxPercentage,
      taxInDollar: matchedLead.taxInDollar,
      tax2: 27.5,
      netProfitInDollar: matchedLead.netProfitInDollar,
      netProfitInPercentage: matchedLead.netProfitInPercentage,
      leadDate: new Date(matchedLead.LeadDate),
    };

    try {
      setRefresh(!refresh);
      // Check if the lead already has financial data
      const existingFinancial = fetchedfinancial.find(
        (financial) => financial.leadID === matchedLead.leadID
      );
      if (existingFinancial) {
        // If financial data exists, update it using PUT request
        // const response = await axios.put(
        //   `//localhost:9000/financial/updatefinancial/${matchedLead.leadID}`,
        //   formattedData
        // );
        await dispatch(
          updateFinancialData({ leadID: matchedLead.leadID, formattedData })
        ).unwrap();
      } else {
        // If not, create a new record using POST request
        // const response = await axios.post(
        //   "//localhost:9000/financial/savefinancial",
        //   formattedData
        // );
        await dispatch(saveFinancialData(formattedData)).unwrap(); // ✅ Wait for response
      }
    } catch (error) {
      console.error("Error saving/updating financial data:", error);
    }
  };

  const getRowColor = (netProfitInPercentage) => {
    if (netProfitInPercentage < 11) {
      return "bg-red-700 text-white"; // Red color for less than 9%
    } else if (netProfitInPercentage >= 11 && netProfitInPercentage <= 21) {
      return "bg-orange-500 text-white"; // Orange color for 10% - 20%
    } else if (netProfitInPercentage >= 21 && netProfitInPercentage <= 100) {
      return "bg-green-500 text-white"; // Orange color for 10% - 20%
    } else {
      return ""; // No specific color for other cases
    }
  };

  const formatNumber = (num) => {
    if (!num) return "";

    // Convert to number (in case it's a string)
    const value = Number(num);

    // Round up if decimal is >= 0.5, otherwise floor
    const roundedValue = Math.floor(value) + (value % 1 >= 0.5 ? 1 : 0);

    // Format with commas
    return new Intl.NumberFormat("en-US").format(roundedValue);
  };

  const [, setRefreshTable] = useState(false);

  const sortAlphabetically = (targetToSort, order) => {
    const key = `${targetToSort}-${order}`;
    switch (key) {
      case "business-az":
        mergedData.sort((a, b) =>
          a.AssignedGroup.localeCompare(b.AssignedGroup)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "business-za":
        mergedData.sort((a, b) =>
          b.AssignedGroup.localeCompare(a.AssignedGroup)
        );
        setRefreshTable((prev) => !prev);
        break;

      case "client-az":
        mergedData.sort((a, b) => a.ClientName.localeCompare(b.ClientName));
        setRefreshTable((prev) => !prev);
        break;

      case "client-za":
        mergedData.sort((a, b) => b.ClientName.localeCompare(a.ClientName));
        setRefreshTable((prev) => !prev);
        break;

      case "project-az":
        mergedData.sort((a, b) => a.ProjectName.localeCompare(b.ProjectName));
        setRefreshTable((prev) => !prev);
        break;

      case "project-za":
        mergedData.sort((a, b) => b.ProjectName.localeCompare(a.ProjectName));
        setRefreshTable((prev) => !prev);
        break;

      default:
    }
  };

  const numericSort = (order) => {

    order === "az"
      ? mergedData.sort((a, b) => Number(a.QuotedValue) - Number(b.QuotedValue))
      : mergedData.sort(
          (a, b) => Number(b.QuotedValue) - Number(a.QuotedValue)
        );

    setRefreshTable((prev) => !prev);
  };

  return (
    <div className="max-h-screen">
      <div>
        <L1Sidebar />

        <div className=" ml-[20%] p-4 bg-white z-55 relative  w-[80%] justify-center top-6 pb-20">
          <div></div>
          <div className="flex">
            <div className="w-1/2 flex">
              <div>
                <h1 className="text-2xl  mb-6 font-semibold">
                  Financial Information
                </h1>
              </div>
              <div className=" space-x-4 w-1/2">
                <div className=" flex items-start justify-start text-3xl ml-[9%]">
                  {selectedTab === "table" && (
                    <span title="Graph">
                      <SignalCellularAltIcon
                        className="cursor-pointer"
                        onClick={() => setSelectedTab("graph")}
                        fontSize="4rem"
                      />
                    </span>
                  )}

                  {selectedTab === "graph" && (
                    <span title="Table">
                      <TableChartIcon
                        className="cursor-pointer"
                        onClick={() => setSelectedTab("table")}
                        fontSize="4rem"
                      />
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="w-1/2"></div>
          </div>
          {selectedTab === "table" ? (
            <div>
              <div className="flex-col">
                <div className="mb-1">
                  <form className="flex flex-wrap">
                    <div className="flex mb-4 mr-4 items-start">
                      <div className="mb-4 mr-4" style={{ flex: "1 0 auto" }}>
                        <label className="block" htmlFor="Name">
                          Summary
                        </label>
                        <select
                          className="flex items-center justify-between border border-gray-400 rounded-md p-2 shadow-md  max-w-[10rem] w-[250px]"
                          required
                          value={summary}
                          onChange={(e) => setSummary(e.target.value)}
                        >
                          <option value="Short Summary">Short Summary</option>
                          <option value="Comprehensive Summary">
                            Comprehensive Summary
                          </option>
                        </select>
                      </div>

                      <div className="mb-4 mr-4">
                        <label className="block" htmlFor="Name">
                          Select Group
                        </label>
                        <select
                          className="flex items-center justify-between border border-gray-400 rounded-md p-2 shadow-md w-[250px] max-w-[10rem]"
                          required
                        >
                          <option value="all">All Groups </option>
                          {LeadReports.filter((lead) => lead.Status === "Won") // Filter clients with Status: "Won"
                            .sort((a, b) =>
                              a.ClientName.trim().localeCompare(
                                b.ClientName.trim()
                              )
                            )
                            .reduce((acc, current) => {
                              const isDupel = acc.some(
                                (item) =>
                                  item.AssignedGroup === current.AssignedGroup
                              );
                              if (!isDupel) {
                                acc.push(current);
                              }
                              return acc;
                            }, [])
                            .map((lead) => (
                              <option key={lead.id} value={lead.AssignedGroup}>
                                {lead.AssignedGroup}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="mb-4">
                        <label className="block" htmlFor="Name">
                          Client
                        </label>

                        <select className="flex items-center justify-between border border-gray-400 rounded-md p-2 shadow-md max-w-[10rem] w-[250px]">
                          <option value="">All Client</option>
                          {LeadReports.filter((lead) => lead.Status === "Won") // Filter clients with Status: "Won"
                            .sort((a, b) =>
                              a.ClientName.trim().localeCompare(
                                b.ClientName.trim()
                              )
                            )
                            .reduce((acc, current) => {
                              const isDupel = acc.some(
                                (item) => item.ClientName === current.ClientName
                              );
                              if (!isDupel) {
                                acc.push(current);
                              }
                              return acc;
                            }, [])
                            .map((lead) => (
                              <option key={lead.id} value={lead.ClientName}>
                                {lead.ClientName}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <br />
              <div className="max-h-[700px]  overflow-auto min-w-full -mt-6">
                {summary === "Short Summary" || !summary ? (
                  <table className="table min-w-full divide-y divide-gray-200">
                    <thead className="bg-black text-white sticky top-0">
                      <tr>
                        <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                          Sr.No
                        </th>
                        <th className="px-4 w-17 py-2 text-center border text-xs font-medium uppercase tracking-wider">
                          <div className="flex items-center justify-between">
                            Business Group
                            <div className="flex flex-col gap-y-1">
                              <div
                                className="cursor-pointer"
                                onClick={() =>
                                  sortAlphabetically("business", "az")
                                }
                              >
                                <ArrowDropUp
                                  color="white"
                                  sx={{ fontSize: "22px" }}
                                />
                              </div>

                              <div
                                className="cursor-pointer -mt-3"
                                onClick={() =>
                                  sortAlphabetically("business", "za")
                                }
                              >
                                <ArrowDropDown
                                  color="white"
                                  sx={{ fontSize: "22px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </th>
                        <th className="px-4 w-17 py-2 text-center border text-xs font-medium uppercase tracking-wider">
                          <div className="flex items-center justify-between gap-x-4">
                            Client Name
                            <div className="flex flex-col gap-y-1">
                              <div
                                className="cursor-pointer "
                                onClick={() =>
                                  sortAlphabetically("client", "az")
                                }
                              >
                                <ArrowDropUp
                                  color="white"
                                  sx={{ fontSize: "22px" }}
                                />
                              </div>

                              <div
                                className="cursor-pointer -mt-3"
                                onClick={() =>
                                  sortAlphabetically("client", "za")
                                }
                              >
                                <ArrowDropDown
                                  color="white"
                                  sx={{ fontSize: "22px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </th>
                        <th className="px-4 w-17 py-2 text-center border text-xs font-medium uppercase tracking-wider">
                          <div className="flex items-center justify-between gap-x-4">
                            Project Name
                            <div className="flex flex-col gap-y-1">
                              <div
                                className="cursor-pointer"
                                onClick={() =>
                                  sortAlphabetically("project", "az")
                                }
                              >
                                <ArrowDropUp
                                  color="white"
                                  sx={{ fontSize: "22px" }}
                                />
                              </div>

                              <div
                                className="cursor-pointer -mt-3"
                                onClick={() =>
                                  sortAlphabetically("project", "za")
                                }
                              >
                                <ArrowDropDown
                                  color="white"
                                  sx={{ fontSize: "22px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </th>
                        <th className="px-3 py-2 text-center border text-xs font-medium uppercase tracking-wider">
                          <div className="flex items-center justify-between">
                            Quoted Value
                            <div className="flex flex-col gap-y-1">
                              <div
                                className="cursor-pointer"
                                onClick={() => numericSort("za")}
                              >
                                <ArrowDropUp
                                  color="white"
                                  sx={{ fontSize: "22px" }}
                                />
                              </div>

                              <div
                                className="cursor-pointer -mt-3"
                                onClick={() => numericSort("az")}
                              >
                                <ArrowDropDown
                                  color="white"
                                  sx={{ fontSize: "22px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </th>
                        <th className="px-3 py-3 w-16 text-center border text-xs font-medium uppercase tracking-wider">
                          Product/Service cost
                        </th>
                        <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                          Gross Revenue
                        </th>
                        <th
                          colspan="2"
                          className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider"
                        >
                          Net Profit
                        </th>

                        {/* <th className="border px-6 py-3 text-center tracking-wider text-sm font-medium uppercase">#</th>
                                    <th className="border px-6 py-3 text-center tracking-wider text-sm font-medium uppercase">Departments</th>
                                    <th className="border px-3 py-3 text-center tracking-wider text-sm font-medium uppercase">Edit</th>
                                    <th className="border px-3 py-3 text-center tracking-wider text-sm font-medium uppercase">Delete</th> */}
                      </tr>
                    </thead>
                    {/* <thead className="bg-black sticky top-0">
                      <tr className="text-white">
                        <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                          Sr.No
                        </th>

                        <th className="px-4 w-17 py-2 text-center border text-xs font-medium uppercase tracking-wider">
                          <div className="flex items-center justify-between">
                            Business Group

                            <div className="flex flex-col gap-y-1">
                              <div className="cursor-pointer" onClick={() => sortAlphabetically('business', 'az')}>
                                <ArrowDropUp color="white" sx={{ fontSize: '22px' }} />
                              </div>

                              <div className="cursor-pointer -mt-3" onClick={() => sortAlphabetically('business', 'za')}>
                                <ArrowDropDown color="white" sx={{ fontSize: '22px' }} />
                              </div>
                            </div>
                          </div>
                        </th>

                        <th className="px-4 w-17 py-2 text-center border text-xs font-medium uppercase tracking-wider">
                          <div className="flex items-center justify-between gap-x-4">
                            Client Name

                            <div className="flex flex-col gap-y-1">
                              <div className="cursor-pointer " onClick={() => sortAlphabetically('client', 'az')}>
                                <ArrowDropUp color="white" sx={{ fontSize: '22px' }} />
                              </div>

                              <div className="cursor-pointer -mt-3" onClick={() => sortAlphabetically('client', 'za')}>
                                <ArrowDropDown color="white" sx={{ fontSize: '22px' }} />
                              </div>
                            </div>
                          </div>
                        </th>

                        <th className="px-4 w-17 py-2 text-center border text-xs font-medium uppercase tracking-wider">
                          <div className="flex items-center justify-between gap-x-4">
                            Project Name

                            <div className="flex flex-col gap-y-1">
                              <div className="cursor-pointer" onClick={() => sortAlphabetically('project', 'az')}>
                                <ArrowDropUp color="white" sx={{ fontSize: '22px' }} />
                              </div>

                              <div className="cursor-pointer -mt-3" onClick={() => sortAlphabetically('project', 'za')}>
                                <ArrowDropDown color="white" sx={{ fontSize: '22px' }} />
                              </div>
                            </div>
                          </div>
                        </th>
                        <th className="px-3 py-2 text-center border text-xs font-medium uppercase tracking-wider">
                          <div className="flex items-center justify-between">
                            Quoted Value

                            <div className="flex flex-col gap-y-1">
                              <div className="cursor-pointer" onClick={() => numericSort('za')}>
                                <ArrowDropUp color="white" sx={{ fontSize: '22px' }} />
                              </div>

                              <div className="cursor-pointer -mt-3" onClick={() => numericSort('az')}>
                                <ArrowDropDown color="white" sx={{ fontSize: '22px' }} />
                              </div>
                            </div>
                          </div>

                        </th>

                        <th className="px-3 py-3 w-16 text-center border text-xs font-medium uppercase tracking-wider">
                          Product/Service cost
                        </th>
                        <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                          Gross Revenue
                        </th>
                        <th
                          colspan="2"
                          className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider"
                        >
                          Net Profit
                        </th>
                       
                      </tr>
                    </thead> */}
                    <tbody className="bg-white divide-y divide-gray-200">
                      {/* <tr className="border text-sm font-bold">
                        <td className="px-3 text-center py-2 border ">#</td>
                        <td className="px-3 text-center py-2 border">Sensor</td>
                        <td className="px-3 text-center py-2 border">$</td>
                        <td className="px-3 text-center py-2 border">$</td>
                        <td className="px-3 text-center py-2 border">$</td>
                        <td className="px-3 text-center py-2 border">$</td>
                        <td className="px-3 text-center py-2 border">%</td>
                      </tr> */}
                      {/* Short Summary */}

                      {mergedData.map((wonLead, index) => (
                        <tr
                          key={wonLead.id}
                          className={`${getRowColor(
                            wonLead.netProfitInPercentage
                          )}`}
                        >
                          <td className="px-3 text-center py-2 border ">
                            {index + 1}
                          </td>
                          <td className="px-3 text-center py-2 border">
                            {wonLead.AssignedGroup}
                          </td>

                          <td className="px-3 text-center py-2 border">
                            {wonLead.ClientName}
                          </td>

                          <td className="px-3 text-center py-2 border">
                            {wonLead.ProjectName}
                          </td>

                          <td className="px-3 text-center py-2 border whitespace-nowrap">
                            <span className="border border-gray-300 rounded-md p-2 py-0">
                              ${formatNumber(wonLead.QuotedValue)}
                            </span>
                          </td>
                          <td className="px-3 text-center py-0 border">
                            <div className="flex items-center border border-gray-300 rounded-md pl-1 bg-transparent">
                              <span className="text-black text-end">$</span>
                              <input
                                type="text"
                                readOnly
                                placeholder="Enter product cost"
                                className="text-center text-black bg-transparent outline-none w-full"
                                value={
                                  productCost[wonLead.id] || wonLead.productCost
                                }
                                onClick={() => openModal(wonLead)}
                              />
                            </div>

                            {selectedItem && (
                              <div className="fixed inset-0 left-80 flex items-center justify-center">
                                <div className="bg-white p-6 rounded-lg shadow-lg w-76">
                                  <h2 className="text-lg text-black font-bold mb-4 text-start">
                                    Enter Product Cost
                                  </h2>
                                  <input
                                    type="number"
                                    placeholder="Enter Product Cost"
                                    className="w-full p-2 border border-gray-300 text-black rounded mb-4"
                                    value={modalValue}
                                    onChange={handleModalInputChange}
                                  />
                                  <div className="flex justify-between">
                                    <button
                                      className="bg-blue-500 hover:bg-blue-800 text-white px-4 py-2 rounded"
                                      onClick={handleSubmit} // ✅ Only calling handleSubmit()
                                    >
                                      Submit
                                    </button>

                                    <button
                                      className="bg-gray-400 hover:bg-black text-white px-4 py-2 rounded "
                                      onClick={() => setSelectedItem(null)}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="px-3 text-center py-2 border whitespace-nowrap">
                            <span className="border border-gray-300 rounded-md p-2 py-0">
                              ${formatNumber(wonLead.grossRevenue)}
                            </span>
                          </td>
                          <td className="px-3 text-center py-2 border whitespace-nowrap">
                            <span className="border border-gray-300 rounded-md p-2 py-0">
                              ${formatNumber(wonLead.netProfitInDollar)}
                            </span>
                          </td>
                          <td className="px-3 text-center py-2 border whitespace-nowrap">
                            {wonLead.netProfitInPercentage}%
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  // ************ Comprehensive Table ******************

                  <div className="relative">
                    {/* Scrollable Table Container */}
                    <div
                      className="overflow-x-auto max-h-[600px]"
                      id="table-container"
                    >
                      <table className="min-w-full w-screen divide-y divide-gray-200">
                        <thead className="bg-black text-white sticky top-0">
                          <tr>
                            <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                              Sr.No
                            </th>
                            <th className="px-6 w-17 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                              <div className="flex items-center justify-between gap-x-4">
                                Business Group
                                <div className="flex flex-col gap-y-1">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() =>
                                      sortAlphabetically("business", "az")
                                    }
                                  >
                                    <ArrowDropUp
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>

                                  <div
                                    className="cursor-pointer -mt-3"
                                    onClick={() =>
                                      sortAlphabetically("business", "za")
                                    }
                                  >
                                    <ArrowDropDown
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                              <div className="flex items-center justify-between gap-x-4">
                                Client Name
                                <div className="flex flex-col gap-y-1">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() =>
                                      sortAlphabetically("client", "az")
                                    }
                                  >
                                    <ArrowDropUp
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>

                                  <div
                                    className="cursor-pointer -mt-3"
                                    onClick={() =>
                                      sortAlphabetically("client", "za")
                                    }
                                  >
                                    <ArrowDropDown
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                              <div className="flex items-center justify-between gap-x-4">
                                Project Name
                                <div className="flex flex-col gap-y-1">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() =>
                                      sortAlphabetically("project", "az")
                                    }
                                  >
                                    <ArrowDropUp
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>

                                  <div
                                    className="cursor-pointer -mt-3"
                                    onClick={() =>
                                      sortAlphabetically("project", "za")
                                    }
                                  >
                                    <ArrowDropDown
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                              <div className="flex items-center justify-between gap-x-4">
                                Quoted Value
                                <div className="flex flex-col gap-y-1">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => numericSort("za")}
                                  >
                                    <ArrowDropUp
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>

                                  <div
                                    className="cursor-pointer -mt-3"
                                    onClick={() => numericSort("az")}
                                  >
                                    <ArrowDropDown
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th className="px-1 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                              Product/Service Cost
                            </th>
                            <th
                              colSpan="2"
                              className="px-3 py-3 w-max text-center border text-xs font-medium uppercase tracking-wider"
                            >
                              Gross Revenue
                            </th>
                            <th
                              colSpan="2"
                              className="px-3 w-max py-3 text-center border text-xs font-medium uppercase tracking-wider"
                            >
                              General and Admin Costs
                            </th>
                            <th
                              colSpan="2"
                              className="px-3 w-max py-3 text-center border text-xs font-medium uppercase tracking-wider"
                            >
                              Income Before Tax
                            </th>
                            <th
                              colSpan="2"
                              className="px-3 w-56 py-3 text-center border text-xs font-medium uppercase tracking-wider"
                            >
                              Tax
                            </th>
                            <th
                              colSpan="2"
                              className="px-3 py-3 w-max text-center border text-xs font-medium uppercase tracking-wider"
                            >
                              Net Profit
                            </th>
                          </tr>
                        </thead>
                        {/* <thead className="bg-black sticky top-0">
                          <tr className="text-white ">
                            <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                              Sr.No
                            </th>
                            <th className="px-6 w-17 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                              <div className="flex items-center justify-between gap-x-4">
                                Business Group
                                <div className="flex flex-col gap-y-1">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() =>
                                      sortAlphabetically("business", "az")
                                    }
                                  >
                                    <ArrowDropUp
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>

                                  <div
                                    className="cursor-pointer -mt-3"
                                    onClick={() =>
                                      sortAlphabetically("business", "za")
                                    }
                                  >
                                    <ArrowDropDown
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                              <div className="flex items-center justify-between gap-x-4">
                                Client Name
                                <div className="flex flex-col gap-y-1">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() =>
                                      sortAlphabetically("client", "az")
                                    }
                                  >
                                    <ArrowDropUp
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>

                                  <div
                                    className="cursor-pointer -mt-3"
                                    onClick={() =>
                                      sortAlphabetically("client", "za")
                                    }
                                  >
                                    <ArrowDropDown
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                              <div className="flex items-center justify-between gap-x-4">
                                Project Name
                                <div className="flex flex-col gap-y-1">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() =>
                                      sortAlphabetically("project", "az")
                                    }
                                  >
                                    <ArrowDropUp
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>

                                  <div
                                    className="cursor-pointer -mt-3"
                                    onClick={() =>
                                      sortAlphabetically("project", "za")
                                    }
                                  >
                                    <ArrowDropDown
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th className="px-3 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                              <div className="flex items-center justify-between gap-x-4">
                                Quoted Value
                                <div className="flex flex-col gap-y-1">
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => numericSort("za")}
                                  >
                                    <ArrowDropUp
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>

                                  <div
                                    className="cursor-pointer -mt-3"
                                    onClick={() => numericSort("az")}
                                  >
                                    <ArrowDropDown
                                      color="white"
                                      sx={{ fontSize: "22px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th className="px-1 py-3 text-center border text-xs font-medium uppercase tracking-wider">
                              Product/Service Cost
                            </th>
                            <th
                              colSpan="2"
                              className="px-3 py-3 w-max text-center border text-xs font-medium uppercase tracking-wider"
                            >
                              Gross Revenue
                            </th>
                            <th
                              colSpan="2"
                              className="px-3 w-max py-3 text-center border text-xs font-medium uppercase tracking-wider"
                            >
                              General and Admin Costs
                            </th>
                            <th
                              colSpan="2"
                              className="px-3 w-max py-3 text-center border text-xs font-medium uppercase tracking-wider"
                            >
                              Income Before Tax
                            </th>
                            <th
                              colSpan="2"
                              className="px-3 w-56 py-3 text-center border text-xs font-medium uppercase tracking-wider"
                            >
                              Tax
                            </th>
                            <th
                              colSpan="2"
                              className="px-3 py-3 w-max text-center border text-xs font-medium uppercase tracking-wider"
                            >
                              Net Profit
                            </th>
                          </tr>
                        </thead> */}
                        <tbody className="bg-white divide-y divide-gray-200">
                          {mergedData.map((Wonlead, index) => (
                            <tr
                              key={Wonlead.id}
                              className={`${getRowColor(
                                Wonlead.netProfitInPercentage
                              )}`}
                            >
                              <td className="px-3 text-center py-2 border">
                                {index + 1}
                              </td>
                              <td className="px-3 text-center py-2 border">
                                {Wonlead.AssignedGroup}
                              </td>
                              <td className="px-3 text-center py-2 border">
                                {Wonlead.ClientName}
                              </td>
                              <td className="px-3 text-center py-2 border">
                                {Wonlead.ProjectName}
                              </td>
                              <td className="px-3 text-center py-2 border">
                                <span className="border border-gray-300 rounded-md p-2 py-0">
                                  ${formatNumber(Wonlead.QuotedValue)}
                                </span>
                              </td>
                              <td className="px-3 text-center py-2 border">
                                <div className="flex">
                                  <div className="flex items-center border border-gray-300 rounded-md pl-1 bg-transparent">
                                    <span className="text-black">$</span>
                                    <input
                                      type=""
                                      placeholder="Enter product cost"
                                      className="text-center text-black bg-transparent outline-none w-full"
                                      value={
                                        productCost[Wonlead.id] ||
                                        Wonlead.productCost ||
                                        ""
                                      }
                                      onClick={() => openModal(Wonlead)}
                                    />
                                  </div>
                                  <div>
                                    <MdEditSquare className="text-xl cursor-pointer" />
                                  </div>
                                </div>
                              </td>
                              <td className="px-3 text-center w-max py-2 border whitespace-nowrap">
                                <span className="border border-gray-300 rounded-md p-2 py-0">
                                  ${formatNumber(Wonlead.grossRevenue)}
                                </span>
                              </td>
                              <td className="px-3 text-center w-max whitespace-nowrap py-2 border">
                                {Wonlead.grossRevenuePercentage}%
                              </td>
                              <td className="px-3 text-center w-max whitespace-nowrap py-2 border">
                                <span className="border border-gray-300 rounded-md p-2 py-0">
                                  $
                                  {formatNumber(Wonlead.generalAdminCostDollar)}
                                </span>
                              </td>
                              <td className="px-3 text-center w-max whitespace-nowrap py-2 border">
                                15%
                              </td>
                              <td className="px-3 text-center w-max whitespace-nowrap py-2 border">
                                <span className="border border-gray-300 rounded-md p-2 py-0">
                                  ${formatNumber(Wonlead.incomeBeforeTaxDollar)}
                                </span>
                              </td>
                              <td className="px-3 text-center w-max whitespace-nowrap py-2 border">
                                {Wonlead.incomeBeforeTaxPercentage}%
                              </td>
                              <td className="px-3 text-center w-max whitespace-nowrap py-2 border">
                                <span className="border border-gray-300 rounded-md p-2 py-0">
                                  ${formatNumber(Wonlead.taxInDollar)}
                                </span>
                              </td>
                              <td className="px-3 text-center w-max whitespace-nowrap py-2 border">
                                27.5%
                              </td>
                              <td className="px-3 text-center w-max whitespace-nowrap py-2 border">
                                <span className="border border-gray-300 rounded-md p-2 py-0">
                                  ${formatNumber(Wonlead.netProfitInDollar)}
                                </span>
                              </td>
                              <td className="px-3 text-center w-max whitespace-nowrap py-2 border">
                                {Wonlead.netProfitInPercentage}%
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="flex flex-col lg:flex-row w-full max-w-none">
              <div className="w-full lg:w-1/2 lg:mb-12 max-w-none ">
                <FinancialPiechart />
              </div>

              <div className="w-full lg:w-1/2 max-w-none">
                <ExpIncome />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FinancialL1;
