import { configureStore } from "@reduxjs/toolkit";
import employeeAdminReducer from "./slices/employeeAdminSlice"; // If using Redux Toolkit slice
import leadReducer from './slices/leadsSlice';
import financialReducer from './slices/financialSlice';
import sourceSlice from './slices/sourceSlice';
import statusReducer from './slices/statusSlice';
import serviceTypesReducer from './slices/serviceTypesSlice';
import leadTypeReducer from "./slices/leadTypeSlice";
import departmentsReducer from './slices/departmentsSlice';
import sessionReducer from "./slices/sessionSlice";
import groupReducer from "./slices/groupSlice";
import fileReducer from "./slices/fileSlice";
import leadsL3Reducer from "./slices/leadsSliceL3";
import dataL3Reducer from "./slices/dataSliceL3";
import filterReducer from "./slices/filterSlice";
import hardwareStoreReducer from "./slices/hardwareStoreSlice";
import labourCostReducer from "./slices/labourCostSlice";
import clientRatingReducer from "./slices/clientRatingDashboardSliceL3";
import ratingsReducer from "./slices/ratingWeightageSlice";
import authReducer from "./slices/credentialsSlice";
import leadReportsReducer from "./slices/globalFilterSlice";
import ratingReducer from "./slices/ratingSlice"

const store = configureStore({
  reducer: {
    session: sessionReducer,
    auth: authReducer,
    employees: employeeAdminReducer,
    leads:leadReducer,
    financial:financialReducer,
    group: groupReducer,
    source: sourceSlice,
    status: statusReducer,
    serviceTypesRedux: serviceTypesReducer,
    leadTypesRedux: leadTypeReducer,
    departments: departmentsReducer,
    files: fileReducer,
    leadsL3: leadsL3Reducer,
    data: dataL3Reducer,
    filters: filterReducer, 
    hardwareStore: hardwareStoreReducer,
    labourCost: labourCostReducer,
    clientRating: clientRatingReducer,
    ratings: ratingsReducer,
    leadReports: leadReportsReducer, // Make sure this matches the slice name
    ratingsfilter:ratingReducer,
  },
});

export default store;
