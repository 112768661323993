import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import BlockIcon from "@mui/icons-material/Block";
import TableChartIcon from "@mui/icons-material/TableChart";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import "../pages/L1/Dashboard.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import L3Sidebar from "./L3/L3Sidebar";
import HistoricalDataL3 from "./L3/DashboardL3.jsx/HistoricalDataL3";
import PotentialStatusL3 from "./L3/DashboardL3.jsx/PotentialStatusL3";
import ProbabilityStatusL3 from "./L3/DashboardL3.jsx/ProbabilityStatusL3";
import ConversionRateL3 from "./L3/DashboardL3.jsx/ConversionRateL3";
import { FaUserCircle } from "react-icons/fa";
import FollowupTableL3 from "./L3/DashboardL3.jsx/FollowupTableL3";
import { useLead } from "../../context/LeadReport";
import LeadSourceL3 from "./L3/DashboardL3.jsx/LeadSourceL3";
import { logoutSession } from "../../redux/slices/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeadsByGroups } from "../../redux/slices/leadsSliceL3";
import { fetchEmployeeAuthData } from "./../../redux/slices/leadsSliceL3";
import { fetchStatus } from "../../redux/slices/statusSlice";
import { fetchLeads } from "../../redux/slices/leadsSlice";
import { fetchSources } from "../../redux/slices/sourceSlice";
import {
  setClientFilterReduxL3,
  setGroupFilterReduxL3,
  setPotentialFromDateReduxL3,
  setPotentialStatusReduxL3,
  setPotentialToDateReduxL3,
  setProbabilityFromDateReduxL3,
  setProbabilityToDateReduxL3,
  setSelectedYearsReduxL3,
  setSourceFromDateReduxL3,
  setSourceReduxL3,
  setSourceToDateReduxL3,
} from "../../redux/slices/filterSlice";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import {
  getClientRatingData,
  getsingleClientRatingData,
} from "../../redux/slices/clientRatingDashboardSliceL3";
import { setClientRatingL3 } from "../../redux/slices/ratingSlice"; // Import the action

const Dashboard2 = () => {
  const { setDashboardReportL3_11 } = useLead();
  const [filteredLeadReports, setFilteredLeadReports] = useState([]);
  const [newLeadReports, setnewLeadReports] = useState([]);
  const [LeadReports, setLeadReports] = useState([]);
  const [GroupNamesL3, setGroupNamesL3] = useState([]);
  const [groupName, setgroupName] = useState("");
  const [clientName, setclientName] = useState("");
  const dispatch = useDispatch();

  const { leadReports } = useSelector((state) => state.leadsL3);
  const { empGroups } = useSelector((state) => state.leadsL3);
  const { status } = useSelector((state) => state.status);
  // const { leads } = useSelector((state) => state.leads);
  // const { source } = useSelector((state) => state.source);
  const clientRatingL3 = useSelector(
    (state) => state.ratingsfilter?.clientRatingsL3 || "0"
  );
  useEffect(() => {
    if (clientRatingL3 !== undefined && clientRatingL3 !== null) {
      setClientData(clientRatingL3);
    }
  }, [clientRatingL3, clientName, groupName, dispatch]);

  useEffect(() => {
    if (
      leadReports.length > 0 &&
      Array.isArray(empGroups) &&
      empGroups.length > 0
    ) {
      const filteredData = leadReports.filter((lead) =>
        empGroups.includes(lead.AssignedGroup)
      );
      setLeadReports(filteredData);
      setFilteredLeadReports(filteredData); // Optional, if you want to maintain both
    } else {
      console.error("empGroups not available or leadReports empty");
      // setLeadReports(leadReports);
      // setFilteredLeadReports(leadReports);
    }
  }, [empGroups, leadReports]);

  useEffect(() => {
    if (status.length === 0) {
      dispatch(fetchStatus());
    }
  }, [dispatch, status]);

  useEffect(() => {
    setGroupNamesL3(empGroups);
  }, [empGroups]);

  useEffect(() => {
    dispatch(fetchLeadsByGroups());
    dispatch(fetchEmployeeAuthData());
    dispatch(fetchLeads());
    dispatch(fetchSources());
  }, [dispatch]);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const dropdownRef = useRef(null);
  const dropdownRefclient = useRef(null);
  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [showClientDropdown, setShowClientDropdown] = useState(false);
  const [selectedYears, setSelectedYears] = useState([]); // Store selected years
  const [showLogout, setshowlogout] = useState(false);
  const popupRef = useRef(null);
  const name = localStorage.getItem("name");

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setshowlogout(false); // Close the popup if clicked outside
    }
  };
  useEffect(() => {
    if (showLogout) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showLogout]);

  const logout = (e) => {
    e.preventDefault();
    setshowlogout(!showLogout);
  };

  const logoutUser = (e) => {
    e.preventDefault();
    dispatch(logoutSession());
    navigate("/");
    enqueueSnackbar("Successfully Logged out!", {
      variant: "success",
      autoHideDuration: 1000,
    });
    // localStorage.removeItem("token");
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefclient.current &&
        !dropdownRefclient.current.contains(event.target)
      ) {
        setShowClientDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowYearDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefGroup.current &&
        !dropdownRefGroup.current.contains(event.target)
      ) {
        setShowGroupDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const currentYear = new Date().getFullYear();

  const years = useMemo(
    () => [
      currentYear,
      currentYear - 1,
      currentYear - 2,
      currentYear - 3,
      currentYear - 4,
    ],
    [currentYear]
  );

  const [LeadCount, setLeadCount] = useState(0);
  const [totalQuotedValue, setTotalQuotedValue] = useState(0);

  const [wonLeadCount, setWonLeadCount] = useState(0);
  const [wonTotalQuotedValue, setWonTotalQuotedValue] = useState(0);

  const [wonRecurringLeadCount, setWonRecurringLeadCount] = useState(0);
  const [wonRecurringTotalQuotedValue, setWonRecurringTotalQuotedValue] =
    useState(0);

  const [pendingLeadCount, setPendingLeadCount] = useState(0);
  const [pendingTotalQuotedValue, setPendingTotalQuotedValue] = useState(0);

  const [lostLeadCount, setLostLeadCount] = useState(0);
  const [lostTotalQuotedValue, setLostTotalQuotedValue] = useState(0);

  const [showGroupDropdown, setShowGroupDropdown] = useState(false);

  const dropdownRefGroup = useRef(null);

  useEffect(() => {
    // Update the count of filtered leads
    setLeadCount(filteredLeadReports.length);

    // Calculate the total QuotedValue
    const total = filteredLeadReports.reduce(
      (sum, lead) => sum + (lead.QuotedValue || 0),
      0
    );
    setTotalQuotedValue(total);

    // Filter and calculate count and total for leads with "Won" status
    const wonLeads = filteredLeadReports.filter(
      (lead) => lead.Status === "Won"
    );
    setWonLeadCount(wonLeads.length);

    const wonTotal = wonLeads.reduce(
      (sum, lead) => sum + (lead.QuotedValue || 0),
      0
    );
    setWonTotalQuotedValue(wonTotal);

    const wonRecurringLeads = filteredLeadReports.filter(
      (lead) => lead.Status === "Won Recurring"
    );
    setWonRecurringLeadCount(wonRecurringLeads.length);

    const wonRecurringTotal = wonRecurringLeads.reduce(
      (sum, lead) => sum + (lead.QuotedValue || 0),
      0
    );
    setWonRecurringTotalQuotedValue(wonRecurringTotal);

    const pendingLeads = filteredLeadReports.filter(
      (lead) => lead.Status === "Pending"
    );
    setPendingLeadCount(pendingLeads.length);

    const pendingTotal = pendingLeads.reduce(
      (sum, lead) => sum + (lead.QuotedValue || 0),
      0
    );
    setPendingTotalQuotedValue(pendingTotal);

    const lostLeads = filteredLeadReports.filter(
      (lead) => lead.Status === "Lost"
    );
    setLostLeadCount(lostLeads.length);

    const lostTotal = lostLeads.reduce(
      (sum, lead) => sum + (lead.QuotedValue || 0),
      0
    );
    setLostTotalQuotedValue(lostTotal);
  }, [filteredLeadReports]);

  const [groupFilter, setGroupFilter] = useState("");
  const [, setTodaysDate] = useState("");
  // const [todaysDate, setTodaysDate] = useState("");
  const [clientFilter, setClientFilter] = useState();

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setTodaysDate(today);
  }, []);

  // Get filter value from Redux store
  const groupFilterReduxL3 = useSelector(
    (state) => state.filters.groupFilterL3
  );
  const ClientFilterReduxL3 = useSelector(
    (state) => state.filters.clientFilterL3
  );
  const YearsFilterReduxL3 = useSelector(
    (state) => state.filters.selectedYearsL3
  );

  useEffect(() => {
    if (groupFilterReduxL3) {
      setGroupFilter(groupFilterReduxL3);
      setgroupName(groupFilterReduxL3);

      // Reset client filter when group changes
      // setclientName("");
      // setClientFilter("");
      // dispatch(setClientFilterReduxL3("")); // Reset client filter in Redux as well
    }
  }, [groupFilterReduxL3, dispatch]);

  useEffect(() => {
    if (ClientFilterReduxL3 !== undefined && ClientFilterReduxL3 !== null) {
      setclientName(ClientFilterReduxL3);
      setClientFilter(ClientFilterReduxL3);
    }
  }, [ClientFilterReduxL3]);

  useEffect(() => {
    if (YearsFilterReduxL3) {
      setSelectedYears(YearsFilterReduxL3);
    }
  }, [YearsFilterReduxL3]);

  useEffect(() => {
    let filteredData = [...LeadReports];

    if (clientFilter && clientFilter.trim() !== "") {
      filteredData = filteredData.filter(
        (lead) =>
          lead.ClientName.toLowerCase().trim() === clientFilter.toLowerCase().trim()
      );
    }
    if (selectedYears && selectedYears.length > 0) {
      filteredData = filteredData.filter((lead) => {
        if (!lead.LeadDate) return false; // Skip if no LeadDate

        // Ensure date conversion works
        const leadYear = new Date(lead.LeadDate).getFullYear();

        return selectedYears.includes(leadYear);
      });
    }
    if (groupFilter && groupFilter.trim() !== "") {
      filteredData = filteredData.filter((lead) =>
        lead.AssignedGroup.toLowerCase().includes(groupFilter.toLowerCase())
      );
    }
    if (groupFilter === "All") {
      setGroupFilter("");
      setgroupName("");
      // setclientName("");
      // setClientFilter("");
      // setSelectedYears([]);
      // dispatch(setSelectedYearsReduxL3("")); // Reset client filter in Redux as well
    }
    if (clientFilter === "All") {
      // setgroupName("");
      setclientName("");
      setClientFilter("");
      // setSelectedYears([]);
      // dispatch(setSelectedYearsReduxL3("")); // Reset client filter in Redux as well
    }
    setFilteredLeadReports(filteredData); // Update a new state variable for filtered data
  }, [selectedYears, LeadReports, clientFilter, groupFilter, dispatch]);

  useEffect(() => {
    let filteredData = [...LeadReports];

    if (clientFilter && clientFilter.trim() !== "") {
      filteredData = filteredData.filter(
        (lead) =>
          lead.ClientName.toLowerCase().trim() === clientFilter.toLowerCase().trim()
      );
    }
    if (groupFilter && groupFilter.trim() !== "") {
      filteredData = filteredData.filter((lead) =>
        lead.AssignedGroup.toLowerCase().includes(groupFilter.toLowerCase())
      );
    }
    if (groupFilter === "All") {
      setGroupFilter("");
      setgroupName("");
      // setclientName("");
      // setClientFilter("");
      // setSelectedYears([]);
      // dispatch(setSelectedYearsReduxL3("")); // Reset client filter in Redux as well
    }
    if (clientFilter === "All") {
      // setgroupName("");
      setclientName("");
      setClientFilter("");
      // setSelectedYears([]);
      // dispatch(setSelectedYearsReduxL3("")); // Reset client filter in Redux as well
    }
    setnewLeadReports(filteredData); // Update a new state variable for filtered data
  }, [selectedYears, LeadReports, clientFilter, groupFilter, dispatch]);

  const handleInputChange = useCallback(
    (groupFilter) => {
      setgroupName(groupFilter);
      setGroupFilter(groupFilter);
      setclientName("");
      setClientFilter("");
      setClientData("");
      setSelectedYears([]);
      setShowGroupDropdown(false);

      dispatch(setGroupFilterReduxL3(groupFilter));
      dispatch(setClientFilterReduxL3(""));
      dispatch(setSelectedYearsReduxL3("")); // Reset client filter in Redux as well
    

      dispatch(
        setClientRatingL3({
          starRating: "0",
        })
      );
      dispatch(setPotentialFromDateReduxL3(""));
      dispatch(setPotentialToDateReduxL3(""));
      dispatch(setPotentialStatusReduxL3("All"));

      dispatch(setProbabilityFromDateReduxL3(""));
      dispatch(setProbabilityToDateReduxL3(""));

      dispatch(setSourceFromDateReduxL3(""));
      dispatch(setSourceToDateReduxL3(""));
      dispatch(setSourceReduxL3("All"));

      // dispatch(setHistoricalYearReduxL3([])); // Update Redux state
      // dispatch(setHistoricalYearReduxL3([]));
    },
    [setGroupFilter, setclientName, setgroupName, dispatch]
  );

  const handleClientChange = useCallback(
    (clientValue) => {
      setclientName(clientValue);
      setClientFilter(clientValue);
      setSelectedYears([]);
      setShowClientDropdown(false);
      dispatch(setClientFilterReduxL3(clientValue));

      dispatch(setPotentialFromDateReduxL3(""));
      dispatch(setPotentialToDateReduxL3(""));
      dispatch(setPotentialStatusReduxL3("All"));

      dispatch(setProbabilityFromDateReduxL3(""));
      dispatch(setProbabilityToDateReduxL3(""));

      dispatch(setSourceFromDateReduxL3(""));
      dispatch(setSourceToDateReduxL3(""));
      dispatch(setSourceReduxL3("All"));

      // dispatch(setHistoricalYearReduxL3([])); // Update Redux state
      // dispatch(setHistoricalYearReduxL3([]));
    },
    [setClientFilter, setclientName, dispatch]
  );

  const handleYearSelection = (event) => {
    const selectedValue = parseInt(event.target.value);

    setSelectedYears((prev) => {
      const updatedYears = event.target.checked
        ? [...prev, selectedValue] // Add year if checked
        : prev.filter((yr) => yr !== selectedValue); // Remove year if unchecked

      dispatch(setSelectedYearsReduxL3(updatedYears)); // Dispatch the updated array to Redux store

      dispatch(setPotentialFromDateReduxL3(""));
      dispatch(setPotentialToDateReduxL3(""));
      dispatch(setPotentialStatusReduxL3("All"));

      dispatch(setProbabilityFromDateReduxL3(""));
      dispatch(setProbabilityToDateReduxL3(""));

      dispatch(setSourceFromDateReduxL3(""));
      dispatch(setSourceToDateReduxL3(""));
      dispatch(setSourceReduxL3("All"));

      // dispatch(setHistoricalYearReduxL3([])); // Update Redux state
      // dispatch(setHistoricalYearReduxL3([]));

      return updatedYears;
    });
  };

  const handleAllLeadsL3 = useCallback(
    (clickedDashStatus) => {
      let AllLeadsL1;

      if (clickedDashStatus === "All") {
        AllLeadsL1 = filteredLeadReports; // Show all data
      } else {
        AllLeadsL1 = filteredLeadReports.filter(
          (item) => item.Status === clickedDashStatus
        );
      }
      setDashboardReportL3_11(AllLeadsL1);
      navigate("/dashboard/L3/lead-report13");
    },
    [filteredLeadReports, navigate, setDashboardReportL3_11]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowYearDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [clientData, setClientData] = useState({
    starRating: "",
  });

  useEffect(() => {
    const fetchLead = async () => {
      try {
        let response;
        if (clientName && !groupName) {
          // Fetch ratings for the client (multiple groups)
          response = await dispatch(
            getsingleClientRatingData(clientName)
          ).unwrap();
        } else if (clientName && groupName) {
          // Fetch ratings for the specific group under the client
          response = await dispatch(
            getClientRatingData({ clientName, groupName })
          ).unwrap();
        } else {
          return;
        }

        const data = response;
        console.log("this isthe rating data===", data);

        if (data.message === "No ratings found") {
          setClientData({ starRating: 0 });
          dispatch(setClientRatingL3({ starRating: "0" }));
          return;
        }

        if (Array.isArray(data)) {
          let filteredData = data;

          if (filteredData.length > 0) {
            const totalRatingSum = filteredData.reduce(
              (acc, curr) => acc + (curr.TotalRating || 0),
              0
            );
            const averageRating = totalRatingSum / filteredData.length;

            console.log("Total Rating Sum:", totalRatingSum);
            console.log("Number of Ratings:", filteredData);
            console.log("Final Average Rating:", averageRating);

            setClientData({ starRating: averageRating });
            dispatch(setClientRatingL3({ starRating: averageRating }));
          } else {
            console.log("Number of Ratings:", filteredData);

            setClientData({ starRating: 0 });
            dispatch(setClientRatingL3({ starRating: "0" }));
          }
        }
      } catch (error) {
        console.error("Error fetching lead data:", error);
        setClientData({ starRating: 0 });
        dispatch(setClientRatingL3({ starRating: "0" }));
      }
    };

    fetchLead();
  }, [clientName, GroupNamesL3, groupName, dispatch]);

  interface StarRatingProps {
    rating?: number;
  }
  const StarRating: React.FC<StarRatingProps> = ({ rating }) => {
    console.log("StarRating received rating:", rating); // Debugging

    if (rating === undefined || rating === null) return null; // Prevent rendering if rating is invalid

    // Normalize the rating to a 5-star system
    const normalizedRating = rating > 5 ? (rating / 10) * 5 : rating;
    const safeRating = Math.min(Math.max(normalizedRating, 0), 5); // Ensure within range

    const fullStars = Math.floor(safeRating);
    const decimalPart = safeRating - fullStars; // Extract decimal part
    const hasHalfStar = decimalPart >= 0.5; // Show half-star if decimal part is 0.5 or more
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0); // Ensure total is 5

    return (
      <div className="flex items-center text-yellow-500 text-3xl">
        {Array(fullStars)
          .fill(null)
          .map((_, i) => (
            <FaStar key={i} />
          ))}
        {hasHalfStar && <FaStarHalfAlt key="half-star" />}
        {Array(emptyStars)
          .fill(null)
          .map((_, i) => (
            <FaRegStar key={`empty-${i}`} />
          ))}
      </div>
    );
  };
  const getUniqueClients = () => {
    let filtered = [];
  
    if (groupFilter && groupFilter !== "All") {
      filtered = LeadReports.filter(
        (lead) => lead.AssignedGroup === groupFilter
      );
    } else if (groupFilter === "All") {
      filtered = [...LeadReports];
    } else {
      filtered = [...LeadReports]; // Default to all
    }
  
    const uniqueClientsMap = new Map();
  
    filtered.forEach((lead) => {
      const key = lead.ClientName.toLowerCase().trim();
      if (!uniqueClientsMap.has(key)) {
        uniqueClientsMap.set(key, lead.ClientName); // or lead, if you want more data
      }
    });
  
    return Array.from(uniqueClientsMap.values());
  };

  return (
    <>
      <div className="h-screen overflow-y-scroll bg-[#f3f8f8]">
        <L3Sidebar />
        <div className="pl-[18%] pt-5 pr-2">
          <div className=" row-span-1 col-start-2 col-span-1">
            <div className=" p-4  bg-white w-full flex items-center rounded-lg top-4">
              <div className="flex flex-col w-full">
                <div className="relative flex w-full items-center justify-between space-x-2">
                  <div className="flex items-center space-x-2">
                    <div className="flex gap-2 align-middle items-center scale-90">
                      <h1 className="font-semibold ">GROUP</h1>

                      <div className=" ">
                        <div
                          ref={dropdownRefGroup}
                          className="relative flex  border p-0  rounded-md  align-middle items-center"
                        >
                          <ExpandMoreIcon
                            color="white"
                            className="absolute cursor-pointer right-2 m-auto bg-white"
                            onClick={() =>
                              setShowGroupDropdown(!showGroupDropdown)
                            }
                          />

                          <button
                            type="button"
                            onClick={() =>
                              setShowGroupDropdown(!showGroupDropdown)
                            }
                            className=" text-sm text-left rounded-md  outline-none text-nowrap truncate p-2 w-40"
                          >
                            {groupFilter ? groupFilter : <p>Select Group</p>}
                          </button>

                          {showGroupDropdown && (
                            <div
                              style={{ zIndex: 9999 }}
                              className="absolute top-full max-w-60  left-0 w-full max-h-64 cursor-pointer overflow-y-auto bg-gray-100 text-sm border border-gray-300 shadow-lg flex flex-col"
                            >
                              <div
                                className="p-2 hover:bg-gray-200 cursor-pointer"
                                onClick={() => handleInputChange("All")}
                              >
                                All
                              </div>
                              {GroupNamesL3.map((group) => (
                                <div
                                  className="p-2 hover:bg-gray-200 text-wrap cursor-pointer"
                                  onClick={() => handleInputChange(group)}
                                  key={group.id}
                                  value={group}
                                >
                                  {group}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-2 align-middle items-center scale-90">
                      <h1 className="font-semibold">CLIENT</h1>

                      <div className="relative">
                        <div className="flex  p-0 border rounded-md align-middle items-center">
                          <ExpandMoreIcon
                            className="absolute right-1 cursor-pointer bg-white"
                            onClick={() =>
                              setShowClientDropdown(!showClientDropdown)
                            }
                          />

                          <button
                            type="button"
                            onClick={() =>
                              setShowClientDropdown(!showClientDropdown)
                            }
                            className=" text-left text-sm outline-none p-2 text-nowrap truncate w-40"
                          >
                            {clientFilter ? clientFilter : <p>Select Client</p>}
                          </button>
                        </div>

                        {showClientDropdown && (
                          <div
                            ref={dropdownRefclient}
                            style={{ zIndex: 9999 }}
                            className="absolute top-full left-0 w-full max-h-64 cursor-pointer overflow-y-auto bg-gray-100 text-sm border border-gray-300 shadow-lg"
                          >
                            <p
                              className="p-2 hover:bg-gray-200"
                              onClick={() => handleClientChange("All")}
                            >
                              All
                            </p>
                            {getUniqueClients().map((clientName, idx) => (
                              <p
                                className="p-2 hover:bg-gray-200"
                                onClick={() => handleClientChange(clientName)}
                                key={idx}
                              >
                                {clientName}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Year SELECTOR */}
                    <div className=" flex gap-2 align-middle items-center scale-90">
                      <h1 className="font-semibold">YEAR</h1>

                      <div className=" ">
                        <div
                          ref={dropdownRef}
                          className="relative flex  p-0  rounded-md border  align-middle items-center"
                        >
                          <ExpandMoreIcon
                            color="white"
                            className="absolute right-0 border-r border-gray-300 m-auto cursor-pointer bg-white"
                            onClick={() =>
                              setShowYearDropdown(!showYearDropdown)
                            }
                          />

                          <button
                            type="button"
                            onClick={() =>
                              setShowYearDropdown(!showYearDropdown)
                            }
                            className=" text-left text-sm rounded-md  outline-none p-2 text-nowrap overflow-hidden w-40"
                          >
                            {selectedYears.length > 0
                              ? selectedYears.join(", ")
                              : "Select Year"}
                          </button>

                          {showYearDropdown && (
                            <div
                              style={{ zIndex: 9999 }}
                              className="absolute -bottom-44 w-full bg-gray-100 text-sm border border-gray-300 shadow-lg"
                            >
                              {years.map((year) => (
                                <label
                                  key={year}
                                  className="flex items-center px-4 py-2 hover:bg-blue-400 cursor-pointer"
                                >
                                  <input
                                    type="checkbox"
                                    value={year}
                                    checked={selectedYears.includes(year)}
                                    onChange={handleYearSelection}
                                    className="mr-2"
                                  />
                                  {year}
                                </label>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      {clientData &&
                        clientData.starRating > 0 && ( // Only show stars if starRating is greater than 0
                          <>
                            <StarRating rating={clientData.starRating} />
                          </>
                        )}
                    </div>
                  </div>

                  <div className="relative cursor-pointer mr-[2%] bg-transparent">
                    <FaUserCircle
                      onClick={logout}
                      className="text-black w-6 h-6"
                    />
                    {showLogout && (
                      <div
                        ref={popupRef}
                        className="absolute right-0 top-full mt-1 text-black text-center px-2 w-36 shadow-2xl overflow-hidden bg-white border border-gray-400 z-50 rounded-lg"
                      >
                        <h1 className="px-6 py-2 rounded-md my-1">{name}</h1>
                        <h1
                          onClick={logoutUser}
                          className="flex items-center gap-2 px-6 py-2 rounded-md my-1 cursor-pointer hover:bg-black hover:text-white"
                        >
                          <MdLogout size={24} /> Logout
                        </h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 py-4">
            <div className="bg-white border shadow-md rounded-lg  ">
              <div
                onClick={() => {
                  handleAllLeadsL3("All");
                }}
                className="flex justify-between items-center rounded-t-lg  bg-[#e32278] text-white text-left p-2"
                style={{ cursor: "pointer" }}
              >
                <h2 className="text-lg font-semibold">All</h2>
                <h1 className="text-2xl font-bold">{LeadCount}</h1>

                <TableChartIcon
                  alt="handshake icon"
                  className="w-12 h-9 bottom-2 right-2 "
                />
              </div>
              <div className="p-4 flex justify-center items-center">
                <div className="flex-col">
                  <div className="flex justify-center items-center gap-4">
                    <p className="text-lg mt-2 text-center font-bold">
                      $
                      {totalQuotedValue
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white border rounded-lg shadow-md overflow-hidden ">
              <div
                onClick={() => {
                  handleAllLeadsL3("Won");
                }}
                className=" flex justify-between items-center bg-[#157f3d] text-white text-left p-2"
                style={{ cursor: "pointer" }}
              >
                <h2 className="text-lg font-semibold">Won</h2>
                <h1 className="text-2xl font-bold">{wonLeadCount}</h1>
                <EmojiEventsIcon
                  alt="handshake icon"
                  className="w-12 h-9 bottom-2 right-2"
                />
              </div>
              <div className="p-4 flex justify-center items-center">
                <div className="flex-col">
                  <div className="flex gap-4">
                    <p className="text-lg mt-2 font-bold">
                      $
                      {wonTotalQuotedValue
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white border rounded-lg shadow-md overflow-hidden ">
              <div
                onClick={() => {
                  handleAllLeadsL3("Won Recurring");
                }}
                className="flex justify-between items-center bg-[#1f41bb] text-white text-left p-2"
                style={{ cursor: "pointer" }}
              >
                <h2 className="text-lg font-semibold">Won Rec</h2>
                <h1 className="text-2xl font-bold">{wonRecurringLeadCount}</h1>
                <PublishedWithChangesIcon
                  alt="handshake icon"
                  className="w-12 h-9 bottom-2 right-2"
                />
              </div>
              <div className="p-4 flex justify-center items-center">
                <div className="flex-col">
                  <div className="flex gap-4">
                    <p className="text-lg mt-2 font-bold">
                      $
                      {wonRecurringTotalQuotedValue
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white border rounded-lg shadow-md overflow-hidden ">
              <div
                onClick={() => {
                  handleAllLeadsL3("Pending");
                }}
                className="flex justify-between items-center bg-[#ff9a00] text-white text-left p-2"
                style={{ cursor: "pointer" }}
              >
                <h2 className="text-lg font-semibold">Pending</h2>
                <h1 className="text-2xl font-bold">{pendingLeadCount}</h1>

                <PendingActionsIcon
                  alt="handshake icon"
                  className="w-12 h-9 bottom-2 right-2"
                />
              </div>
              <div className="p-4 flex justify-center items-center">
                <div className="flex-col">
                  <div className="flex gap-4">
                    <p className="text-lg mt-2 font-bold">
                      $
                      {pendingTotalQuotedValue
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white border rounded-lg shadow-md overflow-hidden ">
              <div
                onClick={() => {
                  handleAllLeadsL3("Lost");
                }}
                className="flex justify-between items-center bg-[#ba1c1d] text-white text-left p-2"
                style={{ cursor: "pointer" }}
              >
                <h2 className="text-lg font-semibold">Lost</h2>
                <h1 className="text-2xl font-bold">{lostLeadCount}</h1>
                <BlockIcon
                  alt="handshake icon"
                  className="w-9 h-7  bottom-2 right-2"
                />
              </div>
              <div className="p-4 flex justify-center items-center">
                <div className="flex-col">
                  <div className="flex gap-4">
                    <p className="text-lg mt-2 font-bold">
                      $
                      {lostTotalQuotedValue
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FollowupTableL3 filteredLeadReports={filteredLeadReports} />

          <div className="flex gap-3 py-2 h-[30rem]">
            <div
              className="px-3 relative pb-3 bg-white rounded-lg w-1/2 h-full justify-center top-4 "
              style={{ fontFamily: "Lora", fontWeight: 600 }}
            >
              <PotentialStatusL3 filteredLeadReports={filteredLeadReports} />
            </div>
            <div
              className="px-3 relative bg-white rounded-lg w-1/2 h-full justify-center top-4 "
              style={{ fontFamily: "Lora", fontWeight: 600 }}
            >
              <ProbabilityStatusL3
                filteredLeadReports={filteredLeadReports}
                empGroups={GroupNamesL3}
              />
            </div>
          </div>
          <div className="flex gap-3 py-2 h-[30rem] ">
            <div
              className="px-3 relative pb-3 bg-white rounded-lg  w-1/2 justify-center top-4 "
              style={{ fontFamily: "Lora", fontWeight: 600 }}
            >
              <LeadSourceL3 filteredLeadReports={filteredLeadReports} />
            </div>
            <div
              className="px-3 relative bg-white rounded-lg  w-1/2 justify-center top-4 "
              style={{ fontFamily: "Lora", fontWeight: 600 }}
            >
              <ConversionRateL3 newLeadReports={newLeadReports} />
            </div>
          </div>

          <div className="pt-12">
            <div
              className="w-full   "
              style={{ fontFamily: "Lora", fontWeight: 600 }}
            >
              <HistoricalDataL3
                filteredLeadReports={filteredLeadReports}
                newLeadReports={newLeadReports}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard2;
