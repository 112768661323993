import { createContext, useContext, useState } from "react";

export const RoleContext = createContext();

const RoleContextProvider = ({children}) => {
    const [role, setRole] = useState(null);
    return (
        <RoleContext.Provider value={{role, setRole}}>
            {children}
        </RoleContext.Provider>
    )
}

export default RoleContextProvider;
export const useRole = () => useContext(RoleContext)