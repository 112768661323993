import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";

// **FETCH Labour Cost**
export const fetchLabourCost = createAsyncThunk(
  "labourCost/fetchLabourCost",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/labourCost/labourCost");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// **ADD Labour Cost**
export const addLabourCost = createAsyncThunk(
  "labourCost/addLabourCost",
  async ({ department, hours, labourCost }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/labourCost/labour", {
        department,
        hours,
        labourCost,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// **UPDATE Labour Cost**
export const updateLabourCost = createAsyncThunk(
  "labourCost/updateLabourCost",
  async ({ labourId, department, hours, labourCost }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/labourCost/labour/${labourId}`, {
        department,
        hours,
        labourCost,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// **DELETE Labour Cost**
export const deleteLabourCost = createAsyncThunk(
  "labourCost/deleteLabourCost",
  async (id, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/labourCost/labour/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const labourCostSlice = createSlice({
  name: "labourCost",
  initialState: {
    labourCosts: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // FETCH
      .addCase(fetchLabourCost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLabourCost.fulfilled, (state, action) => {
        state.loading = false;
        state.labourCosts = action.payload;
      })
      .addCase(fetchLabourCost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // ADD
      .addCase(addLabourCost.pending, (state) => {
        state.loading = true;
      })
      .addCase(addLabourCost.fulfilled, (state, action) => {
        state.loading = false;
        state.labourCosts.push(action.payload);
      })
      .addCase(addLabourCost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // UPDATE
      .addCase(updateLabourCost.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateLabourCost.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.labourCosts.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1) {
          state.labourCosts[index] = action.payload;
        }
      })
      .addCase(updateLabourCost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // DELETE
      .addCase(deleteLabourCost.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteLabourCost.fulfilled, (state, action) => {
        state.loading = false;
        state.labourCosts = state.labourCosts.filter(
          (item) => item.id !== action.payload
        );
      })
      .addCase(deleteLabourCost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default labourCostSlice.reducer;
