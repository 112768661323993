import React, { useState, useEffect, useRef, useCallback } from 'react';
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import SquareIcon from "@mui/icons-material/Square";
import { useDispatch, useSelector } from 'react-redux';
import { fetchFinancialData } from '../../../redux/slices/financialSlice';


const FinancialPiechartL2 = () => {

  const [chartdata, setchartData] = useState([])

  const dispatch = useDispatch();
  const financialData = useSelector((state) => state.financial.financialData);

  useEffect(() => {
    dispatch(fetchFinancialData());
  }, [dispatch]);

  useEffect(()=>{
if(financialData){setchartData(financialData)}
  },[financialData])


  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [noDataMessage, setNoDataMessage] = useState("");
  const FinancialL1ChartRef = useRef(null);
  const chartInstance = useRef(null);

  // Function to update the pie chart with the new data
  const updatePieChartWithOverallProfit = useCallback(
    (chartLabels, chartData) => {
      if (!FinancialL1ChartRef.current) return;

      const ctx = FinancialL1ChartRef.current;
      const quarterColorArray = ["indigo", "orange", "green", "blue"];

      // Destroy existing chart instance if any
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      // Create new chart instance
      chartInstance.current = new Chart(ctx, {
        type: "pie",
        data: {
          labels: chartLabels,
          datasets: [{ data: chartData, backgroundColor: quarterColorArray }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: { label: (tooltipItem) => `${tooltipItem.raw}%` },
            },
            datalabels: {
              formatter: (value) => `${value}%`,
              color: "white",
              font: { weight: "bold", size: 12 },
            },
          },
        },
        plugins: [ChartDataLabels],
      });
    },
    [] // Empty dependencies means this function doesn't recreate unnecessarily
  );





  const handleGroupChange = useCallback((event) => {
    const { name, value } = event.target;

    // Update state based on selection
    if (name === "group") {
      setSelectedGroup(value);
    } else if (name === "year") {
      setSelectedYear(value);
    } else if (name === "quarter") {
      setSelectedQuarter(value);
    }

    const selectedGroupValue = name === "group" ? value : selectedGroup;
    const selectedYearValue = name === "year" ? value : selectedYear;
    const selectedQuarterValue = name === "quarter" ? value : selectedQuarter;

    // Function to extract year from LeadDate
    const extractYear = (dateStr) => {
      if (!dateStr) return null;
      return dateStr.includes("T")
        ? new Date(dateStr).getFullYear().toString()
        : dateStr.split("-")[2];
    };

    // Filter data based on selected group and year
    let filteredData = chartdata.filter((lead) => {
      const leadYear = extractYear(lead.leadDate);
      const isGroupMatch = selectedGroupValue ? lead.AssignedGroup === selectedGroupValue : true;
      const isYearMatch = selectedYearValue ? leadYear === selectedYearValue : true;
      return isGroupMatch && isYearMatch;
    });

    if (filteredData.length === 0) {
      setNoDataMessage(`No data available for "${selectedGroupValue}" and year "${selectedYearValue}"`);
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
      return;
    }

    const quarterMonthRanges = {
      Q1: { months: [3, 4, 5], yearOffset: 0 },
      Q2: { months: [6, 7, 8], yearOffset: 0 },
      Q3: { months: [9, 10, 11], yearOffset: 0 },
      Q4: { months: [12, 1, 2], yearOffset: { 12: 0, 1: 1, 2: 1 } },
    };

    let chartLabels = [];
    let chartData = [];

    // If a specific quarter is selected, only process that quarter
    // if (selectedQuarterValue && quarterMonthRanges[selectedQuarterValue]) {
    if (selectedQuarterValue && quarterMonthRanges[selectedQuarterValue]?.months) {
      const selectedQuarterMonths = quarterMonthRanges[selectedQuarterValue];

      const quarterData = filteredData.filter((lead) => {
        const leadMonth = extractMonth(lead.leadDate);
        return selectedQuarterMonths.months.includes(leadMonth);
      });

      if (quarterData.length > 0) {
        const totalProfit = quarterData.reduce(
          (acc, lead) => acc + (parseFloat(lead.netProfitInPercentage) || 0),
          0
        );
        const overallProfit = (totalProfit / quarterData.length).toFixed(2);
        chartLabels.push(`Net Profit ${selectedQuarterValue}`);
        chartData.push(parseFloat(overallProfit));
      } else {
        chartLabels.push(`Net Profit ${selectedQuarterValue}`);
        chartData.push(0);
      }
    } else {
      // If no quarter is selected, show all quarters
      Object.keys(quarterMonthRanges).forEach((quarter) => {
        const selectedQuarterMonths = quarterMonthRanges[quarter];

        const quarterData = filteredData.filter((lead) => {
          const leadMonth = extractMonth(lead.leadDate);
          return selectedQuarterMonths.months.includes(leadMonth);
          // return selectedQuarterMonths.includes(leadMonth);
        });

        if (quarterData.length > 0) {
          const totalProfit = quarterData.reduce(
            (acc, lead) => acc + (parseFloat(lead.netProfitInPercentage) || 0),
            0
          );
          const overallProfit = (totalProfit / quarterData.length).toFixed(2);
          chartLabels.push(`Net Profit ${quarter}`);
          chartData.push(parseFloat(overallProfit));
        } else {
          chartLabels.push(`Net Profit ${quarter}`);
          chartData.push(0);
        }
      });
    }

    if (chartData.every((val) => val === 0)) {
      setNoDataMessage(`No data available for "${selectedGroup || "all groups"}" and ${selectedYear || "all years"}`);
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
      return;
    }

    updatePieChartWithOverallProfit(chartLabels, chartData);
    setNoDataMessage("");
  }, [chartdata, selectedGroup, selectedYear, selectedQuarter, updatePieChartWithOverallProfit]);

  // Extract month from date
  const extractMonth = (dateStr) => {
    if (!dateStr) return null;
    return new Date(dateStr).getMonth() + 1; // Month is 0-indexed, so we add 1
  };

  const quarters = [
    { label: "Quarter 1 (Mar - May)", value: "Q1" },
    { label: "Quarter 2 (Jun - Aug)", value: "Q2" },
    { label: "Quarter 3 (Sep - Nov)", value: "Q3" },
    { label: "Quarter 4 (Dec - Feb)", value: "Q4" },
  ];

  const [hasChartData, setHasChartData] = useState(true);

  const handleQuarterNetProfitPercentage = useCallback((selectedYear) => {
    setNoDataMessage("");

    if (chartdata.length === 0) {
      setHasChartData(false);
      return;
    }

    let filteredData = chartdata;

    // Filter by group and year if selected
    if (selectedGroup) {
      filteredData = filteredData.filter((lead) => lead.AssignedGroup === selectedGroup);
    }
    if (selectedYear) {
      filteredData = filteredData.filter((lead) => {
        const leadYear = new Date(lead.leadDate).getFullYear().toString();
        return leadYear === selectedYear || leadYear === (parseInt(selectedYear) + 1).toString();
      });
    }

    const quarterMonthRanges = {
      Q1: { months: [3, 4, 5], yearOffset: 0 },
      Q2: { months: [6, 7, 8], yearOffset: 0 },
      Q3: { months: [9, 10, 11], yearOffset: 0 },
      Q4: { months: [12, 1, 2], yearOffset: { 12: 0, 1: 1, 2: 1 } },
    };

    let chartLabels = [];
    let chartData = [];


    if (selectedQuarter) {
      const { months } = quarterMonthRanges[selectedQuarter];

      filteredData = filteredData.filter((lead) => {
        const leadDate = new Date(lead.leadDate);
        const leadMonth = leadDate.getMonth() + 1;
        const leadYear = leadDate.getFullYear();

        if (selectedQuarter === "Q4") {
          const expectedYear = leadMonth === 12 ? parseInt(selectedYear) : parseInt(selectedYear) + 1;
          return months.includes(leadMonth) && (!selectedYear || leadYear === expectedYear);
        } else {
          return months.includes(leadMonth) && (!selectedYear || leadYear === parseInt(selectedYear));
        }
      });


      if (filteredData.length > 0) {
        const totalProfit = filteredData.reduce((acc, lead) => acc + (parseFloat(lead.netProfitInPercentage) || 0),
          0
        );
        const overallProfit = (totalProfit / filteredData.length).toFixed(2);
        chartLabels.push(`Net Profit ${selectedQuarter} ${selectedYear ? `, ${selectedYear}` : ""}`);
        chartData.push(parseFloat(overallProfit));
      } else {
        chartLabels.push(`Net Profit ${selectedQuarter} ${selectedYear ? `, ${selectedYear}` : ""}`);
        chartData.push(0);
      }
    } else {
      // If no specific quarter is selected, show all quarters
      Object.keys(quarterMonthRanges).forEach((quarter) => {
        const { months } = quarterMonthRanges[quarter];

        const quarterData = filteredData.filter((lead) => {
          const leadDate = new Date(lead.leadDate);
          const leadMonth = leadDate.getMonth() + 1;
          const leadYear = leadDate.getFullYear();

          if (quarter === "Q4") {
            const expectedYear =
              leadMonth === 12 ? parseInt(selectedYear) : parseInt(selectedYear) + 1;
            return months.includes(leadMonth) && (!selectedYear || leadYear === expectedYear);
          } else {
            return months.includes(leadMonth) && (!selectedYear || leadYear === parseInt(selectedYear));
          }
        });

        if (quarterData.length > 0) {
          const totalProfit = quarterData.reduce(
            (acc, lead) => acc + (parseFloat(lead.netProfitInPercentage) || 0),
            0
          );
          const overallProfit = (totalProfit / quarterData.length).toFixed(2);
          chartLabels.push(`Net Profit ${quarter}${selectedYear ? `, ${selectedYear}` : ""}`);
          chartData.push(parseFloat(overallProfit));
        } else {
          chartLabels.push(`Net Profit ${quarter}${selectedYear ? `, ${selectedYear}` : ""}`);
          chartData.push(0);
        }
      });
    }

    if (chartData.every((val) => val === 0)) {
      setNoDataMessage(`No data available for "${selectedGroup || "all groups"}" and ${selectedYear || "all years"}`);
      setHasChartData(false);
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
      return;
    }

    setHasChartData(true);
    updatePieChartWithOverallProfit(chartLabels, chartData);
    setNoDataMessage("");
  },
    [chartdata, updatePieChartWithOverallProfit, selectedGroup, selectedQuarter]
  );


  useEffect(() => {
    if (chartdata.length > 0 && !selectedGroup && !selectedYear && !selectedQuarter) {
      handleQuarterNetProfitPercentage(""); // Pass empty year to show all
    }
  }, [chartdata, handleQuarterNetProfitPercentage, selectedGroup, selectedYear, selectedQuarter]);

  return (
    <div className="w-full mb-10 ">
      <div className="flex mb-4 mr-4 items-start space-x-2">
        <div className="mb-4 mr-1 " style={{ flex: "" }}>
          <label className="block" htmlFor="Name">
            Select Group:
          </label>
          <select
            className="flex items-center justify-between border border-gray-400 rounded-md p-2 shadow-md w-28"
            required
            name="group"
            onChange={handleGroupChange}
          >
            <option value="">All Group</option>
            {chartdata.map((lead) => lead.AssignedGroup)
              .filter((value, index, self) => self.indexOf(value) === index)
              .map((group, index) => (
                <option key={index} value={group}>
                  {group}
                </option>
              ))}
          </select>
        </div>

        <div className="mb-4 mr-4">
          <label className="block" htmlFor="Name">
            Select Year :
          </label>
          <select
            className="flex items-center justify-between border border-gray-400 rounded-md p-2 shadow-md w-28"
            required
            name="year"
            value={selectedYear}
            onChange={handleGroupChange}
          >
            <option value="">All Years</option>
            {[...new Set(chartdata.map((lead) => {
              const dateStr = lead.leadDate?.trim();
              let year = "";

              if (!dateStr) return null;

              if (dateStr.includes("T")) {
                year = new Date(dateStr).getFullYear();
              } else if (dateStr.match(/^\d{2}-\d{2}-\d{4}$/)) {
                year = dateStr.split("-")[2];
              }

              return year ? String(year) : null;
            }).filter(Boolean))]
              .sort((a, b) => b - a)
              .map((year, index) => (
                <option key={index} value={year}>
                  {year}
                </option>
              ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block" htmlFor="Name">
            Select Quarter :
          </label>
          <select
            value={selectedQuarter}
            name='quarter'
            onChange={handleGroupChange}
            className="flex items-center justify-between border border-gray-400 rounded-md p-2 shadow-md w-28"
          >
            <option value="">All Quarters</option>
            {quarters.map((quarter) => (
              <option key={quarter.value} value={quarter.value}>
                {quarter.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        {noDataMessage && (
          <div className="text-start mt-4 text-red-500">
            <p>{noDataMessage}</p>
          </div>
        )}
        <div
          className="ml-4 w-[60%] h-auto md:w-[300px] md:h-[300px] lg:w-[300px] lg:h-[400px] xl:w-[500px] xl:h-[370px] 2xl:w-[600px] 2xl:h-[600px]"
        >
          <canvas
            id="probPieChart"
            ref={FinancialL1ChartRef}
            className='w-full max-w-[300px] h-auto md:max-w-[400px] lg:max-w-[450px] xl:max-w-[350px] 2xl:max-w-[700px]'
          ></canvas>
        </div>
        {hasChartData && (
          <div className="flex mt-10 ml-[6%] w-full">
            <div className="mr-3 flex">
              <SquareIcon className="text-indigo-800" /><span className='text-xs'> 1st Qtr</span>
            </div>
            <div className="mr-3 flex">
              <SquareIcon className="text-orange-400 " /> <span className='text-xs'> 2nd Qtr</span>
            </div>
            <div className="mr-3 flex">
              <SquareIcon className="text-green-700 " /> <span className='text-xs'> 3rd Qtr</span>
            </div>
            <div className="mr-3 flex">
              <SquareIcon className="text-blue-700 " /> <span className='text-xs'> 4th Qtr</span>
            </div>
          </div>
        )}
      </div>
    </div>

  )
}

export default FinancialPiechartL2;