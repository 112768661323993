import { createContext, useContext } from "react";
import { useState } from "react";

const LeadReportContext = createContext();

const LeadReportProvider = ({ children }) => {
    const [LeadReports, setLeadReports] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [chartDataArray, setChartDataArray] = useState([]);
    const [fileUpload, setFileUpload] = useState(0);
    const [chartAmount, setChartAmount] = useState(0);

    const [potentialReport_11, setPotentialReport_11] = useState([])
    const [probabilityReport_11, setProbabilityReport_11] = useState([])
    const [leadSourceReport_11, setLeadSourceReport_11] = useState([])
    const [dashboardReport_11, setDashboardReport_11] = useState([])

    const [historicalL1, setHistoricalL1] = useState([])
    const [historicalTableL1, setHistoricalTableL1] = useState([])

    const [potentialReportL2_12, setPotentialReportL2_12] = useState([])
        const [probabilityReportL2_12, setProbabilityReportL2_12] = useState([])
        const [leadSourceReportL2_12, setLeadSourceReportL2_12] = useState([])
        const [dashboardReportL2_12, setDashboardReportL2_12] = useState([])

   const [historicalChartL2, setHistoricalChartL2] = useState([])
    const [historicalTableL2, setHistoricalTableL2] = useState([])

    const [potentialReportL3_11, setPotentialReportL3_11] = useState([])
          const [probabilityReportL3_11, setProbabilityReportL3_11] = useState([])
          const [leadSourceReportL3_11, setLeadSourceReportL3_11] = useState([])
          const [dashboardReportL3_11, setDashboardReportL3_11] = useState([])

     
   const [historicalChartL3, setHistoricalChartL3] = useState([])
   const [historicalTableL3, setHistoricalTableL3] = useState([])

    return (
        <LeadReportContext.Provider value={{
            LeadReports, setLeadReports, uploadedFiles, setUploadedFiles, fileUpload, setFileUpload, chartDataArray, setChartDataArray, 
            chartAmount, setChartAmount, setPotentialReport_11,potentialReport_11, setProbabilityReport_11, probabilityReport_11, 
            leadSourceReport_11 ,setLeadSourceReport_11, dashboardReport_11, setDashboardReport_11, 

            dashboardReportL2_12, setDashboardReportL2_12, leadSourceReportL2_12, setLeadSourceReportL2_12,
            probabilityReportL2_12, setProbabilityReportL2_12, potentialReportL2_12, setPotentialReportL2_12,
            
            dashboardReportL3_11, setDashboardReportL3_11, potentialReportL3_11, setPotentialReportL3_11,
            probabilityReportL3_11, setProbabilityReportL3_11, leadSourceReportL3_11, setLeadSourceReportL3_11,

            historicalL1, setHistoricalL1, historicalTableL1, setHistoricalTableL1,
            historicalChartL2, setHistoricalChartL2, historicalTableL2, setHistoricalTableL2, 

            historicalChartL3, setHistoricalChartL3,  historicalTableL3, setHistoricalTableL3
            }}>
            {children}
        </LeadReportContext.Provider>
    )
}

export default LeadReportProvider;
export const useLead = () => useContext(LeadReportContext)