import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotAuthorized = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // const timer = setTimeout(() => {
    //   navigate("/");
    // }, 1000); // Redirect after 4 seconds
    navigate("/");
    // return () => clearTimeout(timer); // Cleanup the timeout when unmounting
  }, [navigate]);

  // const Redirect = () =>{
  //   navigate('/')
  // }


  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-r from-gray-100 to-gray-300">
      <div className="text-center">
        <div className="relative w-32 h-32 mx-auto mb-6">
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-20 h-20 bg-red-600 rounded-full border-4 border-red-800 animate-pulse"></div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="absolute inset-10 w-12 h-12 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 6l12 12M6 18L18 6"
            />
          </svg>
        </div>

        <h1 className="text-4xl font-bold text-gray-700">403 - Not Authorized</h1>
        <p className="mt-4 text-gray-500">
          Oops! You don’t have permission to access this page.
        </p>
        {/* <button onClick={Redirect} 
        className='ml-2 mt-6 py-2 px-5 bg-black hover:bg-white hover:text-black border border-black overflow-hidden shadow-md text-white rounded-sm'
        >Back To Login</button> */}
      </div>
    </div>
  );
};

export default NotAuthorized;
