import axios from "axios";
import config from './api'; // Import backend URL

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: config.backendUrl,
});

// Attach the Authorization header for every request
axiosInstance.interceptors.request.use((requestConfig) => {
    const token = localStorage.getItem("token"); // Get token from localStorage
    if (token) {
      requestConfig.headers.Authorization = `Bearer ${token}`;
    } else {
      console.warn("⚠ No token found in localStorage tokencheckgroup");
    }
    return requestConfig;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
