// import React from 'react';
import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate()
  // const [, setShowPopup] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    enqueueSnackbar("Please enter correct path ", { variant: "error" });
    // localStorage.removeItem("email");
    navigate("/");
    // setRole(null);
    // const timer = setTimeout(() => {
    //   setShowPopup(false);
    //   navigate("/login");
    // }, 1000);

    // return () => clearTimeout(timer);
  }, [ enqueueSnackbar,navigate]);
  return (
    <>
      {/* {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>Page not found! Redirecting to login...</p>
          </div>
        </div>
      )} */}
    </>
    // <div className="flex items-center justify-center h-screen bg-gray-100">
    //     <div className="text-center">
    //         <img
    //             src={PageNotFoundImg}
    //             alt="404 Not Found"
    //             className="mx-auto max-w-3xl w-full animate-bounce"
    //         />
    //     </div>
    // </div>
  );
};

export default NotFoundPage;
