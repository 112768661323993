import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";

export const fetchLeadTypes = createAsyncThunk("leadTypes/fetchLeadTypes", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get("/leadTypes");
    return response.data;
  } catch (error) {
    console.error("Error fetching lead types:", error);
    return rejectWithValue(error.response?.data || "Failed to fetch lead types");
  }
});


// Add a new lead type
export const addLeadType = createAsyncThunk("leadTypes/addLeadType", async (LeadType, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post("/leadTypes", { LeadType });
    return response.data;
  } catch (error) {
    console.error("Error adding lead type:", error);
    return rejectWithValue(error.response?.data || "Failed to add lead type");
  }
});

// Update a lead type
export const updateLeadType = createAsyncThunk("leadTypes/updateLeadType", async ({ id, LeadType }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.put(`/leadTypes/${id}`, { LeadType });
    return response.data;
  } catch (error) {
    console.error("Error updating lead type:", error);
    return rejectWithValue(error.response?.data || "Failed to update lead type");
  }
});

// Delete a lead type
export const deleteLeadType = createAsyncThunk("leadTypes/deleteLeadType", async (id, { rejectWithValue }) => {
  try {
    await axiosInstance.delete(`/leadTypes/${id}`);
    return id;
  } catch (error) {
    console.error("Error deleting lead type:", error);
    return rejectWithValue(error.response?.data || "Failed to delete lead type");
  }
});

const leadTypeSlice = createSlice({
  name: "serviceTypesRedux",
  initialState: {
    leadTypesRedux: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch lead types
      .addCase(fetchLeadTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLeadTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.leadTypesRedux = action.payload;
      })
      .addCase(fetchLeadTypes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Add lead type
      .addCase(addLeadType.pending, (state) => {
        state.loading = true;
      })
      .addCase(addLeadType.fulfilled, (state, action) => {
        state.loading = false;
        state.leadTypesRedux.push(action.payload);
      })
      .addCase(addLeadType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update lead type
      .addCase(updateLeadType.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateLeadType.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.leadTypesRedux.findIndex((lead) => lead.id === action.payload.id);
        if (index !== -1) {
          state.leadTypesRedux[index] = action.payload;
        }
      })
      .addCase(updateLeadType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete lead type
      .addCase(deleteLeadType.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteLeadType.fulfilled, (state, action) => {
        state.loading = false;
        state.leadTypesRedux = state.leadTypesRedux.filter((lead) => lead.id !== action.payload);
      })
      .addCase(deleteLeadType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default leadTypeSlice.reducer;
