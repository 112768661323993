import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


const AuthCheck = () => {
        const navigate = useNavigate();
    
   const SessionRole = sessionStorage.getItem("userRole")
   useEffect(()=>{
    navigate("/dashboard/"+SessionRole)
   },[navigate,SessionRole])
  return (
    <div>
      redirecting to dashoard
    </div>
  )
}

export default AuthCheck

// implementing routing as per userRole
// user role