import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";// Import axios instance

// **1️⃣ Fetch all sources**
export const fetchSources = createAsyncThunk("source/fetchSources", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get("/source");
    return response.data; // Assuming API returns an array of sources
  } catch (error) {
    return rejectWithValue(error.response?.data || "Error fetching sources");
  }
});

// **2️⃣ Add a new source**
export const addSource = createAsyncThunk("source/addSource", async (newSource, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post("/source", { newSource });
    return response.data; // Assuming API returns the created source
  } catch (error) {
    return rejectWithValue(error.response?.data || "Error adding source");
  }
});

// **3️⃣ Update an existing source**
export const updateSource = createAsyncThunk("source/updateSource", async ({ id, newSource }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.put(`/source/${id}`, { newSource });
    return response.data; // Assuming API returns the updated source
  } catch (error) {
    return rejectWithValue(error.response?.data || "Error updating source");
  }
});

// **4️⃣ Delete a source**
export const deleteSource = createAsyncThunk("source/deleteSource", async (id, { rejectWithValue }) => {
  try {
    await axiosInstance.delete(`/source/${id}`);
    return id; // Return the deleted source ID to update Redux state
  } catch (error) {
    return rejectWithValue(error.response?.data || "Error deleting source");
  }
});

const sourceSlice = createSlice({
  name: "source",
  initialState: {
    source: [],
    loading: false,
    error: null,
  },
  reducers: {}, // No standard reducers needed as we use asyncThunks
  extraReducers: (builder) => {
    builder
      .addCase(fetchSources.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSources.fulfilled, (state, action) => {
        state.loading = false;
        state.source = action.payload;
      })
      .addCase(fetchSources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addSource.fulfilled, (state, action) => {
        state.source.push(action.payload);
      })
      .addCase(updateSource.fulfilled, (state, action) => {
        const index = state.source.findIndex((src) => src.id === action.payload.id);
        if (index !== -1) state.source[index] = action.payload;
      })
      .addCase(deleteSource.fulfilled, (state, action) => {
        state.source = state.source.filter((src) => src.id !== action.payload);
      });
  },
});

export default sourceSlice.reducer;
