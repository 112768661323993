

export function ValidDate(dateString) {
    // Try parsing using Date constructor (ISO 8601 format: '2000-01-19T18:30:00.000Z')
    const isoDate = new Date(dateString);
    if (!isNaN(isoDate.getTime())) {
      return isoDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD
    }
  
    // Check for the "MM-DD-YYYY" format (like "01-15-2025")
    const regex = /^(\d{2})-(\d{2})-(\d{4})$/;
    const match = dateString.match(regex);
    if (match) {
      const [ , month, day, year] = match;
      return new Date(`${year}-${month}-${day}`).toISOString().split('T')[0]; // Format to YYYY-MM-DD
    }
  
    // Check for the "Tue Feb 11 2025" format (like "Tue Feb 11 2025 00:00:00 GMT+0530")
    const dateObject = new Date(dateString);
    if (!isNaN(dateObject.getTime())) {
      return dateObject.toISOString().split('T')[0]; // Format to YYYY-MM-DD
    }
  
    // If none of the above formats work, return an error or null
    console.error("Invalid date format:", dateString);
    return null;
  }
  
export function ConversionValidDate(dateString) {
  if (!dateString) return null; // Handle empty or undefined values

  // Try parsing using Date constructor (ISO 8601 format: '2000-01-19T18:30:00.000Z')
  const parsedDate = new Date(dateString);
  if (!isNaN(parsedDate.getTime())) {
    return formatToMMDDYYYY(parsedDate);
  }

  // Check for the "YYYY-MM-DD" or "MM-DD-YYYY" format
  const regex = /^(\d{2})-(\d{2})-(\d{4})$/; // MM-DD-YYYY
  const match = dateString.match(regex);
  if (match) {
    const [ , month, day, year] = match;
    return `${month}-${day}-${year}`;
  }

  // If none of the above formats work, return null
  console.error("Invalid date format:", dateString);
  return null;
}

// Helper function to format a Date object to MM-DD-YYYY
function formatToMMDDYYYY(dateObj) {
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Get month (0-based index)
  const day = String(dateObj.getDate()).padStart(2, "0"); // Get day
  const year = dateObj.getFullYear(); // Get year
  return `${month}-${day}-${year}`; // Format as MM-DD-YYYY
}


  // export const ValidDateConversion = (dateString)=> {
  //   // Try parsing using Date constructor (ISO 8601 format: '2000-01-19T18:30:00.000Z')
  //   const isoDate = new Date(dateString);
  //   if (!isNaN(isoDate.getTime())) {
  //     return isoDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD
  //   }
  
  //   // Check for the "MM-DD-YYYY" format (like "01-15-2025")
  //   const regex = /^(\d{2})-(\d{2})-(\d{4})$/;
  //   const match = dateString.match(regex);
  //   if (match) {
  //     const [ , month, day, year] = match;
  //     return new Date(`${year}-${month}-${day}`).toISOString().split('T')[0]; // Format to YYYY-MM-DD
  //   }
  
  //   // Check for the "Tue Feb 11 2025" format (like "Tue Feb 11 2025 00:00:00 GMT+0530")
  //   const dateObject = new Date(dateString);
  //   if (!isNaN(dateObject.getTime())) {
  //     return dateObject.toISOString().split('T')[0]; // Format to YYYY-MM-DD
  //   }
  
  //   // If none of the above formats work, return an error or null
  //   console.error("Invalid date format:", dateString);
  //   return null;
  // }