import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { MdLogout } from "react-icons/md";
import BlockIcon from "@mui/icons-material/Block";
import TableChartIcon from "@mui/icons-material/TableChart";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import ConfirmationModal from "../pages/L1/ConfirmationModal";
import { useLead } from "../../context/LeadReport";
import "../pages/L1/Dashboard.css";
import L1Sidebar from "./L1/L1Sidebar";
import HistoricalDataL1 from "./L1/Dashboard/HistoricalDataL1";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ValidDate } from "../Utils/DateUtils";
import ConversionRateL1 from "./L1/Dashboard/ConversionRateL1";
import LeadSourceL1 from "./L1/Dashboard/LeadSourceL1";
import PotentialStatusL1 from "./L1/Dashboard/PotentialStatusL1";
import ProbabilityStatusL1 from "./L1/Dashboard/ProbabilityStatusL1";
import { FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { logoutSession } from "../../redux/slices/sessionSlice";
import { deleteLead, fetchLeads } from "../../redux/slices/leadsSlice";
import FollowupTableL1 from "./L1/Dashboard/FollowupTableL1";
import { fetchGroups } from "../../redux/slices/groupSlice";
import {
  setGroupFilterRedux,
  setClientFilterRedux,
  setSelectedYearsRedux,
  setPotentialFromDateRedux,
  setPotentialToDateRedux,
  setPotentialStatusRedux,
  setProbabilityFromDateRedux,
  setProbabilityToDateRedux,
  setSourceFromDateRedux,
  setSourceToDateRedux,
  setSourceRedux,
} from "../../redux/slices/filterSlice";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import { getClientRatingData, getsingleClientRatingData } from "../../redux/slices/clientRatingDashboardSliceL3";
import { setClientRating } from "../../redux/slices/ratingSlice"; // Import the action

const Dashboard = () => {

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [clientName, setclientName] = useState("");
  const [reduxLeads, setReduxLeads] = useState([]);
  const [filteredLeadReports, setFilteredLeadReports] = useState([]);
  const [newLeadReports, setnewLeadReports] = useState([]);
  const [tableLeadReports, setTableLeadReports] = useState([]);
  const [LeadReports, setLeadReports] = useState([]);
  const [GroupNames, setGroupNames] = useState([]);
  const [groupName, setgroupName] = useState("");
  const dispatch = useDispatch();

  const { group } = useSelector((state) => state.group);
  const { leads } = useSelector((state) => state.leads);
  const clientRating = useSelector((state) => state.ratingsfilter?.clientRatings || "0");
   console.log("this is the clientRating",clientRating)

  //  useEffect(() => {
  //   dispatch(setClientRating())
  //  },[dispatch])

  useEffect(() => {
    if (clientRating !== undefined && clientRating !== null) {
      setClientData( clientRating );
    }
  }, [clientRating, clientName, groupName, dispatch]);

  
  useEffect(() => {
    dispatch(fetchLeads());
    dispatch(fetchGroups());
  }, [dispatch]);

  useEffect(() => {
    setGroupNames(group);
  }, [group]);

  useEffect(() => {
    const leadsCopy = [...leads]; // ✅ Create a copy before modifying
    setReduxLeads(leadsCopy);
    setLeadReports(leadsCopy);
  }, [leads]);

  useEffect(() => {
    if (!reduxLeads || reduxLeads.length === 0) return;

    const currentYear = new Date().getFullYear();
    const twoYearsAgo = currentYear - 2;

    const normalizedData = reduxLeads
      .filter((lead) => lead.LeadDate) // ✅ Ensure LeadDate exists
      .map((lead) => ({
        ...lead,
        LeadDate: ValidDate(lead.LeadDate),
      }))
      .filter((lead) => {
        const leadYear = new Date(lead.LeadDate).getFullYear();
        return (
          leadYear >= twoYearsAgo &&
          (lead.Status === "Pending" || lead.Status === "Won Recurring")
        );
      });

    setFilteredLeadReports(normalizedData);
    setTableLeadReports(normalizedData);
  }, [reduxLeads]);



  const logout = (e) => {
    e.preventDefault();
    setshowlogout(!showLogout);
  };

  const logoutUser = (e) => {
    e.preventDefault();
    dispatch(logoutSession());
    navigate("/");
    enqueueSnackbar("Successfully Logged out!", {
      variant: "success",
      autoHideDuration: 1000,
    });
  };

  const dropdownRef = useRef(null);
  const dropdownRefGroup = useRef(null);
  const dropdownRefclient = useRef(null);

  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [showClientDropdown, setShowClientDropdown] = useState(false);
  const [showGroupDropdown, setShowGroupDropdown] = useState(false);

  const [selectedYears, setSelectedYears] = useState([]); // Store selected years
  console.log('selectedYears dashfilter',selectedYears)

  const currentYear = new Date().getFullYear();
  const name = localStorage.getItem("name");

  const years = useMemo(
    () => [
      currentYear,
      currentYear - 1,
      currentYear - 2,
      currentYear - 3,
      currentYear - 4,
    ],
    [currentYear]
  );

  
  const [LeadCount, setLeadCount] = useState(0);
  const [totalQuotedValue, setTotalQuotedValue] = useState(0);
  const [wonLeadCount, setWonLeadCount] = useState(0);
  const [wonTotalQuotedValue, setWonTotalQuotedValue] = useState(0);
  const [wonRecurringLeadCount, setWonRecurringLeadCount] = useState(0);
  const [wonRecurringTotalQuotedValue, setWonRecurringTotalQuotedValue] =
    useState(0);
  const [pendingLeadCount, setPendingLeadCount] = useState(0);
  const [pendingTotalQuotedValue, setPendingTotalQuotedValue] = useState(0);
  const [lostLeadCount, setLostLeadCount] = useState(0);
  const [lostTotalQuotedValue, setLostTotalQuotedValue] = useState(0);

  
  const [clientFilter, setClientFilter] = useState("");
    const [groupFilter, setGroupFilter] = useState("");

  const [, setTodaysDate] = useState("");
  const [showLogout, setshowlogout] = useState(false);
  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setshowlogout(false); // Close the popup if clicked outside
    }
  };
  useEffect(() => {
    if (showLogout) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showLogout]);

  useEffect(() => {
    // Update the count of filtered leads
    setLeadCount(filteredLeadReports.length);

    // Calculate the total QuotedValue
    const total = filteredLeadReports.reduce(
      (sum, lead) => sum + (lead.QuotedValue || 0),
      0
    );
    setTotalQuotedValue(total);

    // Filter and calculate count and total for leads with "Won" status
    const wonLeads = filteredLeadReports.filter(
      (lead) => lead.Status === "Won"
    );
    setWonLeadCount(wonLeads.length);

    const wonTotal = wonLeads.reduce(
      (sum, lead) => sum + (lead.QuotedValue || 0),
      0
    );
    setWonTotalQuotedValue(wonTotal);

    const wonRecurringLeads = filteredLeadReports.filter(
      (lead) => lead.Status === "Won Recurring"
    );
    setWonRecurringLeadCount(wonRecurringLeads.length);

    const wonRecurringTotal = wonRecurringLeads.reduce(
      (sum, lead) => sum + (lead.QuotedValue || 0),
      0
    );
    setWonRecurringTotalQuotedValue(wonRecurringTotal);

    const pendingLeads = filteredLeadReports.filter(
      (lead) => lead.Status === "Pending"
    );
    setPendingLeadCount(pendingLeads.length);

    const pendingTotal = pendingLeads.reduce(
      (sum, lead) => sum + (lead.QuotedValue || 0),
      0
    );
    setPendingTotalQuotedValue(pendingTotal);

    const lostLeads = filteredLeadReports.filter(
      (lead) => lead.Status === "Lost"
    );
    setLostLeadCount(lostLeads.length);

    const lostTotal = lostLeads.reduce(
      (sum, lead) => sum + (lead.QuotedValue || 0),
      0
    );
    setLostTotalQuotedValue(lostTotal);
  }, [filteredLeadReports]);

  const { setDashboardReport_11 } = useLead();

  useEffect(() => {}, [tableLeadReports]);

  const deleteLeadReport = async (id) => {
    try {
      const resultAction = await dispatch(deleteLead(id)); // Dispatch Redux thunk

      if (deleteLead.fulfilled.match(resultAction)) {
        enqueueSnackbar("Leadreport deleted successfully!", { variant: "success" });
        setFilteredLeadReports((prevReports) =>
          prevReports.filter((report) => report.id !== id)
        );
      } else {
        enqueueSnackbar("Failed to delete leadreport!", { variant: "error" });
      }
    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar("Connection Error!", { variant: "error" });
    }
  };

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setTodaysDate(today);
  }, []);

  useEffect(() => {
    setTableLeadReports(filteredLeadReports);
  }, [filteredLeadReports]);

  // Get filter value from Redux store
  const groupFilterRedux = useSelector((state) => state.filters.groupFilter);
  const ClientFilterRedux = useSelector((state) => state.filters.clientFilter);
  const YearsFilterRedux = useSelector((state) => state.filters.selectedYears);
  console.log('groupFilterRedux dashfilter',groupFilterRedux)
  console.log('ClientFilterRedux dashfilter',ClientFilterRedux)
console.log('YearsFilterRedux dashfilter',YearsFilterRedux)

  useEffect(() => {
    if (groupFilterRedux) {
      setGroupFilter(groupFilterRedux);
      setgroupName(groupFilterRedux);
    }
  }, [groupFilterRedux, dispatch]);

  useEffect(() => {
    if (ClientFilterRedux !== undefined && ClientFilterRedux !== null) {
      setclientName(ClientFilterRedux);
      setClientFilter(ClientFilterRedux);
    }
  }, [ClientFilterRedux]);

  useEffect(() => {
    if (YearsFilterRedux) {
      setSelectedYears(YearsFilterRedux);
    }
  }, [YearsFilterRedux]);

  useEffect(() => {
    let filteredData = [...LeadReports];
    if (clientFilter && clientFilter.trim() !== "") {
      filteredData = filteredData.filter(
        (lead) =>
          lead.ClientName.toLowerCase().trim() === clientFilter.toLowerCase().trim()
      );
    }
    if (selectedYears && selectedYears.length > 0) {
      filteredData = filteredData.filter((lead) => {
        if (!lead.LeadDate) return false; // Skip if no LeadDate

        // Ensure date conversion works
        const leadYear = new Date(lead.LeadDate).getFullYear();

        return selectedYears.includes(leadYear);
      });
    }
    if (groupFilter && groupFilter.trim() !== "") {
      filteredData = filteredData.filter((lead) =>
        lead.AssignedGroup.toLowerCase().includes(groupFilter.toLowerCase())
      );
    }
    if (groupFilter === "All") {
      setGroupFilter("");
      setgroupName("");
      // setclientName("");
      // setClientFilter("");
      // setSelectedYears([]);
      // dispatch(setSelectedYearsRedux("")); // Reset client filter in Redux as well
    }
    if (clientFilter === "All") {
      // setgroupName("");
      setclientName("");
      setClientFilter("");
      // setSelectedYears([]);
      // dispatch(setSelectedYearsRedux("")); // Reset client filter in Redux as well
    }
    setFilteredLeadReports(filteredData); // Update a new state variable for filtered data
  }, [selectedYears, LeadReports, clientFilter, groupFilter, dispatch]);

  useEffect(() => {
    let filteredData = [...LeadReports];

    if (clientFilter && clientFilter.trim() !== "") {
      filteredData = filteredData.filter(
        (lead) =>
          lead.ClientName.toLowerCase().trim() === clientFilter.toLowerCase().trim()
      );
    }
    if (groupFilter && groupFilter.trim() !== "") {
      filteredData = filteredData.filter((lead) =>
        lead.AssignedGroup.toLowerCase().includes(groupFilter.toLowerCase())
      );
    }
    if (groupFilter === "All") {
      setGroupFilter("");
      setgroupName("");
      // setclientName("");
      // setClientFilter("");
      // setSelectedYears([]);
      // dispatch(setSelectedYearsRedux("")); // Reset client filter in Redux as well
    }
    if (clientFilter === "All") {
      // setgroupName("");
      setclientName("");
      setClientFilter("");
      // setSelectedYears([]);
      // dispatch(setSelectedYearsRedux("")); // Reset client filter in Redux as well
    }
    setnewLeadReports(filteredData); // Update a new state variable for filtered data
  }, [selectedYears, LeadReports, clientFilter, groupFilter, dispatch]);

  useEffect(() => {
  }, [newLeadReports,filteredLeadReports]);

  

  const handleInputChange = useCallback( 
    (groupFilter) => {
      setgroupName(groupFilter);
      setGroupFilter(groupFilter);
      setclientName("");
      setClientFilter("");
      setClientData("");
      setSelectedYears([]);
      setShowGroupDropdown(false);
      
      dispatch(setGroupFilterRedux(groupFilter));
      dispatch(setClientFilterRedux(""))
      dispatch(setSelectedYearsRedux([])); // Reset client filter in Redux as well

      dispatch(setClientRating({
        starRating: "0",
      }))

      dispatch(setPotentialFromDateRedux(""));
      dispatch(setPotentialToDateRedux(""))
      dispatch(setPotentialStatusRedux("All"))

      dispatch(setProbabilityFromDateRedux(""));
      dispatch(setProbabilityToDateRedux(""));

      dispatch(setSourceFromDateRedux(""));
      dispatch(setSourceToDateRedux(""));
      dispatch(setSourceRedux("All"));

      // dispatch(setHistoricalYearRedux([])); // Update Redux state
      // dispatch(setHistoricalYearRedux({}));
      
    },
    [dispatch, setGroupFilter, setclientName, setgroupName] // Added missing dependencies
  );
 
  const handleClientChange = useCallback(
    (clientValue) => {
      setclientName(clientValue);
      setClientFilter(clientValue);
      setSelectedYears([]);
      setShowClientDropdown(false);
      dispatch(setClientFilterRedux(clientValue));

      dispatch(setPotentialFromDateRedux(""));
      dispatch(setPotentialToDateRedux(""))
      dispatch(setPotentialStatusRedux("All"))

      dispatch(setProbabilityFromDateRedux(""));
      dispatch(setProbabilityToDateRedux(""));

      dispatch(setSourceFromDateRedux(""));
      dispatch(setSourceToDateRedux(""));
      dispatch(setSourceRedux("All"))

      // dispatch(setHistoricalYearRedux([])); // Update Redux state
      // dispatch(setHistoricalYearRedux([]));

    },
    [setClientFilter, setclientName, dispatch]
  );

  const handleYearSelection = (event) => {
    const selectedValue = parseInt(event.target.value);
  
    setSelectedYears((prev) => {
      const updatedYears = event.target.checked
        ? [...prev, selectedValue] // Add year if checked
        : prev.filter((yr) => yr !== selectedValue); // Remove year if unchecked
  
      dispatch(setSelectedYearsRedux(updatedYears)); // Dispatch the updated array to Redux store

      dispatch(setPotentialFromDateRedux(""));
      dispatch(setPotentialToDateRedux(""))
      dispatch(setPotentialStatusRedux("All"))

      dispatch(setProbabilityFromDateRedux(""));
      dispatch(setProbabilityToDateRedux(""));

      dispatch(setSourceFromDateRedux(""));
      dispatch(setSourceToDateRedux(""));
      dispatch(setSourceRedux("All"))

      // dispatch(setHistoricalYearRedux([])); // Update Redux state
      // dispatch(setHistoricalYearRedux([]));

      return updatedYears;
    });
  };

  const handleAllLeads = useCallback(
    (clickedDashStatus) => {
      let AllLeadsL1;
      if (clickedDashStatus === "All") {
        AllLeadsL1 = filteredLeadReports; // Show all data
      } else {
        AllLeadsL1 = filteredLeadReports.filter(
          (item) => item.Status === clickedDashStatus
        );
      }
      setDashboardReport_11(AllLeadsL1);
      navigate("/dashboard/L1/leadreport-11");
    },
    [filteredLeadReports, navigate, setDashboardReport_11]
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [LeadToDelete, setLeadToDelete] = useState(null);

  const closeDeleteModal = () => {
    setIsModalOpen(false);
    setLeadToDelete(null);
  };

  const confirmDelete = async () => {
    if (LeadToDelete) {
      await deleteLeadReport(LeadToDelete); // Call your existing delete function
    }
    closeDeleteModal();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowYearDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefGroup.current &&
        !dropdownRefGroup.current.contains(event.target)
      ) {
        setShowGroupDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefclient.current &&
        !dropdownRefclient.current.contains(event.target)
      ) {
        setShowClientDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {}, [clientName, groupName]);

  const [clientData, setClientData] = useState({
    starRating: "0",
  });


  // useEffect(() => {
  //   const fetchLead = async () => {
  //     try {
  //       let response;
  //       if (clientName && !groupName) {
  //         response = await dispatch(getsingleClientRatingData(clientName)).unwrap();
  //       } else if (clientName && groupName) {
  //         response = await dispatch(getClientRatingData({ clientName, groupName })).unwrap();
  //       } else {
  //         return;
  //       }

  //       if (response.message === "No ratings found") {
  //         setClientData({ starRating: 0 });
  //         dispatch(setClientRating({ clientName, rating: 0 })); // Store in Redux
  //         return;
  //       }

  //       if (Array.isArray(response)) {
  //         const totalRatingSum = response.reduce((acc, curr) => acc + (curr.TotalRating || 0), 0);
  //         const averageRating = totalRatingSum / response.length;

  //         setClientData({ starRating: averageRating });
  //         dispatch(setClientRating({ clientName, rating: averageRating })); // Store in Redux
  //       }
  //     } catch (error) {
  //       console.error("Error fetching lead data:", error);
  //       setClientData({ starRating: 0 });
  //       dispatch(setClientRating({ clientName, rating: 0 })); // Store in Redux
  //     }
  //   };

  //   fetchLead();
  // }, [clientName, GroupNames, groupName, dispatch]);

  useEffect(() => {
    const fetchLead = async () => {
      try {
        let response;
        if (clientName && !groupName) {
          response = await dispatch(getsingleClientRatingData(clientName)).unwrap();
        } else if (clientName && groupName) {
          response = await dispatch(getClientRatingData({ clientName, groupName })).unwrap();
        } else {
          return;
        }
  
        if (response.message === "No ratings found") {
          setClientData({ starRating: "0" });
          dispatch(setClientRating({ starRating: "0" })); // Store in Redux
          return;
        }
  
        if (Array.isArray(response)) {
          const totalRatingSum = response.reduce((acc, curr) => acc + (curr.TotalRating || 0), 0);
          const averageRating = (totalRatingSum / response.length).toFixed(1); // Keep 1 decimal place
  
          setClientData({ starRating: averageRating });
          dispatch(setClientRating({ starRating: averageRating })); // Store in Redux
        }
      } catch (error) {
        console.error("Error fetching lead data:", error);
        setClientData({ starRating: "0" });
        dispatch(setClientRating({ starRating: "0" })); // Store in Redux
      }
    };
  
    fetchLead();
  }, [clientName, groupName, dispatch]); // Removed GroupNames
  

  interface StarRatingProps {
    rating?: number;
  }
 const StarRating: React.FC<StarRatingProps> = ({ rating }) => {
     console.log("StarRating received rating:", rating); // Debugging
 
     if (rating === undefined || rating === null) return null; // Prevent rendering if rating is invalid
 
     // Normalize the rating to a 5-star system
     const normalizedRating = rating > 5 ? (rating / 10) * 5 : rating;
     const safeRating = Math.min(Math.max(normalizedRating, 0), 5); // Ensure within range
 
     const fullStars = Math.floor(safeRating);
     const decimalPart = safeRating - fullStars; // Extract decimal part
     const hasHalfStar = decimalPart >= 0.5; // Show half-star if decimal part is 0.5 or more
     const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0); // Ensure total is 5
 
     return (
       <div className="flex items-center text-yellow-500 text-3xl">
         {Array(fullStars)
           .fill(null)
           .map((_, i) => (
             <FaStar key={i} />
           ))}
         {hasHalfStar && <FaStarHalfAlt key="half-star" />}
         {Array(emptyStars)
           .fill(null)
           .map((_, i) => (
             <FaRegStar key={`empty-${i}`} />
           ))}
       </div>
     );
   };
   const getUniqueClients = () => {
    let filtered = [];
  
    if (groupFilter && groupFilter !== "All") {
      filtered = LeadReports.filter(
        (lead) => lead.AssignedGroup === groupFilter
      );
    } else if (groupFilter === "All") {
      filtered = [...LeadReports];
    } else {
      filtered = [...LeadReports]; // Default to all
    }
  
    const uniqueClientsMap = new Map();
  
    filtered.forEach((lead) => {
      const key = lead.ClientName.toLowerCase().trim();
      if (!uniqueClientsMap.has(key)) {
        uniqueClientsMap.set(key, lead.ClientName); // or lead, if you want more data
      }
    });
  
    return Array.from(uniqueClientsMap.values());
  };
  

  return (
    <>
      <div className="h-screen overflow-y-scroll bg-[#f3f8f8]">
        <ConfirmationModal
          isOpen={isModalOpen}
          onClose={closeDeleteModal}
          onConfirm={confirmDelete}
        />
        <L1Sidebar />
        <div className="pl-[18%] pt-5 pr-2">
          <div className=" row-span-1 col-start-2 col-span-1">
            <div className=" p-4  bg-white w-full flex items-center rounded-lg top-4">
              <div className="flex flex-col w-full">
                <div className="relative flex w-full items-center justify-between space-x-2">
                  <div className="flex items-center space-x-2">
                    <div className="flex gap-2 align-middle items-center scale-90">
                      <h1 className="font-semibold ">GROUP</h1>

                      <div className=" ">
                        <div
                          ref={dropdownRefGroup}
                          className="relative flex  border p-0  rounded-md  align-middle items-center"
                        >
                          <ExpandMoreIcon
                            color="white"
                            className="absolute cursor-pointer right-2 m-auto bg-white"
                            onClick={() =>
                              setShowGroupDropdown(!showGroupDropdown)
                            }
                          />

                          <button
                            type="button"
                            onClick={() =>
                              setShowGroupDropdown(!showGroupDropdown)
                            }
                            className=" text-sm text-left rounded-md  outline-none text-nowrap truncate p-2 w-40"
                          >
                            {groupFilter ? groupFilter : <p>Select Group</p>}
                          </button>

                          {showGroupDropdown && (
                            <div
                              style={{ zIndex: 9999 }}
                              className="absolute top-full max-w-60  left-0 w-full max-h-64 cursor-pointer overflow-y-auto bg-gray-100 text-sm border border-gray-300 shadow-lg flex flex-col"
                            >
                              <div
                                className="p-2 hover:bg-gray-200 cursor-pointer"
                                onClick={() => handleInputChange("All")}
                              >
                                All
                              </div>
                              {GroupNames.map((group) => (
                                <div
                                  className="p-2 hover:bg-gray-200 text-wrap cursor-pointer"
                                  onClick={() =>
                                    handleInputChange(group.newGroupName)
                                  }
                                  key={group.id}
                                  value={group.newGroupName}
                                >
                                  {group.newGroupName}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-2 align-middle items-center scale-90">
                      <h1 className="font-semibold">CLIENT</h1>

                      <div className="relative">
                        <div className="flex  p-0 border rounded-md align-middle items-center">
                          <ExpandMoreIcon
                            className="absolute right-1 cursor-pointer bg-white"
                            onClick={() =>
                              setShowClientDropdown(!showClientDropdown)
                            }
                          />

                          <button
                            type="button"
                            onClick={() =>
                              setShowClientDropdown(!showClientDropdown)
                            }
                            className=" text-left text-sm outline-none p-2 text-nowrap truncate w-40"
                          >
                            {clientFilter ? clientFilter : <p>Select Client</p>}
                          </button>
                        </div>

                        {showClientDropdown && (
                          <div
                            ref={dropdownRefclient}
                            style={{ zIndex: 9999 }}
                            className="absolute top-full left-0 w-full max-h-64 cursor-pointer overflow-y-auto bg-gray-100 text-sm border border-gray-300 shadow-lg"
                          >
                            <p
                              className="p-2 hover:bg-gray-200"
                              onClick={() => handleClientChange("All")}
                            >
                              All
                            </p>
                            {getUniqueClients().map((clientName, idx) => (
                              <p
                                className="p-2 hover:bg-gray-200"
                                onClick={() => handleClientChange(clientName)}
                                key={idx}
                              >
                                {clientName}
                              </p>
                            ))}
                          </div>
                        )}

                      </div>
                    </div>
                    {/* Year SELECTOR */}
                    <div className=" flex gap-2 align-middle items-center scale-90">
                      <h1 className="font-semibold">YEAR</h1>

                      <div className=" ">
                        <div
                          ref={dropdownRef}
                          className="relative flex  p-0  rounded-md border  align-middle items-center"
                        >
                          <ExpandMoreIcon
                            color="white"
                            className="absolute right-0 border-r border-gray-300 m-auto cursor-pointer bg-white"
                            onClick={() =>
                              setShowYearDropdown(!showYearDropdown)
                            }
                          />

                          <button
                            type="button"
                            onClick={() =>
                              setShowYearDropdown(!showYearDropdown)
                            }
                            className=" text-left text-sm rounded-md  outline-none p-2 text-nowrap overflow-hidden w-40"
                          >
                            {selectedYears.length > 0
                              ? selectedYears.join(", ")
                              : "Select Year"}
                          </button>

                          {showYearDropdown && (
                            <div
                              style={{ zIndex: 9999 }}
                              className="absolute -bottom-44 w-full bg-gray-100 text-sm border border-gray-300 shadow-lg"
                            >
                              {years.map((year) => (
                                <label
                                  key={year}
                                  className="flex items-center px-4 py-2 hover:bg-blue-400 cursor-pointer"
                                >
                                  <input
                                    type="checkbox"
                                    value={year}
                                    checked={selectedYears.includes(year)}
                                    onChange={handleYearSelection} // Use function here
                                    className="mr-2"
                                  />
                                  {year}
                                </label>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      {clientData && clientData.starRating > 0 && ( // Only show stars if starRating is greater than 0
                        <>
                          <StarRating rating={clientData.starRating} />
                        </>
                      )}
                    </div>

                  </div>

                  <div className="relative cursor-pointer mr-[2%] bg-transparent">
                    <FaUserCircle
                      onClick={logout}
                      className="text-black w-6 h-6"
                    />
                    {showLogout && (
                      <div
                        ref={popupRef}
                        className="absolute right-0 top-full mt-1 text-black text-center px-2 w-36 shadow-2xl overflow-hidden bg-white border border-gray-400 z-50 rounded-lg"
                      >
                        <h1 className="px-6 py-2 rounded-md my-1">{name}</h1>
                        <button
                          onClick={(e) => logoutUser(e)}
                          className="flex items-center gap-2 px-6 py-2 rounded-md my-1 cursor-pointer hover:bg-black hover:text-white"
                        >
                          <MdLogout size={24} /> Logout
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 py-4">
            <div className="bg-white border shadow-md rounded-lg  ">
              <div
                onClick={() => {
                  handleAllLeads("All");
                }}
                className="flex justify-between items-center rounded-t-lg  bg-[#e32278] text-white text-left p-2"
                style={{ cursor: "pointer" }}
              >
                <h2 className="text-lg font-semibold">All</h2>
                <h1 className="text-2xl font-bold">{LeadCount}</h1>

                <TableChartIcon
                  alt="handshake icon"
                  className="w-12 h-9 bottom-2 right-2 "
                />
              </div>
              <div className="p-4 flex justify-center items-center">
                <div className="flex-col">
                  <div className="flex justify-center items-center gap-4">
                    <p className="text-lg mt-2 text-center font-bold">
                      $
                      {totalQuotedValue
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white border rounded-lg shadow-md overflow-hidden ">
              <div
                onClick={() => {
                  handleAllLeads("Won");
                }}
                className=" flex justify-between items-center bg-[#157f3d] text-white text-left p-2"
                style={{ cursor: "pointer" }}
              >
                <h2 className="text-lg font-semibold">Won</h2>
                <h1 className="text-2xl font-bold">{wonLeadCount}</h1>
                <EmojiEventsIcon
                  alt="handshake icon"
                  className="w-12 h-9 bottom-2 right-2"
                />
              </div>
              <div className="p-4 flex justify-center items-center">
                <div className="flex-col">
                  <div className="flex gap-4">
                    <p className="text-lg mt-2 font-bold">
                      $
                      {wonTotalQuotedValue
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white border rounded-lg shadow-md overflow-hidden ">
              <div
                onClick={() => {
                  handleAllLeads("Won Recurring");
                }}
                className="flex justify-between items-center bg-[#1f41bb] text-white text-left p-2"
                style={{ cursor: "pointer" }}
              >
                <h2 className="text-lg font-semibold">Won Rec</h2>
                <h1 className="text-2xl font-bold">{wonRecurringLeadCount}</h1>
                <PublishedWithChangesIcon
                  alt="handshake icon"
                  className="w-12 h-9 bottom-2 right-2"
                />
              </div>
              <div className="p-4 flex justify-center items-center">
                <div className="flex-col">
                  <div className="flex gap-4">
                    <p className="text-lg mt-2 font-bold">
                      $
                      {wonRecurringTotalQuotedValue
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white border rounded-lg shadow-md overflow-hidden ">
              <div
                onClick={() => {
                  handleAllLeads("Pending");
                }}
                className="flex justify-between items-center bg-[#ff9a00] text-white text-left p-2"
                style={{ cursor: "pointer" }}
              >
                <h2 className="text-lg font-semibold">Pending</h2>
                <h1 className="text-2xl font-bold">{pendingLeadCount}</h1>

                <PendingActionsIcon
                  alt="handshake icon"
                  className="w-12 h-9 bottom-2 right-2"
                />
              </div>
              <div className="p-4 flex justify-center items-center">
                <div className="flex-col">
                  <div className="flex gap-4">
                    <p className="text-lg mt-2 font-bold">
                      $
                      {pendingTotalQuotedValue
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white border rounded-lg shadow-md overflow-hidden ">
              <div
                onClick={() => {
                  handleAllLeads("Lost");
                }}
                className="flex justify-between items-center bg-[#ba1c1d] text-white text-left p-2"
                style={{ cursor: "pointer" }}
              >
                <h2 className="text-lg font-semibold">Lost</h2>
                <h1 className="text-2xl font-bold">{lostLeadCount}</h1>
                <BlockIcon
                  alt="handshake icon"
                  className="w-9 h-7  bottom-2 right-2"
                />
              </div>
              <div className="p-4 flex justify-center items-center">
                <div className="flex-col">
                  <div className="flex gap-4">
                    <p className="text-lg mt-2 font-bold">
                      $
                      {lostTotalQuotedValue
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <FollowupTableL1 filteredLeadReports={filteredLeadReports} />

          <div className="flex gap-3 py-2 h-[30rem]">
            <div
              className="px-3 relative pb-3 bg-white rounded-lg w-1/2 h-full justify-center top-4 "
              style={{ fontFamily: "Lora", fontWeight: 600 }}
            >
              <PotentialStatusL1 filteredLeadReports={filteredLeadReports} />
            </div>
            <div
              className="px-3 relative bg-white rounded-lg w-1/2 h-full justify-center top-4 "
              style={{ fontFamily: "Lora", fontWeight: 600 }}
            >
              <ProbabilityStatusL1 filteredLeadReports={filteredLeadReports} />
            </div>
          </div>
          <div className="flex gap-3 py-2 h-[30rem] ">
            <div
              className="px-3 relative pb-3 bg-white rounded-lg  w-1/2 justify-center top-4 "
              style={{ fontFamily: "Lora", fontWeight: 600 }}
            >
              <LeadSourceL1 filteredLeadReports={filteredLeadReports} />
            </div>
            <div
              className="px-3 relative bg-white rounded-lg  w-1/2 justify-center top-4 "
              style={{ fontFamily: "Lora", fontWeight: 600 }}
            >
              <ConversionRateL1 newLeadReports={newLeadReports} />
            </div>
          </div>

          <div className="pt-12">
            <div
              className="w-full "
              style={{ fontFamily: "Lora", fontWeight: 600 }}
            >
              <HistoricalDataL1 filteredLeadReports={filteredLeadReports} newLeadReports={newLeadReports} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
