import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../routes/axiosConfig";

// ** Fetch Leads **
export const fetchLeads = createAsyncThunk("leads/fetchLeads", async () => {
  const response = await axiosInstance.get("/leads");
  return response.data;
});

// ** Add Lead **
export const addLead = createAsyncThunk("leads/addLead", async (leadData) => {
  const response = await axiosInstance.post("/leads", leadData);
  return response.data;
});

// ** Update Lead **
export const updateLead = createAsyncThunk("leads/updateLead", async ({ id, updatedData }) => {
  const response = await axiosInstance.put(`/leads/${id}`, updatedData);
  return response.data;
});

// ** Delete Lead **
export const deleteLead = createAsyncThunk("leads/deleteLead", async (id) => {
  await axiosInstance.delete(`/leads/${id}`);
  return id;
});

// ** Define the slice **
const leadSlice = createSlice({
  name: "leads",
  initialState: {
    leads: [],
    loading: false,
    error: null,
  },
  reducers: {}, 
  extraReducers: (builder) => {
    builder
      // Fetch Leads
      .addCase(fetchLeads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLeads.fulfilled, (state, action) => {
        state.loading = false;
        state.leads = action.payload;
      })
      .addCase(fetchLeads.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Add Lead
      .addCase(addLead.fulfilled, (state, action) => {
        state.leads.push(action.payload);
      })

      // Update Lead
      .addCase(updateLead.fulfilled, (state, action) => {
        const index = state.leads.findIndex((lead) => lead.id === action.payload.id);
        if (index !== -1) {
          state.leads[index] = action.payload;
        }
      })

      // Delete Lead
      .addCase(deleteLead.fulfilled, (state, action) => {
        state.leads = state.leads.filter((lead) => lead.id !== action.payload);
      });
  },
});

export default leadSlice.reducer;
