import { createSlice } from "@reduxjs/toolkit";

const initialState = {
// dashboardL1
  groupFilter: "",
  clientFilter: "",
  selectedYears: [],
//potentialL1
  statusFilterDateFrom: "",
  statusFilterToFrom: "",
  statusFilter: "All",
//ProbabilityL1
  probabilityFilterDateFrom: "",
  probabilityFilterToFrom: "",
  probabilityGroupFilter: "",
//LeadSourceL1
  sourceFilterDateFrom: "",
  sourceFilterToDate: "",
  sourceFilter: "All",
//HistoricalL1 
  selectedYearsHistorical: [],
  selectedStatusHistorical: [],
//MeanL1
  selectedMeanGroup: "",
  selectedMeanStatus: "",
//LeadreportL1
  fromDateLeadReport: "",
  toDateLeadReport: "",
  statusLeadReport:[],
  GroupLeadReport: "",


// dashboardL2
  groupFilterL2: "",
  clientFilterL2: "",
  selectedYearsL2: [],
//potentialL2
  statusFilterDateFromL2: "",
  statusFilterToFromL2: "",
  statusFilterL2: "All",
//ProbabilityL2
  probabilityFilterDateFromL2: "",
  probabilityFilterToFromL2: "",
  probabilityGroupFilterL2: "",
//LeadSourceL2
  sourceFilterDateFromL2: "",
  sourceFilterToDateL2: "",
  sourceFilterL2: "All",
//HistoricalL2 
  selectedYearsHistoricalL2: [],
  selectedStatusHistoricalL2: [],
//MeanL2
  selectedMeanGroupL2: [],
  selectedMeanStatusL2: "",
//LeadreportL2
  fromDateLeadReportL2: "",
  toDateLeadReportL2: "",
  statusLeadReportL2:[],
  GroupLeadReportL2: "",

  
// dashboardL3
groupFilterL3: "",
clientFilterL3: "",
selectedYearsL3: [],
//potentialL3
statusFilterDateFromL3: "",
statusFilterToFromL3: "",
statusFilterL3: "All",
//ProbabilityL3
probabilityFilterDateFromL3: "",
probabilityFilterToFromL3: "",
probabilityGroupFilterL3: "",
//LeadSourceL3
sourceFilterDateFromL3: "",
sourceFilterToDateL3: "",
sourceFilterL3: "All",
//HistoricalL3 
selectedYearsHistoricalL3: [],
selectedStatusHistoricalL3: [],
//MeanL3
selectedMeanGroupL3: [],
selectedMeanStatusL3: "",
//LeadreportL3
fromDateLeadReportL3: "",
toDateLeadReportL3: "",
statusLeadReportL3:[],
GroupLeadReportL3: "",

  
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
// dashboardL1
    setGroupFilterRedux: (state, action) => {
      state.groupFilter = action.payload;
    },
    setClientFilterRedux: (state, action) => {
        state.clientFilter = action.payload;
      },
    setSelectedYearsRedux: (state, action) => {
        state.selectedYears = action.payload;
      },
//potentialL1
    setPotentialFromDateRedux: (state, action) => {
        state.statusFilterDateFrom = action.payload;
      },
    setPotentialToDateRedux: (state, action) => {
        state.statusFilterToFrom = action.payload;
      },
    setPotentialStatusRedux: (state, action) => {
        state.statusFilter = action.payload;
      },
//ProbabilityL1
      setProbabilityFromDateRedux: (state, action) => {
        state.probabilityFilterDateFrom = action.payload;
      },
    setProbabilityToDateRedux: (state, action) => {
        state.probabilityFilterToFrom = action.payload;
      },
    setProbabilityGroupRedux: (state, action) => {
        state.probabilityGroupFilter = action.payload;
      },
//LeadSourceL1
      setSourceFromDateRedux: (state, action) => {
        state.sourceFilterDateFrom = action.payload;
      },
    setSourceToDateRedux: (state, action) => {
        state.sourceFilterToDate = action.payload;
      },
    setSourceRedux: (state, action) => {
        state.sourceFilter = action.payload;
      },
//HistoricalL1
      setHistoricalYearRedux: (state, action) => {
        state.selectedYearsHistorical = action.payload;
      },
    setHistoricalStatusRedux: (state, action) => {
        state.selectedStatusHistorical = action.payload; 
      },
//MeanL1
      setMeanGroupRedux: (state, action) => {
        state.selectedMeanGroup = action.payload;
      },
    setMeanStatusRedux: (state, action) => {
        state.selectedMeanStatus = action.payload; 
      },
//LeadreportL1
      setFromDateLeadReportRedux: (state, action) => {
        state.fromDateLeadReport = action.payload;
      },
      setToDateLeadReportRedux: (state, action) => {
        state.toDateLeadReport = action.payload; 
      },
      setStatusLeadReportRedux: (state, action) => {
        state.statusLeadReport = action.payload; 
      },
      setGroupLeadReportRedux: (state, action) => {
        state.GroupLeadReport = action.payload; 
      },


  // dashboardL2
    setGroupFilterReduxL2: (state, action) => {
      state.groupFilterL2 = action.payload;
    },
    setClientFilterReduxL2: (state, action) => {
        state.clientFilterL2 = action.payload;
      },
    setSelectedYearsReduxL2: (state, action) => {
        state.selectedYearsL2 = action.payload;
      },
//potentialL2
    setPotentialFromDateReduxL2: (state, action) => {
        state.statusFilterDateFromL2 = action.payload;
      },
    setPotentialToDateReduxL2: (state, action) => {
        state.statusFilterToFromL2 = action.payload;
      },
    setPotentialStatusReduxL2: (state, action) => {
        state.statusFilterL2 = action.payload;
      },
//ProbabilityL2
      setProbabilityFromDateReduxL2: (state, action) => {
        state.probabilityFilterDateFromL2 = action.payload;
      },
    setProbabilityToDateReduxL2: (state, action) => {
        state.probabilityFilterToFromL2 = action.payload;
      },
    setProbabilityGroupReduxL2: (state, action) => {
        state.probabilityGroupFilterL2 = action.payload;
      },
//LeadSourceL2
      setSourceFromDateReduxL2: (state, action) => {
        state.sourceFilterDateFromL2 = action.payload;
      },
    setSourceToDateReduxL2: (state, action) => {
        state.sourceFilterToDateL2 = action.payload;
      },
    setSourceReduxL2: (state, action) => {
        state.sourceFilterL2 = action.payload;
      },
//HistoricalL2
      setHistoricalYearReduxL2: (state, action) => {
        state.selectedYearsHistoricalL2 = action.payload;
      },
    setHistoricalStatusReduxL2: (state, action) => {
        state.selectedStatusHistoricalL2 = action.payload; 
      },
//MeanL2
      setMeanGroupReduxL2: (state, action) => {
        state.selectedMeanGroupL2 = action.payload;
      },
    setMeanStatusReduxL2: (state, action) => {
        state.selectedMeanStatusL2 = action.payload; 
      },
//LeadreportL2
      setFromDateLeadReportReduxL2: (state, action) => {
        state.fromDateLeadReportL2 = action.payload;
      },
      setToDateLeadReportReduxL2: (state, action) => {
        state.toDateLeadReportL2 = action.payload; 
      },
      setStatusLeadReportReduxL2: (state, action) => {
        state.statusLeadReportL2 = action.payload; 
      },
      setGroupLeadReportReduxL2: (state, action) => {
        state.GroupLeadReportL2 = action.payload; 
      },


      
  // dashboardL3
    setGroupFilterReduxL3: (state, action) => {
      state.groupFilterL3 = action.payload;
    },
    setClientFilterReduxL3: (state, action) => {
        state.clientFilterL3 = action.payload;
      },
    setSelectedYearsReduxL3: (state, action) => {
        state.selectedYearsL3 = action.payload;
      },
//potentialL3
    setPotentialFromDateReduxL3: (state, action) => {
        state.statusFilterDateFromL3 = action.payload;
      },
    setPotentialToDateReduxL3: (state, action) => {
        state.statusFilterToFromL3 = action.payload;
      },
    setPotentialStatusReduxL3: (state, action) => {
        state.statusFilterL3 = action.payload;
      },
//ProbabilityL3
      setProbabilityFromDateReduxL3: (state, action) => {
        state.probabilityFilterDateFromL3 = action.payload;
      },
    setProbabilityToDateReduxL3: (state, action) => {
        state.probabilityFilterToFromL3 = action.payload;
      },
    setProbabilityGroupReduxL3: (state, action) => {
        state.probabilityGroupFilterL3 = action.payload;
      },
//LeadSourceL3
      setSourceFromDateReduxL3: (state, action) => {
        state.sourceFilterDateFromL3 = action.payload;
      },
    setSourceToDateReduxL3: (state, action) => {
        state.sourceFilterToDateL3 = action.payload;
      },
    setSourceReduxL3: (state, action) => {
        state.sourceFilterL3 = action.payload;
      },
//HistoricalL3
      setHistoricalYearReduxL3: (state, action) => {
        state.selectedYearsHistoricalL3 = action.payload;
      },
    setHistoricalStatusReduxL3: (state, action) => {
        state.selectedStatusHistoricalL3 = action.payload; 
      },
//MeanL3
      setMeanGroupReduxL3: (state, action) => {
        state.selectedMeanGroupL3 = action.payload;
      },
    setMeanStatusReduxL3: (state, action) => {
        state.selectedMeanStatusL3 = action.payload; 
      },
//LeadreportL3
      setFromDateLeadReportReduxL3: (state, action) => {
        state.fromDateLeadReportL3 = action.payload;
      },
      setToDateLeadReportReduxL3: (state, action) => {
        state.toDateLeadReportL3 = action.payload; 
      },
      setStatusLeadReportReduxL3: (state, action) => {
        state.statusLeadReportL3 = action.payload; 
      },
      setGroupLeadReportReduxL3: (state, action) => {
        state.GroupLeadReportL3 = action.payload; 
      },


  },
});

export const { 
  
    setGroupFilterRedux,setClientFilterRedux, setSelectedYearsRedux,
    setPotentialFromDateRedux, setPotentialToDateRedux, setPotentialStatusRedux,
    setProbabilityFromDateRedux,setProbabilityToDateRedux, setProbabilityGroupRedux,
    setSourceFromDateRedux, setSourceToDateRedux, setSourceRedux,
    setHistoricalYearRedux, setHistoricalStatusRedux,
    setMeanGroupRedux, setMeanStatusRedux,
    setFromDateLeadReportRedux, setToDateLeadReportRedux,setStatusLeadReportRedux, setGroupLeadReportRedux,

    setGroupFilterReduxL2,setClientFilterReduxL2, setSelectedYearsReduxL2,
    setPotentialFromDateReduxL2, setPotentialToDateReduxL2, setPotentialStatusReduxL2,
    setProbabilityFromDateReduxL2, setProbabilityToDateReduxL2, setProbabilityGroupReduxL2,
    setSourceFromDateReduxL2, setSourceToDateReduxL2, setSourceReduxL2,
    setHistoricalYearReduxL2, setHistoricalStatusReduxL2,
    setMeanGroupReduxL2, setMeanStatusReduxL2,
    setFromDateLeadReportReduxL2, setToDateLeadReportReduxL2,setStatusLeadReportReduxL2, setGroupLeadReportReduxL2,

    setGroupFilterReduxL3,setClientFilterReduxL3, setSelectedYearsReduxL3,
    setPotentialFromDateReduxL3, setPotentialToDateReduxL3, setPotentialStatusReduxL3,
    setProbabilityFromDateReduxL3, setProbabilityToDateReduxL3, setProbabilityGroupReduxL3,
    setSourceFromDateReduxL3, setSourceToDateReduxL3, setSourceReduxL3,
    setHistoricalYearReduxL3, setHistoricalStatusReduxL3,
    setMeanGroupReduxL3, setMeanStatusReduxL3,
    setFromDateLeadReportReduxL3, setToDateLeadReportReduxL3, setStatusLeadReportReduxL3, setGroupLeadReportReduxL3

 } = filterSlice.actions;
export default filterSlice.reducer;
